.price-box {
  width: 170px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.price-box-title {
  width: 100%;
}

.price-box-title .label {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  letter-spacing: -0.5px;
}

.price-box.disabled .price-box-title .label {
  color: #b5b5b5;
}

.price-box.invalid .price-box-title .label {
  color: var(--main-red);
}

.price-box-title .helper {
  font-size: 11px;
  color: var(--font-primary);
  margin-left: 3px;
  font-weight: 500;
}

.price-box.disabled .helper {
  color: #b5b5b5;
}

.price-box.invalid .helper {
  color: var(--main-red);
}

.price-box-content {
  width: 100%;
  height: 35px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 0 2px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  position: relative;
}

.price-box.disabled .price-box-content {
  border: 1px solid #e8e8e8;
}

.price-box.invalid .price-box-content {
  border: 1px solid var(--main-red);
}

.price-box-content .price-box-value {
  /* height: 100%; */
  width: 80%;
}

.price-box-content .price-box-value input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: none;
  padding-left: 10px;
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
  background-color: transparent;
}

.price-box-content .price-box-value input::-webkit-outer-spin-button,
.price-box-content .price-box-value input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.price-box.disabled .price-box-content .price-box-value input {
  color: #b5b5b5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  cursor: default;
  background-color: transparent;
  -moz-appearance: textfield;
}

.price-box-content .price-box-value > span {
  padding-left: 10px;
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
}

.price-box.disabled .price-box-content .price-box-value > span {
  color: #b5b5b5;
}

.price-box-options {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-box-calculator {
  position: absolute;
  right: 0;
  top: 105%;
  width: 60%;
  height: 120px;
  background-color: var(--white);
  z-index: 10000;
  border-radius: 3px;
  box-shadow: 1px 3px 5px #e8e8e8;
  display: flex;
}

.price-box-calculator .calculator-col {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #e8e8e8;
}

.price-box-calculator .calculator-col > span {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s;
}

.price-box-calculator .calculator-col > span:hover {
  background-color: #f7f7f7;
}

.price-box-calculator .calculator-col > span > img {
  width: 16px;
}

@media only screen and (max-width: 768px) {
  .price-box {
    width: 170px;
    margin-right: 0px;
  }

  .price-box-title .label {
    font-size: 14px;
  }

  .price-box-title .helper {
    font-size: 14px;
  }

  .price-box-content {
    width: 100%;
    height: 45px;
  }

  .price-box-content .price-box-value {
    width: 80%;
  }

  .price-box-content .price-box-value input {
    font-size: 15px;
  }

  .price-box-content .price-box-value > span {
    font-size: 14px;
  }

  .price-box-calculator {
    position: absolute;
    right: 0;
    top: 105%;
    width: 100%;
    height: 160px;
    background-color: var(--white);
    z-index: 10000;
    border-radius: 3px;
    box-shadow: 1px 3px 5px #e8e8e8;
    display: flex;
  }
}
