.user-login {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.user-login-popup-wrapper {
  padding: 15px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85%;
  min-height: 600px;
  max-height: 650px;
  width: 400px;
  position: relative;
  background-color: #ffffff;
  row-gap: 10px;
}

.user-login-popup-wrapper .popup-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.user-login-popup-wrapper .popup-loader p {
  font-size: 13px;
  font-weight: 600;
}

.user-login-popup-wrapper .login-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.user-login-popup-wrapper .login-header img{
  width: 40px;
}

.user-login-popup-wrapper .login-header h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

.user-login-popup-wrapper .login-header h2 a {
  text-decoration: none;
  letter-spacing: -1px;
  color: var(--font-primary);
}

.user-login-popup-wrapper .login-header h2 a i {
  font-weight: 300;
}

.user-login-popup-wrapper .login-header span {
  font-size: 14px;
  font-weight: 500;
}

.user-login-popup-wrapper .login-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 150px);
}

.user-login-popup-wrapper .login-body {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 0 0%;
}

.login-body .resend-otp-button{
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.login-body .resend-message {
  font-size: 12px;
  font-weight: 500;
  margin-top: -5px;
}

.login-body .resend-message.hide {
  opacity: 0;
}

.login-body .login-notes {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  background-color: #fafafa;
  padding: 10px 10px;
  border-radius: 5px;
}

.login-body .login-notes .login-note-title {
  font-weight: 600;
}

.login-body .login-notes .login-note {
  font-size: 13px;
  font-weight: 500;
}

.login-body .login-notes .login-note a{
  color: var(--font-primary);
  text-decoration: none;
}

.login-content .login-account-selection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
}

.login-account-selection .account-text {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.login-account-selection .login-accounts {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  padding: 10px 10px;
  overflow-y: scroll;
}

.login-accounts .login-account-row {
  width: 100%;
  display: flex;
  column-gap: 5px;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.login-accounts .login-account-row:hover {
  background-color: #fafafa;
}

.login-account-row .account-icon {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-account-row .account-icon .account-profile {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--font-primary);
}

.login-account-row .account-icon span {
  font-size: 16px;
  font-weight: 600;
}

.login-account-row .account-info {
  width: 90%;
  display: flex;
  flex-direction: column;
  row-gap: 1px;
}

.login-account-row .account-info .account-name {
  font-size: 13px;
  font-weight: 600;
}

.login-account-row .account-info .account-role {
  font-size: 11px;
  font-weight: 500;
}

.user-login-popup-wrapper .login-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.login-footer .swift-button-icon p {
  font-weight: 700;
}

.user-login-popup-wrapper .footer-line {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footer-line p {
  font-size: 11px;
  text-align: center;
  font-weight: 500;
}

.footer-line p a{
  color: var(--font-secondary);
  text-decoration: none;
  font-weight: 700;
}

.user-login-popup-wrapper .swift-input-box {
  height: 90px;
}



.user-login-popup-wrapper .swift-input-box > p {
  font-weight: 700;
}

.user-login-popup-wrapper .swift-input-box input {
  width: 100%;
  height: 50px;
}

.user-login-popup-wrapper .input-box-note {
  font-size: 12px;
  font-weight: 500;
}

.user-login-popup-wrapper .login-message{
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
}

.user-login-popup-wrapper .login-message>p{
  font-size: 14px;
  font-weight: 600;
}

.user-login-popup-wrapper .login-message>span{
  font-size: 12px;
  font-weight: 500;
  color: var(--font-secondary);
}

.user-login-popup-wrapper #password-note{
  font-size: 12px;
  font-weight: 500;
}

.swift-signup-status-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.swift-signup-status-main a {
  color: var(--font-primary);
  text-decoration: none;
}

.swift-signup-status-main p.brand {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1px;
  color: rgba(1, 22, 39, 0.7);
}

.swift-signup-status-main .swift-signup-status-heading {
  padding-left: 50px;
  padding-top: 30px;
}

.swift-signup-status-main .swift-signup-status-heading p {
  color: var(--text-color);
  font-size: var(--font-heading);
  font-style: normal;
  font-weight: var(--font-weight-heavy);
  line-height: normal;
  letter-spacing: -0.8px;
}

.swift-signup-status-main .swift-signup-status-info-1 {
  width: 100%;
  color: rgba(1, 22, 39, 0.7);
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: var(--font-weight-light);
  line-height: normal;
  letter-spacing: -1px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.swift-signup-status-main .swift-signup-status-button {
  border: 1px solid #404040;
  background-color: transparent;
  border-radius: 3px;
  font-size: 16px;
  color: var(--font-primary);
  padding: 15px 50px;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
}

.swift-signup-status-main .swift-signup-status-info-2 {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 23px;
}

.swift-signup-status-main .swift-signup-status-info {
  width: 50%;
  margin: auto;
}


.logout-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
}

.logout-wrapper>p{
  font-size: 18px;
  font-weight: 700;
}

.logout-wrapper>span{
  font-size: 16px;
  font-weight: 500;
}



@media only screen and (max-width: 768px) {

  .user-login{
    align-items: flex-start;
  }

  .user-login-popup-wrapper{
    border: none;
    height: 100%;
    row-gap: 40px;
    padding: 15px 20px 50px;
    max-height: initial;
  }

  .user-login-popup-wrapper .popup-loader p{
    font-size: 15px; 
  }

  .user-login-popup-wrapper .login-header img{
    width: 60px;
  }

  .user-login-popup-wrapper .login-header h2 {
    font-size: 24px;
    line-height: 26px;
  }

  .user-login-popup-wrapper .login-header span {
    font-size: 16px;
  }

  .user-login-popup-wrapper .footer-line{
    row-gap: 5px;
  }

  .footer-line p{
    font-size: 14px;
  }

  .login-body .login-notes .login-note{
    font-size: 14px;
  }

  .login-body .resend-otp-button{
    font-size: 14px;
  }

}