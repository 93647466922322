.customer-search-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 3px;
}

.customer-search-wrapper .search-title {
  font-size: 12px;
  font-weight: 500;
}

.customer-search-wrapper .customer-search-box {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  border-radius: 5px;
  position: relative;
}

.customer-search-box input {
  flex: 5;
  height: 40px;
  border: none;
  background-color: transparent;
  outline: none;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.customer-search-box input::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.customer-search-box .filter-icon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-search-wrapper .customer-search-box .customer-search-result {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 10000;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  padding: 6px 0;
  max-height: 250px;
  overflow-y: scroll;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 8px 0px;
}

.customer-search-box .customer-search-result .result-row {
  cursor: pointer;
  padding: 8px 0 8px 12px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
}

.customer-search-box .customer-search-result .result-row:hover {
  background-color: #f9f9f9;
}

.customer-search-box .customer-search-result .result-row > span {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.2px;
}

.customer-search-box .customer-search-result .result-row > p {
  font-size: 12px;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .customer-search-wrapper .search-title {
    font-size: 14px;
    font-weight: 500;
  }

  .customer-search-box input{
    font-size: 14px;
  }

  .customer-search-box input::placeholder{
    font-size: 14px;
    font-weight: 500;
  }

  .customer-search-box .customer-search-result .result-row > span{
    font-size: 12px;
  }

  .customer-search-box .customer-search-result .result-row > p{
    font-size: 14px;
  }
}
