.swift-stock-management-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  padding: 0 20px;
}

.swift-stock-management-wrapper .swift-stock-container {
  width: 100%;
  max-width: 1380px;
  position: sticky;
}

.swift-stock-management-wrapper .swift-stock-management-inner {
  height: 100%;
  width: 100%;
  margin-top: 80px;
}

.swift-stock-management-wrapper a {
  text-decoration: none;
}
.swift-stock-management-wrapper .minus,
.swift-stock-feed-modal .minus {
  color: var(--main-red) !important;
}
.swift-stock-management-wrapper .plus,
.swift-stock-feed-modal .plus {
  color: var(--main-green) !important;
}

.swift-stock-management-wrapper .loader {
  margin: auto !important;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.swift-stock-management-wrapper .dot-pulse {
  align-self: center;
  justify-self: center;
}

.swift-stock-management-wrapper button {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--font-primary);
}
.swift-stock-management-wrapper .swift-stock-management-inner > div {
  margin-top: 40px;
}
.swift-stock-management-wrapper
  .swift-stock-management-inner
  > div:nth-child(1) {
  margin-top: 0px;
}
.swift-stock-management-wrapper
  .swift-stock-management-inner
  > div:nth-child(2) {
  margin-top: 40px;
}
.swift-stock-management-wrapper .swift-stock-management-inner > div:last-child {
  margin-top: 0px;
}

.swift-stock-management-wrapper .swift-stock-header-wrapper{
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  z-index: 100;
  background: white;
}

.swift-stock-management-wrapper .swift-stock-management-header {
  display: flex;
  align-items: flex-start;
  padding: 10px 10px;
  column-gap: 20px;
  width: 100%;
  max-width: 1380px;
  margin: 0 auto;
}
.swift-stock-management-wrapper .swift-stock-management-title {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.swift-stock-management-wrapper .swift-stock-management-menu-icon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}
.swift-stock-management-wrapper .swift-stock-management-menu-icon img {
  width: 45px;
}
.swift-stock-management-wrapper .swift-stock-management-title {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .swift-stock-management-title span {
  font-style: italic;
}
.swift-stock-management-wrapper .swift-stock-management-title p {
  font-weight: 700;
  display: inline;
}
.swift-stock-management-wrapper .swift-stock-management-detail {
  /* padding: 10px 25px; */
  background: #ffffff;
  /* border: 1px solid #e8e8e8; */
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.swift-stock-management-wrapper .swift-stock-management-detail-row-1,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2 {
  display: flex;
  column-gap: 45px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-left {
  /* width: 30%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* row-gap: 6px; */
  padding-bottom: 30px;
}
.swift-stock-management-wrapper .swift-stock-management-deatil-left-1 {
  display: flex;
  flex-direction: column;
}

.swift-stock-management-wrapper .swift-stock-management-detail-symbol {
  font-weight: 700;
  font-size: 16px;
  color: var(--font-primary);
  letter-spacing: -1px;
}

.swift-stock-management-wrapper .swift-stock-management-detail-title {
  font-weight: 500;
  font-size: 13px;
  color: var(--text-color-blur);
}
.swift-stock-management-wrapper .swift-stock-management-detail-value {
  display: flex;
  column-gap: 50px;
  font-weight: 700;
  font-size: 15px;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .swift-stock-management-detail-right-row-2 {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  column-gap: 65px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-right-target,
.swift-stock-management-wrapper .swift-stock-management-detail-right-value {
  display: flex;
}

.swift-stock-management-wrapper .swift-stock-management-detail-right {
  padding: 10px 20px 10px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  width: 30%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.swift-stock-management-wrapper .swift-stock-management-detail-right-btn.mid {
  align-self: flex-end;
  justify-self: flex-end;
  padding-top: 20px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-right-btn.right {
  align-self: flex-end;
  justify-self: flex-end;
  padding-top: 10px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-right-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--font-primary);
}
.swift-stock-management-wrapper
  .swift-stock-management-detail-right-target
  span {
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
}

.swift-stock-management-wrapper .swift-stock-management-detail-right-target p {
  font-size: 16px;
  line-height: 24px;
  color: #011627;
}
.swift-stock-management-wrapper .swift-stock-management-detail-right-value {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-right-btn
  button {
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  background: #f1f1f1;
  border: 1px solid #e8e8e8;
  border-radius: 35px;
  padding: 8px 10px 8px 14px;
  width: 100px;
  cursor: pointer;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .swift-stock-management-detail-row-2 {
  display: flex;
  column-gap: 45px;
  /* border: 2px solid black; */
}

.swift-stock-management-wrapper .swift-stock-management-detail-row-2-left,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-mid,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-right {
  width: 30%;
  display: flex;
  flex-direction: column;
  /* border: 2px solid black; */
}
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-left,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-mid {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-left,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-mid,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-right {
  padding: 25px;
}

.swift-stock-management-wrapper .swift-stock-management-detail-row-2-right {
  /* width: 100%; */
  padding: 10px 20px 10px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-left-detail {
  padding: 12px 0px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-left-row {
  padding: 7px 0 16px;
  border-bottom: 1px solid rgba(1, 22, 39, 0.1);
  display: flex;
  justify-content: space-between;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-left-row:last-child {
  border-bottom: none; /* Remove border for the last child */
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-left-title {
  font-weight: 700;
  font-size: 16px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-left-row-title {
  font-weight: 500;
  font-size: 13px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-left-row-value {
  font-weight: 500;
  font-size: 13px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-mid-detail {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-top: 12px;
  flex: 1;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-mid-detail-title {
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-mid-detail-value {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-detail-rows {
  padding: 15px 0px 15px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-detail-rows:first-child {
  padding-top: 0;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-detail-rows:last-child {
  border-bottom: none; /* Remove border for the last child */
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-news {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: var(--font-primary);
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-date {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: var(--text-color-blur);
}
.swift-stock-management-wrapper .swift-stock-management-detail-menu-btn.active {
  font-weight: 700;
}
.swift-stock-management-wrapper table td {
  min-width: 80px;
}

/* =============================================================CHART=============================== */

.swift-stock-management-wrapper .swift-stock-management-chart {
  padding: 10px 25px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  width: 100%;
  max-width: 1280px;
}

.swift-stock-management-wrapper .swift-stock-management-chart-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 45px;
}

.swift-stock-management-wrapper .swift-stock-management-chart-right {
  display: flex;
  column-gap: 5px;
  align-items: flex-start;
}
.swift-stock-management-wrapper .swift-stock-management-chart-price-btn,
.swift-stock-management-wrapper .swift-stock-management-chart-pe-btn {
  background: none;
  border: none;
  background: #cccccc;
  border-radius: 35px;
  padding: 10px 35px;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
}

.swift-stock-management-wrapper .swift-stock-management-chart-pe-btn {
  border: 1px solid rgba(1, 22, 39, 0.7);
  border-radius: 35px;
  background: none;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .swift-stock-management-chart-wrapper {
  width: 100%;
  aspect-ratio: 16/7;
  position: relative;
}

.swift-stock-management-wrapper
  .swift-stock-management-chart-wrapper
  .chart-wrapper-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff9d;
  z-index: 100;
}

.swift-stock-management-wrapper .stocks-management-chart-range {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-top: 20px;
}

.swift-stock-management-wrapper
  .stocks-management-chart-range
  .chart-range-value {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.swift-stock-management-wrapper
  .stocks-management-chart-range
  .chart-range-value.active {
  font-size: 13px;
  font-weight: 700;
}
.swift-stock-management-wrapper .swift-stock-management-feed {
  width: 30% !important;
}
/* =============================================================INCOME=============================== */

.swift-stock-management-wrapper .swift-stock-management-income {
  padding: 10px 25px 10px 25px;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.swift-stock-management-wrapper .swift-stock-management-income-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swift-stock-management-wrapper .swift-stock-management-income-title,
.swift-stock-management-wrapper .swift-stock-management-chart-title,
.swift-stock-management-wrapper .swift-stock-management-balance-header,
.swift-stock-management-wrapper .swift-stock-management-case-header,
.swift-stock-management-wrapper .swift-stock-management-technical-header {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.swift-stock-management-wrapper .swift-stock-management-income-header-right {
  display: flex;
  column-gap: 15px;
}

.swift-stock-management-wrapper .swift-stock-management-income-btn {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  width: 220px;
  padding: 8px 10px;
}

.swift-stock-management-wrapper .swift-stock-management-income-btn span {
  font-size: 13px;
  font-weight: 700;
  text-align: left;
}
.swift-stock-management-wrapper .swift-stock-management-income-btn p {
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  display: inline;
}

.swift-stock-management-wrapper .swift-stock-management-income-time-btn {
  align-self: flex-end;
  display: flex;
  column-gap: 15px;
  padding: 0px 10px;
  color: var(--font-primary);
  font-size: 11px;
  text-align: center;
}

.swift-stock-management-wrapper
  .swift-stock-management-income-time-btn
  .selected {
  color: var(--QuoteNav-row-color);
  font-weight: 700;
}

/* ========================================================Table=========================================== */
.swift-stock-management-wrapper .stock-management-table {
  width: 100%;
  height: 97%;
  padding: 0;
  border-radius: 5px;
  overflow-x: auto;
}

.swift-stock-management-wrapper .stock-management-create-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  overflow-x: auto;
}

.swift-stock-management-wrapper .stock-management-create-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: var(--white);
  background-color: #fcfcfc;
}

.swift-stock-management-wrapper .stock-management-create-table tr {
  height: 35px;
  width: 80px;
}

.swift-stock-management-wrapper .stock-management-create-table td {
  text-align: left;
  border: none;
}

.swift-stock-management-wrapper .stock-management-create-table thead td {
  padding-bottom: 0;
  padding-top: 2px;
  padding-left: 10px;
  border: none;
  padding: 0 10px;
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
  text-align: left;
  background-color: white;
}

.swift-stock-management-wrapper .stock-management-create-table tbody td {
  height: 10px;
  border: none;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 500;
  color: var(--font-primary);
  padding-bottom: 0;
  padding-top: 2px;
  background-color: white;
}

.swift-stock-management-wrapper
  .stock-management-create-table
  tr
  td:first-child {
  padding: 0 10px;
  text-align: left;
  min-width: 120px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  padding-left: 0;
}

.swift-stock-management-wrapper .stock-management-create-table tr {
  border-bottom: 1px solid #f5f5f5;
}

.swift-stock-management-wrapper .stock-management-create-table td.cell__normal {
  font-weight: 500;
}

.swift-stock-management-wrapper .stock-management-create-table td.cell__bold {
  font-weight: 700;
}

/* =============================== BALANCE SHEET =============================== */

.swift-stock-management-wrapper .swift-stock-management-balance,
.swift-stock-management-wrapper .swift-stock-management-case,
.swift-stock-management-wrapper .swift-stock-management-technical {
  padding: 25px 25px;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

/* ================================================SEARCH BAR=====================================  */

.swift-stock-management-wrapper .stock__search__icons__wrapper {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--light-box-shadow);
  width: 500px;
}

.swift-stock-management-wrapper .stock__search {
  width: 500px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.swift-stock-management-wrapper .stock__search .stock__search__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px 0 15px;
}

.swift-stock-management-wrapper .stock__search .stock__search__icon img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.swift-stock-management-wrapper .stock__search input {
  border: none;
  outline: none;
  width: 100%;
  height: 90%;
  padding: 13px 0px;
  padding-left: 15px;
  color: var(--font-primary);
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase !important;
  background-color: transparent;
}

.swift-stock-management-wrapper .stock__search input::placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-stock-management-wrapper .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: 0%;
  margin-top: 3px;
  z-index: 10000;
  border-radius: 5px;
  max-height: 425px;
  /* overflow-y : scroll; */
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
  /* display: none; */
}

.swift-stock-management-wrapper .stock__suggestions .suggestion__stocks {
  overflow-y: scroll;
  max-height: 375px;
}

.swift-stock-management-wrapper .stock__search .stock__suggestions.active {
  display: block;
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  .search__loader {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  .search__loader
  p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  padding: 0 !important;
  display: flex;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .stock__search .stock__suggestions p {
  padding: 10px 10px 10px 20px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .suggestion__stocks .suggestion__empty {
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.swift-stock-management-wrapper .stock__search .stock__suggestions p.active {
  background-color: #dbd9d9;
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  p
  span.search__highlight {
  /* color: #c5c5c5; */
  font-weight: 500;
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(1) {
  flex: 4;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  /* max-width: 140px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(2) {
  flex: 5;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  /* max-width: 200px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(3) {
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .stock__search .stock__suggestions p:hover {
  background-color: #f9f9f9;
}

/* =============================================Technical=============================== */

.swift-stock-management-wrapper .swift-stock-management-technical {
  width: 30%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.swift-stock-management-technical-technicals {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  row-gap: 40px;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view
  .swift-stock-management-technical-slider__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view
  .swift-stock-management-technical-slider__wrapper
  .swift-stock-management-technical-view__title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view
  .swift-stock-management-technical-slider__wrapper
  .swift-stock-management-technical-slider
  .stock__view__label {
  font-size: 13px;
  font-weight: 600;
  text-align: right;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view
  .swift-stock-management-technical-text {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
}
.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view
  .swift-stock-management-technical-text
  p {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  color: var(--text-color-blur);
  margin-top: -2px;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;
  row-gap: 30px;
}

.swift-stock-management-technical-tech__view__final {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 10px 15px;
}

.swift-stock-management-technical-tech__view__final
  .swift-stock-management-technical-view__title {
  font-size: 13px;
  font-weight: 600;
}

.swift-stock-management-technical-tech__view__final
  .swift-stock-management-technical-text {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-color-blur);
}

.swift-stock-management-technical-tech__view__final .stock__view__label {
  margin-top: 6px;
  font-size: 13px;
  font-weight: 700;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price {
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 10px 15px;
  row-gap: 10px;
}
.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__genie {
  width: 100%;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__genie
  p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__value {
  width: 100%;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__value
  p {
  font-size: 12px;
  line-height: 16px;
  color: var(--font-primary);
  font-weight: 500;
  display: flex;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__value
  p
  > span.span__bold {
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 700;
}
.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__value
  p
  .swift-stock-management-technical-target__change.negative {
  color: var(--main-red);
  font-size: 12px;
}

/* ==========================================Feed Modal================================ */
.swift-stock-feed-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 800px;
  height: 100%;
  background-color: var(--white);
  padding: 20px 0px;
  border: 1px solid #e8e8e8;
  z-index: 1000000;
  max-width: 100%;
  box-shadow: -2px 0px 10px #e8e8e8;
}
.swift-stock-feed-modal-header {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
}
.swift-stock-feed-modal-title {
  font-weight: 600;
  font-size: 18px;
}
.swift-stock-feed-modal-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 0 30px;
}
.swift-stock-feed-modal-detail-rows {
  padding: 15px 0px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.swift-stock-feed-modal-detail-rows:last-child() {
  border-bottom: none; /* Remove border for the last child */
}

.swift-stock-feed-modal-news {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--font-primary);
}

.swift-stock-feed-modal-date {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: var(--font-primary);
}

/* =======================================================About Modal =======================================*/

.swift-stock-about-modal {
  width: 900px;
}

.swift-stock-about-modal-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
.swift-stock-about-row-1 {
  display: flex;
  column-gap: 40px;
  padding-top: 10px;
}
.swift-stock-about-target,
.swift-stock-about-value {
  display: flex;
}
.swift-stock-about-target span {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #011627;
}

.swift-stock-about-target p {
  font-size: 16px;
  line-height: 24px;
  color: #011627;
}
.swift-stock-about-value {
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
}

.swift-stock-about-row-2 {
  padding-top: 36px;
  width: 43%;
}

.swift-stock-about-desc {
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}
.swift-stock-about-regional {
  padding-top: 20px;
  font-weight: 700;
}

.swift-stock-management-wrapper .swift-stock-management-detail-row-2-menu {
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  align-items: center;
  display: none;
}
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-menu div {
  cursor: pointer;
}

.swift-stock-management-wrapper .swift-stock-management-detail-menu-btn {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
  padding-left: 0px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.swift-stock-management-wrapper .swift-menu {
  display: none;
  row-gap: 0px;
}
.swift-stock-management-wrapper .swift-account-svg svg {
  width: 16px;
  cursor: pointer;
}
.swift-stock-management-wrapper .stock-management-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: rgba(1, 22, 39, 0.9);
}

@media screen and (max-width: 1000px) {
  .swift-stock-management-wrapper .swift-stock-management-chart-feed-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
  .swift-stock-management-wrapper .swift-stock-management-chart {
    width: 100%;
  }
  .swift-stock-management-wrapper .swift-stock-management-feed {
    width: 100% !important;
  }
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-left,
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-mid,
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-right {
    width: 50%;
  }
  .swift-stock-management-wrapper .swift-stock-management-technical {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .swift-stock-management-wrapper {
    padding: 0 15px;
  }

  .swift-stock-management-wrapper .swift-stock-management-inner {
    padding: 0px 0;
  }
  .swift-stock-management-wrapper
    .swift-stock-management-inner
    > div:nth-child(2) {
    margin-top: 10px;
  }
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-left,
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-mid,
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-right {
    width: 100%;
  }
  .swift-stock-management-wrapper .swift-stock-management-technical {
    width: 100%;
  }
  .swift-stock-management-wrapper .swift-stock-management-detail-row-1,
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2 {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-menu {
    display: flex;
  }
  
  .swift-stock-management-wrapper .swift-stock-management-header-row-1 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;
    height: 50px;
  }

  .swift-stock-management-wrapper .swift-menu {
    position: fixed;
    left: 0;
    display: flex;
    box-shadow: none;
    height: 100%;
    width: 50px;
    max-width: 100%;
    background: transparent;
    transition: width 0.1s;
  }
  .swift-stock-management-wrapper .swift-menu.active {
    width: 300px;
    max-width: 100%;
    box-shadow: 2px 0px 10px #e8e8e8;
    background: white;
  }

  .swift-stock-management-wrapper .swift-menu .swift-menu-options {
    display: none;
    flex-direction: column;
    width: 100%;
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .swift-stock-management-wrapper .swift-menu.active .swift-menu-options{
    display: flex;
  }

  .swift-stock-management-wrapper .swift-menu.active .menu-text {
    font-size: 16px;
    width: auto;
  }

  .swift-stock-management-wrapper .swift-stock-management-menu-icon {
    display: flex;
  }
  .swift-stock-management-wrapper .swift-stock-management-income-title,
  .swift-stock-management-wrapper .swift-stock-management-chart-title,
  .swift-stock-management-wrapper .swift-stock-management-balance-header,
  .swift-stock-management-wrapper .swift-stock-management-case-header,
  .swift-stock-management-wrapper .swift-stock-management-technical-header {
    font-size: 13px;
  }
  .swift-stock-about-modal {
    width: 100vw;
  }
  .swift-stock-feed-modal {
    width: 100%;
    height: 100%;
    background-color: var(--white);
  }
  .swift-stock-about-row-2 {
    padding-top: 36px;
    width: 100%;
  }
  .swift-stock-management-wrapper .swift-stock-management-income-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 15px;
  }
  .swift-stock-management-wrapper .swift-stock-management-header {
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
    padding-bottom: 10px;
    align-items: flex-start;
    row-gap: 10px;
    padding: 0 10px;
  }

  .swift-stock-management-wrapper .swift-stock-management-menu-icon {
    display: flex;
  }
  .swift-stock-management-wrapper .swift-menu .swift-menu-logo {
    display: flex;
  }

  .swift-stock-management-wrapper .stock__search__icons__wrapper {
    width: 100%;
  }
  .swift-stock-management-wrapper .stock__search {
    width: 100%;
  }
  .swift-stock-management-wrapper .swift-stock-management-income-header-right {
    flex-direction: column;
    row-gap: 10px;
  }

  .swift-stock-management-wrapper .swift-stock-management-balance,
  .swift-stock-management-wrapper .swift-stock-management-case,
  .swift-stock-management-wrapper .swift-stock-management-technical {
    padding: 10px 15px;
  }

  .swift-stock-management-wrapper .swift-stock-management-income-time-btn {
    padding: 0;
  }

  .swift-stock-management-wrapper .swift-stock-management-income-btn {
    padding: 0;
    border: none;
    width: 180px;
  }
}
