.custom-alert-box {
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translateX(-50%);
  width: 450px;
  box-shadow: 2px 2px 10px #e8e8e8;
  z-index: 1000000000;
  border-radius: 5px;
  transition: 0.4s;
  display: flex;
  border-radius: 50px;
  background-color: #a8a8a8;
}

.custom-alert-box.active {
  /* display: flex; */
  top: 3%;
}

.custom-alert-box .custom-alert-box-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50px;
}

.custom-alert-box .custom-alert-sideline {
  position: absolute;
  left: 0px;
  width: 5px;
  height: 100%;
}

.custom-alert-box .custom-alert-content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 25px;
}

.custom-alert-box .custom-alert-content .custom-alert-text {
  margin-right: 10px;
  width: 90%;
}

.custom-alert-box .custom-alert-content .custom-alert-text h6 {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.custom-alert-box .custom-alert-content .custom-alert-text p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  word-wrap: break-word;
  margin-top: 5px;
  /* text-transform: uppercase; */
}

.custom-alert-box .custom-alert-content .custom-alert-close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-radius: 3px;
}

.custom-alert-box .custom-alert-bottom-line {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.05;
  border-radius: 50px;
}

@keyframes animateLine {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@media only screen and (max-width: 768px) {
  .custom-alert-box {
    width: 95%;
  }
}
