.swift-transaction-management .strategy-content {
  position: relative;
}

.trasaction-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.trasaction-header .trasaction-title {
  font-size: 18px;
  /* color: var(--font-primary); */
  color: rgba(1, 22, 39, 0.9) 98;
  font-weight: 700;
}

.orders-wrapper {
  height: 85%;
  padding: 0 0px;
}

.orders-wrapper.full {
  height: 95%;
}

.orders-wrapper .orders-menu {
  display: flex;
  padding: 0 0px;
}

.orders-wrapper .orders-menu .orders-menu-option {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  border: 1px solid var(--white);
  padding: 8px 20px;
  border-radius: 3px;
  transition: 0.3s;
}

.orders-menu .orders-menu-option.active {
  border-color: #e8e8e8;
}

.orders-wrapper .orders-menu .orders-menu-option > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders-menu .orders-menu-option svg {
  width: 18px;
  height: 18px;
  fill: var(--font-primary);
}

.orders-menu .orders-menu-option span {
  font-size: 12px;
  color: var(--font-primary);
  margin-left: 5px;
  font-weight: 700;
}

.orders-wrapper .table-container,
.orders-wrapper .table-loader {
  background-color: var(--white);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding: 0px 0px 5px;
  overflow-y: scroll;
  padding: 10px 15px;
  border: 1px solid #f5f5f5;
}

.orders-wrapper .table-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
}

.orders-wrapper .table-loader > p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px !important;
}

.orders-wrapper .order-trades-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.orders-wrapper .order-trades-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
}

.orders-wrapper .order-trades-config {
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 100%;
}

.orders-wrapper .order-trades-config .expand-collapse-box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.orders-wrapper .order-trades-config .config-box {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  flex: 4;
}

.orders-wrapper .order-trades-config .config-box.name span {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orders-wrapper .order-trades-config .config-box.arrow,
.orders-wrapper .order-trades-config .config-box.select,
.orders-wrapper .order-trades-config .config-box.remove {
  flex: 1;
}

.orders-wrapper .order-trades-config .config-box.remove {
  cursor: pointer;
}

.order-trades-config .config-box span {
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
}

.order-trades-config .config-box.trade span,
.order-trades-config .config-box.sell span,
.order-trades-config .config-box.buy span {
  font-weight: 600;
}

.order-trades-config .config-box select {
  background-color: transparent;
  color: var(--font-primary);
  border: 1px solid #f5f5f5;
  outline: none;
  font-size: 12px;
  border-radius: 5px;
  padding: 2px 4px;
}

/* 
.orders-wrapper .order-trades-config .config-box.count {
  flex: 2;
}

.orders-wrapper .order-trades-config .config-box.sell,
.orders-wrapper .order-trades-config .config-box.buy,
.orders-wrapper .order-trades-config .config-box.trade{
  flex: 3;
} */

.order-trades-section:nth-child(1) .order-trades-config .config-box span {
  font-weight: 700;
}

.order-trades-header .config-box.execute {
  flex: 5;
}

.order-trades-header .config-box.execute button {
  border: none;
  outline: none;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 20px;
  width: fit-content;
  padding: 8px 16px;
  transition: 0.3s;
}

.order-trades-header .config-box.execute button:hover {
  background-color: #f1f1f1;
}

.orders-wrapper .order-trades-container-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 100%;
  row-gap: 10px;
}

.orders-wrapper .order-trades-container-wrapper.hide {
  display: none;
}

.orders-wrapper .order-trades-options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.orders-wrapper .order-trades-options > p {
  font-size: 12px;
  font-weight: 700;
}

.orders-wrapper .order-trades-search {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f5f5f5;
  width: 280px;
  padding: 0 10px;
  border-radius: 5px;
}

.order-trades-search input {
  background-color: transparent;
  height: 100%;
  width: 80%;
  outline: none;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  padding: 10px 0px;
  border: none;
  outline: none;
}

.order-trades-search input::placeholder {
  font-weight: 500;
}

.order-trades-search .filter-icon {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.orders-wrapper .order-trades-container,
.orders-wrapper .orders-history-container,
.orders-wrapper .orders-files-container,
.orders-wrapper .orders-transactions-container {
  border-radius: 15px;
  width: 100%;
  padding: 0px 0px 5px;
  overflow-y: scroll;
  padding: 0 0px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  position: relative;
}

.orders-wrapper .orders-history-container,
.orders-wrapper .orders-transactions-container {
  height: 100%;
  overflow-y: scroll;
}

.orders-history-container .orders-history-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
}

.orders-history-container .orders-history-loading > p {
  font-size: 14px;
  font-weight: 600;
}

.orders-wrapper .order-trades-container table,
.orders-wrapper .orders-history-container table,
.orders-wrapper .orders-transactions-container table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.order-container-empty,
.orders-history-empty,
.orders-transactions-empty {
  width: 100%;
  min-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-container-empty > img,
.orders-history-empty > img,
.orders-files-empty > img,
.orders-transactions-empty > img {
  width: 100px;
}

.order-container-empty > div,
.orders-history-empty > div,
.orders-files-empty > div,
.orders-transactions-empty > div {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-container-empty > div > p,
.orders-history-empty > div > p,
.orders-files-empty > div > p,
.orders-transactions-empty > div > p {
  font-size: 18px !important;
  color: var(--font-primary);
  letter-spacing: -0.5px;
  font-weight: 600;
}

.order-container-empty > div > span,
.orders-history-empty > div > span,
.orders-files-empty > div > span,
.orders-transactions-empty > div > span {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  color: var(--font-primary);
}

.order-trades-container table thead,
.orders-history-container table thead,
.orders-transactions-container table thead {
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.order-trades-container table thead tr td,
.orders-history-container table thead tr td,
.orders-transactions-container table thead tr td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.order-trades-container table thead tr td:nth-child(1) {
  width: 200px;
}

.order-trades-container.orders table thead tr td:nth-child(10) {
  width: 40px;
}

.order-trades-container.trades table thead tr td:nth-child(11) {
  width: 60px;
}

.order-trades-container.trades table td.order-item-remove,
.order-trades-container.trades table td.order-item-type {
  cursor: pointer;
}

.orders-history-container table tr td:nth-child(1) {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orders-history-container table thead tr td:nth-child(5),
.orders-history-container table thead tr td:nth-child(6),
.orders-history-container table thead tr td:nth-child(7),
.orders-history-container table thead tr td:nth-child(10) {
  width: 100px;
}

/* .order-trades-container table thead tr th:nth-child(8)
{
  text-align: center;
} */

.order-trades-container table tbody,
.orders-history-container table tbody,
.orders-transactions-container table tbody {
  width: 100%;
}

.order-trades-container table tbody tr,
.orders-history-container table tbody tr,
.orders-transactions-container table tbody tr {
  cursor: initial;
  transition: 0.3s;
  border-bottom: 1px solid #f5f5f5c5;
}

.order-trades-container table tbody tr:hover,
.orders-history-container table tbody tr:hover,
.orders-transactions-container table tbody tr:hover {
  background-color: #fcfcfc;
}

.order-trades-container table tbody tr td,
.orders-history-container table tbody tr td,
.orders-transactions-container table tbody tr td {
  padding: 14px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.order-trades-container table tbody tr td.order-item-stock-name {
  position: relative;
}

.order-trades-container table tbody tr td .order-item-error {
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: 60%;
  transform: translateY(-50%);
  background-color: var(--main-red);
  border-radius: 10px;
}

.orders-transactions-container table tr td.status {
  width: 100px;
}

.orders-transactions-container table thead tr td.modify,
.orders-transactions-container table thead tr td.delete {
  width: 50px;
}

.orders-transactions-container table tbody tr td.modify,
.orders-transactions-container table tbody tr td.delete {
  cursor: pointer;
}

.order-trades-container table tbody tr td.order-item-type {
  cursor: pointer;
}

.order-trades-container table tbody tr td select {
  background-color: transparent;
  color: var(--font-primary);
  border: 1px solid #f5f5f5;
  outline: none;
  font-size: 12px;
  border-radius: 5px;
  padding: 2px 4px;
}

/* .order-trades-container table tbody tr td:nth-child(1) {
  width: 180px;
}

*/
.order-trades-container table tbody tr td .stock-info {
  display: flex;
  flex-direction: column;
}

.order-trades-container table tbody tr td .stock-info .stock-name {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
}

.order-trades-container table tbody tr td .stock-info .stock-fullname {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-left: 8px; */
  color: var(--font-primary);
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.2px;
}

.order-trades-container table tbody tr td .order-item-type-box {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.order-trades-container table tbody tr td:nth-child(9) {
  min-width: 60px;
}

.order-trades-container table tbody tr td.order-item-remove {
  padding: 8px 10px;
  cursor: pointer;
}

.order-trades-container table tr td.order-item-remove {
  width: 60px;
}

.order-trades-container table tbody tr td .modify-order,
.order-trades-container table tbody tr td .cancel-order {
  color: var(--white);
  border-radius: 3px;
  transition: 0.3s;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
}

.order-trades-container table tbody tr td .modify-order > img,
.order-trades-container table tbody tr td .cancel-order img {
  width: 18px;
}

.order-trades-container table tbody tr td .modify-order:hover,
.order-trades-container table tbody tr td .cancel-order:hover {
  border: 2px solid #404040;
}

.order-trades-container table tbody tr td .buy {
  color: var(--main-green);
  font-weight: 500;
}

.order-trades-container table tbody tr td .sell {
  color: var(--main-red);
  font-weight: 500;
}

.order-trades-container table tbody tr td .complete,
.order-trades-container table tbody tr td .cancelled,
.order-trades-container table tbody tr td .pending,
.order-trades-container table tbody tr td .rejected {
  border-radius: 2px;
  padding: 4px 0;
  font-weight: 500;
  display: flex;
  /* justify-content: center; */
}

/* order files */

.orders-wrapper .orders-files-loading,
.orders-wrapper .orders-files-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
}

.orders-wrapper .orders-files-loading > p {
  font-size: 14px;
  font-weight: 600;
}

.orders-wrapper .orders-files-container {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 20px;
  height: 100%;
  overflow-y: hidden;
}

.orders-files-container .orders-files-data {
  width: 25%;
  height: 100%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 0px;
}

.orders-files-container .orders-files-data > p {
  font-size: 13px;
  font-weight: 700;
  height: 5%;
  padding: 0 15px;
}

.orders-files-data .orders-file-info {
  width: 100%;
  height: 95%;
  overflow-y: scroll;
  padding: 0 10px;
}

.orders-file-info .orders-file-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 5px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.orders-file-info .orders-file-row > p {
  font-size: 13px;
  font-weight: 600;
}

.orders-file-info .orders-file-row > span {
  font-size: 11px;
  font-weight: 500;
  margin-top: -5px;
}

.orders-file-info .orders-file-row:hover {
  background-color: #fafafa;
}

.orders-files-container .orders-files-preview {
  width: 75%;
  height: 100%;
  border-radius: 15px;
  padding: 15px 15px;
  overflow-y: scroll;
  position: relative;
}

.orders-files-container .orders-files-preview.active {
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
}

.orders-files-preview .files-loading{
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  background-color: rgba(255, 255, 255, 0.75);
}

.orders-files-preview .files-loading p{
  font-size: 14px;
}

.orders-files-preview .files-preview-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
}

.orders-files-preview .files-preview-header > p {
  font-size: 13px;
  font-weight: 600;
}

.orders-files-preview .files-preview-header > div {
  font-size: 13px;
  cursor: pointer;
  transition: 0.3s;
  color: var(--font-secondary);
  cursor: pointer;
  display: flex;
  column-gap: 20px;
}

.orders-files-preview .files-preview-header > div a,
.orders-files-preview .files-preview-header > div span {
  text-decoration: none;
  color: var(--font-primary);
  background-color: #f1f1f1;
  border: 1px solid #e8e8e8;
  padding: 8px 20px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
}

.orders-files-preview .files-preview-table {
  width: 100%;
  border-radius: 15px;
  border: 1px solid #f5f5f5;
  margin-top: 20px;
  overflow-y: scroll;
}

.orders-files-preview .files-preview-table table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.files-preview-table table thead {
  border-bottom: 1px solid #f5f5f5;
}

.files-preview-table table thead td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
}

.files-preview-table table tbody tr {
  border-bottom: 1px solid #f5f5f5c5;
}

.files-preview-table table tbody td {
  text-align: left;
  padding: 10px 10px 10px 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.files-preview-table tbody td:nth-child(2) {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-preview-table tbody tr td .stock-name-code {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.files-preview-table tbody tr td .stock-name-code > p {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  line-height: 14px;
}

.files-preview-table tbody tr td .stock-name-code > span {
  width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.2px;
  line-height: 12px;
}

.files-preview-table thead tr td.name {
  width: 150px;
}

.files-preview-table thead tr td.side,
.files-preview-table thead tr td.quantity,
.files-preview-table thead tr td.type {
  width: 90px;
}

.orders-execution {
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.orders-wrapper .order-execution-result {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100000;
  display: flex;
  justify-content: center;
}

.order-execution-result .execution-result-box {
  margin-top: 40px;
  width: 450px;
  height: 500px;
  padding: 0px 25px;
  background-color: white;
  box-shadow: 1px 3px 5px #e8e8e8;
}

.execution-result-box .result-header {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-header > p {
  font-size: 13px;
  font-weight: 700;
}

.result-header .result-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.execution-result-box .result-table {
  width: 100%;
  height: 430px;
  overflow-y: scroll;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
}

.result-table table {
  width: 100%;
  max-height: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.result-table table thead {
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.result-table table tr td {
  font-size: 12px;
  font-weight: 500;
  padding: 10px 12px;
}

.result-table table thead tr td {
  font-weight: 700;
}

.result-table table tbody tr td {
  vertical-align: top;
}

.result-table table tbody tr td a {
  color: var(--font-primary);
  background-color: var(--white);
  border: 1px solid var(--font-primary);
  padding: 4px 12px;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
}

.orders-entry-wrapper {
  width: 440px;
  height: 95%;
  padding: 0px 15px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.orders-entry-wrapper .loading-wrapper {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  row-gap: 10px;
}

.orders-entry-wrapper > p {
  font-size: 14px;
  font-weight: 500;
}

.orders-entry-wrapper .entry-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-top: 15px;
  position: relative;
}

.orders-entry-wrapper .entry-box .entry-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  position: absolute;
  padding-top: 100px;
  background-color: hsla(0, 0%, 100%, 0.85);
  z-index: 1000;
  font-size: 14px;
  font-weight: 600;
}

.orders-entry-wrapper .entry-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}

.orders-entry-wrapper .entry-row.submit {
  justify-content: flex-end;
}

.orders-entry-wrapper .entry-box .swift-date-wrapper,
.orders-entry-wrapper .entry-box .swift-input-box {
  height: auto;
}

.orders-entry-wrapper .entry-box .entry-box-half {
  width: 50%;
}

.orders-entry-wrapper .entry-stock-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}

.orders-entry-wrapper .entry-stock-search .search-title {
  font-size: 12px;
  font-weight: 500;
}

.entry-stock-search .search-wrapper {
  width: 100%;
  position: relative;
}

.entry-stock-search .search-wrapper input {
  width: 100%;
  height: 40px;
  border: none;
  background-color: transparent;
  outline: none;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  text-transform: uppercase;
}

.entry-stock-search .search-wrapper input:focus {
  border-color: #d8d8d8;
}

.entry-stock-search .search-wrapper input::placeholder {
  text-transform: initial;
}

.entry-stock-search .search-wrapper .entry-stock-suggessions {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  height: 200px;
  max-height: 200px;
  background-color: white;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 5px 5px;
  z-index: 1000;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
}

.search-wrapper .entry-stock-suggessions .suggession-row {
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.search-wrapper .entry-stock-suggessions .suggession-row:hover {
  background-color: #f5f5f5;
}

.entry-stock-suggessions .suggession-row span:nth-child(1) {
  flex: 4;
  font-size: 12px;
  font-weight: 500;
}

.entry-stock-suggessions .suggession-row span:nth-child(2) {
  flex: 9;
  font-size: 12px;
}

.entry-stock-suggessions .suggession-row span:nth-child(3) {
  flex: 1;
  font-size: 12px;
  font-weight: 500;
}

.managed-holdings {
  width: 100%;
  height: 100%;
  position: relative;
}

.managed-holdings.loader,
.managed-holdings.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.managed-holdings.loader > p,
.managed-holdings.empty > p {
  font-size: 16px;
  font-weight: 600;
}

.managed-holdings.empty > span {
  font-size: 14px;
}

.managed-holdings .managed-holdings-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.managed-holdings .managed-holdings-loading > p {
  font-weight: 14px;
  font-weight: 600;
}

.managed-holdings .managed-holdings-table {
  width: 100%;
  height: calc(100% - 60px);
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.managed-holdings table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.managed-holdings table thead {
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.managed-holdings table tr td:nth-child(1) {
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.managed-holdings table thead tr td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.managed-holdings table tbody tr {
  cursor: initial;
  transition: 0.3s;
  border-bottom: 1px solid #f5f5f5c5;
}

.managed-holdings table tbody tr td {
  padding: 14px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.managed-holdings table tr td.apply {
  width: 120px;
}

.managed-holdings table tr td button.apply-btn {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  background-color: #f5f5f5;
  padding: 6px 20px;
  border-radius: 20px;
  color: var(--font-primary);
  border: 1px solid #e8e8e8;
}

.managed-holdings .managed-holdings-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.order-type-popup {
  width: 900px;
  height: 580px;
  background-color: #ffffff;
  padding: 10px 20px;
  position: relative;
  border-radius: 5px;
  cursor: initial;
}

.order-type-popup .order-type-loader {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}

.order-type-popup .order-type-header {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 12px;
  border-bottom: 1px solid #f5f5f5;
}

.order-type-popup .order-type-footer {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-top: 1px solid #f5f5f5;
}

.order-type-header .order-stock-info {
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 15px;
}

.order-type-header .order-stock-info .order-buysell {
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0px;
  background-color: #e8e8e8;
}

.order-type-header .order-stock-info .order-buysell.buy {
  background-color: var(--main-green-light);
}

.order-type-header .order-stock-info .order-buysell.sell {
  background-color: var(--main-red-light);
}

.order-type-header .order-stock-info > p {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.order-type-header .order-stock-close {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-type-popup .order-type-body {
  width: 100%;
  height: calc(100% - 50px - 60px);
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.order-type-popup .order-type-body .order-type-section {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 50%;
  padding: 10px 0;
}

.order-type-body .order-depth-section {
  width: 50%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.order-type-body .order-customer-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.order-type-body .order-customer-info .customer-info-box {
  display: flex;
  flex-direction: column;
  row-gap: -2px;
}

.order-customer-info .customer-info-box span {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.order-customer-info .customer-info-box p {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.order-type-body .order-type-select-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.order-type-select-wrapper > p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.order-type-select-wrapper .order-type-select {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
}

.order-type-popup .order-type-select span {
  width: 100px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-secondary);
  cursor: pointer;
  transition: 0.2s;
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
}

.order-type-popup .order-type-select span.active {
  color: var(--font-primary);
  font-weight: 700;
  background-color: #f5f5f5;
}

.order-type-body .order-type-info-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.order-type-info-wrapper > p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.order-type-info-wrapper > p > span {
  font-weight: 600;
}

.order-type-popup .order-type-input-wrapper {
  width: 100%;
  display: flex;
  column-gap: 15px;
}

.order-type-input-wrapper .order-type-input {
  flex: 1;
}

.order-type-input .price-box {
  width: 100%;
}

.order-type-popup .error-text {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.order-type-popup .submit-button {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

.order-type-popup .order-type-body .note {
  font-size: 13px;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .orders-wrapper.full {
    height: 100%;
  }

  .orders-wrapper .table-loader > p {
    font-size: 16px;
  }

  .orders-history-container .orders-history-loading > p {
    font-size: 16px;
  }

  .orders-wrapper .orders-transactions-container,
  .orders-wrapper .orders-history-container {
    border: none;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }

  .orders-wrapper .orders-history-container::-webkit-scrollbar {
    width: 0;
  }

  .orders-wrapper .orders-transactions-container .transaction-row,
  .orders-wrapper .orders-history-container .orders-history-row {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0 10px;
  }

  .orders-wrapper .orders-transactions-container .transaction-row .tran-row,
  .orders-wrapper .orders-history-container .orders-history-row .history-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  }

  .orders-wrapper
    .orders-transactions-container
    .transaction-row
    .tran-row:nth-child(1),
  .orders-wrapper
    .orders-transactions-container
    .transaction-row
    .tran-row:nth-child(3),
  .orders-wrapper
    .orders-history-container
    .orders-history-row
    .history-row:nth-child(1),
  .orders-wrapper
    .orders-history-container
    .orders-history-row
    .history-row:nth-child(3) {
    font-size: 13px;
    color: var(--font-secondary);
  }

  .orders-wrapper
    .orders-transactions-container
    .transaction-row
    .tran-row:nth-child(2),
  .orders-wrapper
    .orders-history-container
    .orders-history-row
    .history-row:nth-child(2) {
    font-weight: 700;
  }

  .orders-wrapper
    .orders-transactions-container
    .transaction-row
    .tran-row
    > div,
  .orders-wrapper
    .orders-history-container
    .orders-history-row
    .history-row
    > div {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  .orders-wrapper .table-container,
  .orders-wrapper .table-loader {
    border: none;
    padding: 0;
    border-radius: 0;
  }

  .orders-wrapper .table-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .orders-execution {
    display: none;
  }

  .orders-wrapper .order-trades-section {
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  .orders-wrapper .order-trades-header {
    padding: 0px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
  }

  .orders-wrapper .order-trades-config {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
  }

  .orders-wrapper .order-trades-config .trades-config-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .orders-wrapper .order-trades-config .trades-config-row > div {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  .orders-wrapper .order-trades-config .trades-config-row > div > p {
    font-size: 14px;
  }

  .orders-wrapper .order-trades-config .trades-config-row > div > span {
    font-size: 14px;
    font-weight: 700;
  }

  .orders-wrapper .order-trades-config .trades-config-row > div.mobile {
    align-items: flex-end;
  }

  .orders-wrapper .order-trades-container-wrapper {
    width: 100%;
    height: 100%;
    background-color: white;
    inset: 0;
    padding: 0;
  }

  .orders-wrapper .order-trades-container-wrapper.hide {
    display: initial;
  }

  .orders-wrapper .order-trades-container {
    border: none;
    padding: 10px 0px;
    border-radius: 0;
  }

  .orders-wrapper .order-trades-container .order-trades-container-header {
    padding: 6px 0 6px;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .orders-wrapper .order-trades-container .order-trades-container-header > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .orders-wrapper .order-trades-container .order-trades-container-header > p {
    font-size: 16px;
    font-weight: 700;
  }

  .orders-wrapper .order-trades-container .orders-row-container {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    border-bottom: 1px solid #e8e8e8;
    padding: 14px 0px;
  }

  .orders-wrapper .order-trades-container .order-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .orders-wrapper .order-trades-container .order-row > div {
    display: flex;
    column-gap: 6px;
  }

  .orders-wrapper .order-trades-container .order-row > div > span {
    font-size: 14px;
  }

  .orders-wrapper .order-trades-container .order-row:nth-child(2) {
    font-weight: 700;
  }
}
