.apply__smallcase__container .smallcase__container__loading {
    width: 100%;
    height: 100%;
    row-gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;
}

.apply__smallcase__container .smallcase__container__loading>p {
    font-size: 14px;
    font-weight: 600;
}
.apply__smallcase__container .capital__selection__input {
    margin: 0 0px;
    display: flex;
    align-items: center;
    position: relative;
}

.capital__selection__input .custom__capital {
    height: 35px;
    width: 200px;
    border-radius: 3px;
    outline: none;
    box-shadow: none;
    border: 1px solid #e8e8e8;
    padding: 0px 10px;
    font-size: 11px;
    color: var(--font-primary);
}

.capital__selection__input .currency__symbol {
    position: absolute;
    font-size: 11px;
    left: 5px;
}

.min__cash__hint {
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 600;
    margin-top: 0px;
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.already__applied {
    font-size: 12px;
    font-weight: 500;
}

.apply__smallcase__container .custom-select .custom-select-container {
    max-height: 200px;
    overflow: scroll;
}

.portfolio__selection .custom__portfolio {
    margin: 0 20px;
}

.apply__smallcase__container {
    width: 100%;
    height: 100%;
    padding: 0px 0px;
    position: relative;
    display: flex;
    column-gap: 10px;
}

.apply__smallcase__container .apply__smallcase__success {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.apply__smallcase__success .success__message {
    margin-top: 14px;
    font-weight: 500;
    color: var(--font-primary);
}

.apply__smallcase__success .success__info {
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
}

.apply__smallcase__success .success__info a {
    text-decoration: none;
    color: var(--font-primary);
    font-weight: 700;
}

.apply__smallcase__heading>span {
    position: absolute;
    right: 1%;
    top: 1%;
    margin-left: 4px;
    cursor: pointer;
}

.apply__smallcase__heading>span>img {
    width: 30px;
}

.apply__smallcase__content {
    /* height: 35%; */
    display: flex;
    flex-direction: column;
    width: 340px;
    height: 100%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 15px 15px;
}

.apply__smallcase__content .portfolio__customer {
    width: 100%;
    height: 80px;
}

.apply__smallcase__content .customer__options {
    display: flex;
    flex-direction: column;
}

.cash__position__value {
    font-size: 12px;
    font-weight: 500;
    color: var(--font-primary);
}

.selection__wrapper {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: flex-start;
    margin: 5px 0;
    width: 100%;
    height: 80px;
}

.selection__wrapper .selection__label {
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 500;
    width: 140px;
}

.portfolio__selection input {
    width: 200px;
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
    outline: none;
    /* border: 1px solid #e8e8e8; */
    border-radius: 3px;
    color: var(--font-primary);
}

.portfolio__selection input::placeholder {
    color: var(--font-primary) !important;
}

.portfolio__selection>span {
    margin: 0 10px;
    font-size: 12px;
}

.smallcase__info {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.cash__wrapper {
    display: flex;
    column-gap: 10px;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
}

.apply__smallcase__content div button {
    margin: 0 10px 0 0px;
    background-color: var(--white);
    border: 1px solid #e8e8e8;
    padding: 7px 15px 7px 15px;
    font-size: 11px;
    font-weight: 700;
    color: var(--font-primary);
    border-radius: 3px;
    transition: 0.3s;
}

.smallcase__portfolio__stocks.empty {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #f5f5f5;
    border-radius: 3px;
    width: 1000px;
    height: 100%;
}

.smallcase__portfolio__stocks.empty>img {
    width: 140px;
}

.smallcase__portfolio__stocks.empty>p {
    text-align: center;
    font-size: 14px;
    margin-top: 15px !important;
    font-weight: 500;
}

.smallcase__portfolio__stocks {
    height: 100%;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.smallcase__portfolio__stocks .smallcase__portfolio__table {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #f5f5f5;
    border-radius: 15px;
}

.smallcase__portfolio__stocks table {
    width: 100%;
    border-collapse: collapse;
}

.smallcase__portfolio__stocks table thead {
    /* position: sticky; */
    /* inset-block-start: 0; */
    /* z-index: 1; */
    width: 100%;
    background-color: var(--white);
    box-shadow: 0 1px 1px #f5f5f5;
}


.smallcase__portfolio__stocks table thead tr td {
    text-align: left;
    padding: 5px 10px 5px 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}

.smallcase__portfolio__stocks table thead tr td:first-child {
    width: 150px;
}

.smallcase__portfolio__stocks table tbody tr{
    border-bottom: 1px solid #f8f8f8;
}

.smallcase__portfolio__stocks table tbody tr td {
    padding: 10px 10px;
    font-size: 13px;
    text-align: left;
    display: table-cell !important;
    font-weight: 500;
}

.smallcase__portfolio__stocks table tbody tr td:nth-child(2),
.smallcase__portfolio__stocks table tbody tr td:nth-child(7),
.smallcase__portfolio__stocks table tbody tr td:nth-child(8),
.smallcase__portfolio__stocks table tbody tr td:nth-child(10) {
    font-weight: 700;
}

.smallcase__portfolio__stocks table tbody .portfolio__stock__code {
    display: flex;
    align-items: center;
}

.smallcase__portfolio__stocks table tbody .portfolio__stock__code .stock__status {
    width: 5px;
    height: 5px;
    background-color: var(--main-blue);
    border-radius: 100%;
}

.smallcase__portfolio__stocks table tbody .portfolio__stock__code .stock__code {
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 600;
}

.smallcase__portfolio__stocks table tbody .portfolio__stock__name {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--font-primary);
    font-size: 9px;
    font-weight: 500;
    letter-spacing: -0.2px;
}

.smallcase__portfolio__stocks table tbody .remove__stock {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.apply__smallcase__container .apply__smallcase__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.apply__smallcase__footer .smallcase__values {
    display: flex;
    column-gap: 30px;
}

.apply__smallcase__footer .smallcase__values p {
    border-radius: 5px;
    padding: 0px 0px;
    font-size: 11px;
    font-weight: 700;
}

.apply__smallcase__container .apply__smallcase__button button {
    background-color: var(--white);
    border: 1px solid #e8e8e8;
    padding: 8px 15px 8px 15px;
    font-size: 12px;
    font-weight: 500;
    color: var(--font-primary);
    border-radius: 3px;
    transition: 0.3s;
}

.load__smallcase__btn {
    width: 140px;
    height: 35px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 3px;
    border: 1px solid #e8e8e8;
    color: var(--font-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}


.smallcase__customer__table {
    border: none;
    border-radius: 15px;
    width: calc(100% - 340px);
    height: 100%;
    position: relative;
}

.smallcase__customer__table.full{
    width: 100%;
}

.smallcase__customer__table .scheme-data-wrapper{
    width: 100%;
    height: 90%;
    overflow-y: scroll;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 0px 0px 10px;
}

.scheme-data-wrapper .scheme-row{
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: 20px;
    border-bottom: 1px solid #f5f5f5;
    padding: 10px 15px 10px;
}

.scheme-data-wrapper .scheme-row .scheme-row-box{
    flex: 3;
    height: 40px;
    display: flex;
    align-items: center;
}

.scheme-data-wrapper .scheme-row .scheme-row-box.strategy{
    flex: 4;
}

.scheme-data-wrapper .scheme-row .scheme-row-box.arrow,
.scheme-data-wrapper .scheme-row .scheme-row-box.remove{
    flex: 1;
    cursor: pointer;
}

.scheme-data-wrapper .scheme-row .scheme-row-box span{
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
}

.scheme-data-wrapper .scheme-row:nth-child(1) .scheme-row-box span{
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
}

.smallcase__customer__table .customer__table__empty {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 15px;
}

.smallcase__customer__table .customer__table__empty>p {
    text-align: center;
    font-size: 14px;
    margin-top: 15px !important;
    font-weight: 500;
}

.smallcase__customer__table .customer__table__wrapper.hide{
    display: none;
}

.smallcase__customer__table .customer__table__wrapper {
    width: auto;
    overflow-y: scroll;
    border: 1px solid #f5f5f5;
    border-radius: 15px;
    display: block;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
}

.customer__table__wrapper table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.customer__table__wrapper table thead {
    position: sticky;
    inset-block-start: 0;
    z-index: 1;
    width: 100%;
    background-color: var(--white);
    box-shadow: 0 1px 1px #f5f5f5;
}


.customer__table__wrapper table thead tr td {
    text-align: left;
    padding: 5px 15px 5px 15px;
    font-weight: 700;
    font-size: 11px;
    line-height: 20px;
}

.customer__table__wrapper table tbody tr td {
    padding: 10px 15px;
    font-size: 13px;
    text-align: left;
    display: table-cell !important;
    font-weight: 500;
}

.customer__table__wrapper table td.customer__name {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .customer__table__wrapper table td.customer__portfolio,.customer__table__wrapper table td.customer__strategy{
    width: 180px;
} */
.customer__table__wrapper table td.customer__remove {
    width: 60px;
    cursor: pointer;
}

.customer__table__wrapper table td.customer__investment input {
    width: 100%;
    height: 25px;
    background-color: transparent;
    border: 1px solid #f5f5f5;
    border-radius: 3px;
    padding-left: 5px;
    color: var(--font-primary);
    font-weight: 600;
    font-size: 11px;
    outline: none;
}

.customer__table__wrapper table td.customer__investment input:focus {
    border-color: #d8d8d8;
}

.smallcase__customer__table .customer__smallcase__buttons {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 20px;
}

.smallcase__customer__table .customer__smallcase__buttons button {
    padding: 10px 20px;
    background-color: transparent;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    color: var(--font-primary);
}

.smallcase__customer__table .smallcase__customer__loading {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    background-color: rgba(255, 255, 255, 0.90);
    border: 1px solid #f5f5f5;
    border-radius: 15px;
    padding-top: 100px;
    z-index: 10000;
}

.smallcase__customer__table .smallcase__customer__loading>p {
    font-size: 14px;
    font-weight: 600;
}

.smallcase__customer__table .customer__stocks__table{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 0px;
    overflow-y: scroll;
}

.smallcase__customer__table .customer__stocks__table .customer__stocks__row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px 10px;
    border-bottom: 1px solid #f5f5f5;
}

.customer__stocks__row .stocks__row__config{
    display: flex;
    align-items: center;
    grid-column-gap: 20px;
    column-gap: 20px;
    width: 100%;
}

.customer__stocks__table .customer__stocks__row .config__box {
    display: flex;
    flex-direction: column;
    grid-column-gap: 10px;
    column-gap: 10px;
    flex: 5 1;
}

.customer__stocks__row .config__box span {
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
}

.customer__stocks__row .config__box.name{
    flex: 6 1;
}

.customer__stocks__row .config__box.name span,.customer__stocks__row .config__box.strategy span{
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customer__stocks__row .config__box.arrow, .customer__stocks__row .config__box.remove {
    flex: 2 1;
    cursor: pointer;
}

.customer__stocks__row:nth-child(1) .config__box span {
    font-weight: 700;
}

.customer__stocks__table .stocks__container__wrapper{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    width: 100%;
}

.customer__stocks__table .stocks__container__wrapper.hide{
    display: none;
}

.stocks__container__wrapper .stocks__options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.stocks__container__wrapper .stocks__options>p {
    font-size: 12px;
    font-weight: 700;
}

.stocks__container__wrapper .customer__stock__search {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f5f5f5;
    width: 280px;
    padding: 0 10px;
    border-radius: 5px;
}

.customer__stock__search input {
    background-color: transparent;
    height: 100%;
    width: 80%;
    outline: none;
    color: var(--font-primary);
    font-weight: 600;
    font-size: 12px;
    padding: 10px 0px;
    border: none;
    outline: none;
}

.customer__stock__search .filter-icon {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}