.stock__details__popup__overlay {
  position: fixed;
  inset: 0;
  background: #87888850;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
}
.stock__details__popup {
  z-index: 1000000;
  width: 700px;
  height: 450px;
  min-width: 700px;
  border-radius: 5px;
  transform: translateY(8px);
  background-color: var(--white);
  animation: getup 0.1s linear forwards;
  padding: 10px 20px;
}

.stock__details__popup.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
}

.stock__details__popup.loading>p{
  font-size: 14px;
  font-weight: 600;
}

@keyframes getup {
  0% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.sd__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
}

.sd__header .sd__heading {
  font-size: 14px;
  font-weight: 500;
  color: var(--font-primary);
}

.sd__header .sd__heading > span {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

.sd__header .sd__close {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  cursor: pointer;
}

.sd__header .sd__close > img {
  width: 30px;
}

.sd__body .sd__tables {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 20px;
}
.sd__body .sd__tables .buyers__table,
.sd__body .sd__tables .sellers__table {
  width: 48%;
  border: 1px solid #f5f5f5;
  min-height: 100px;
  border-radius: 15px;
}

.sd__body .sd__tables .buyers__table table,
.sd__body .sd__tables .sellers__table table {
  width: 100%;
}

.sd__body .sd__tables table thead{
    position: sticky;
    z-index: 1;
    inset-block-start: 0;
    width: 100%;
    background-color: var(--white);
    box-shadow: 0 1px 1px #f5f5f5;
}

/* .sd__body .sd__tables .sellers__table {
    color: var(--main-red)
}
.sd__body .sd__tables .buyers__table {
    color: var(--main-green)
} */

.sd__body .sd__tables .buyers__table table thead tr,
.sd__body .sd__tables .sellers__table table thead tr {
  border-bottom: 1px solid #e8e8e8;
}

.sd__body .sd__tables .buyers__table table thead th,
.sd__body .sd__tables .sellers__table table thead th {
    text-align: center;
    padding: 4px 10px 4px 10px;
    font-weight: 700;
    font-size: 11px;
    line-height: 20px;
}

.sd__body .sd__tables .buyers__table table tbody,
.sd__body .sd__tables .sellers__table table tbody {
  width: 100%;
}

.sd__body .sd__tables .buyers__table table tbody td,
.sd__body .sd__tables .sellers__table table tbody td {
    text-align: center;
  font-weight: 500;
  font-size: 12px;
  padding: 4px 10px 4px 10px;
}

.sd__body .sd__tables .buyers__table table tbody td.buy_quantity {
  color: var(--main-green);
  font-weight: 600;
}

.sd__body .sd__tables .sellers__table table tbody td.sell_quantity {
  color: var(--main-red);
  font-weight: 600;
}
.sd__body .sd__prices__wrapper {
  display: flex;
  justify-content: space-between;
}

.sd__body .sd__prices {
  width: 48%;
  margin-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.sd__body .sd__prices .sd__row {
  width: 100%;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
}

.sd__prices .sd__row .sd__row__title {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.sd__prices .sd__row .sd__row__value {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.sd__prices .sd__row .sd__row__value.buy_quantity {
  color: var(--main-green);
}

.sd__prices .sd__row .sd__row__value.sell_quantity {
  color: var(--main-red);
}

@media only screen and (max-width: 768px) {
  .stock__details__popup {
    min-width: 100%;
    min-height: 100%;
    padding: 5px 10px;
  }
  .stock__details__popup .sd__wrapper .sd__container {
    width: 100%;
    height: 100%;
    padding: 10px 10px 20px;
  }

  .sd__header .sd__heading {
    font-size: 14px;
  }

  .sd__header .sd__heading > span {
    font-size: 10px;
  }

  .sd__header .sd__close > img {
    width: 30px;
  }

  .sd__body .sd__tables {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .sd__body .sd__tables .buyers__table,
  .sd__body .sd__tables .sellers__table {
    width: 100%;
    border-radius: 3px;
    min-height: 130px;
    margin-bottom: 10px;
  }

  .sd__body .sd__tables .buyers__table table thead th,
  .sd__body .sd__tables .sellers__table table thead th {
    font-size: 12px;
  }

  .sd__body .sd__tables .buyers__table table tbody td,
  .sd__body .sd__tables .sellers__table table tbody td {
    font-size: 12px;
  }

  .sd__body .sd__prices__wrapper {
    flex-direction: column;
  }

  .sd__body .sd__prices {
    width: 100%;
  }

  .sd__prices .sd__row .sd__row__title {
    font-size: 12px;
  }

  .sd__prices .sd__row .sd__row__value {
    font-size: 12px;
  }
}
