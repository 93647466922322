.roles-management {
    width: 100%;
    height: 100%;
}

.roles-management .roles-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.roles-header .roles-title {
    font-size: 18px;
    /* color: var(--font-primary); */
    color: rgba(1, 22, 39, 0.90)98;
    font-weight: 700;
}

.roles-header .roles-subtext {
    font-size: 20px;
    font-weight: 300;
}

.roles-management .roles-data {
    width: 100%;
    height: 95%;
    margin-top: 10px;
    display: flex;
    column-gap: 10px;
}

.roles-management .roles-data.hide {
    display: none;
}

.roles-data .role-addition {
    width: 520px;
    height: 100%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px 15px;
    position: relative;
}

.role-addition .addition-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    background-color: #f9f9f950;
    z-index: 1000;
}

.role-addition .title {
    font-size: 13px;
    font-weight: 700;
}

.role-addition .role-addition-content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.role-addition-content .role-addition-row {
    display: flex;
    column-gap: 15px;
}

.role-addition .role-addition-footer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.role-addition .role-addition-footer .addition-error {
    font-size: 12px;
    font-weight: 600;
}

.roles-data .roles-users-table {
    width: 650px;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px 15px;
}

.roles-users-table .title {
    font-size: 13px;
    font-weight: 700;
}

.roles-users-table .users-table {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #f5f5f5;
    padding: 0px 5px;
    border-radius: 5px;
    max-height: 90%;
    overflow-y: scroll;
}

.roles-users-table .users-table table {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
}

.roles-users-table .users-table table thead {
    position: sticky;
    top: 0%;
    background-color: white;
}

.users-table table thead td {
    font-weight: 700;

}

.users-table table tr td {
    max-width: 150px;
    word-wrap: break-word;
    text-align: left;
    padding: 4px 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users-table table tr td.center {
    text-align: center;
}

.roles-users-table .users-loading,
.roles-users-table .users-empty {
    width: 100%;
    min-height: 120px;
    max-height: 350px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.roles-management .roles-customers {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.roles-management .roles-customers.hide {
    display: none;
}

.roles-customers .roles-customers-header {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.roles-customers-header .roles-customers-search {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f5f5f5;
    width: 280px;
    padding: 0 10px;
    border-radius: 5px;
}

.roles-customers-header .roles-customers-search input {
    background-color: transparent;
    height: 100%;
    width: 80%;
    outline: none;
    color: var(--font-primary);
    font-weight: 600;
    font-size: 12px;
    padding: 10px 0px;
    border: none;
    outline: none;
}

.roles-customers-header .roles-customers-search .filter-icon {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.roles-customers .roles-customers-content {
    width: 100%;
    height: 90%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.roles-customers .roles-customer-loading-content {
    width: 100%;
    height: 100%;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 6px;
    margin-top: 10px;
}

.roles-customer-loading-content .message {
    font-size: 16px;
    font-weight: 500;
}

.roles-customer-loading-content .roles-customers-add {
    padding: 10px 20px;
    background-color: var(--font-primary);
    color: var(--white);
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 30px;
    transition: 0.3s;
}

.roles-customer-loading-content .roles-customers-add:hover {
    opacity: 0.95;
}

.roles-customer-loading-content .roles-customers-add a {
    color: var(--white);
    text-decoration: none;
}

.roles-customers-content .customer-content-header {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    position: sticky;
    z-index: 1;
    inset-block-start: 0;
    background-color: white;
}

.roles-customers-content .cust-config {
    flex: 4;
    padding: 6px 15px;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.roles-customers-content .customer-content-header .cust-config {
    font-weight: 700;
}

.roles-customers-content .cust-config.name {
    flex: 6;
}

.roles-customers-content .cust-config.kyc {
    flex: 3;
}


.roles-customers-content .cust-config.arrow {
    flex: 1;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.roles-customers-content .cust-config a {
    color: var(--font-primary);
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
}

.roles-customers-content .cust-config.broker {
    flex: 2;
}

.roles-customers-content .cust-config.broker button {
    background-color: transparent;
    color: var(--font-primary);
    border: none;
    outline: none;
    padding: 4px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 11px;
    font-weight: 700;
    transition: 0.3s;
}

.roles-customers-content .cust-config.broker button:hover {
    opacity: 0.95;
}

.roles-customers-content .customer-content-body {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.customer-content-body .customer-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
}

.customer-content-body .customer-row .customer-info {
    width: 100%;
    display: flex;
    padding: 2px 0;
}

.customer-content-body .customer-row .customer-broker-info {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.customer-content-body .customer-row .customer-broker-info.hide {
    display: none;
}

.customer-row .customer-broker-info .broker-info-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
}

.customer-broker-info .broker-info-empty>p {
    font-size: 12px;
    font-weight: 700;
}

.customer-broker-info .customer-broker-table {
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    padding: 0 4px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.customer-broker-info .customer-broker-table table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.customer-broker-table table thead tr td {
    font-size: 12px;
    font-weight: 700;
    padding: 8px 10px;
}

.customer-broker-table table tbody tr td {
    font-size: 12px;
    font-weight: 500;
    padding: 8px 10px;
}