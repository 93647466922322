.sp__click__overlay {
  position: fixed;
  bottom: 0%;
  left: 0;
  background-color: rgba(189, 195, 199, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  animation: slideup 0.05s linear forwards;
}

@keyframes slideup {
  0% {
    bottom: -100%;
    left: 0%;
  }

  100% {
    bottom: 0%;
    left: 0%;
  }
}

.sp__click__wrapper {
  width: 100%;
  flex-grow: 1;
}

.sp__click__options {
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--white);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 2%);
  border-radius: 5px;
}

.sp__click__stock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #f3f3f3;
}

.sp__click__options .sp__click__divs {
  padding: 5px 20px;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.sp__click__options .sp__click__divs > span {
  margin-right: 10px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 12px;
  padding: 3px 0;
  font-weight: 500;
}

.sp__click__divs > span > img {
  width: 20px;
}

.sp__click__divs > span.buy__div {
  background-color: var(--main-green);
  color: var(--white);
}

.sp__click__divs > span.sell__div {
  background-color: var(--main-red);
  color: var(--white);
}

.sp__click__options .sp__click__divs > p {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.sp__hover__options {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: auto;
  z-index: 10;
}

.sp__hover__options .sp__hover__buttons {
  width: 45px;
  height: 25px;
  margin: 0px 1px 0px 3px;
  border-radius: 15px;
  background-color: #e8e8e8;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sp__hover__options .sp__hover__buttons > span {
  font-size: 13px;
  font-weight: 700;
}

.sp__hover__options .sp__hover__buttons:hover {
  background-color: #f5f5f5;
}

.sp__hover__options .sp__hover__buttons.buy__button,
.sp__hover__options .sp__hover__buttons.sell__button {
  font-weight: bold;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: 1px solid;
  letter-spacing: 0.2px;
}

.sp__hover__options .sp__hover__buttons.buy__button {
  border-color: var(--main-green);
  color: var(--main-green);
}

.sp__hover__options .sp__hover__buttons.sell__button {
  border-color: var(--main-red);
  color: var(--main-red);
}

.sp__hover__options .sp__hover__buttons > img {
  width: 16px;
}

.cash__position__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.cash__position {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border-radius: 15px;
  padding: 0 0px;
  overflow-y: hidden;
  border: 1px solid #f5f5f5;
}

.cash__position__zoom {
  padding: 0px 5px 0;
  border: none;
}

.cash__position .cp__watchlist__header {
  display: flex;
  justify-content: space-between;
  height: 8%;
}

.cash__position .cp__data {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px 5px;
  border-radius: 5px;
  flex-grow: 1;
}

.cash__position .cp__data .cp__watchlist {
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cash__position .cp__data .cp__watchlist .cp__watchlist__text {
  display: flex;
  flex-direction: column;
}

.cash__position .cp__data .cp__watchlist__loading {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cash__position .cp__data .cp__watchlist__loading > p {
  margin-top: 10px !important;
  font-size: 14px !important;
  color: var(--font-primary);
  font-weight: 500;
}

.cash__position .cp__dropdown {
  position: relative;
  display: flex;
  /* justify-content: center; */
  width: 100%;
}

.cash__position__wrapper .cp__watchlist__close {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px 3px 0 0;
  margin-right: 5px;
}

.cash__position__wrapper .cp__watchlist__close img {
  width: 30px;
}

.cash__position .cp__dropdown .custom-select {
  width: 100% !important;
  height: 45px !important;
  box-shadow: none;
}

.cash__position .cp__dropdown .custom-select .custom-select-container {
  min-width: 100% !important;
}

.cash__position .custom-select .custom-select-container .custom-select-option {
  height: 40px !important;
  display: flex;
  align-items: center;
}

.cash__position .cp__dropdown .cp__dropdown__icon {
  padding: 4px;
  margin-right: 4px;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}

.cash__position .cp__dropdown .cp__dropdown__options {
  position: absolute;
  width: 100%;
  background-color: var(--white);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.02);
  top: 100%;
  z-index: 100;
  display: none;
}

.cash__position .cp__dropdown .cp__dropdown__options p {
  padding: 8px 0px 8px 12px;
  cursor: pointer;
  margin: 0px 0px 2px 0px !important;
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.cash__position .cp__dropdown .cp__dropdown__options p:hover {
  background-color: #f2f2f2;
}

.cash__position .portfolio__cashposition {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  margin-top: 5px;
  width: 94%;
}

.cash__position .cashposition__portfolio__loading {
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cash__position .cashposition__portfolio__loading > p {
  font-size: 12px !important;
  font-weight: 600;
  color: var(--font-primary);
}

.cash__position .cp__title {
  display: flex;
  align-items: center;
  flex: 1;
}

.cash__position .cp__title img {
  width: 20px;
}

.cash__position .cp__title span {
  padding-left: 6px;
  font-size: 12px;
  /* font-weight: 600; */
  color: var(--font-primary);
}

.cash__position .cp__value {
  /* padding-left: 20px; */
  margin-top: -2px;
  color: var(--font-primary);
  flex: 1;
  text-align: right;
}

.cash__position .cp__value span {
  font-size: 14px;
  font-weight: 500;
}

.cash__position .cashposition__portfolio {
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #19E683; */
}

.cash__position .cp__deatils__row {
  width: 94%;
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.cp__deatils__row .cp__deatils__title,
.cp__deatils__row .cp__deatils__value {
  flex: 1;
  display: flex;
  align-items: center;
}

.cp__deatils__row .cp__deatils__title img {
  width: 20px;
}

.cp__deatils__row .cp__deatils__title p {
  font-size: 12px !important;
  color: var(--font-primary);
  /* font-weight: 600; */
  margin-left: 6px !important;
}

.cp__deatils__row .cp__deatils__value {
  justify-content: flex-end;
}

.cp__deatils__row .cp__deatils__value span {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.cash__position .cp__portfolio__details .cp__portfolio__edit,
.cash__position .cp__watchlist .cp__watchlist__edit {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* padding: 6px 10px; */
  border-radius: 2px;
  transition: 0.3s;
  /* box-shadow: 0 0 2px rgb(0 0 0 / 10%); */
}

.cash__position .cp__portfolio__details .cp__portfolio__edit img,
.cash__position .cp__watchlist .cp__watchlist__edit img {
  width: 16px;
  margin-right: 4px;
}

.cash__position .cp__portfolio__details .cp__portfolio__edit span,
.cash__position .cp__watchlist .cp__watchlist__edit span {
  font-size: 10px;
  font-weight: 500;
  color: var(--font-primary);
}

.cash__position .cp__watchlist .cp__watchlist__text > p {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.cash__position .cp__watchlist .cp__watchlist__text .cash-value {
  margin-top: 20px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  height: 20px;
}

.cash__position .cp__watchlist .cp__watchlist__text .cash-value > p {
  font-size: 13px;
  font-weight: 500;
}

.cash__position .cp__watchlist .cp__watchlist__text .cash-value > p > span {
  font-weight: 500;
  font-size: 28px;
}

.cash__position .cp__portfolio .cp__income {
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
  display: flex;
  align-items: flex-end;
}

.cash__position .cp__portfolio__value .cp__change {
  font-size: 9px;
  color: var(--main-green);
  font-weight: 700;
}

.cash__position .cp__portfolio__value .cp__change span {
  font-size: 12px;
}

.cash__position .cp__shares__container {
  margin-top: 5px;
  position: relative;
  width: 96%;
  height: 90%;
  overflow-y: scroll;
}

.cash__position .cp__shares__container.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding-top: 30px;
}

.cash__position .cp__shares__container.empty > img {
  width: 40%;
  margin-bottom: 20px;
}

.cp__shares__container .empty__watchlist {
  font-size: 14px;
  text-align: center;
  color: var(--font-primary);
  font-weight: 500;
}

.cp__shares__container .create__watchlist {
  padding: 10px 15px;
  border-radius: 35px;
  color: var(--font-primary);
  font-size: 12px;
  margin-top: 0;
  letter-spacing: 0;
  cursor: pointer;
  background-color: var(--white);
  border: 1px solid #e8e8e8;
  font-weight: 700;
}

.cash__position .cp__shares__container::-webkit-scrollbar {
  display: none;
}

.cash__position .share__profile__wrapper {
  margin-bottom: 0px;
  transition: 0.3s;
  padding: 10px 0px 10px 0px;
  position: relative;
  margin: 5px 0;
  background-color: var(--white);
  border-bottom: 1px solid #f5f5f5;
  cursor: grab;
}

.cash__position .share__profile__wrapper.loading-box {
  background-color: #fcfcfc;
  height: 50px;
  animation: blink 2s linear infinite;
}

@keyframes blink {
  0%,
  100% {
    background-color: #fcfcfc;
  }
  50% {
    background-color: #f3f3f3;
  }
}

.cash__position .share__profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .cash__position .share__profile__wrapper:hover
{
  background-color: #fbfbfb;
}

*/
.cash__position .share__profile .sp__name {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
  flex: 8;
}

.cash__position .share__profile .cp__price {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
}

.cash__position .share__profile .cp__change {
  font-size: 13px;
  font-weight: 600;
  flex: 3;
}

.cash__position .share__profile .cp__change__per {
  font-size: 12px;
  font-weight: 600;
  flex: 2;
}

.cash__position .share__profile .cp__icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.cash__position .share__profile .sp__profile {
  /* flex: 5; */
  display: flex;
  padding-left: 0px;
  /* align-items: center; */
}

.cash__position .share__profile .sp__drag {
  flex: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: grab;
}

.cash__position .share__profile .sp__options {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.cash__position .share__profile .sp__options__list {
  position: absolute;
  top: 105%;
  right: 0%;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 120px;
  z-index: 1000;
  padding: 8px 0px;
}

.cash__position .share__profile .sp__options__list > p {
  font-size: 11px;
  font-weight: 500;
  padding: 4px 10px;
  transition: 0.3s;
}

.cash__position .share__profile .sp__options__list > p:hover {
  background-color: #f8f8f8;
}

.cash__position .share__profile .sp__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 8;
  row-gap: 3px;
}

.cash__position .share__profile .sp__price {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}

.cash__position .share__profile .sp__fullname {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-blur);
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 12px;
}

.cash__position .sp__quantity {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  color: var(--font-primary);
}

.cash__position .sp__quantity .sp__quantity__value {
  padding-left: 6px;
}

.cash__position .sp__quantity .sp__average {
  display: flex;
  justify-content: flex-end;
  letter-spacing: 0.4px;
}

.cash__position .sp__quantity img {
  width: 16px;
  transform: rotateY(180deg);
}

.cash__position .sp__price {
  justify-content: flex-end;
}

.cash__position .sp__price .sp__income {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  height: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: "Lato", sans-serif !important;
}

.cash__position .share__profile .sp__price .sp__change {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 6px;
}

.cash__position .sp__price .cp__change {
  font-weight: 600;
  font-size: 12px;
}

.cash__position .sp__remove {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 10px;
}

.cash__position .sp__remove span {
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 3px;
}

.cash__position .sp__remove span:hover {
  background-color: #bce8ff;
}

.cash__position .share__profile .sp__quantity {
  font-size: 14px;
  font-weight: 700;
  color: var(--font-primary);
  flex: 1;
  display: flex;
  align-items: center;
}

.cash__position .share__profile .sp__quantity .sp__quantity__value {
  flex: 1;
  display: flex;
  align-items: center;
}

.cash__position .share__profile .sp__quantity .sp__quantity__value img {
  width: 18px;
  transform: rotateY(180deg);
}

.cash__position .share__profile .sp__quantity .sp__average {
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
  width: 50%;
}

.dl-list {
  max-height: 410px !important;
}

.watchlist__popup__overlay {
  position: fixed;
  inset: 0;
  background: #87888850;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
}

.watchlist__popup {
  position: absolute;
  width: 500px;
  height: 600px;
  min-width: 500px;
  background: var(--white);
  border-radius: 5px;
  transform: translateY(8px);
  animation: getup 0.1s linear forwards;
  transition: 0.3s;
}

@keyframes getup {
  0% {
    transform: translateY(8px);
  }

  100% {
    transform: translateY(0px);
  }
}

.watchlist__popup .watclist__popup__header {
  height: 10%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
}

.watchlist__popup .watclist__popup__header > p {
  margin: 0 !important;
  font-size: 13px !important;
  font-weight: 600;
  color: var(--font-primary);
}

.watchlist__popup .watclist__popup__header > span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s;
}

.watchlist__popup .watclist__popup__header > span:hover {
  background-color: #f6f6f6;
}

.watchlist__popup .watclist__popup__header > span > img {
  width: 30px;
}

.watchlist__popup .watchlist__popup__body {
  width: 100%;
  height: calc(100% - 60px);
  padding: 10px 0px;
}

.watchlist__popup__body .stock__search__wrapper {
  padding: 0 20px;
}

.watchlist__popup__body .stock__search {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border: 1px solid #e8e8e8;
  border-radius: 40px;
}

.watchlist__popup__body .stock__search > img {
  width: 16px;
}

.watchlist__popup__body .stock__search > input {
  width: 90%;
  margin-left: 15px;
  font-weight: 600;
  letter-spacing: -0.5px;
  color: var(--font-primary);
  border: none;
  outline: none;
  background-color: transparent;
  text-transform: uppercase;
}

.watchlist__popup__body .stock__search > input::placeholder {
  color: #888888;
  letter-spacing: 0.2px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.watchlist__popup__body .stock__suggestions {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  padding-top: 10px;
}

.watchlist__popup__body .stock__suggestions .stock__suggestions__loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  padding-top: 100px;
}

.watchlist__popup__body .stock__suggestions .stock__suggestions__loader > span {
  color: var(--font-primary);
  font-weight: 600;
  font-size: 13px;
}

.stock__suggestions .stock__suggestions__loader .stock__suggestions__empty {
  color: var(--font-primary);
  font-weight: 600;
  font-size: 13px;
}

.watchlist__popup__body .stock__suggestions > p {
  width: 100%;
  padding: 10px 20px !important;
  margin: 2px 0 !important;
  display: flex;
  transition: 0.3s;
  cursor: pointer;
}

.watchlist__popup__body .stock__suggestions > p:hover {
  background-color: #f7f7f7;
}

.watchlist__popup__body .stock__suggestions > p .search__highlight {
  font-weight: 600;
}

.watchlist__popup__body .stock__suggestions > p > span:nth-child(1) {
  flex: 3;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.watchlist__popup__body .stock__suggestions > p > span:nth-child(2) {
  flex: 7;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.watchlist__popup__body .stock__suggestions > p > span:nth-child(3) {
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.edit__portfolio__name {
  background: #fff;
  margin: 10px 0 0 0;
}

.edit__portfolio__name input {
  outline: none;
  border: none;
  box-shadow: 2px 2px 2px #eeecec, -2px -2px 2px #ebe9e9;
  margin-right: 4px;
  padding-left: 1ch;
  font-size: 12px;
  font-weight: 600;
}

.edit__portfolio__name button {
  font-size: 11px;
  font-weight: 900;
  margin: 4px;
  padding: 2px 3px 2px 3px;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {


  .cash__position {
    border-radius: 5px;
    border: none;
  }

  .cash__position .cp__data {
    padding: 0px 0;
  }

  .cash__position .cp__data .cp__watchlist {
    width: 100%;
    height: 25px;
  }

  .cash__position .cp__data .cp__watchlist__loading > p{
    font-size: 16px !important;
  }

  .cash__position .cp__watchlist .cp__watchlist__text > p{
    font-size: 16px;
  }

  .cash__position .cp__watchlist .cp__watchlist__text .cash-value{
    display: none;
  }

  .cash__position .cp__shares__container {
    padding: 0px 0px 0px;
    position: relative;
    width: 100%;
    height: calc(100% - 25px);
    overflow-y: scroll;
  }

  .cash__position .share__profile__wrapper {
    padding: 10px 0px;
  }

  .cash__position .share__profile .sp__name {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: var(--font-primary);
  }

  .cash__position .share__profile .sp__fullname {
    width: 200px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: var(--font-primary);
  }

  .cash__position .sp__quantity {
    font-size: 12px;
  }

  .cash__position .sp__quantity .sp__quantity__value {
    flex: 3;
    padding-left: 12px;
  }

  .cash__position .sp__quantity img {
    width: 14px;
  }

  .cash__position .sp__price .cp__change > div {
    margin-right: 2px;
  }

  .cash__position .share__profile .cp__price {
    font-size: 16px;
    line-height: 20px;
  }

  .cash__position .sp__price .cp__change span {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }

  .cash__position .share__profile .cp__change__per {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }


  .cash__position .share__profile .sp__quantity {
    font-size: 14px;
    font-weight: 700;
    color: var(--font-primary);
    flex: 1;
    display: flex;
    align-items: center;
  }

  .cash__position .share__profile .sp__quantity .sp__quantity__value {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .cash__position .share__profile .sp__quantity .sp__quantity__value img {
    width: 18px;
    transform: rotateY(180deg);
  }

  .cash__position .share__profile .sp__quantity .sp__average {
    font-size: 12px;
    font-weight: 700;
    color: var(--font-primary);
    width: 50%;
  }

  .sp__hover__options{
    column-gap: 4px;
  }
  
  .sp__hover__options .sp__hover__buttons {
    width: 60px;
    height: 35px;
    border-radius: 20px;
  }
  
  .sp__hover__options .sp__hover__buttons > span {
    font-size: 14px;
    font-weight: 700;
  }
  
  .sp__hover__options .sp__hover__buttons > img {
    width: 18px;
    height: 18px;
  }

  .dl-list {
    max-height: 410px !important;
  }

  .watchlist__popup {
    width: 100%;
    min-width: auto;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* top: 50%; */
    /* transform: translate(-50%,-50%); */
  }

  .watchlist__popup .watclist__popup__header {
    height: 60px;
    width: 100%;
    padding: 0 10px;
  }

  .watchlist__popup .watclist__popup__header > p {
    margin: 0;
    font-size: 16px !important;
  }

  .watchlist__popup .watchlist__popup__body {
    width: 100%;
  }

  .watchlist__popup__body .stock__search__wrapper {
    padding: 0 10px;
  }

  .watchlist__popup__body .stock__search {
    height: 50px;
  }

  .watchlist__popup__body .stock__suggestions .stock__suggestions__loader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .watchlist__popup__body .stock__suggestions {
    overflow-y: auto;
  }

  .watchlist__popup__body .stock__suggestions > p {
    padding: 10px 5px !important;
  }

  .watchlist__popup .watclist__popup__header > span > img {
    width: 30px;
  }

  .watchlist__popup__body .stock__search > img {
    width: 14px;
  }

  .watchlist__popup__body .stock__search > input {
    font-size: 16px;
    margin-left: 10px;
    font-weight: 600;
    text-decoration: none;
  }

  .watchlist__popup__body .stock__search > input::placeholder{
    font-size: 16px;
    letter-spacing: -0.5px;
  }

  .watchlist__popup__body
    .stock__suggestions
    .stock__suggestions__loader
    > span {
    font-size: 14px;
    font-weight: 600;
  }

  .stock__suggestions .stock__suggestions__loader .stock__suggestions__empty {
    font-size: 12px;
  }

  .watchlist__popup__body .stock__suggestions > p > span:nth-child(1) {
    flex: 4;
    font-size: 14px;
    font-weight: 600;
  }

  .watchlist__popup__body .stock__suggestions > p > span:nth-child(2) {
    flex: 6;
    font-size: 12px;
    font-weight: 500;
    width: 100px;
  }

  .watchlist__popup__body .stock__suggestions > p > span:nth-child(3) {
    flex: 1;
    font-size: 14px;
    font-weight: 600;
  }
}
