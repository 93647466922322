.smallcase__configure__popup
{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000000;
    width: 100%;
    height: 100%;
}
.smallcase__configure__popup .sc__popup__overlay
{
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.60);
    display: flex;
    justify-content: center;
    align-items: center;
}
.smallcase__configure__popup .sc__popup__overlay .sc__popup__content
{
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    box-shadow: 0 0 8px #e8e8e8;
    min-width: 500px;
    height: 60%;
    min-height: 200px;
    border-radius: 5px;
    padding: 0px 20px;
    animation: getup 0.1s linear forwards;
}

@keyframes getup
{
    0%
    {
        transform: translateY(8px);
    }
    100%
    {
        transform: translateY(0px);
    }
}

.sc__popup__content .sc__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;
    border-bottom: 1px solid #f3f3f3;
}

.sc__popup__content .sc__header .heading>p
{
    color: var(--font-primary);
    font-weight: 500;
    font-size: 14px;
}

.sc__popup__content .sc__header .close
{
    cursor: pointer;
}

.sc__popup__content .sc__header .close img
{
    width: 30px;
}

.sc__popup__content .sc__body
{
    width: 100%;
    height: 70%;
}

.sc__popup__content .sc__body .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}
.sc__popup__content .sc__body .row .custom-select .custom-select-container {
    max-height: 200px;
    overflow: scroll;
}
.sc__popup__content .sc__body .row input {
    height: 40px;
    width: 240px;
    border-radius: 3px;
    border: none;
    outline: none;
    border: 1px solid #e8e8e8;
    padding: 0px 10px;
    font-size: 12px;
}

.sc__popup__content .sc__body .row>span,
.sc__popup__content .sc__body .row>div>p
{
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 500;
}

.sc__popup__content .sc__footer {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.sc__popup__content .sc__footer .apply__button {
    display: flex;
    justify-content: center;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    font-size: 12px;
    padding: 8px 15px;
    color: var(--font-primary);
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
}

@media only screen and (max-width:768px)
{

   
    .smallcase__configure__popup .sc__popup__overlay .sc__popup__content
    {
        min-width: 350px;
        width: 95%;
        height: 60%;
        min-height: 400px;
    }

    
    .sc__popup__content .sc__header .heading>p
    {
        font-weight: 500;
    }
      
    .sc__popup__content .sc__body .row input {
        height: 40px;
        width: 240px;
    }

}