
.custom-checkbox
{
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    box-sizing: border-box;
    transition: background-color .35s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}


.custom-checkbox.disabled
{
    background-color: #f5f5f5;
    border: 1px solid #d8d8d8;
    /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.15); */
    cursor: auto;
}