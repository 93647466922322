/* broker trades management */

.broker-management {
  width: 100%;
  height: 100%;
}

.swift-broker-trades-management {
  width: 100%;
  height: 95%;
  padding: 30px 60px 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.swift-broker-trades-management.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding-top: 200px;
  font-size: 14px;
  font-weight: 600;
}

.swift-broker-trades-management .client-trade-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 10%;
}

.swift-broker-trades-management .client-trade-header .client-header-text {
  display: flex;
  flex-direction: column;
  row-gap: 0px;
}

.client-trade-header .client-header-text .client-title {
  font-size: 16px;
  color: var(--font-primary);
  font-weight: 600;
}

.client-trade-header .client-header-text .client-title span {
  font-weight: 500;
}

.client-trade-header .client-header-button button {
  background-color: var(--white);
  padding: 10px 12px;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--font-primary);
  border: 1px solid var(--font-primary);
  transition: 0.3s;
}

.client-trade-header .client-header-button button:hover {
  opacity: 0.95;
}

.swift-broker-trades-management .client-trades-table {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-top: 10px;
}

.client-trades-table .trades-table-menu {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.trades-table-menu .trades-status {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.trades-table-menu .trades-status .status-menu {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.trades-table-menu .trades-status .status-menu.active {
  font-weight: 700;
}

.trades-table-menu .trades-search {
  width: 300px;
  height: 40px;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.trades-table-menu .trades-search input {
  background-color: transparent;
  height: 100%;
  width: 80%;
  outline: none;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  padding: 10px 0px;
  border: none;
  outline: none;
}

.trades-table-menu .trades-search .filter-icon {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.client-trades-table .trades-table-wrapper {
  width: 100%;
  height: 90%;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  overflow-y: scroll;
}

.trades-table-wrapper table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.trades-table-wrapper table thead {
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.trades-table-wrapper table tbody {
  width: 100%;
}

.trades-table-wrapper tr {
  border-bottom: 1px solid #f1f1f1;
}

.trades-table-wrapper thead tr td {
  text-align: left;
  padding: 10px 10px 10px 10px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trades-table-wrapper tbody tr td {
  padding: 10px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trades-table-wrapper table thead tr td:nth-child(1) {
  max-width: 140px;
}

.trades-table-wrapper table tbody tr td:nth-child(2) {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trades-table-wrapper table tbody tr td .stock-info {
  display: flex;
  flex-direction: column;
}

.trades-table-wrapper table tbody tr td .stock-info .stock-name {
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
}

.trades-table-wrapper table tbody tr td .stock-info .stock-fullname {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.2px;
}

.trades-table-wrapper table tbody tr td.order-item-current-value {
  font-weight: 600;
}

.trades-table-wrapper table tbody tr td.empty-table-cell {
  width: 100%;
  height: 200px;
}

.trades-table-wrapper .empty-table-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.trades-table-wrapper .empty-table-data > p {
  font-size: 16px;
  font-weight: 500;
}

.swift-trades-uploads-wrapper {
  width: 450px;
  height: auto;
  background-color: var(--white);
  border-radius: 5px;
  padding: 10px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  position: relative;
}

.swift-trades-uploads-wrapper .swift-trades-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000000;
  inset: 0;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.8);
}

.swift-trades-uploads-wrapper .swift-trades-upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.swift-trades-upload .swift-upload-header {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swift-trades-upload .swift-upload-header > p {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-trades-upload .swift-upload-header .upload-header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.swift-trades-upload .swift-upload-content {
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 60px;
}

.swift-upload-content .browse-file {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.swift-upload-content .browse-file .message {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
}

.swift-upload-content .browse-file .file-browse-btn {
  padding: 8px 36px;
  background-color: #f1f1f1;
  color: var(--font-primary);
  border: 1px solid #e8e8e8;
  outline: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
}

.swift-upload-content .browse-file .uploaded-file {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}

.swift-upload-content .upload-file {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* funds request view css */

.broker-management .funds-request-wrapper {
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.broker-management .funds-request-wrapper .request-box {
  width: auto;
  border: 1px solid #e8e8e8;
  border-radius: 25px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.broker-management .funds-request-wrapper .request-box.loader {
  display: flex;
  justify-content: center;
  min-height: 200px;
  border: none;
}

.funds-request-wrapper .request-box .request-box-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.funds-request-wrapper .request-box .request-box-header img {
  width: 60px;
}

.funds-request-wrapper .request-box .request-box-header h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

.funds-request-wrapper .request-box .request-box-header h2 a {
  text-decoration: none;
  letter-spacing: -1px;
  color: var(--font-primary);
}

.funds-request-wrapper .request-box .request-box-header h2 a i {
  font-weight: 300;
}

.funds-request-wrapper .request-box .request-box-header span {
  font-size: 14px;
  font-weight: 500;
}

.funds-request-wrapper .request-box .request-box-body {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 25px 30px;
  background-color: #f1f1f1;
  border-radius: 10px;
  width: 430px;
}

.funds-request-wrapper .request-box .request-box-body > p {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
}

.funds-request-wrapper .request-box .request-box-body > p > span {
  font-weight: 700;
  font-size: 14px;
}

.funds-request-wrapper .request-box .request-box-body > h4 {
  font-weight: 500;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 14px;
}

.funds-request-wrapper .request-box .request-box-body > h4 > span {
  font-weight: 700;
}

.funds-request-wrapper .request-box .request-box-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
}

.broker-management .bonds-content-wrapper {
  height: 100%;
}

.broker-management .broker-bonds-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px 20px 0;
}

.broker-management .broker-bonds-wrapper .bonds-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: calc(100% - 40px);
}

.broker-management .bonds-table-wrapper {
  height: 100%;
}

.broker-management .bonds-table-wrapper table tr td.issue {
  width: 240px;
}

.broker-management .bonds-table-wrapper table tr td.type,
.broker-management .bonds-table-wrapper table tr td.yield,
.broker-management .bonds-table-wrapper table tr td.amount,
.broker-management .bonds-table-wrapper table tr td.price,
.broker-management .bonds-table-wrapper table tr td.shares,
.broker-management .bonds-table-wrapper table tr td.quantity,
.broker-management .bonds-table-wrapper table tr td.offer-yield,
.broker-management .bonds-table-wrapper table tr td.offer-price {
  width: 100px;
}

.broker-management .bonds-table-wrapper table tr td.status {
  width: 120px;
}

.broker-management .bonds-table-wrapper table tr td.offer-date,
.broker-management .bonds-table-wrapper table tr td.input-date {
  width: 160px;
}

.broker-management .bonds-table-wrapper .swift-account-btn {
  background: none;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 7px 15px;
  border: 1px solid #e8e8e8;
  background-color: var(--white);
  border-radius: 35px;
  color: var(--font-primary);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .broker-management .funds-request-wrapper .request-box {
    border: none;
  }

  .funds-request-wrapper .request-box .request-box-body {
    width: 100%;
  }
}
