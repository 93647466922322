.create-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  overflow-x: scroll;
}

.create-table th {
  width: 150px;
}

.create-table thead td {
  color: var(--font-primary);
  font-weight: 500;
  font-size: 12px;
}

.create-table tr {
  width: 80px;
}

.create-table tr td:first-child {
  padding: 0px 10px;
  text-align: left;
  /* font-weight: 500; */
  min-width: 120px;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: var(--white);
}

.create-table tbody tr {
  height: 20px;
}

.create-table tbody td,
.create-table thead td {
  padding-bottom: 0 !important;
  padding-top: 2px !important;
  padding-left: 15px !important;
  padding-right: 15px;
  height: 10px;
  border: none;
  padding: 0 30px;
  font-size: 11px;
  font-weight: 500;
  color: var(--font-primary);
}

.create-table tbody td:not(:first-child) {
  border: 1px solid #ccc;
  border-bottom-style: 1px solid;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}

.GlobalScrollBar {
  overflow: scroll;
  scroll-behavior: smooth;
  overflow-wrap: break-word;
  color: black;
}

.create-table td.cell__bold {
  font-weight: 700;
  color: var(--font-primary);
}

.create-table td.cell__blue {
  /* font-weight: 700; */
  color: var(--main-blue);
}

.create-table td.cell__normal {
  font-weight: 500;
}