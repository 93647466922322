.index__select__popup {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000000;
  width: 350px;
  height: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  background-color: var(--white);
  transform: translateX(100%);
  animation: slide 0.2s linear forwards;
  padding: 10px 0px 5px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.index__select__popup.hide{
  display: none;
}

.index__select__loading{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.index__select__popup .index__select__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 20px;
}

.index__select__popup .index__select__header > p {
  font-size: 16px;
  font-weight: 600;
  color: var(--font-primary);
}

.index__select__popup .index__select__header > div{
  cursor: pointer;
}

.index__select__popup .index__select__header .index__list__total {
  font-size: 10px;
  font-weight: 500;
  color: #b8b8b8;
  margin-top: 0px;
  display: inline-block;
}

.index__select__header .index__select__close {
  cursor: pointer;
}

.index__select__header .index__select__close img {
  width: 30px;
  height: 30px;
}

.index__select__popup .index__select__container {
  width: 100%;
  height: calc(100% - 50px - 80px);
  overflow-y: scroll;
  border-radius: 5px;
}

.index__select__popup .index__select__container .index_row{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 14px 20px;
  cursor: pointer;
  background-color: #ffffff;
  height: 60px;
}

.index__select__container .index_row:hover{
  background-color: #fbfbfb;
  cursor: pointer;
}

.index__select__popup .index__select__container .index_row.sticky{
  position: sticky;
  top: 0;
}

.index__select__popup .index__select__container .separator{
  width: 100%;
  height: 0.5px;
  background-color: #a8a8a8;
}

.index__select__container .index_row>div{
  flex: 2;
  text-align: left;
  font-weight: 500;
  font-size: 11px;
}

/* .index__select__container .index_row:first-child{
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
} */
/* 
.index__select__container .index_row:first-child>div{
  font-size: 11px;
  font-weight: 700;
} */

.index__select__container .index_row .index_row_left{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
}

.index__select__container .index_row .index_row_right{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.index__select__container .index_row .reorder{
  cursor: grab;
}

.index__select__container .index_row .name{
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
}

.index__select__container .index_row .price{
  font-size: 12px;
  font-weight: 600;
}

.index__select__container .index_row .change{
  font-size: 11px;
  font-weight: 600;
}

.index__select__container .index_row .action{
  text-align: center;
}

.index__select__container .index_row .pin_button span{
  background-color: #f5f5f5;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 600;
  transition: 0.3s;
}

.index__select__container .index_row .pin_button span:hover{
  background-color: #e8e8e8;
}

.index__select__container .index__select__item {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-bottom: 0.1px solid #f3f3ff;
}

.index__select__item .index__check {
  width: 10%;
  /* background-color: #e0e0e0; */
  height: 100%;
  display: flex;
  align-items: center;
}

.index__select__item .index__name {
  width: 60%;
  /* background-color: #bebebe; */
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
}

.index__select__item .index__options {
  width: 30%;
  /* background-color: #e7e7e7; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.index__select__item .index__options .index__option__wrapper {
  margin: 0 10px;
  padding: 2px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.index__select__item .index__options .index__option__wrapper:hover {
  background-color: #f0f0f0;
}

.index__select__item .index__options .index__option__wrapper img {
  width: 20px;
  height: 20px;
}

.index__select__popup .index__select__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 15px;
  padding: 0px 15px 5px;
  height: 80px;
}

.index__select__popup .index__select__footer button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 10px 0px;
  flex: 1;
  transition: 0.3s;
  color: var(--font-primary);
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.index__select__popup .index__select__footer button:hover {
  background-color: #f5f5f5;
}

.index__select__popup .index__select__footer button.disabled {
  background-color: #eaeaea;
  box-shadow: none;
  cursor: not-allowed;
  color: #b0b0b0;
}

.index__select__popup .index__select__footer button.disabled:hover {
  color: #b0b0b0;
}

@media only screen and (max-width: 768px) {
  .index__select__popup{
    width: 100%;
    height: 100%;
    box-shadow: none;
  }

  .index__select__popup .index__select__header{
    padding: 0 15px;
  }

  .index__select__popup .index__select__container .index_row{
    height: 70px;
    padding: 0 15px;
  }

  .index__select__container .index_row .reorder{
    display: flex;
    column-gap: 10px;
    cursor: default;
  }

  .index__select__container .index_row .reorder.mobile>div{
    cursor: pointer;
  }

  .index__select__container .index_row .name{
    font-size: 14px;
  }

  .index__select__container .index_row .price{
    font-size: 14px;
  }

  .index__select__container .index_row .change{
    font-size: 13px;
  }

  .index__select__popup .index__select__footer button {
    font-size: 15px;
    font-weight: 600;
  }

}
