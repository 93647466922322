.price__digit
{
    overflow: hidden;
    margin: 0px;
}

.price__digit__column
{
  display : flex;
  flex-direction : column;
  transition : 1s;
}

.digit__box
{
  display: flex;
  justify-content: center;
  align-items: center;
}