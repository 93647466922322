.swift-footer {
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.swift-footer .copyright-text {
  font-size: 12px;
  font-weight: 500;
}

.swift-footer .swift-social {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.swift-footer .swift-social svg {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .swift-footer {
    display: none;
  }
}
