.app__hero__page {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 20px;
}
.app__hero__loader{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.first>* {
    color: var(--font-primary);
    font-family: 'Open Sans', sans-serif;
    letter-spacing: -0.5px;
}

.first {
    width: 85%;
    margin: auto;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.firstone {
    width: fit-content;
    font-size: 80px;
    font-weight: 700;
    letter-spacing: -1px;
    color: rgba(1, 22, 39, 0.7);
}

.firsttag {
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
}

.firsttwo {
    font-size: 20px;
    font-weight: 300;
}

.firstthree {
    font-size: 20px;
    font-weight: 900;
    width: 50%;
    margin: auto;
    text-align: left;
    margin-top: 4%;
    margin-left: 0;
    letter-spacing: -1px;
}

.touch {
    position: fixed;
    right: 0;
    margin: 10px;
    margin-right: 0px;
    box-shadow: 1px 3px 5px #e8e8e8;
    padding: 10px 30px;
    border-radius: 40px 0px 0 40px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: -1px;
    font-size: 14px;
    background-color: #ffffff;
}

.touch a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--font-primary) !important;
    text-decoration: none !important;
}

.touch:hover {
    box-shadow: 1px 3px 5px #ccc;
}

.touch a:hover {
    font-weight: 900;
    letter-spacing: -1px;
}

.touch img {
    width: 25px;
    margin-right: 10px;
}

.logo img {
    position: fixed;
    top: 5%;
    width: 60px;
}

.process {
    width: 800px;
    margin: 3em auto;
    margin-left: 0;
    margin-top: 1%;
    cursor: default;
}

.process-items {
    display: table;
    margin: 0 0 10px;
    padding: 0;
    list-style-type: none;
    color: var(--font-primary);
    font-size: 18px;
    text-align: center;
}

.process li {
    display: table-cell;
    width: 25%;
    vertical-align: bottom;
    padding: 0 .5em;
    transform: scale(1) translateY(40px);
    transform-origin: bottom center;
    transition: transform .5s;
    font-size: 12px;
    letter-spacing: -1px;
    font-weight: 700;
    color: rgba(1, 22, 39, 0.90);
    opacity: 0.80;
}

.process .active {
    transform: scale(1.2) translateY(0);
}

.process em {
    display: block;
    margin-top: .5em;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
}

.processmobile {
    display: none;
}

.login {
    margin-top: 20px;
    width: fit-content;
}

.login a {
    color: var(--font-primary);
    text-decoration: none;
}

@media only screen and (max-width:900px) {

    .first {
        width: 90%;
        margin: auto;
        margin-top: 10%;
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .firstone {
        font-size: 3em;
        font-weight: 900;
        letter-spacing: -1px;
        margin-top: 30%;
    }

    .firsttag {
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        width: 75%;
    }

    .firsttwo {
        font-size: 20px;
        font-weight: 300;
        width: 75%;
        margin-top: 5%;
    }

    .firstthree {
        font-size: 16px;
        font-weight: 900;
        width: 100%;
        margin: auto;
        text-align: left;
        margin-top: 5%;
        margin-left: 0;
        letter-spacing: -1px;
    }

    .process {
        display: none;
    }

    .processmobile {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: fit-content;
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5%;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        margin: 1%;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 900;
        letter-spacing: -1px;
    }

    .card span {
        font-size: 12px;
        font-weight: 300;
    }

    .logo img {
        position: fixed;
        top: 5%;
        width: 35px;
    }


}