.admin-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-popup-wrapper {
  padding: 15px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  height: 85%;
  max-height: 650px;
  min-height: 600px;
  width: 400px;
  position: relative;
  background-color: #ffffff;
}

.login-popup-wrapper .popup-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-popup-wrapper .login-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.login-popup-wrapper .login-header img {
  width: 40px;
}

.login-popup-wrapper .login-header h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

.login-popup-wrapper .login-header a {
  text-decoration: none;
  letter-spacing: -1px;
  color: var(--font-primary);
}

.login-popup-wrapper .login-header h2 a i {
  font-weight: 300;
}

.login-popup-wrapper .login-header span {
  font-size: 14px;
  font-weight: 500;
}

.login-popup-wrapper .login-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 150px);
}

.login-popup-wrapper .login-body {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.login-popup-wrapper .swift-input-box {
  height: 90px;
}

.login-popup-wrapper .swift-input-box > p {
  font-weight: 700;
}

.login-popup-wrapper .swift-input-box input {
  width: 100%;
  height: 50px;
}

.login-body .resend-message {
  font-size: 12px;
  font-weight: 500;
  margin-top: -5px;
}

.login-body .resend-message.hide {
  opacity: 0;
}

.login-popup-wrapper .login-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.login-footer .swift-button-icon p {
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .login-body .resend-message {
    font-size: 14px;
  }
}
