.value-select-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
}

.value-select-wrapper .value-select-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    padding: 8px 8px;
    font-size: 12px;
    font-weight: 600;
}

.value-select-content .value-select-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.value-select-wrapper .value-select-options-wrapper{
    position: absolute;
    top: 105%;
    left: 0%;
    width: 100%;
}

.value-select-wrapper .value-select-options {
    position: fixed;
    background-color: white;
    box-shadow: 0px 0px 0px #f8f8f8;
    border: 1px solid #f5f5f5;
    border-radius: 3px;
    z-index: 1000;
    padding: 6px 6px;
}

.value-select-options .value-select-option {
    font-size: 11px;
    font-weight: 600;
    padding: 10px 4px;
    border-bottom: 1px solid #f5f5f5;
    width: 100%;
}

.value-select-options .value-select-option:hover {
    background-color: #fafafa;
}