.menu__section
{
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 1px 3px 5px #e8e8e8;
  /* animation: slideUp .2s linear forwards; */
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  padding: 0 50px;
}

@keyframes slideUp{
    0%
    {
        transform: translateY(100%);
    }
    100%{
        transform: translateY(0%);
    }
}

.menu__section .menu__section__wrapper 
{
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  padding: 40px 10px 0;
}

.menu__section .menu__section__wrapper .menu__section__close
{
  
  position: absolute;
  right: 1%;
  top: 1%;
  margin-left: 4px;
  cursor: pointer;
}

.menu__section .menu__section__wrapper .menu__section__close img
{
  width: 30px;
}

.menu__section .menu__content
{
  width: 100%;
  height: 100%;
}

@media only screen and (max-width : 768px)
{

  .menu__section
  {
    width: 100%;
    height: 100%;
    /* background-color: #cccccc; */
    z-index: 1000000;
    padding: 0 10px;
  } 

  .menu__section .menu__section__wrapper 
  {
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    padding: 40px 2px 0;
  }

  .menu__section .menu__section__wrapper .menu__section__close
  {    
    right: 5%;
    top: 3%;
  }

  .menu__section .menu__section__wrapper .menu__section__close img
  {
    width: 30px;
  }

  .menu__section .menu__content
  {
    overflow-y: scroll;
  }

  @media only screen and (max-width:768px)
  {
    .menu__section .menu__section__wrapper .menu__section__close
    {
      top:1%;
      right: 1%;
    }
  }

}