.app__login__screen {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.app__login__screen a{
    color: var(--font-primary);
    text-decoration: none;
}

.app__login__screen .app__login__process > p {
  margin-top: 10px !important;
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
  letter-spacing: -0.5px;
  text-align: center;
}

.app__login__screen .app__login__process .login__animation {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 160, 227, 0.8);
  border-radius: 50%;
  position: relative;
}

.app__login__screen .app__login__process .login__animation > span {
  position: absolute;
  background-color: rgba(0, 160, 227, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-block;
  animation: animate 1.5s linear infinite;
  animation-delay: calc(0.5s * var(--i));
}

@keyframes animate {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  90% {
    transform: scale(2);
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}

.app__login__screen .app__login__process .login__animation .rocket {
  position: relative;
  animation: rocket 0.1s ease infinite;
}

@keyframes rocket {
  0%,
  100% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(1px);
  }
}

.app__login__screen .app__login__process .login__animation .rocket > img {
  width: 60px;
}

.app__login__screen .app__login__process .login__animation .rocket::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 10px;
  height: 150px;
  background: linear-gradient(white, transparent);
  filter: blur(1px);
  opacity: 0.3;
}

.app__login__screen .app__login__noauth,
.app__login__screen .app__login__process {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app__login__screen .app__login__noauth h2 {
  font-size: 130px;
  font-weight: 300;
  letter-spacing: -2px;
}

.app__login__screen .app__login__unauthorized p {
  font-size: 34px;
  color: var(--font-primary);
  font-weight: 500;
  text-align: center;
}

.app__login__screen .app__login__noauth > p {
  color: #404040;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
}

.app__login__screen .brand {
  font-size: 30px!important;
  font-weight: 700!important;
  letter-spacing: -1px;
  background: linear-gradient(
    90deg,
    var(--font-primary),
    var(--main-brand) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.app__login__screen .app__login__button {
    margin-top: 40px;
}

.app__login__screen .app__login__button button{
    border: 1px solid #404040;
    background-color: transparent;
    border-radius: 3px;
    font-size: 16px;
    color: var(--font-primary);
    padding: 10px 50px;
    font-weight: 500;
    transition: .3s;
}

.app__login__screen .app__login__button:hover {
  opacity: 0.9;
}

.app__login__screen .app__login__button button a {
  text-decoration: none;
  color: var(--font-primary);
}

@media only screen and (max-width: 768px) {
  .app__login__screen .app__login__process > p {
    margin-top: 15px !important;
    font-size: 16px;
    font-weight: 600;
  }

  .app__login__screen .app__login__process .login__animation {
    width: 100px;
    height: 100px;
  }

  .app__login__screen .app__login__process .login__animation .rocket > img {
    width: 50px;
  }

  .app__login__screen .app__login__noauth,
  .app__login__screen .app__login__process {
    margin-top: 0px;
    padding: 20px 10px;
  }

  .app__login__screen .app__login__noauth h2 {
    font-size: 60px;
    color: var(--font-primary);
  }

  .app__login__screen .app__login__noauth p {
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px !important;
    margin-bottom: 20px !important;
    color: var(--font-primary);
  }

  .app__login__screen .app__login__button button {
    font-size: 12px;
    font-weight: 500;
  }
}
