.client-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.client-header .client-title {
    font-size: 18px;
    /* color: var(--font-primary); */
    color: rgba(1, 22, 39, 0.90)98;
    font-weight: 700;
}

.client-strategy-content {
    width: 100%;
    height: 100%;
    display: flex;
    column-gap: 20px;
}

.client-strategy-content .client-add {
    width: 450px;
    height: 100%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    position: relative;
}

.client-strategy-content .client-add .title,
.client-strategy-content .client-kyc-table .title {
    font-size: 13px;
    font-weight: 700;
    height: 5%;
}

.client-strategy-content .client-customers-table .title {
    font-size: 13px;
    font-weight: 700;
    height: 5%;
}

.client-add .client-add-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    background-color: #f9f9f950;
    z-index: 1000;
}

.client-strategy-content .client-kyc-table {
    width: 700px;
    height: 100%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
}

.client-strategy-content .client-customers-table {
    width: 900px;
    height: 100%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
}

.client-kyc-table .kyc-table {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #f5f5f5;
    border-radius: 15px;
    max-height: 95%;
    overflow-y: scroll;
}

.client-customers-table .customers-table {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #f5f5f5;
    border-radius: 15px;
    max-height: 95%;
    overflow-y: scroll;
}

.client-kyc-table .kyc-table.loading,
.client-kyc-table .kyc-table.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    border: none;
}

.client-customers-table .customers-table.loading,
.client-customers-table .customers-table.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    border: none;
}


.client-kyc-table .kyc-table.loading>p,
.client-kyc-table .kyc-table.empty>p {
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
}

.client-customers-table .customers-table.loading>p,
.client-customers-table .customers-table.empty>p {
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
}

.brokers-table.empty>p {
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
}

.kyc-table table {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
}

.kyc-table table thead,
.customers-table table thead {
    position: sticky;
    z-index: 1;
    inset-block-start: 0;
    background-color: white;
    box-shadow: 0 1px 1px #f5f5f5;
}

.kyc-table table tr td,
.customers-table table tr td {
    font-size: 12px;
    font-weight: 500;
    padding: 8px 10px;
}

.kyc-table table tr td a,
.customers-table table tr td a {
    color: var(--font-primary);
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
}

.kyc-table table thead tr td,
.customers-table table thead tr td {
    font-weight: 700;
}


.brokers-table table {
    width: 100%;
    font-size: 13px;
    font-weight: 500;
}

.brokers-table table thead {
    position: sticky;
    z-index: 1;
    inset-block-start: 0;
    background-color: white;
    box-shadow: 0 1px 1px #f5f5f5;
}

.brokers-table table thead tr td {
    font-weight: 700;
}

.brokers-table table tr td,
.brokers-table table tr td {
    font-size: 13px;
    font-weight: 500;
    padding: 8px 10px;
}

.swift-superuser-customer-popup {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    width: 90vw;
    height: 90vh;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
}

.swift-superuser-customer-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.swift-superuser-customer-popup-body {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.swift-superuser-customer-popup-body-row {
    display: flex;
    column-gap: 2px;
    font-size: 14px;
}

.swift-superuser-customer-popup-body-row>span {
    font-weight: 600;
}

.swift-superuser-customer-popup-btn {
    color: #011627;
    font-size: 12px;
    width: 130px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px;
    border: none;
    border-radius: 35px;
    background-color: #f0f0f0;
    cursor: pointer;
    height: fit-content;
}

.customers-table .customers-table-view-btn {
    color: #011627;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    background-color: #f0f0f0;
    cursor: pointer;
    height: fit-content;
}