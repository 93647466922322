.confirm-box {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000000;
    border-radius: 5px;
    transition: 0.4s;
    display: flex;
    justify-content: center;
    background-color: rgba(140, 140, 140, 0.50);
}

.confirm-box .confirm-box-container {
    margin-top: 100px;
    min-width: 400px;
    max-width: 500px;
    background-color: var(--white);
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    padding: 15px 20px 20px;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    position: relative;
}

.confirm-box-container .confirm-box-loader {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.45);
}

.confirm-box-container .confirm-box-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.confirm-box-header .confirm-box-title {
    font-size: 13px;
    font-weight: 600;
    color: var(--font-primary);
}

.confirm-box-header .confirm-box-close {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.confirm-box-container .confirm-box-body {
    margin-top: 15px;
}

.confirm-box-body .confirm-box-description {
    font-size: 13px;
}

.confirm-box-container .confirm-box-footer {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
}

.confirm-box-footer button {
    padding: 8px 30px;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
    background-color: var(--white);
    font-size: 12px;
    font-weight: 700;
    color: var(--font-primary);
}

.confirm-box-footer button:hover {
    background-color: #f5f5f5;
}


/* responsive ui */

@media only screen and (max-width: 768px){
    .confirm-box .confirm-box-container{
        min-width: 95%;
        max-width: 95%;
        padding: 10px 10px;
    }

    .confirm-box-header .confirm-box-title{
        font-size: 15px;
        font-weight: 700;
    }

    .confirm-box-body .confirm-box-description{
        font-size: 15px;
    }

    .confirm-box-footer button{
        font-size: 14px;
    }

}