.swift-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-client {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.welcome-message {
  font-size: 26px;
  font-weight: 300;
  line-height: 26px;
  color: var(--light-text-color);
}

.swift-dashboard .dashboard-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.swift-dashboard .dashboard-loading > p {
  font-size: 14px;
  font-weight: 600;
}

.swift-dashboard .dashboard-content {
  width: 100%;
  display: flex;
  height: 93%;
  column-gap: 20px;
  justify-content: space-between;
}

.dashboard-content .dashboard-left {
  display: flex;
  column-gap: 20px;
}

.swift-dashboard-news-mobile {
  display: none;
}

.swift-dashboard-news {
  width: 400px;
  height: 100%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 0;
  box-shadow: var(--light-box-shadow);
}

.swift-dashboard-news .dashboard-box-title {
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.swift-dashboard-news .dashboard-box-title .back-icon {
  display: none;
}

.swift-dashboard-news .dashboard-box-title .title {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-dashboard-news .dashboard-news-content {
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-news-content .news-menu {
  width: 100%;
  height: 8%;
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 0 15px;
}

.dashboard-news-content .news-menu > p {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.dashboard-news-content .news-menu > p.active {
  color: var(--font-primary);
  font-weight: 800;
}

.dashboard-news-content .news-content {
  height: 100%;
}

.dashboard-news-content .dashboard-news-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding-top: 60px;
}

.dashboard-news-content .dashboard-news-loading > p {
  font-size: 12px;
  font-weight: 600;
}

.dashboard-news-content .dashboard-news-google,
.dashboard-news-content .dashboard-news-twitter {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 0px 5px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.dashboard-news-content .dashboard-news-google.hide,
.dashboard-news-content .dashboard-news-twitter.hide {
  display: none;
}

.dashboard-news-google .google-feeds-row {
  width: 100%;
  padding: 5px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  border-bottom: 1px solid #f5f5f5;
}

.dashboard-news-google .google-feeds-row > p {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--font-primary);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-news-google .google-feeds-row > p:nth-child(1) {
  cursor: pointer;
}

.dashboard-news-google .google-feeds-row > p:nth-child(1):hover {
  color: #000000;
}

.dashboard-news-google .google-feeds-row > p > span {
  font-weight: 500;
  font-size: 11px;
  color: var(--text-color-blur);
}

/* .dashboard-news-content .dashboard-news-twitter {
    width: 100%;
    height: 48%;
} */

.dashboard-news-twitter .twitter-feeds-row {
  width: 100%;
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  border-bottom: 1px solid #f5f5f5;
}

.twitter-feeds-row .twitter-row-profile {
  width: 10%;
}

.twitter-feeds-row .twitter-row-profile img {
  width: 100%;
  border-radius: 50%;
}

.twitter-feeds-row .twitter-row-content {
  width: 85%;
}

.twitter-row-content .twitter-header-row {
  width: 100%;
  display: flex;
  column-gap: 4px;
}

.twitter-row-content .twitter-header-row > span.name {
  font-weight: 700;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

.twitter-row-content .twitter-header-row > span.time,
.twitter-row-content .twitter-header-row > span.screen-name {
  color: #71767b;
  font-size: 13px;
}

.twitter-row-content .twitter-header-row > span.link {
  cursor: pointer;
  margin-left: 5px;
}

.twitter-row-content .twitter-row-text {
  width: 100%;
  font-size: 13px;
}

.twitter-row-content .twitter-row-text .twitter-text-hashtag,
.twitter-row-content .twitter-row-text a {
  font-weight: 500;
  color: #1da1f2;
  text-decoration: none;
}

.swift-dashboard-top {
  height: 100%;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.swift-dashboard-top .dashboard-box-title {
  width: 100%;
  height: 20px;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-dashboard-top .dashboard-business-title h3 {
  font-size: 18px;
  font-weight: 700;
}

.swift-dashboard-top .dashboard-business-title p {
  font-size: 18px;
  font-weight: 300;
}

.swift-dashboard-top .dashboard-data-snapshot {
  width: 100%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 15px;
  height: 35%;
  position: relative;
  box-shadow: var(--light-box-shadow);
}

.swift-dashboard-top .dashboard-data-snapshot #broking-history {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 15px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.dashboard-data-snapshot .dashboard-snapshot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.dashboard-snapshot .data-snapshot-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 15px;
}

.dashboard-snapshot .data-snapshot-row .snap-data-box {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  flex: 3;
}

.dashboard-data-snapshot .data-snapshot-row .snap-data-box:first-child {
  flex: 4;
}

.snap-data-box .snap-box-title {
  font-size: 11px;
  font-weight: 500;
}

.snap-data-box .snap-box-value-box {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.snap-data-box .snap-box-value {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.snap-data-box .snap-box-value.hero {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.snap-data-box .snap-box-value.hero-huge {
  font-size: 24px;
  line-height: 35px;
}

.snap-data-box .snap-box-subtext {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.snap-data-box .snap-box-subtext .change {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  font-size: 14px;
  font-weight: 600;
}

.swift-dashboard-top .customer-overview {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  padding-left: 5px;
}

.swift-dashboard-top .customer-overview .snap-data-box {
  padding: 15px;
  width: 325px;
  border-radius: 10px;
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
}

.swift-dashboard-top .customer-overview .snap-box-title {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.swift-dashboard-top .customer-overview .snap-box-title a {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: var(--font-primary);
  text-decoration: none;
}

.swift-dashboard-top .dashboard-data-charts {
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}

.swift-dashboard-top .top-clients {
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  padding: 10px 0px;
  width: 100%;
  border-radius: 15px;
  height: 63%;
  box-shadow: var(--light-box-shadow);
}

.swift-dashboard-top .clients-wrapper {
  justify-self: flex-end;
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  overflow-y: scroll;
  padding: 10px 5px 0 5px;
}

.swift-dashboard-top .dash-box-title {
  font-size: 12px;
  font-weight: 600;
  padding: 0 15px;
}

.clients-wrapper .clients-wrapper-header {
  width: 100%;
  padding: 0 5px 0 10px;
  display: flex;
}

.clients-wrapper .clients-wrapper-header > div {
  font-size: 12px;
  font-weight: 700;
}

.clients-wrapper .client-row > div:nth-child(1) {
  flex: 6;
}

.clients-wrapper .client-row > div:nth-child(2) {
  flex: 4;
}

.clients-wrapper .client-row > div:nth-child(3) {
  flex: 4;
}

.clients-wrapper .client-row > div:nth-child(4) {
  flex: 4;
}

.clients-wrapper .top-client-row {
  width: 100%;
  display: flex;
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #f5f5f5;
}

.top-client-row .client-name {
  font-size: 12px;
  font-weight: 500;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-client-row .client-role {
  font-size: 10px;
  font-weight: 500;
}

.top-client-row .client-data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}

.top-client-row .client-data .client-info {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}

.top-client-row .client-data .client-info p {
  font-size: 12px;
  font-weight: 500;
  line-height: 13px;
}

.top-client-row .client-investment > p,
.top-client-row .client-value > p,
.top-client-row .client-cash > p {
  font-size: 12px;
  font-weight: 500;
}

.revenue-trend {
  width: 500px;
  height: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.revenue-trend .dash-box-title {
  font-size: 12px;
  font-weight: 800;
}

.revenue-trend .dash-box-subtitle {
  font-size: 10px;
  font-weight: 500px;
  line-height: 12px;
  margin-top: 5px;
}

.revenue-trend .revenue-chart-wrapper {
  height: 80%;
}

.swift-dashboard-bottom {
  width: 420px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dash-bottom-box {
  width: 100%;
  height: 460px;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
}

.swift-dashboard-bottom .client-search {
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  box-shadow: var(--light-box-shadow);
  border-radius: 15px;
  padding: 10px 15px;
  height: 30%;
}

.client-search .client-search-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.client-search .client-name {
  font-size: 13px;
  font-weight: 600;
}

.client-search .client-search-header .client-search-wrapper {
  display: flex;
  flex-direction: column;
  column-gap: 5px;
}

.client-search-header .client-search-wrapper p {
  font-size: 12px;
  font-weight: 700;
}

.client-search-header .client-search-wrapper span {
  font-size: 12px;
  font-weight: 500;
}

.client-search .client-search-data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 2px;
  margin-top: 10px;
}

.client-search-data .client-search-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-items: center;
  margin-top: 25px;
}

.client-search-data .client-search-empty > p {
  font-size: 13px;
  font-weight: 500;
}

.client-search-data .search-data-box-row {
  display: flex;
  column-gap: 2px;
  width: 100%;
  margin-top: 4px;
}

.client-search-data .search-data-box {
  flex: 2;
}

.client-search-data .search-data-box-row .search-data-box:nth-child(1) {
  flex: 3;
}

.client-search-data .search-data-box-row .search-data-box:nth-child(2) {
  flex: 4;
}

.client-search-data .search-data-box.data-box-email {
  width: auto;
}

.client-search-data .search-data-box span {
  font-size: 11px;
  font-weight: 500;
}

.client-search-data .search-data-box p {
  font-size: 12px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  margin-top: -2px;
  color: #000000;
}

.tasks-wrapper {
  width: 100%;
  height: 68%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  box-shadow: var(--light-box-shadow);
  border-radius: 15px;
  padding: 15px 10px;
}

.tasks-wrapper .tasks-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.tasks-wrapper .dash-bottom-box-title {
  font-size: 12px;
  font-weight: 600;
}

.tasks-wrapper .tasks-content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 90%;
  padding-top: 20px;
  padding-right: 10px;
  overflow-y: scroll;
}

.tasks-wrapper .tasks-content .tasks-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.tasks-wrapper .tasks-content .tasks-loader p {
  font-size: 13px;
  font-weight: 500;
}

.tasks-content .top-task-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.tasks-content .task-content-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  row-gap: 5px;
}

.tasks-content .task-content-empty > p {
  font-size: 13px;
  font-weight: 500;
}
.top-task-row .top-task-data {
  width: 80%;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
}

.top-task-data .task-dates {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  font-size: 11px;
  font-weight: 300;
}

.top-task-data .task-description {
  font-size: 12px;
  font-weight: 500;
  word-break: break-all;
}

.top-task-row .top-task-action {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

/* broking history popup */
.broker-history-popup {
  width: 550px;
  height: 600px;
  background-color: var(--white);
  border-radius: 5px;
  padding: 15px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  position: relative;
}

.broker-history-popup .broker-history-popup-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}

.broker-history-popup .broker-history-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.broker-history-header .broker-history-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.broker-history-header .broker-history-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.broker-history-popup .broker-history-body {
  padding: 10px 0 20px;
}

.broker-history-body .broker-history-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.broker-history-body .broker-history-empty > p {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.broker-history-body .broker-history-table {
  width: 100%;
  height: 520px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  overflow-y: scroll;
}

.broker-history-body .broker-history-table table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.broker-history-table table thead {
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.broker-history-table table tfoot {
  position: sticky;
  z-index: 1;
  inset-block-end: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 -1px 1px #f5f5f5;
}

.broker-history-table table thead tr td,
.broker-history-table table tfoot tr td {
  text-align: left;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
}

.broker-history-table table tbody tr td {
  text-align: left;
  padding: 8px 10px 8px 10px;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: var(--font-primary);
}

/*             ===========================================Task Popup Css ======================= */

.swift-superuser-task-popup {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 15px 20px 15px;
  width: 450px;
  height: 550px;
}

.swift-superuser-task-popup .task-popup-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding-top: 100px;
}

.swift-superuser-task-popup .task-popup-loader p {
  font-size: 13px;
  font-weight: 500;
}

.swift-superuser-task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swift-superuser-task-title {
  font-size: 13px;
  font-weight: 600;
}
.swift-superuser-task-body {
  padding-top: 30px;
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.swift-superuser-task-submit-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}
@media only screen and (max-width: 768px) {
  .swift-dashboard .dashboard-loading {
    border: none;
  }

  .swift-dashboard {
    justify-content: flex-start;
    row-gap: 10px;
  }

  .welcome-message {
    font-size: 22px;
  }

  .swift-dashboard .dashboard-loading > p {
    font-size: 16px;
  }

  .swift-dashboard-top {
    width: 100%;
    row-gap: 20px;
  }

  .dashboard-client {
    padding: 0 10px;
  }

  .swift-dashboard .dashboard-content {
    flex-direction: column;
    overflow-y: scroll;
    justify-content: flex-start;
    row-gap: 20px;
  }

  .swift-dashboard .dashboard-content::-webkit-scrollbar {
    display: none;
  }

  .swift-dashboard-top .customer-overview {
    row-gap: 10px;
    padding-left: 0;
    padding: 0 10px;
  }

  .dashboard-content .dashboard-left {
    flex-direction: column;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
  }

  .swift-dashboard-top .customer-overview .snap-box-title {
    font-weight: 700;
    font-size: 14px;
  }

  .snap-data-box .snap-box-value.hero-huge {
    font-size: 26px;
  }

  .swift-dashboard-top .customer-overview .snap-data-box {
    border-top: none;
    width: 100%;
    padding: 5px 0;
    border-bottom: none;
  }

  .swift-dashboard-news-mobile {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    row-gap: 15px;
    padding-bottom: 15px;
  }

  .swift-dashboard-news-mobile > p {
    font-size: 26px;
    font-weight: 600;
    color: var(--font-secondary);
  }

  .swift-dashboard-news-mobile .news-button-row {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  .swift-dashboard-news-mobile .news-button {
    display: flex;
    column-gap: 10px;
    cursor: pointer;
    align-items: center;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: #f1f1f1;
    border: 1px solid #e8e8e8;
  }

  .swift-dashboard-news-mobile .news-button > p {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  .swift-dashboard-news {
    width: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    transition: 0.2s;
    border: none;
  }

  .swift-dashboard-news.active {
    left: 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    z-index: 10000;
  }

  .dashboard-news-content .dashboard-news-google,
  .dashboard-news-content .dashboard-news-twitter {
    row-gap: 0px;
  }

  .dashboard-news-google .google-feeds-row {
    border: none;
    padding: 20px 0;
    border-bottom: 1px solid #e8e8e8;
  }

  .swift-dashboard-news .dashboard-box-title {
    column-gap: 10px;
    padding: 0 10px;
    height: 30px;
  }

  .swift-dashboard-news .dashboard-box-title .back-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swift-dashboard-news .dashboard-box-title .title {
    font-size: 18px;
  }

  .swift-dashboard-news .dashboard-news-content {
    height: calc(100% - 60px);
  }

  .dashboard-news-google .google-feeds-row > p {
    font-size: 16px;
  }

  .dashboard-news-google .google-feeds-row > p > span {
    font-size: 13px;
  }

  .swift-dashboard-top .dashboard-data-snapshot {
    height: auto;
  }

  .swift-dashboard-bottom {
    width: 100%;
    row-gap: 20px;
  }

  .swift-dashboard-top .top-clients {
    height: auto;
  }

  .swift-dashboard-bottom .client-search {
    width: 100%;
    height: auto;
  }

  .broker-history-popup {
    width: 100%;
    height: 100%;
  }
}
