.family-portfolios {
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: 20px;
  padding-top: 10px;
}

.family-portfolios .family-portfolios-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-top: 100px;
}

.family-portfolios .family-portfolios-loading > p {
  font-size: 14px;
  font-weight: 600;
}

.family-portfolios-left {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.family-portfolios-left .family-portfolios-header {
  height: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 0px;
}

.family-portfolios-left .family-portfolios-header > p {
  font-size: 13px;
  font-weight: 700;
}

.family-portfolios-header .family-portfolios-add {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.family-portfolios-left .family-portfolios-wrapper {
  width: 100%;
  height: 90%;
  padding: 0px 10px 0px;
  overflow-y: scroll;
}

.family-portfolios-left .family-portfolios-empty {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
}

.family-portfolios-empty > p {
  font-size: 13px;
  font-weight: 600;
}

.family-portfolios-empty > button {
  padding: 8px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  color: var(--font-primary);
  background-color: var(--white);
  margin-top: 15px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.family-portfolios-wrapper .family-portfolio-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 5px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.family-portfolios-wrapper .family-portfolio-card:hover {
  background-color: #fafafa;
}

.family-portfolio-card .family-card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.family-portfolio-card .family-card-header > p {
  font-size: 13px;
  font-weight: 600;
}

.family-portfolio-card .family-card-header > span {
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.family-portfolio-card .family-card-details {
  font-size: 11px;
  font-weight: 500;
  margin-top: -5px;
}

.family-portfolios-right {
  width: 78%;
  height: 100%;
  height: 100%;
  overflow-y: scroll;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.family-portfolios-right .family-portfolio-details-default {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.family-portfolio-details-default > h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--font-primary);
}

.family-portfolio-details-default > p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.family-portfolio-details-default > button {
  padding: 8px 20px;
  border: 1px solid #e8e8e8;
  background-color: var(--white);
  border-radius: 3px;
  color: var(--font-primary);
  margin-top: 15px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.family-portfolio-details {
  width: 100%;
  height: 100%;
  padding: 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.family-portfolio-details .family-details-section {
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  border-radius: 15px;
}
.family-portfolio-details .family-portfolio-customers-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.family-portfolio-customers-empty > p {
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}

.family-portfolio-customers-empty > button {
  padding: 8px 20px;
  border: 1px solid #e8e8e8;
  background-color: var(--white);
  border-radius: 3px;
  color: var(--font-primary);
  margin-top: 15px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.family-portfolio-details .family-portfolio-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding-top: 100px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.family-portfolio-details .family-portfolio-loading > p {
  font-size: 14px;
  font-weight: 600;
}

.family-portfolio-details .family-name-options {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
}

.family-portfolio-details .family-name-options .family-name {
  font-size: 16px;
  font-weight: 800;
}

.family-portfolio-details .family-name-options .family-options {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 10px;
}

.family-name-options .family-options > span {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  background-color: #f5f5f5;
  padding: 6px 16px;
  border-radius: 20px;
  color: var(--font-primary);
}

.family-name-options .family-options > span:hover {
  color: var(--font-primary);
}

.family-portfolio-details .family-details-title {
  font-size: 12px;
  font-weight: 500;
}

.family-portfolio-details .family-portfolio-customers {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.family-portfolio-customers .customers-header {
  display: flex;
  column-gap: 10px;
}

.family-portfolio-customers .customers-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 10px;
}

.family-portfolio-customers .customers-wrapper .customer-box {
  width: fit-content;
  padding: 5px 10px;
  border: 1px solid #e8e8e8;
  border-radius: 30px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.customers-wrapper .customer-box .customer-name {
  font-size: 12px;
  font-weight: 600;
}

.customers-wrapper .customer-box .customer-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.family-portfolios-right .family-portfolio-config {
  width: 100%;
  display: flex;
}

.family-portfolio-config .family-config-box {
  width: 200px;
}

.family-portfolio-config .family-config-box > span {
  font-size: 14px;
  font-weight: 700;
}

.family-portfolios-right .family-portfolio-stocks {
  width: 100%;
}

.family-portfolio-stocks .family-stocks-table {
  width: 100%;
  border-radius: 15px;
  border: 1px solid #f5f5f5;
  margin-top: 10px;
}

.family-stocks-table table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.family-stocks-table table thead {
  border-bottom: 1px solid #f5f5f5;
}

.family-stocks-table thead tr td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
}

.family-stocks-table thead tr td.name {
  width: 155px;
}

.family-stocks-table thead tr td.quantity,
.family-stocks-table thead tr td.weight {
  width: 80px;
}

.family-stocks-table thead tr td.returns,
.family-stocks-table thead tr td.today {
  width: 80px;
}

.family-stocks-table tbody tr {
  border-bottom: 1px solid #f5f5f5c5;
}

.family-stocks-table tbody tr td {
  text-align: left;
  padding: 12px 10px 12px 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.family-stocks-table tbody tr td .stock-name-code {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.family-stocks-table tbody tr td .stock-name-code > p {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  line-height: 14px;
}

.family-stocks-table tbody tr td .stock-name-code > span {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.2px;
  line-height: 12px;
}

.family-portfolios-right .family-portfolio-charts {
  width: 100%;
  display: flex;
  column-gap: 40px;
  padding: 0 15px;
  padding-bottom: 20px;
}

.family-portfolio-charts .family-sector,
.family-portfolio-charts .family-market {
  width: 580px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 10px;
  row-gap: 10px;
}

.family-portfolio-details .strategy__sector__chart {
  width: 100%;
  height: 300px;
}

.strategy__sector__chart ul::-webkit-scrollbar {
  width: 5px;
}

.strategy__sector__chart ul::-webkit-scrollbar-track {
  background: #ffffff;
}

.strategy__sector__chart ul::-webkit-scrollbar-thumb {
  background: #e8e8e8;
  border-radius: 10px;
}

/* rename family portfolio */
.family-rename-popup {
  width: 450px;
  height: fit-content;
  background-color: var(--white);
  border-radius: 5px;
  padding: 15px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  position: relative;
}

.family-rename-popup .family-rename-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.family-rename-header .family-rename-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.family-rename-header .family-rename-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.family-rename-popup .family-rename-body {
  padding: 10px 0 20px;
}

.family-rename-popup .family-rename-footer {
  display: flex;
  justify-content: flex-end;
}

/* new family portfolio */
.family-new-popup {
  width: 450px;
  height: fit-content;
  background-color: var(--white);
  border-radius: 5px;
  padding: 15px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  position: relative;
}

.family-new-popup .family-new-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.family-new-header .family-new-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.family-new-header .family-new-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.family-new-popup .family-new-body {
  padding: 10px 0 20px;
}

.family-new-popup .family-new-footer {
  display: flex;
  justify-content: flex-end;
}

/* family customer add */
.family-customer-popup {
  position: relative;
  width: 450px;
  height: fit-content;
  background-color: var(--white);
  border-radius: 5px;
  padding: 15px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

.family-customer-popup .family-customer-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.family-customer-header .family-customer-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.family-customer-header .family-customer-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.family-customer-popup .family-customer-body {
  padding: 10px 0 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.family-customer-popup .family-customer-footer {
  display: flex;
  justify-content: flex-end;
}

.family-rename-popup .family-rename-popup-loader,
.family-new-popup .family-new-popup-loader,
.family-customer-popup .family-customer-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}

/* responsive ui */

@media only screen and (max-width: 768px) {
  .family-portfolios {
    flex-direction: column;
    row-gap: 20px;
    padding-top: 0;
  }

  .family-portfolios-left {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0 10px;
    height: 120px;
  }

  .family-portfolios-left .family-portfolios-header {
    padding: 0px 0px 0px;
    height: 30px;
  }

  .family-portfolios-left .family-portfolios-header > p{
    font-size: 15px;
  }

  .family-portfolio-details-default > h2{
    font-size: 22px;
  }

  .family-portfolio-details-default > p{
    font-size: 15px;
  }

  .family-portfolio-details-default > button {
    font-size: 16px;
    margin-top: 40px;
  }

  .family-portfolios-left .family-portfolios-wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 80px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    overflow-y: initial;
    overflow-x: scroll;
    padding: 5px 5px;
  }

  .family-portfolios-left .family-portfolios-empty{
    display: none;
  }

  .family-portfolios-left .family-portfolios-wrapper::-webkit-scrollbar {
    display: none;
  }

  .family-portfolios-wrapper .family-portfolio-card {
    width: 200px;
    height: 100%;
    flex-shrink: 0;
    border: 1px solid #e8e8e8;
  }

  .family-portfolios-right {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .family-portfolios-right::-webkit-scrollbar{
    display: none;
  }

  .family-portfolios-right .family-portfolio-details-default {
    border: none;
    justify-content: flex-start;
  }

  .family-new-popup {
    width: 100%;
    height: 100%;
    padding: 15px 10px 20px;
  }

  .family-portfolio-details .family-details-section {
    padding: 0 10px;
    row-gap: 20px;
  }

  .family-portfolio-details .family-name-options {
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 0;
  }

  .family-portfolio-details .family-name-options .family-options {
    column-gap: 10px;
  }

  .family-name-options .family-options > span{
    border-radius: 10px;
    font-size: 13px;
    line-height: 15px;
  }

  .family-portfolio-details .family-details-title{
    font-size: 14px;
    font-weight: 500;
  }

  .family-portfolio-config .family-config-box > span{
    font-size: 16px;
  }

  .customers-wrapper .customer-box .customer-name{
    font-size: 13px;
  }

  .family-portfolios-right .family-portfolio-config {
    display: grid;
    row-gap: 10px;
    grid-template-columns: auto auto;
  }

  .family-portfolio-config .family-config-box {
    width: auto;
  }

  .family-portfolio-stocks .family-stocks-table {
    border: none;
  }

  .family-portfolio-stocks .family-stocks-table .family-stocks-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 2px;
    row-gap: 2px;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0;
  }

  .family-portfolio-stocks .family-stocks-table .family-stocks-row .fs-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  }

  .family-portfolio-stocks .family-stocks-table .family-stocks-row .fs-row > div {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  .family-portfolio-stocks .family-stocks-table .family-stocks-row .fs-row:nth-child(1){
    font-weight: 700;
  }

  .family-portfolio-stocks .family-stocks-table .family-stocks-row .fs-row:nth-child(2),
  .family-portfolio-stocks .family-stocks-table .family-stocks-row .fs-row:nth-child(3){
    font-size: 13px;
    color: var(--font-secondary);
    font-weight: 500;
  }

  .family-portfolios-right .family-portfolio-charts{
    flex-direction: column;
    row-gap: 10px;
    padding: 0 10px;
  }

  .family-portfolio-charts .family-sector, .family-portfolio-charts .family-market {
    width: 100%;
    padding: 15px 0;
  }

  .family-portfolio-details .strategy__sector__chart{
    height: 400px;
  }

  .family-customer-popup,.family-rename-popup{
    width: 100%;
    height: 100%;
    padding: 15px 10px 20px;
  }

  .family-new-header .family-new-title{
    font-size: 15px;
    font-weight: 700;
  }

}
