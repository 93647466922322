.swift-admin-wrapper {
  width: 100vw;
  height: 100%;
  display: flex;
}

.swift-admin-wrapper .swift-menu.active {
  width: 300px;
}

.swift-admin-wrapper .swift-menu .swift-menu-icon .menu-text {
  width: 200px;
}

.swift-admin-body {
  position: absolute;
  top: 0;
  left: 50px;
  width: calc(100% - 50px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px;
}

.swift-admin-body .header-options {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
}

.swift-admin-body-wrapper {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
}


.swift-admin-body-wrapper .admin-body-loader{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.swift-admin-body-wrapper .admin-body-loader>p{
  font-size: 14px;
  font-weight: 600;
}

.swift-admin-body .header-options .title {
  font-size: 16px;
  font-weight: 600;
  color: var(--font-primary);
  letter-spacing: -0.2px;
}

.swift-account-main {
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
}


.swift-account-main .swift-account-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: center;
}

.swift-account-main .swift-account-heading {
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.swift-account-main .swift-account-svg,
.swift-account-main .swift-account-btn,
.swift-modal .swift-account-svg,
.swift-modal .swift-account-btn {
  cursor: pointer;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swift-account-main .swift-account-btn {
  border: none;
  background: none;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 7px 15px;
  border: 1px solid #e8e8e8;
  background-color: #f5f5f5;
  border-radius: 35px;
  color: var(--font-primary);
  cursor: pointer;
}

.swift-account-main .swift-account-btn.disabled{
  cursor: not-allowed;
  color: var(--text-color-blur);
}

.swift-account-main .swift-account-svg.disabled{
  cursor: not-allowed;
}

.swift-account-main .swift-account-btn.text{
  padding: 0;
  background-color: transparent;
  border: none;
  font-weight: 700;
}

.swift-account-main .empty-data-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.swift-account-main .empty-data-wrapper>p{
  font-size: 15px;
  font-weight: 600;
}

/* Style the popup */

.swift-modal .popup-content {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.swift-modal .popup-content img {
  width: 50vw;
  height: 80vh;
  object-fit: contain;
}

.swift-modal .popup-content button {
  border: none;
  padding-top: 10px;
  background: none;
  text-align: center;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: center;
  cursor: pointer;
}

/* Form Style */
.swift-account-main .swift-account-form,
.swift-modal .swift-account-form {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  background: #fff;
  border-radius: 10px;
  padding: 10px 15px 10px;
  width: 500px;
  min-height: 550px;
  max-height: 650px;
}

.swift-account-main .swift-customer-form,
.swift-modal .swift-customer-form {
  height: fit-content;
  min-height: auto;
  max-height: fit-content;
}

.swift-account-main .swift-account-form-header,
.swift-modal .swift-account-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swift-account-main .swift-account-form-title,
.swift-modal .swift-account-form-title {
  font-size: 14px;
  font-weight: 600;
}

.swift-account-main .swift-account-form-section,
.swift-modal .swift-account-form-section {
  display: flex;
  align-items: baseline;
}

.swift-account-main .swift-account-form-section span,
.swift-modal .swift-account-form-section span {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px;
  /* margin-top: 3%; */
}

.swift-account-main .swift-account-form-section button,
.swift-modal .swift-account-form-section button {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 7px 10px;
  border: none;
  border-radius: 35px;
  background-color: #f0f0f0;
  cursor: pointer;
  margin-left: 0;
  height: fit-content;
  margin-left: 1%;
  color: var(--font-primary);
}

.swift-account-main .broker-tabs,
.swift-modal .broker-tabs {
  display: flex;
  padding: 10px 0;
}

.swift-account-main .broker-tab,
.swift-modal .broker-tab {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* padding: 10px; */
  /* padding-left: 0px; */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
  padding: 7px 10px;

  /* border: 2px solid black; */
}

.swift-account-main table svg {
  height: 25px;
}

.swift-account-main .broker-tab.active,
.swift-modal .broker-tab.active {
  /* background-color: white;
  padding-left: 0px;
  font-weight: 600; */
  line-height: normal;
  /* padding: 10px; */
  border: none;
  border-radius: 35px;
  background-color: #f0f0f0;
  /* height: fit-content; */
  /* border: 2px solid black; */
}

.swift-account-main .broker-tab.delete,
.swift-modal .broker-tab.delete {
  padding: 10px;
  margin: auto;
  line-height: normal;
  border: none;
  border-radius: 35px;
  background-color: #f0f0f0;
  color: var(--font-primary);
}

.swift-account-main .swift-account-form-body,
.swift-modal .swift-account-form-body {
  padding-top: 30px;
  flex: 1;
  /* Use flex to take the remaining space */
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.swift-account-main .swift-account-form-body-content,
.swift-modal .swift-account-form-body-content {
  width: 100%;
  flex: 1;
  /* overflow-y: auto; */
  /* height: 80vh; */
}

.swift-account-main .swift-account-form-body-content-extra,
.swift-modal .swift-account-form-body-content-extra {
  /* width: 100%; */
  /* flex: 1; */
  overflow-y: auto;
  /* padding: 0 30px; */
  padding-right: 10px;
  /* height: 58vh; */
}

.swift-modal .swift-account-form-body-content-extra {
  height: 450px;
}

.swift-account-main .login-footer,
.swift-modal .login-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 25px 0 15px 0;
}

.swift-account-main .login-footer.extra,
.swift-modal .login-footer.extra {
  justify-content: space-between;
}

.swift-account-main .resend-message,
.swift-modal .resend-message {
  font-size: 12px;
  font-weight: 500;
  /* margin-top: 5px; */
  padding-bottom: 10px;
}

.swift-account-main .resend-message.hide,
.swift-modal .resend-message.hide {
  opacity: 0;
}

.swift-account-main .confirm-popup-btn,
.swift-modal .confirm-popup-btn {
  padding: 10px 40px 0px 40px;
  display: flex;
  justify-content: space-between;
}

.swift-account-main .loader,
.swift-modal .loader {
  margin: auto;
}

.swift-account-main .loader .dot-pulse,
.swift-modal .loader .dot-pulse {
  left: -9855px;
  top: 17px;
}

.swift-account-main .select-search-wrapper,
.swift-modal .select-search-wrapper {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
  align-self: flex-end;
  /* padding-bottom: 20px; */
}

.swift-account-main .select-search-box .filter-icon,
.swift-modal .select-search-box .filter-icon {
  padding-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swift-account-main .error-text,
.swift-modal .error-text {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  line-height: 12px;
}

.swift-account-main .error-text.hide,
.swift-modal .error-text.hide {
  opacity: 0;
}

.swift-account-main .swift-error,
.swift-modal .swift-error {
  padding: 2px 0px 10px 0;
}

.swift-account-main .swift-input-box,
.swift-modal .swift-input-box {
  padding-bottom: 15px;
  height: fit-content;
}

.swift-account-main .swift-account-btn.extra-btn,
.swift-modal .swift-account-btn.extra-btn {
  padding-top: 0px;
}

.swift-account-heading-right {
  display: flex;
  align-items:center;
  column-gap: 10px;
}

.swift-account-heading-right button {
  font-size: 13px;
  font-weight: 600;
  padding: 10px 35px;
  border-radius: 45px;
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  color: var(--font-primary);
  cursor: pointer;
  text-align: center;
  height: fit-content;
}

.swift-account-main .svgicon,
.swift-modal .svgicon {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .swift-account-main .select-search-wrapper {
    width: 90%;
    align-self: flex-start;
    padding: 20px 0px;
  }

  .swift-account-main .swift-account-heading {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 550px) {
  .swift-account-main .swift-account-form {
    width: 450px;
  }
}

@media screen and (max-width: 500px) {
  .swift-account-main .swift-account-form {
    width: 400px;
  }

  .swift-account-main .swift-button-icon {
    width: 150px;
    min-width: 150px;
  }

  .swift-account-main .swift-button-icon p {
    font-size: 12px;
  }
}

@media screen and (max-width: 450px) {
  .swift-account-main .swift-account-form {
    width: 350px;
  }

  .swift-account-main .swift-button-icon {
    width: 130px;
    min-width: 130px;
  }

  .swift-account-main .select-search-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .swift-account-main .swift-account-form {
    width: 300px;
  }

  .swift-account-main .swift-button-icon {
    width: 100px;
    min-width: 100px;
  }
}

@media screen and (max-width: 350px) {
  .swift-account-main .swift-account-form {
    width: 250px;
  }

  .swift-account-main .swift-button-icon {
    width: 90px;
    min-width: 90px;
  }
}



/* ================================================SEARCH BAR=====================================  */

.swift-admin-stock-search-wrapper {
  padding-bottom: 15px;
}

.swift-admin-stock-search-wrapper .stock__search__icons__wrapper {
  /* height: 45px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  box-shadow: var(--light-box-shadow);
  width: 100%;
  row-gap: 5px;
}

.swift-admin-stock-search-wrapper .stock__search__icons__wrapper>p {
  font-size: 12px;
  font-weight: 500;
}

.swift-admin-stock-search-wrapper .stock__search {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}

.swift-admin-stock-search-wrapper .stock__search .stock__search__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px 0 15px;
}

.swift-admin-stock-search-wrapper .stock__search .stock__search__icon img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.swift-admin-stock-search-wrapper .stock__search input {
  border: none;
  outline: none;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  color: var(--font-primary);
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase !important;
  background-color: transparent;
}

.swift-admin-stock-search-wrapper .stock__search input::placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: 0%;
  margin-top: 3px;
  z-index: 10000;
  border-radius: 5px;
  max-height: 200px;
  box-shadow: 1px 1px 20px rgb(0 0 0 / 10%);
}

.swift-admin-stock-search-wrapper .stock__suggestions .suggestion__stocks {
  overflow-y: scroll;
  max-height: 200px;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions.active {
  display: block;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions .search__loader {
  width: 100%;
  height: 200px ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions .search__loader p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  padding: 0 !important;
  display: flex;
  color: var(--font-primary);
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p {
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.swift-admin-stock-search-wrapper .suggestion__stocks .suggestion__empty {
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p.active {
  background-color: #dbd9d9;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p span.search__highlight {
  font-weight: 500;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p>span:nth-child(1) {
  flex: 4;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p>span:nth-child(2) {
  flex: 10;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p>span:nth-child(3) {
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  text-align: right;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p:hover {
  background-color: #f9f9f9;
}