.business__news__section {
  width: 100%;
  background-color: transparent;
  z-index: 1000;
  border-radius: 3px;
}

.business__news__section.hide {
  display: none;
}

.business__news__section.open {
  /* transform: translateY(0%); */
  height: 100%;
}

.business__news__section .business__news__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 16px;
  font-weight: 600;
}

.business__news__section .business__news__wrapper .business__news__content {
  width: 100%;
  height: 100%;
  /* box-shadow: 0px 0px 3px #f1f1f1; */
  /* display: none; */
  border-radius: 8px;
}

.business__news__section .business__news__wrapper .business__container {
  width: 100%;
  height: 100%;
  padding: 0 0px;
  display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.business__news__section .business__news__wrapper .business__container__loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.business__news__section .business__news__wrapper .business__container__loader p {
  margin-top: 15px !important;
  font-size: 12px;
  color: var(--font-primary);
}

.business__news__section .business__news__wrapper .business__container__empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* background-color: #470985; */
}

.business__news__section .business__news__wrapper .business__container__empty.hide {
  display: none;
}

.business__news__section .business__news__wrapper .business__container__empty .container__header {
  position: absolute;
  width: 100%;
  height: 10%;
  top: 0;
  left: 0;
}

.business__container__empty .bn__empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.business__container__empty .bn__empty p {
  font-weight: 500 !important;
  font-size: 13px !important;
  margin-top: 20px !important;
}

.business__container__empty .bn__empty .bn__empty__image {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* width:  250px; */
  /* height: 250px; */
}

.business__container__empty .bn__empty .bn__empty__image img {
  height: 100%;
}

.business__news__section .business__news__wrapper .bn__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 240px;
  background-color: var(--white);
  right: 0%;
  top: -1%;
  transform: translateY(-100%);
  padding: 5px 10px;
  /* box-shadow: -2px 2px 2px var(--shadow-primary), -2px -2px 2px var(--shadow-primary); */
  /* box-shadow: -1px -1px 2px #f3f3f3; */
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  opacity: 1;
  z-index: 100000;
}

.business__news__section .business__news__wrapper .bn__title>div {
  width: 50%;
}

.business__news__section .business__news__wrapper .bn__title>div:nth-child(1)>p:first-child {
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
}

.business__news__section .business__news__wrapper .bn__title>div:nth-child(1)>p:last-child {
  width: 100%;
  font-size: 9px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--font-secondary);
  font-weight: 500;
}

.business__news__section .business__news__wrapper .bn__title>div:nth-child(2) {
  align-self: flex-end;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.business__news__section .business__news__wrapper .bn__title>div:nth-child(2)>p {
  align-self: flex-end;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: -1px;
  color: var(--font-primary);
  cursor: pointer;
}

.business__news__section .business__news__wrapper .bn__close {
  position: absolute;
  top: 2%;
  right: 3%;
  transform: translate(50%, 50%);
  display: block;
  cursor: pointer;
}

.business__news__section .business__news__wrapper .bn__close img {
  width: 30px;
}

.business__news__section .business__news__wrapper .bn__close.active {
  display: block;
}

/*Buisness Wrapper Menu */
.container__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  row-gap: 15px;
  height: 12%;
}

.container__header .stock__info {
  z-index: 10;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 20px;
  height: 35px;
}

.container__header .stock__info .stock__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  column-gap: 10px;
}

.container__header .stock__info .stock__details p {
  font-size: 13px;
  font-weight: 700;
}


.container__header .stock__info .stock__details .stock__type {
  width: auto;
  height: auto;
  border-radius: 15px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.container__header .stock__info .stock__details .stock__type img {
  width: 14px;
  margin-right: 6px;
}

.container__header .stock__info .stock__details .stock__type p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.container__header .stock__info #stock__full__name {
  font-size: 10px;
  color: var(--font-primary);
  font-weight: 500;
}

.container__header .stock__info .stock__price__purchase {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 15px;
}

.container__header .stock__info .stock__price__details {
  display: flex;
  align-items: center;
}

.container__header .stock__info .stock__price__details p span {
  font-size: 16px;
  font-weight: 700;
  margin-right: 2px;
}

.container__header .stock__info .stock__price__details span.price__decimals {
  color: var(--font-primary);
  font-size: 24px;
  font-weight: 900;
}

.container__header .stock__info .stock__price__details span.price__fraction {
  color: var(--font-primary);
  font-size: 14px;
  font-weight: 700;
  align-self: flex-start;
  margin-left: 2px;
}

.container__header .stock__info .stock__price__details span.stock__performance {
  font-size: 12px;
  color: #470985;
}

.container__header .stock__info .stock__price__details p span.clock {
  margin: 0 3px;
  font-size: 12px;
}

.container__header .stock__info .stock__purchase {
  display: flex;
  column-gap: 15px;
  margin-left: 20px;
}

.container__header .stock__info .stock__purchase>div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container__header .stock__info .stock__purchase>div span {
  text-transform: capitalize;
  font-weight: 500;
}


.container__header .stock__info .stock__price__change {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  color: var(--font-primary);
}

.container__header .stock__info .stock__price__change div {
  margin-left: 0px;
  font-weight: 600;
}

.container__header .stock__info .stock__links {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-left: 30px;
}


.container__header .stock__info .stock__links .stock__link__box {
  display: flex;
  align-items: center;
  column-gap: 2px;
  cursor: pointer;
}

.container__header .stock__info .stock__links .stock__link__box>a {
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
  color: var(--font-primary);
  display: flex;
  align-items: center;
  column-gap: 2px;
}

.business__news__section .business__news__wrapper .business__news__menu {
  display: flex;
  align-items: center;
}

.business__news__section .business__news__wrapper .business__news__menu .menu__row {
  display: flex;
  column-gap: 35px;
  padding-top: 0px;
  align-self: center;
  list-style: none;
}

.business__news__section .business__news__wrapper .business__news__menu .menu__row li {
  padding: 0;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0px;
  cursor: pointer;
}

.business__news__section .business__news__wrapper .business__news__menu .menu__row li:hover {
  font-weight: 500;
}

.business__news__section .business__news__wrapper .business__news__menu .menu__row.business__menu li {
  font-size: 13px;
}


.business__news__section .business__news__wrapper .business__news__menu .menu__row.business__menu li.active-nav-0 {
  font-weight: 700;
}

.business__news__section .business__news__wrapper .business__news__menu .menu__row.business__menu li.active-nav-0:hover {
  font-weight: 700;
}

.business__news__section .business__news__wrapper .business__news__box {
  width: 100%;
  height: 88%;
  padding: 0px 0px 0;
  overflow: hidden;
}

.business__news__section .business__news__wrapper .business__news__box .bn__stock__overview__loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business__news__section .business__news__wrapper .business__news__box__loader {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.business__news__section .business__news__wrapper .business__news__box__loader p {
  margin-top: 15px !important;
  font-size: 14px !important;
  color: var(--font-primary);
}

.price__decimals {
  font-size: 14px;
  color: var(--font-primary);
  font-weight: 700;
}

.price__fraction {
  color: var(--font-primary);
  font-size: 14px;
  font-weight: 500;
  align-self: flex-start;
  margin-left: 2px;
}


/* Technicals Section */

.bn__stock__technicals {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bn__stock__technicals .bn__stock__views{
  width: 80%;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
}

.bn__stock__technicals .bn__stock__target{
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view {
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0px;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view.small .bn__stock__slider__wrapper {
  width: 100%;
}


.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__view__title,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__view__title {
  font-size: 12px !important;
  font-weight: 600;
  padding-top: 8px;
  color: var(--font-primary);
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__slider,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__slider {
  padding-left: 15px;
  margin: auto 0;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__slider .stock__view__label,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__slider .stock__view__label {
  font-size: 12px;
  font-weight: 600;
  text-align: right;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__text,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__text {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__text p,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__text p {
  font-size: 11px;
  font-weight: 500;
  display: flex;
  color: #909090;
  margin-top: -2px;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__text p>span {
  color: #e51a4b;
}

.bn__stock__tech__view__final{
  width: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 5px 10px;
}

.bn__stock__tech__view__final .bn__stock__view__title{
  font-size: 12px;
  font-weight: 600;
}

.bn__stock__tech__view__final .bn__stock__text{
  font-size: 11px;
  font-weight: 500;
  color: #909090;
  margin-top: -2px;
}

.bn__stock__tech__view__final .stock__view__label{
  margin-top: 6px;
  font-size: 14px;
  font-weight: 700;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price {
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 10px 10px;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__genie {
  width: 100%;
}


.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__genie p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value {
  width: 100%;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value .bn__stock__target__empty {
  text-align: center;
  font-size: 11px !important;
  color: var(--main-red)
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p {
  font-size: 12px;
  line-height: 16px;
  color: var(--font-primary);
  font-weight: 500;
  display: flex;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p>span.span__bold {
  font-size: 14px;
  color: var(--font-primary);
  font-weight: 700;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p .bn__stock__target__change.negative {
  color: var(--main-red);
  font-size: 12px;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p .bn__stock__target__change.positive {
  color: var(--main-green);
  font-size: 12px;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p span.bn__stock__target__predictibility {
  font-size: 12px !important;
  font-weight: 500;
  color: var(--font-primary);
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p.bn__stock__target__predictibility.positive span {
  color: var(--main-green);
  font-size: 12px;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p.bn__stock__target__predictibility.negative span {
  color: var(--main-red);
  font-size: 12px;
}


.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p.bn__stock__target__predictibility span {
  font-size: 11px !important;
  font-weight: 600;
}


.financials__table thead td {
  text-align: left;
}

.financials__table tr {
  height: 35px !important;
  /* border: 0.5px solid black!important; */
}

/* .financials__table tbody tr:nth-child(odd)
{
  background-color: #fafafa;
}

.financials__table tbody tr:nth-child(even)
{
  background-color: #ffffff;
} */

/* .financials__table tbody tr
{
  border-bottom: 0.5px solid #EAEAEA;
} */

.financials__table thead td {
  font-weight: 600;
  color: #000000;
}

.financials__table td {
  text-align: left;
  color: black;
  border: none !important;
  letter-spacing: -0.2px;
}

.financials__table td.cell__bold {
  /* font-weight: 700; */
  color: var(--font-primary);
}

.financials__table td.cell__blue {
  /* font-weight: 700; */
  color: var(--main-blue);
}

.financials__table td.cell__normal {
  font-weight: 500;
}


.financials__table__data {
  overflow-y: scroll;
}

.credit__ratings {
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  min-width: 80%;
  color: var(--font-primary);
}

.credit__ratings .credit__loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.credit__ratings .credit__empty {
  width: 100%;
  height: 70%;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.credit__ratings .credit__empty p {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: var(--font-primary);
}

.credit__ratings .credit__title {
  font-size: 13px !important;
  color: var(--font-primary);
  font-weight: 700 !important;
  margin-bottom: 10px !important;
}

.bn__stock__credit .bn__credit__block {
  margin: 0px 0 8px 0;
}

.bn__stock__credit .bn__credit__block .bn__credit__title {
  font-size: 12px !important;
  color: var(--font-primary);
  font-weight: 700 !important;
  text-decoration: none;
}

.bn__stock__credit .bn__credit__block .bn__credit__text {
  font-size: 12px !important;
  color: var(--font-primary);
  font-weight: 500 !important;
  margin-top: -2px !important;
}

.bn__stock__financial__chart {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.financials__table__data__loader {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media only screen and (max-width: 768px) {
  .business__news__section {
    border-radius: 3px;
    z-index: 100000;
  }

  .business__news__section .business__news__wrapper {
    font-size: 12px;
    font-weight: 600;
  }

  .business__news__section .business__news__wrapper .business__news__content {
    width: 100%;
    height: 100%;
  }

  .business__news__section .business__news__wrapper .business__container {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    padding: 0 10px;
  }

  .business__news__section .business__news__wrapper .business__container__loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .business__news__section .business__news__wrapper .business__container__loader p {
    margin-top: 10px !important;
    font-size: 12px;
    font-weight: 700;
    color: var(--font-primary);
  }

  .business__news__section .business__news__wrapper .bn__title {
    width: 220px;
    right: 2%;
    top: 0%;
    transform: translateY(-100%);
    padding: 9px 10px;
    border-radius: 30px;
    display: none;
  }

  .business__news__section .business__news__wrapper .bn__title>div {
    width: 50%;
  }

  .business__news__section .business__news__wrapper .bn__title>div:nth-child(1)>p:first-child {
    font-size: 11px;
    font-weight: 700;
  }

  .business__news__section .business__news__wrapper .bn__title>div:nth-child(1)>p:last-child {
    width: 100%;
    font-size: 8px;
  }

  .business__news__section .business__news__wrapper .bn__title>div:nth-child(2)>p {
    align-self: center;
    font-size: 11px;
    font-weight: 700;
    color: var(--font-primary);
    cursor: pointer;
  }

  .business__news__section .business__news__wrapper .bn__close {
    position: absolute;
    top: 2%;
    right: 6%;
    transform: translate(50%, 50%);
    margin-left: 4px;
    display: block;
    cursor: pointer;
    z-index: 10000;
  }

  .business__news__section .business__news__wrapper .bn__close img {
    width: 30px;
  }

  .business__news__section .business__news__wrapper .bn__close.active {
    display: block;
  }



  /*Buisness Wrapper Menu */
  .container__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    /* background-color: #BBBBBB; */
    height: 20%;
  }

  .container__header .stock__info {
    z-index: 10;
    padding-top: 10px;
    flex: 1;
  }

  .container__header .stock__info .stock__details {
    display: flex;
  }

  .container__header .stock__info .stock__details p {
    font-size: 14px;
    /* font-weight: 700; */
    color: var(--font-primary);
  }


  .container__header .stock__info .stock__details .stock__type {
    width: auto;
    height: auto;
    border-radius: 0px;
    margin: 0 0px;
    margin-left: 30px;
  }

  .container__header .stock__info .stock__details .stock__type img {
    width: 12px;
  }

  .container__header .stock__info .stock__details .stock__type p {
    font-size: 10px;
  }

  .container__header .stock__info #stock__full__name {
    font-size: 10px;
    /* color: var(--font-secondary); */
    /* font-weight: 600; */
  }

  .container__header .stock__info .stock__price__purchase {
    display: flex;
    /* align-items: center; */
    margin-top: 0px;
  }

  .container__header .stock__info .stock__price__details {
    display: flex;
    align-items: center;
  }

  .container__header .stock__info .stock__price__details p span {
    font-size: 12px;
    font-weight: 700;
    margin-right: 2px;
  }

  .container__header .stock__info .stock__price__details span.stock__performance {
    font-size: 10px;
    color: var(--font-primary);
  }

  .container__header .stock__info .stock__price__details p span.clock {
    margin: 0 3px;
    font-size: 12px;
  }

  .container__header .stock__info .stock__purchase {
    display: flex;
    /* margin-left: 30px; */
  }

  .container__header .stock__info .stock__price__change {
    display: flex;
    font-size: 12px;
    margin-top: 0;
    color: var(--font-primary);
  }

  .container__header .stock__info .stock__price__change>div {
    margin-left: 1px;
  }



  .business__news__section .business__news__wrapper .business__news__menu {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: none; */
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .business__news__section .business__news__wrapper .business__news__menu .row {
    display: none;
  }

  .business__news__section .business__news__wrapper .business__news__menu .menu__row {
    display: flex;
    padding: 5px 0;
    align-self: center;
    list-style: none;
    column-gap: 10px;
  }



  .business__news__section .business__news__wrapper .business__news__menu .menu__row li {
    padding: 0;
    font-size: 12px;
    margin: 0 4px;
    cursor: pointer;
  }

  .business__news__section .business__news__wrapper .business__news__menu .menu__row.business__menu li {
    font-size: 14px;
  }

  .business__news__section .business__news__wrapper .business__news__menu .menu__row.business__menu li>div>span {
    font-size: 8px !important;
    display: none;
  }

  .business__news__section .business__news__wrapper .business__news__box {
    width: 100%;
    height: 80%;
    padding: 10px 0px 20px;
    overflow-y: scroll;
    /* background-color: #BBBBBB; */
    overflow-x: hidden;
    margin-top: 10px;
  }

  .price__decimals {
    font-size: 24px;
    color: var(--font-primary);
  }

  .price__fraction {
    color: var(--font-primary);
    font-size: 12px;
    align-self: flex-start;
    margin-left: 2px;
  }


  /* Technicals Section */

  .bn__stock__technicals {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .bn__stock__technicals .bn__stock__views,
  .bn__stock__technicals .bn__stock__target {
    width: 100%;
    height: 100%;
  }

  .bn__stock__technicals .bn__stock__target {
    padding-bottom: 20px;
  }

  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view {
    width: 100%;
    height: 100px;
    display: flex;
  }

  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper {
    width: 75%;
    padding: 2px 5px;
  }

  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper .MuiSlider-root,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper .MuiSlider-root {
    width: 180px !important;
  }

  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view.small .bn__stock__slider__wrapper {
    width: 100%;
  }


  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__view__title,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__view__title {
    font-size: 12px !important;
  }


  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__text,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__text {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__text p,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__text p {
    font-size: 10px !important;
    margin: 5px 0 !important;
    text-align: center;
  }


  .bn__stock__technicals .bn__stock__target .bn__stock__target__price {
    padding: 5px 2px;
    min-height: 250px;
    text-align: center;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__genie {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__genie img {
    width: 50px;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__genie p {
    font-size: 14px !important;
    margin-top: 10px !important;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value .bn__stock__target__empty {
    text-align: center;
    font-size: 11px !important;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p {
    text-align: center;
    font-size: 11px !important;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p>span {
    font-size: 18px !important;
  }


  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p.bn__stock__target__predictibility {
    font-size: 11px !important;
    font-weight: 600;
    color: var(--font-primary);
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p.bn__stock__target__predictibility span {
    font-size: 11px !important;
    font-weight: 600;
  }


  .financials__table thead td {
    text-align: left;
  }

  .financials__table tr {
    height: 35px !important;
    /* border: 0.5px solid black!important; */
  }

  .financials__table thead td {
    font-weight: 600;
  }

  .financials__table td {
    text-align: left;
    color: black;
    border: none !important;
  }

  .financials__table td.cell__bold {
    color: var(--font-primary);
  }

  .financials__table td.cell__blue {
    color: var(--main-blue);
  }



  .financials__table__data {
    overflow-y: scroll;
  }

  .credit__ratings .credit__title {
    font-size: 12px !important;
  }

  .bn__stock__credit .bn__credit__block {
    margin: 0px 0 8px 0;
  }

  .bn__stock__credit .bn__credit__block .bn__credit__title {
    font-size: 11px !important;
  }

  .bn__stock__credit .bn__credit__block .bn__credit__text {
    font-size: 11px !important;
    font-weight: 500 !important;
  }

  .bn__stock__financial__chart {
    width: 100%;
    min-height: 100px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .financials__table__data__loader {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}