.swift-modal {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000000000;
}

.swift-modal .swift-modal-box {
  width: fit-content;
  height: fit-content;
}

@media only screen and (max-width: 768px) {
  .swift-modal {
    padding-top: 0% !important;
  }

  .swift-modal .swift-modal-box {
    width: 100%;
    height: 100%;
  }
}
