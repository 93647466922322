.order-popup {
  z-index: 1000000;
  width: 1120px;
  height: 650px;
  border-radius: 5px;
  transform: translateY(8px);
  box-shadow: 0 0 14px 0 rgb(15 17 17 / 10%);
  /* box-shadow: 1px 3px 5px #e8e8e8; */
  background-color: var(--white);
  animation: getup 0.1s linear forwards;
}

@keyframes getup {
  0% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.order-popup.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-header {
  width: 100%;
  height: 60px;
  padding: 0 20px;
}

.order-title {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
}

.order-title .title-name {
  display: flex;
  align-items: center;
}

.title-name .title-action {
  width: 85px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  margin-right: 10px;
  letter-spacing: 0px;
  background-color: #e8e8e8;
}

.title-name .title-action.buy {
  background-color: var(--main-green-light);
}

.title-name .title-action.sell {
  background-color: var(--main-red-light);
}

.title-name .title-symbol {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.order-title .title-search {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.order-title .title-search > img {
  width: 16px;
}

.order-title .title-search.active {
  position: absolute;
  right: 0;
  background-color: var(--white);
  width: 100%;
  height: 90%;
  transition: 0.1s;
}

.title-search .stock-search {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.stock-search .stock-search-header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.stock-search-header .search-area {
  width: 80%;
  display: flex;
  align-items: center;
}

.search-area > img {
  width: 20px;
  margin-right: 20px;
}

.search-area input {
  width: 95%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  text-transform: uppercase;
  background-color: transparent;
}

.search-area input::placeholder {
  font-size: 14px;
  color: #cccccc;
  font-weight: 500;
  text-transform: capitalize;
}

.stock-search-header .search-close {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stock-search-header .search-close > img {
  width: 30px;
}

.stock-search .stock-container {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  max-height: 350px;
  background-color: var(--white);
  z-index: 100000;
  border-radius: 3px;
  overflow-y: scroll;
  /* box-shadow: 1px 1px 5px #e8e8e8; */
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
}

.stock-search .stock-container::-webkit-scrollbar {
  display: none;
}

.stock-search .stock-container .search-loader {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stock-search .stock-container .search-loader > p {
  margin-top: 10px !important;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.stock-search .stock-container > p {
  width: 100%;
  display: flex;
  padding: 10px 25px;
  font-size: 12px;
  transition: 0.3s;
  border-bottom: 1px solid #f5f5f5;
}

.stock-search .stock-container > p:hover {
  background-color: #f3f3f3;
}

.stock-search .stock-container > p > span:nth-child(1) {
  flex: 3;
  font-weight: 600;
}

.stock-search .stock-container > p > span:nth-child(2) {
  flex: 7;
  font-size: 11px;
  font-weight: 500 !important;
}

.stock-search .stock-container > p > span:nth-child(3) {
  flex: 1;
  font-weight: 600;
  text-align: right;
}

.stock-search .stock-container > p .search-highlight {
  /* background-color: #b7e8ff; */
  font-weight: 600;
}

.order-side {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.order-side-buy,
.order-side-sell {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s;
  padding: 0 20px;
  position: relative;
  background-color: #fafafa;
  /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px; */
  font-size: 12px;
}

.order-side-buy {
  border-radius: 60px 0px 0px 60px;
}

.order-side-sell {
  border-radius: 0px 60px 60px 0px;
}

.order-side-buy:hover,
.order-side-sell:hover {
  background-color: #fbfbfb;
}

.order-side-buy.active {
  background-color: var(--main-green-light);
}

.order-side-sell.active {
  background-color: var(--main-red-light);
}

.order-side-sell {
  align-items: flex-end;
}

.order-side-buy .order-side-line,
.order-side-sell .order-side-line {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  border-radius: 10px;
}

/* .order-side-buy .order-side-line
{
    display: none;
    background-color: #d2d2d2;
}

.order-side-sell .order-side-line
{
    display: none;
    background-color: #909090;
    }
    
    */

.order-side-buy.active .order-side-line,
.order-side-sell.active .order-side-line {
  display: block;
}

.order-side-difference {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  font-weight: 700;
  background-color: var(--white);
  border: 1px solid #f3f3f3;
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  z-index: 10000;
}

.order-body {
  width: 100%;
  height: calc(100% - 60px - 80px);
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.order-body .order-setup-section {
  width: 60%;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.order-body .order-section-separator,
.order-type-body .order-section-separator {
  height: 100%;
}

.order-body .order-section-separator div,
.order-type-body .order-section-separator div {
  width: 0.5px;
  height: 100%;
  background-color: #e8e8e8;
}

.order-body .order-depth-section {
  width: 40%;
  padding: 8px 0;
}

.order-body .order-depth-section .order-market{
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}


.order-body .order-body-row {
  display: flex;
  align-items: center;
  margin: 12px 0;
}

.order-body-row.portfolio > span {
  font-size: 10px;
  margin-left: 10px;
}

.order-body .order-body-row.customers {
  width: 300px;
}
.order-body-row.portfolio .new-portfolio {
  font-size: 11px;
  font-weight: 600;
  width: 200px;
  height: 30px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  color: var(--font-primary);
  margin-left: 10px;
  cursor: pointer;
}

.order-body-row.portfolio .new-portfolio > input {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding-left: 10px;
}

.order-body .order-body-section {
  width: 100%;
  height: fit-content;
  position: relative;
}

.order-body .order-body-section .order-body-loading {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  z-index: 1000;
}

.order-body-row .funds {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
}

.order-body-row .funds img {
  width: 20px;
}

.order-body-row .funds span {
  font-size: 14px;
  margin-left: 10px;
}

.order-body-row.order-box {
  flex-wrap: wrap;
}

.order-body-row .order-type-box {
  width: 100px;
  height: 35px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #909090;
  border-radius: 35px;
  font-size: 12px;
  font-weight: 500;
  color: #909090;
  cursor: pointer;
  transition: 0.2s;
}

.order-body-row .order-type-box.active {
  border: 1px solid var(--font-primary);
  color: var(--font-primary);
  font-weight: 700;
}

.order-body-row .order-error {
  width: 100%;
}

.order-body-row .order-error p {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary) !important;
}

.order-body-row .order-value {
  width: 170px;
  margin-right: 20px;
}

.order-body-row .order-value > p:nth-child(1) {
  font-size: 12px;
  font-weight: 500;
  color: #909090;
}

.order-body-row .order-value > p:nth-child(2) {
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
}

.order-body-section .exit-position {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  margin-top: 20px;
}

.order-body-section .exit-position > p {
  font-size: 14px;
  font-weight: 600;
}

.order-body .order-info-button{
  display: none;
}

.order-body .order-market-depth,
.order-type-body .order-market-depth {
  width: 100%;
  display: flex;
  column-gap: 4px;
}


.order-market-depth.loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-market-depth .order-market-offers,
.order-market-depth .order-market-bids {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.order-market-depth .order-market-offers > p,
.order-market-depth .order-market-bids > p {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-secondary);
  padding: 0px 0 4px;
}

.order-market-depth table {
  flex: 1;
}

.order-market-depth .separator {
  width: 1px;
  background-color: #e8e8e8;
}

.order-market-depth table tr td {
  text-align: center;
  padding: 6px 8px 6px 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.order-market-depth table tr td:nth-child(1),
.order-market-depth table tr td:nth-child(2) {
  text-align: left;
}

.order-market-depth table tr td:nth-child(3) {
  text-align: right;
}

.order-market-depth table thead tr td {
  font-weight: 700;
  font-size: 11px;
}

.order-market-extra {
  width: 100%;
  display: flex;
  padding: 15px 10px;
  column-gap: 25px;
  background-color: #fafafa;
  border-radius: 15px;
}

.order-market-extra .market-extra-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.order-market-extra .market-extra-column > p {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-secondary);
}

.order-market-extra .market-extra-column > p > span:last-child {
  color: var(--font-primary);
}

.order-footer {
  padding: 0 20px;
  width: 100%;
  height: 80px;
}

.order-footer .order-footer-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f5f5f5;
}

.order-footer .order-error p {
  font-size: 12px;
  font-weight: 600;
  color: var(--main-red) !important;
}

.order-footer .order-info p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}
.order-footer .order-buttons {
  display: flex;
}

.order-footer .order-buttons .order-button {
  width: 90px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
}

.order-footer .order-buttons .order-button:hover {
  opacity: 0.9;
}

.order-button.buy {
  background-color: var(--main-green);
  color: var(--white);
}

.order-button.buy.disabled,
.order-button.sell.disabled {
  opacity: 0.7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: not-allowed;
}

.order-button.buy.disabled:hover,
.order-button.sell.disabled:hover {
  opacity: 0.7;
}

.order-button.sell {
  background-color: var(--main-red);
  color: var(--white);
}

.order-button.close {
  background-color: var(--white);
  color: var(--font-primary);
  border: 1px solid var(--font-primary);
}

/* success popup */

.custom-success-box {
  position: fixed;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1000000000;
  background: rgba(140, 140, 140, 0.5);
}

.custom-success-box .order-success-popup {
  width: 450px;
  padding: 15px 0 30px;
  background-color: var(--white);
  box-shadow: 0 0 14px 0 rgb(15 17 17 / 10%);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3px;
}

.order-success-popup .order-success-title {
  font-size: 16px;
  font-weight: 600;
}

.order-success-popup .order-success-subtitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-secondary);
}

.order-success-popup > button {
  margin-top: 25px;
  background: var(--white);
  border: 1px solid var(--font-primary);
  cursor: pointer;
  width: 110px;
  height: 35px;
  border-radius: 5px;
  color: var(--font-primary);
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .order-popup {
    z-index: 1000000;
    width: 100%;
    height: 100%;
    position: fixed;
  }

  .order-header {
    padding: 0 10px;
    height: 50px;
  }

  .title-name .title-action {
    width: 60px;
    height: 22px;
    border-radius: 25px;
    font-size: 11px;
    margin-right: 10px;
  }

  .title-name .title-symbol {
    font-size: 14px;
  }

  .order-title .title-search > img {
    width: 20px;
  }

  .search-area input {
    font-size: 14px;
    font-weight: 600;
  }

  .stock-search-header .search-close {
    width: 10%;
  }

  .stock-search-header .search-close > img {
    width: 30px;
  }

  .stock-search .stock-container > p {
    padding: 10px 15px;
    font-size: 12px;
  }

  .stock-search .stock-container > p > span:nth-child(2) {
    flex: 7;
    font-size: 11px;
    font-weight: 600 !important;
  }

  .order-side {
    width: 100%;
    height: 50%;
  }

  .order-side-buy,
  .order-side-sell {
    font-size: 14px;
    row-gap: 6px;
  }

  .order-side-buy > span,
  .order-side-sell > span {
    margin-bottom: -5px;
  }

  .order-side-buy .order-side-line,
  .order-side-sell .order-side-line {
    height: 2px;
  }

  .order-side-difference {
    font-size: 14px;
    width: 70px;
    height: 25px;
  }

  .order-body .order-body-section {
    padding: 5px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    align-items: flex-start;
  }

  .order-body {
    width: 100%;
    flex-direction: column;
    height: auto;
    overflow: scroll;
    height: calc(100% - 120px);
    padding: 0 10px;
  }

  .order-body .order-setup-section {
    width: 100%;
    row-gap: 25px;
  }

  .order-side {
    height: 55px;
  }

  .order-body .order-body-row {
    margin: 0px;
    flex-wrap: wrap;
  }

  .order-body .order-body-row.order-config {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 10px;
  }

  .order-body-row .order-value {
    width: 100%;
    margin: 0;
  }

  .order-body-row .order-value > p:nth-child(1) {
    font-size: 13px;
  }

  .order-body-row .order-value > p:nth-child(2) {
    font-size: 15px;
  }

  .order-body-row.buy,
  .order-body-row.sell {
    flex-wrap: wrap;
    margin-top: 0px;
    column-gap: 20px;
  }

  .order-body-row.portfolio {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-body-row.portfolio > span {
    font-size: 10px;
    margin-left: 10px;
  }

  .order-body-row.portfolio .custom-select {
    width: 240px !important;
    height: 45px !important;
  }

  .order-body-row.portfolio .new-portfolio {
    font-size: 11px;
    font-weight: 600;
    width: 240px;
    height: 45px;
    margin-left: 0;
    margin-top: 10px;
  }

  .order-body-row .order-type-box {
    width: 120px;
    height: 40px;
    margin-right: 0px;
    font-size: 14px;
  }

  .order-body-row.order-box {
    justify-content: space-between;
  }

  .order-body-row.order-box .price-box {
    width: 48%;
    margin-bottom: 0;
  }

  .order-body-row .order-error {
    display: block;
  }

  .order-body-row .order-error>p{
    font-size: 14px;
  }

  .order-footer {
    position: fixed;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    height: 12%;
    padding: 0 10px;
  }

  .order-footer .order-error {
    display: none;
  }

  .order-footer .order-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: auto;
  }

  .order-footer .order-buttons .order-button {
    width: 150px;
    height: 45px;
    font-size: 16px;
    margin-left: 0px;
  }

  .order-body .order-section-separator {
    display: none;
  }

  .order-body .order-depth-section {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    inset: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10000;
    top: 100%;
  }

  .order-body .order-depth-section.active {
    top: 0;
  }

  .order-body .order-depth-section .order-market{
    row-gap: 0;
  }

  .order-market-depth .separator{
    display: none;
  }

  .order-market-extra {
    background-color: white;
    flex-direction: column;
    border-radius: 0;
    row-gap: 20px;
  }

  .order-market-extra .market-extra-column > p{
    font-size: 15px;
    color: var(--font-primary);
    font-weight: 600;
  }

  .order-body .order-info-button {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
  }

  .order-body .order-body-market-depth-button {
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    color: var(--font-primary);
  }

  .order-body .order-market-depth,
  .order-type-body .order-market-depth {
    flex-direction: row;
    row-gap: 10px;
    background-color: white;
    padding: 0px 0 10px;
    column-gap: 10px;
  }

  .order-market-depth .order-market-offers > p,
  .order-market-depth .order-market-bids > p {
    display: none;
  }

  .order-market-depth .order-market-offers,
  .order-market-depth .order-market-bids {
    row-gap: 5px;
  }

  .order-market-depth table {
    border: none;
    border-radius: 5px;
  }

  .order-market-depth table thead tr td {
    font-size: 13px;
  }

  .order-market-depth table tr td {
    font-size: 14px;
    padding: 6px 6px;
  }
}
