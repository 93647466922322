.backoffice-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backoffice__login__check {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.windowtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 15px;
  color: #404040;
}

.windowtitle > div {
  display: flex;
  align-items: center;
}

.windowtitle .trade-options {
  display: flex;
  margin-left: 20px;
}

.windowtitle .trade-options > div {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  min-width: 120px;
  height: 30px;
  padding: 4px 10px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 500;
}

.windowtitle .trade-options > div.active {
  background-color: #606060;
  color: var(--white);
}

.windowtitle > div > p {
  font-size: 18px;
  font-weight: 700;
}

.windowtitle .logout-btn {
  /* width: 100px; */
  /* height: 35px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  transition: 0.3s;
  cursor: pointer;
  padding: 8px 14px;
}

.windowtitle > div > span {
  letter-spacing: 0.6px;
  font-weight: 500;
  transition: 0.3s;
}

.windowtitle > div > img {
  width: 20px;
  transform: rotateY(180deg);
  margin-left: 10px;
}

.windowtitle > div:hover {
  border-color: #888888;
  background-color: #ffffff;
}

.windowtitle > div:hover > span {
  font-weight: 700;
}

.getOrder__container {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 12px;
  border-radius: 3px;
  width: 90%;
  height: 100%;
  padding-top: 20px;
  max-width: 1800px;
}

.container__empty {
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container__empty > img {
  width: 240px;
  transform: rotateY(180deg);
}

.container__empty > p {
  margin-top: 30px !important;
  font-size: 16px;
  font-weight: bold;
}

.getOrder__selection__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.getOrder__selection__wrapper > div {
  display: flex;
}

.getOrder__header {
  width: 100%;
}

.getOrder__header__wrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.getOrder__header__wrapper.no-space {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.getOrder__header__wrapper.no-space .getOrder__details {
  margin-right: 50px;
}

.getOrder__details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
  width: 22%;
  border-radius: 3px;
  height: 60px;
  box-shadow: 1px 3px 5px #e8e8e8;
}

.getOrder__details > img {
  width: 30px;
  height: 30px;
}

.getOrder__details > div {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: #404040;
}

.getOrder__details > div .getOrder__details__heading {
  font-size: 12px;
}

.getOrder__details > div .getOrder__details__val {
  font-size: 16px;
  font-weight: 700;
}

.getOrder__header__comp {
  display: flex;
  margin: 0 20px 0 0;
}

.getOrder__header__comp .custom-select {
  box-shadow: 0 0 2px #c1c1c1;
  color: #404040 !important;
  /* border: 1px solid #404040; */
}

.header__comp__input {
  height: 40px;
  width: 240px;
  box-shadow: 0 0 2px #c1c1c1;
  border-radius: 3px;
}

.header__comp__input input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
  font-weight: bold;
  color: #404040;
}

.header__comp__input input::placeholder {
  color: #404040;
  /* font-weight: 500; */
}

.getOrder__header__heading {
  font-weight: normal;
  /* width: 100px; */
  align-self: center;
}

.getOrder__header__comp .custom-select .custom-select-option {
  display: block;
}

.getOrder__header__comp .custom-select .custom-select-container span {
  padding: 6px 12px !important;
}
.getOrder__header__comp
  .custom-select
  .custom-select-container
  span
  .custom-select-additional-option {
  padding: 0px !important;
  font-size: 10px !important;
  /* color: grey!important; */
  text-align: left !important;
}

.getOrder__header__val {
  /* font-size: 12px; */
  font-weight: 600;
}
.getOrder__add__import {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px 0;
}

.order__browse {
  display: flex;
  align-items: center;
  margin-left: 15px;
  /* width: 40%; */
}

.order__search {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.getOrder__import {
  display: flex;
  align-items: center;
  position: relative;
}
.getOrder__import input[type="file"] {
  left: 0;
  top: 0;
  opacity: 0;
  position: absolute;
  /* font-size: 13px */
}
.getOrder__import .btn-upload {
  background-color: #470985;
  color: white;
  padding: 5px 20px;
  border-radius: 3px;
  /* font-size: 13px; */
  font-weight: bold;
}
.getOrder__add__import label {
  /* font-size: 14px; */
  font-weight: 600;
  color: #470985;
  /* width: 100px; */
}

.getOrder__add {
  width: 40%;
}

.getOrder__add__import .getOrder__add {
  margin-left: 10px;
  height: 35px;
  width: 400px;
  box-shadow: var(--shadow-code-secondary);
}

.getOrder__add__import .getOrder__add .stock__search {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  width: 100%;
  border: none !important;
  box-shadow: 0 0 2px #e8e8e8;
  position: relative;
  border-radius: 3px;
}

.getOrder__add__import .getOrder__add .stock__search input {
  border: none;
  outline: none;
  width: 80%;
  /* font-size: 14px; */
  font-weight: 700;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--font-primary);
}
.getOrder__add__import .getOrder__add .stock__search .stock__search__icon {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.getOrder__add__import .getOrder__add .stock__search .stock__search__icon img {
  width: 16px;
}

.getOrder__add__import .getOrder__add .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: 0%;
  margin-top: 3px;
  z-index: 1000;
  border-radius: 3px;
  max-height: 250px;
  overflow-y: scroll;
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
  /* display: none; */
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  .search__loader {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  .search__loader
  p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  display: flex;
  color: var(--font-primary);
}

.getOrder__add__import .getOrder__add .stock__search .stock__suggestions p {
  padding: 8px 20px 8px 20px;
  cursor: pointer;
  /* font-size: 14px; */
  font-weight: 600;
  display: flex;
  align-items: center;
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p.active {
  background-color: #dbd9d9;
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p
  span.search__highlight {
  background-color: #e2c9fc;
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(1) {
  flex: 3;
  /* font-size: 12px; */
  font-weight: 700;
  color: var(--font-primary);
}
.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(2) {
  flex: 7;
  font-size: 9px;
  font-weight: 600;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
}
.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(3) {
  flex: 1;
  /* font-size: 12px; */
  font-weight: 700;
  text-align: right;
  color: var(--font-primary);
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p:hover {
  background-color: #f7f7f7;
}

.getOrder__download__format {
  background-color: var(--main-brand);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: bold;
  display: flex-end;
  cursor: pointer;
  margin-left: 20px;
}
.getOrder__download__format a {
  color: white;
  font-weight: bold;
}

.getOrder__container .getOrder__sections {
  display: flex;
  align-items: center;
}

.getOrder__container .getOrder__sections .order__section {
  margin: 0 20px 0 0;
  width: 150px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: var(--font-primary);
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.getOrder__container .getOrder__sections .order__section.active {
  background-color: #606060;
  color: var(--white);
}

.getOrder__sections .order__section a {
  color: var(--font-primary);
  text-decoration: none;
}
.getOrder__container .getOrder__table {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 350px;
  padding: 0px 5px 3px;
  overflow-y: scroll;
  box-shadow: 0 0 2px #f1f1f1;
  border-radius: 3px;
}

.getOrder__container .getOrder__table__empty {
  width: 100%;
  height: 400px;
  box-shadow: 0px 0px 2px #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.getOrder__container .getOrder__table__empty > img {
  width: 100px;
  margin-bottom: 30px;
}

.getOrder__container .getOrder__table__empty > p {
  font-size: 14px;
  font-weight: 500;
  color: #a0a0a0;
}

.getOrder__container .getOrder__table__empty > p > span {
  color: #470985;
  font-weight: 700;
  cursor: pointer;
}

.getOrder__container .getOrder__table thead {
  background-color: #fefefe;
  position: sticky;
  top: 0;
  z-index: 100;
}

.getOrder__container .getOrder__table thead tr th:nth-child(5),
.getOrder__container .getOrder__table thead tr th:nth-child(6),
.getOrder__container .getOrder__table thead tr th:nth-child(7),
.getOrder__container .getOrder__table thead tr th:nth-child(10),
.getOrder__container .getOrder__table thead tr th:nth-child(11) {
  min-width: 80px;
}

.getOrder__container .getOrder__table table thead tr th {
  width: 1px;
  white-space: nowrap;
  text-align: left;
  padding: 10px 5px;
  /* border-bottom: solid 1px grey; */
  font-size: 11px;
}
/* .getOrder__container .getOrder__table table thead tr th:nth-child(9){
  width: 100px;
} */

.getOrder__container .getOrder__table table tbody tr td {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  width: 1px;
  white-space: nowrap;
  padding: 10px 5px;
  color: var(--font-primary);
}

.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td:nth-child(9)
  .custom-select
  .custom-select-container {
  /* height: 100px; */
  overflow-y: scroll;
}
.getOrder__table table {
  width: 100%;
}

.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td
  .custom-select
  .custom-select-header {
  padding: 0 3px;
}

.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td
  .custom-select
  .custom-select-header
  .custom-select-title {
  font-size: 10px !important;
}
.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td
  .custom-select
  .custom-select-header
  span {
  font-size: 10px !important;
}
.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td
  .custom-select
  .custom-select-header
  span
  img {
  height: 10px;
  width: 10px;
}

.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td
  .custom-select
  .custom-select-container
  span {
  font-size: 10px !important;
  padding: 2px 5px;
}
.getOrder__container .getOrder__table table tbody tr td input {
  width: 60px;
  padding: 3px 5px;
  border-radius: 2px;
  border: none;
  outline: none;
  border: 1px solid #e8e8e8;
  background-color: transparent;
  color: var(--font-primary);
}

.getOrder__container .getOrder__table table tbody tr td input:focus {
  border: 1px solid #e8e8e8;
}

.getOrder__container .getOrder__footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.getOrder__container .getOrder__footer > div {
  width: 100%;
}

.getOrder__container .getOrder__footer > div.getOrder__message {
  display: flex;
  flex-direction: column;
}

.getOrder__container .getOrder__footer > div.getOrder__message > p#error {
  color: var(--main-red);
  font-weight: 700;
}

.getOrder__table .custom-select {
  box-shadow: none;
  border: 1px solid #e8e8e8;
}

.order__button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.order__button button {
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #e8e8e8;
  color: #404040;
  transition: 0.3s;
  background-color: transparent;
}

.order__button button:hover {
  opacity: 0.9;
}

#success {
  color: #2fd6aa;
  font-weight: 700;
  font-size: 14px;
}

/* orderbook styling */
.orderbook__container {
  /* border: 1px solid #e8e8e8; */
  border-radius: 3px;
  padding: 0 5px;
  height: 300px;
  box-shadow: 0px 0px 2px #f1f1f1;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: scroll;
}
.orderbook__container.empty,
.orderbook__container.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.orderbook__container.empty {
  flex-direction: column;
}
.orderbook__container.empty > img {
  width: 100px;
}
.orderbook__container.empty > p {
  font-size: 14px;
  font-weight: 600;
}
.orderbook__container table {
  width: 100%;
}
.orderbook__container table thead {
  width: 100%;
  color: var(--font-primary);
  background-color: var(--white);
  border-bottom: 1px solid #e8e8e8;
}

.orderbook__container table thead tr th {
  padding: 10px 0 10px 0px;
  position: sticky;
  top: 0;
  font-size: 11px;
  color: var(--font-primary);
  text-align: left;
  background-color: var(--white);
}

/* .orderbook__container table thead tr th:nth-child(8)
{
  text-align: center;
} */

.orderbook__container table tbody {
  width: 100%;
}

.orderbook__container table tbody tr {
  cursor: pointer;
  transition: 0.3s;
}

.orderbook__container table tbody tr:hover {
  background-color: #fcfcfc;
}

.orderbook__container table tbody tr td {
  padding: 12px 0 12px 0px;
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 500;
}

.orderbook__container table tbody tr td:nth-child(1) {
  width: 150px;
}

.orderbook__container table tbody tr td .stock-info {
  display: flex;
  flex-direction: column;
}

.orderbook__container table tbody tr td .stock-info .stock-name {
  font-weight: 700;
}

.orderbook__container table tbody tr td .stock-info .stock-fullname {
  font-size: 10px;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.orderbook__container table tbody tr td:nth-child(10),
.orderbook__container table tbody tr td:nth-child(11) {
  text-align: center;
}

.orderbook__container table tbody tr td .modify-order,
.orderbook__container table tbody tr td .cancel-order {
  color: var(--white);
  border-radius: 3px;
  transition: 0.3s;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
}

.orderbook__container table tbody tr td .modify-order > img,
.orderbook__container table tbody tr td .cancel-order img {
  width: 18px;
}

.orderbook__container table tbody tr td .modify-order:hover,
.orderbook__container table tbody tr td .cancel-order:hover {
  border: 2px solid #404040;
}

.orderbook__container table tbody tr td .buy {
  color: var(--main-green);
  font-weight: 700;
}

.orderbook__container table tbody tr td .sell {
  color: var(--main-red);
  font-weight: 700;
}

.orderbook__container table tbody tr td .complete,
.orderbook__container table tbody tr td .cancelled,
.orderbook__container table tbody tr td .pending,
.orderbook__container table tbody tr td .rejected {
  border-radius: 2px;
  padding: 4px 0;
  font-weight: 700;
  display: flex;
  /* justify-content: center; */
}

.orderbook__container table tbody tr td .complete {
  color: var(--main-green);
}

.orderbook__container table tbody tr td .cancelled {
  color: var(--main-red);
}

.orderbook__container table tbody tr td .pending {
  color: var(--main-blue);
}

.orderbook__container table tbody tr td .rejected {
  color: #e67e22;
}

.view__split {
  color: var(--white);
  cursor: pointer;
  background-color: #606060;
  padding: 3px 5px;
  border-radius: 2px;
}

/* share split popup style */
.share__split__overlay {
  position: fixed;
  inset: 0;
  background: #87888850;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
}

.share__split__popup {
  z-index: 1000000;
  width: 30%;
  height: 60%;
  border-radius: 5px;
  padding: 0 20px;
  transform: translateY(8px);
  box-shadow: 0 0 14px 0 rgb(15 17 17 / 10%);
  /* box-shadow: 1px 3px 5px #e8e8e8; */
  background-color: var(--white);
  animation: getup 0.1s linear forwards;
}

@keyframes getup {
  0% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.share__split__popup .share__popup__header {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #eeeeee;
  position: relative;
}

.share__split__popup .share__popup__header > p {
  font-size: 13px;
  font-weight: 700;
  color: var(--font-primary);
}

.share__split__popup .share__popup__header > div > img {
  width: 30px;
  cursor: pointer;
}

.share__split__popup .share__popup__body {
  width: 100%;
  height: 90%;
}

.share__popup__body .share__lable {
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
  margin: 10px 0 !important;
}

.share__popup__body .share__lable > span {
  font-weight: 700;
}

.share__popup__body .share__table {
  width: 100%;
  border: 1px solid #e8e8e8;
  max-height: 70%;
  border-radius: 3px;
  margin-top: 20px;
  overflow: scroll;
}

.share__popup__body .share__table table {
  width: 100%;
  table-layout: fixed;
}

.share__table table thead tr {
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  position: sticky;
}

.share__table table thead tr th {
  padding: 6px 0;
  text-align: center;
  color: var(--font-primary);
}

.share__table table tbody tr td {
  padding: 8px 0;
  text-align: center;
  color: var(--font-primary);
}

/* dealer console style */

.backoffice-wrapper .dealer-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px 50px;
}

.dealer-wrapper .dealer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.dealer-wrapper .dealer-header .dealer-header-brand {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.dealer-header .dealer-header-brand img {
  width: 36px;
}

.dealer-header .dealer-header-brand p {
  font-size: 16px;
  font-weight: 600;
}

.dealer-wrapper .dealer-content {
  width: 100%;
  height: calc(100% - 60px);
  padding: 0 0 0 0px;
}

.dealer-content .dealer-menu {
  width: 100%;
  height: 20px;
}

.dealer-content .dealer-content-wrapper {
  width: 100%;
  height: calc(100% - 30px);
}

.dealer-content-wrapper .dealer-orders {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.dealer-content-wrapper .dealer-orders.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding-top: 100px;
}

.dealer-content-wrapper .dealer-orders.loading > p {
  font-size: 14px;
  font-weight: 600;
}

.dealer-content-wrapper .dealer-orders .dealer-orders-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 60px;
}

.dealer-orders .dealer-orders-header .header-customers {
  width: 350px;
}

.dealer-orders .dealer-orders-header .header-config {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.dealer-orders-header .header-config .portfolio-config {
  display: flex;
  column-gap: 20px;
}

.header-config .portfolio-config .portfolio-config-box {
  display: flex;
  flex-direction: column;
}

.header-config .portfolio-config .portfolio-config-box > span {
  font-size: 12px;
  font-weight: 500;
}

.header-config .portfolio-config .portfolio-config-box > p {
  font-size: 15px;
  font-weight: 700;
}

.dealer-content-wrapper .dealer-orders .dealer-orders-table-wrapper {
  width: 100%;
  height: calc(100% - 80px);
}

.dealer-content-wrapper .dealer-orders .dealer-orders-table-wrapper.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dealer-orders .dealer-orders-table-wrapper .dealer-orders-table {
  width: 100%;
  height: calc(100% - 70px);
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  overflow-y: scroll;
}

.dealer-orders-table-wrapper .dealer-orders-table .orders-table-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
}

.dealer-orders-table-wrapper .dealer-orders-table .orders-table-empty > p {
  font-size: 15px;
  font-weight: 500;
}

.dealer-orders-table table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.dealer-orders-table table thead {
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.dealer-orders-table thead tr td {
  text-align: left;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.dealer-orders-table table tbody tr {
  cursor: default;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid #f5f5f5;
}

.dealer-orders-table table thead tr td.name {
  width: 180px;
}

.dealer-orders-table table thead tr td.price,
.dealer-orders-table table thead tr td.bid,
.dealer-orders-table table thead tr td.ask,
.dealer-orders-table table thead tr td.ltp,
.dealer-orders-table table thead tr td.holdings,
.dealer-orders-table table thead tr td.trade {
  width: 120px;
}

.dealer-orders-table table thead tr td.remove {
  width: 40px;
}

.dealer-orders-table table tbody tr td {
  vertical-align: center;
  padding: 15px 10px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.dealer-orders-table table tbody tr td .quantity-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 2px;
  grid-column-gap: 5px;
  column-gap: 5px;
}

.dealer-orders-table table tbody tr td .quantity-box span {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
  background-color: #f5f5f5;
  font-size: 10px;
}

.dealer-orders-table table tbody tr td input {
  width: 90%;
  background-color: transparent;
  color: var(--font-primary);
  border: none;
  outline: none;
  border-radius: 3px;
  padding: 0px 0px 0px;
  font-size: 13px;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
}

.dealer-orders-table table tbody tr td .ordertype-box {
  display: flex;
  column-gap: 4px;
  align-items: center;
  cursor: pointer;
}

.dealer-orders-table table tbody tr td .remove-symbol {
  cursor: pointer;
}

.dealer-orders-table-wrapper .dealer-orders-footer {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dealer-orders-footer .trade-value {
  display: flex;
  column-gap: 15px;
}

.dealer-orders-footer .trade-value > p {
  font-size: 12px;
  font-weight: 500;
}

.dealer-orders-footer .trade-value > p > span {
  font-weight: 600;
}

.dealer-content-wrapper .orders-confirmation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.orders-confirmation .orders-confirmation-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
}

.orders-confirmation .orders-confirmation-header .status-menu {
  display: flex;
  column-gap: 20px;
}

.orders-confirmation .orders-confirmation-header .status-menu p {
  font-size: 12px;
  cursor: pointer;
}

.orders-confirmation .orders-confirmation-header .status-menu p.active {
  font-weight: 700;
}

.orders-confirmation.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.orders-confirmation.loader > p {
  font-size: 14px;
  font-weight: 600;
}

.orders-confirmation .orders-confirmation-table {
  width: 100%;
  height: calc(100% - 50px);
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  overflow-y: scroll;
}

.orders-confirmation .orders-confirmation-table .empty-table-data{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.orders-confirmation .orders-confirmation-table table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.orders-confirmation .orders-confirmation-table table thead {
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.orders-confirmation .orders-confirmation-table tr {
  border-bottom: 1px solid #f8f8f8;
}

.orders-confirmation .orders-confirmation-table thead tr td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orders-confirmation .orders-confirmation-table table thead tr td:nth-child(1) {
  max-width: 140px;
}

.orders-confirmation .orders-confirmation-table table tbody {
  width: 100%;
}

.orders-confirmation .orders-confirmation-table tbody tr td {
  padding: 8px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orders-confirmation .orders-confirmation-table table tbody tr td .stock-info {
  display: flex;
  flex-direction: column;
}

.orders-confirmation .orders-confirmation-table table tbody tr td .stock-info .stock-name {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
}

.orders-confirmation .orders-confirmation-table table tbody tr td .stock-info .stock-fullname {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.2px;
}