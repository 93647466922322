.funds-management{
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: 20px;
}

.funds-management .funds-title{
  font-weight: 600;
  font-size: 14px;
}

.funds-management .funds-request{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 350px;
  height: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  padding: 5px 10px;
}

.funds-request .funds-request-wrapper{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 10px;
}

.funds-request .funds-process-button{
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: var(--font-secondary);
}

.funds-request-wrapper .funds-wrapper-loader{
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.funds-request-wrapper .funds-request-box.loader{
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.funds-request-wrapper .funds-request-box.info-box{
  width: 100%;
  padding: 10px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  border: 1px solid #f0f0f0;
}


.funds-request-wrapper .funds-request-box.info-box p{
  font-size: 13px;
  font-weight: 500;
}

.funds-request-wrapper .funds-request-box.info-box p span{
  font-weight: 600;
}

.funds-request .funds-request-button{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.funds-history{
  width: 1000px;
  border: 1px solid #f5f5f5;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border-radius: 15px;
}

.funds-history.loader{
  align-items: center;
  padding: 60px;
}

.funds-history-table {
  width: 100%;
  max-height: 500px;
  border-radius: 15px;
  overflow-y: scroll;
  padding: 0 0px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  position: relative;
}

.funds-history-table.empty{
  width: 100%;
  height: 100%;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}

.funds-history-table.empty>p{
  font-size: 14px;
  font-weight: 600;
}

.funds-history-table.loader{
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.funds-history table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.funds-history table thead {
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.funds-history table thead td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.funds-history table tbody {
  width: 100%;
}

.funds-history table tbody td {
  padding: 14px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.funds-history table tbody tr {
  cursor: initial;
  transition: 0.3s;
  border-bottom: 1px solid #f5f5f5c5;
}

.funds-history table td.customer{
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.funds-history table td.utr{
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* funds process popup */
.funds-process{
  width: 500px;
  height: 600px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.funds-process .funds-process-header{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid #e8e8e8;
}

.funds-process .funds-process-header-mobile{
  display: none;
}

.funds-process .funds-process-header>p{
  font-size: 13px;
  font-weight: 600;
}

.funds-process .funds-process-header .funds-process-close{
  cursor: pointer;
}

.funds-process .funds-process-body{
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 15px;
}

.funds-process .funds-process-body .funds-process-section{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.funds-process .funds-process-body .funds-process-title{
  font-size: 13px;
  font-weight: 600;
}

.funds-process .funds-process-body .funds-process-info{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.funds-process .funds-process-body .funds-process-info p{
  font-size: 13px;
  font-weight: 500;
}

.funds-process .funds-process-body .funds-process-info>div{
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.funds-process .funds-process-body .funds-process-info>div span{
  font-weight: 600;
}


/* responsive ui */

@media only screen and (max-width: 768px){
  .funds-management{
    display: flex;
    flex-direction: column;
  }

  .funds-management .funds-request-popup{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  .funds-management .funds-request{
    background-color: white;
    width: 100%;
    border-radius: 0;
    padding: 10px 10px;
  }

  .funds-request .funds-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .funds-request .funds-header>div{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .funds-request .funds-title{
    font-weight: 700;
  }

  .funds-history{
    width: 100%;
    border: none;
    padding: 0;
  }

  .funds-history .funds-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .funds-management .funds-title{
    font-weight: 700;
    font-size: 15px;
  }

  .funds-history-table{
    border: none;
    border-radius: 0;
  }

  .funds-history-table.empty{
    padding-top: 40px;
  }

  .funds-history-table .history-table-row{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;
    row-gap: 2px;
  }

  .funds-history-table .history-table-row .table-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  }

  .funds-history-table .history-table-row .customer-name{
    font-size: 15px;
    font-weight: 600;
  }

  .funds-history-table .history-table-row .table-row:nth-child(1),.funds-history-table .history-table-row .table-row:nth-child(2){
    font-weight: 700;
  }

  .funds-history-table .history-table-row .table-row:last-child{
    font-size: 13px;
    font-weight: 500;
  }

  .funds-process{
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .funds-process .funds-process-header{
    display: none;
  }

  .funds-process .funds-process-header-mobile{
    width: 100%;
    display: flex;
    padding: 10px 10px;
  }


}