.customer-trades{
  width: 100%;
  height: 100%;
  padding: 30px 60px 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.customer-trades .customer-trades-header{
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer-trades .customer-trades-header .trades-header-left{
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.customer-trades .customer-trades-header .trades-header-left>div,.customer-trades .customer-trades-header .trades-header-left>div>a{
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-trades .customer-trades-header img{
  width: 30px;
}

.customer-trades .customer-trades-title{
  font-size: 16px;
  font-weight: 700;
}

.customer-trades .customer-trades-table{
  height: calc(100% - 60px);
  width: 100%;
}

.customer-trades .customer-trades-table.loader{
  display: flex;
  justify-content: center;
  align-items: center;
}



@media only screen and (max-width: 768px){
  .customer-trades{
    width: 100%;
    height: 100%;
    padding: 10px 10px 0;
  }

  .customer-trades .customer-trades-header img{
    width: 24px;
  }

  .customer-trades .customer-trades-title{
    font-size: 15px;
  }
}