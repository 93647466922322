.swift-wrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  background: var(--white);
  display: flex;
}

.swift-wrapper-hidden {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  display: none;
  padding: 80px 10px;
}

.swift-wrapper-hidden .swift-name {
  font-size: 36px;
  font-weight: 800;
  color: var(--font-primary);
  background: linear-gradient(to right, #063970, #192b40);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -1px;
}

.swift-wrapper-hidden .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swift-wrapper-hidden .message {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 0 20px;
}

.swift-wrapper-hidden .message span {
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  display: inline-block;
}

.swift-menu {
  position: absolute;
  width: 50px;
  height: 100%;
  background-color: var(--white);
  padding: 5px 5px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  z-index: 100;
  overflow: hidden;
  transition: 0.2s;
  border-radius: 5px;
  box-shadow: 2px 0px 2px #fafafa;
}

.swift-menu.active {
  width: 200px;
}

.swift-menu .swift-menu-icon {
  display: flex;
  flex-direction: row;
  padding: 8px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.swift-menu .swift-menu-icon .menu-icon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.swift-menu .swift-menu-icon.active {
  background-color: #e8e8e8;
}

.swift-menu .swift-menu-icon .menu-text {
  padding-left: 15px;
  color: var(--font-primary);
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  transition: 0.2s;
  width: 140px;
}
/* 
.swift-menu.active .swift-menu-icon .menu-text{
  display: inline;
  opacity: 1;
} */

.swift-menu .swift-menu-icon.active .menu-text{
  font-weight: 700;
}

.swift-menu .swift-menu-options {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
}

.swift-menu .swift-menu-options-mobile{
  display: none;
}

.swift-menu .swift-menu-options .swift-menu-section {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
}

.swift-menu-options .swift-menu-section .menu-section-title {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--font-secondary);
  padding-left: 10px;
  opacity: 0;
  letter-spacing: 0px;
  transition: 0.2s;
  text-wrap: nowrap;
}

.swift-menu.active .swift-menu-options .swift-menu-section .menu-section-title{
    opacity: 1;
}

.swift-menu-options .swift-menu-section .menu-section-line{
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
}

.swift-menu-options .swift-menu-section .menu-section-options {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
}

.swift-menu .swift-menu-options .swift-menu-icon:hover {
  background-color: #f8f8f8;
}

.swift-menu .swift-menu-options .swift-menu-icon.active:hover {
  background-color: #e8e8e8;
}

.swift-menu svg {
  cursor: pointer;
  font-size: 20px;
  color: #cccccc;
}

.swift-menu a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  flex: 1;
}

.swift-menu a.menu-active svg {
  color: #b937e7;
}

.swift-menu .swift-menu-logo {
  width: fit-content;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.swift-menu .swift-menu-logo img {
  width: 30px;
}

.swift-menu .swift-menu-logo .menu-text {
  font-size: 18px;
  font-weight: 700;
  color: var(--font-primary);
}

.swift-body {
  position: absolute;
  left: 50px;
  width: calc(100% - 50px);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  row-gap: 15px;
  justify-content: space-between;
}

.swift-content-wrapper {
  width: 100%;
  height: calc(100% - 40px - 20px - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.swift-content-wrapper.full{
  height: 100%;
}

.swift-content-wrapper .swift-content-data-section {
  width: 100%;
  height: 100%;
  padding-right: 15px;
}

.swift-content-wrapper .swift-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.swift-content-wrapper .swift-submenu-wrapper{
  background-color: white;
}

.swift-content-wrapper .swift-submenu-item{
  display: none;
}

.swift-content-wrapper .swift-submenu {
  display: flex;
  column-gap: 40px;
  min-height: 15px;
  max-height: 15px;
}

.swift-content-wrapper .swift-submenu .swift-submenu-close{
  display: none;
}

.swift-content-wrapper .swift-submenu .swift-submenu-items{
  width: 100%;
  height: 100%;
}

.swift-content-wrapper .swift-submenu a {
  text-decoration: none;
  color: var(--font-primary);
}

.swift-content-wrapper .swift-submenu p {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
}

.swift-content-wrapper .swift-submenu p.active {
  font-weight: 700;
}

.swift-tooltip {
  font-size: 18px;
}

/* activation box ui */

.activation-box{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  padding-top: 100px;
}

.activation-box .activation-box-sec1{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.activation-box .activation-box-sec1>span{
  font-size: 14px;
  font-weight: 500;
}

.activation-box .activation-box-sec1>p{
  font-size: 18px;
  font-weight: 700;
}

.activation-box .activation-box-sec2{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.activation-box .activation-box-sec2>p{
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.activation-box .activation-box-sec3 button{
  border-radius: 30px;
  cursor: pointer;
  border: none;
  background-color: var(--main-brand);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* hide content ui */

/* @media only screen and (max-width: 1366px) {
  .swift-wrapper {
    display: none;
  }

  .swift-wrapper-hidden {
    display: flex;
  }
} */


.more-management{
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.more-management .title{
  font-size: 18px;
  font-weight: 700;
}

.more-management .more-section{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.more-management .more-section a{
  text-decoration: none;
}

.more-management .more-section .more-section-row{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

.more-management .more-section .more-section-row .row-icon{
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  align-items: center;
  padding: 15px 0;
}

.more-management .more-section .more-section-row .row-icon p{
  font-size: 15px;
  font-weight: 600;
  color: var(--font-primary);
}

/* responsive ui */

@media only screen and (max-width: 768px){
  .swift-wrapper{
    display: flex;
    flex-direction: column-reverse;
    width: 97%;
    margin: auto;
  }

  .swift-menu{
    width: 100%;
    height: 70px;
    display: flex;
    border-radius: 0;
    border-top: 1px solid #e8e8e8;
    position: initial;
  }

  .swift-content-wrapper .swift-submenu-wrapper{
    position: relative;
    background-color: transparent;
    width: 240px;
    height: 45px;
  }

  .swift-menu.active{
    width: 100%;
  }

  .swift-menu .swift-menu-logo{
    display: none;
  }

  .swift-menu .swift-menu-options {
    display: none;
  }
  
  .swift-menu .swift-menu-options-mobile{
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 0 0px;
  }

  .swift-menu .swift-menu-options-mobile .swift-menu-icon{
    flex-direction: column;
    padding: 0;
    justify-content: center;
  }

  .swift-menu .swift-menu-icon .menu-icon{
    height: auto;
  }

  .swift-menu .swift-menu-options-mobile .swift-menu-icon.active{
    background-color: transparent;
  }

  .swift-menu .swift-menu-options-mobile .swift-menu-icon a{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 4px;
  }

  .swift-menu .swift-menu-options-mobile .swift-menu-icon .menu-text{
    width: auto;
    padding: 0;
    font-size: 11px;
    font-weight: 600;
  }

  .swift-body{
    width: 100%;
    height: calc(100% - 70px);
    position: initial;
    padding: 5px 0px;
    row-gap: 5px;
    justify-content: flex-start;
  }

  .swift-content-wrapper{
    height: calc(100% - 30px - 10px);
    overflow-y: scroll;
  }

  .swift-content-wrapper::-webkit-scrollbar{
    display: none;
  }

  .swift-content-wrapper.active{
    height: calc(100% - 130px - 10px);
  }

  .swift-content-wrapper .swift-content-data-section{
    padding: 0 0px;
  }

  .activation-box{
    border: none;
  }

  .swift-content-wrapper .swift-content{
    row-gap: 16px;
    padding: 10px 0;
  }

  .swift-content-wrapper .swift-submenu-item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    border-radius: 40px;
    padding: 0px 15px;
    position: sticky;
    top: 0;
    background-color: #f0f0f0;
    border: 1px solid #e8e8e8;
  }

  .swift-content-wrapper .swift-submenu-item p{
    font-size: 14px;
    font-weight: 700;
  }

  .swift-content-wrapper .swift-submenu {
    position: absolute;
    top: 110%;
    left: 0%;
    min-width: 100%;
    min-height: fit-content;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 10px;
    background-color: #f0f0f0;
    z-index: 1000;
    transition: 0.03s;
    padding: 10px 15px;
    border-radius: 20px;
    display: none;
  }

  .swift-content-wrapper .swift-submenu>a{
    padding: 10px 0;
    width: 100%;
    font-size: 14px;
    border-bottom: 0.5px solid rgba(176, 176, 176, 0.3);
  }

  .swift-content-wrapper .swift-submenu>p{
    padding: 10px 0;
    width: 100%;
    font-size: 14px;
    border-bottom: 0.5px solid rgba(176, 176, 176, 0.3);
  }

  .swift-content-wrapper .swift-submenu .swift-submenu-close{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    z-index: 1000;
    top: 10px;
    right: 10px;
  }

  .swift-content-wrapper .swift-submenu.active{
    display: flex;
  }
  
  .swift-content-wrapper .swift-submenu p {
    font-size: 15px;
    line-height: 20px;
  }

}