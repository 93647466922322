/*app header*/
.swift-header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 100px;
  -webkit-column-gap: 100px;
  column-gap: 100px;
}

.swift-header.swift-header__zoom {
  height: 0;
  display: none;
}

.swift-header .search__tabs {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 5px;
  column-gap: 25px;
  border-bottom: 1px solid #f5f5f5;
}

.swift-header .search__tabs .s__tab {
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--font-primary);
  background-color: var(--white);
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}

.swift-header .search__tabs .s__tab.active {
  font-weight: 700;
}

.swift-header .stock__search__icons__wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--light-box-shadow);
}

.swift-header .stock__search {
  width: 450px;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
}

.swift-header .stock__search .stock__search__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px 0 15px;
}

.swift-header .stock__search .stock__search__icon img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.swift-header .stock__search input {
  border: none;
  outline: none;
  width: 100%;
  height: 90%;
  padding-left: 2px;
  color: var(--font-primary);
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase !important;
  background-color: transparent;
}

.swift-header .stock__search input::placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-header .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: 0%;
  margin-top: 3px;
  z-index: 10000;
  border-radius: 5px;
  max-height: 350px;
  /* overflow-y : scroll; */
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
  /* display: none; */
}

.stock__suggestions .suggestion__stocks {
  overflow-y: scroll;
  max-height: 300px;
}

.swift-header .stock__search .stock__suggestions.active {
  display: block;
}

.swift-header .stock__search .stock__suggestions .search__loader {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swift-header .stock__search .stock__suggestions .search__loader p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  padding: 0 !important;
  display: flex;
  color: var(--font-primary);
}

.swift-header .stock__search .stock__suggestions p {
  padding: 10px 10px 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.suggestion__stocks .suggestion__empty {
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.swift-header .stock__search .stock__suggestions p.active {
  background-color: #dbd9d9;
}

.swift-header .stock__search .stock__suggestions p span.search__highlight {
  /* color: #c5c5c5; */
  font-weight: 500;
}

.swift-header .stock__search .stock__suggestions p > span:nth-child(1) {
  flex: 4;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swift-header .stock__search .stock__suggestions p > span:nth-child(2) {
  flex: 5;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
}

.swift-header .stock__search .stock__suggestions p > span:nth-child(3) {
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  color: var(--font-primary);
}

.swift-header .stock__search .stock__suggestions p:hover {
  background-color: #f9f9f9;
}

.swift-header .app__menu {
  display: flex;
  align-items: center;
  /* margin-left: 20px; */
  height: 100%;
  /* background-color: #888888; */
}

.swift-header .app__menu__mobile__icon {
  display: none;
  justify-content: center;
  align-items: center;
}

.swift-header .app__menu__mobile__icon.hidden {
  display: none;
}

.swift-header .app__menu__mobile__icon > img {
  width: 20px;
}

.swift-header .app__suggestion__close__icon {
  display: none;
}

.swift-header .app__menu__mobile {
  display: none;
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: var(--white);
  width: 100%;
  height: 100%;
  z-index: 1000000;
}

.swift-header .app__menu__mobile.active {
  display: block;
}

.swift-header .app__menu span {
  font-size: 12px;
  margin: 0 10px;
  letter-spacing: -0.2px;
  font-weight: 500;
  color: var(--font-primary);
  cursor: pointer;
}

.swift-header .app__menu span:hover {
  font-weight: 600;
}

.swift-header .app__menu span#account-code {
  border: 1px solid #e8e8e8;
  padding: 4px 10px;
  border-radius: 3px;
}

.swift-header .app__menu span.active {
  color: var(--main-blue);
}

.swift-header .app__menu span.dotmenu__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.swift-header .app__menu .dotmenu__icon .dotmenu__wrapper {
  position: absolute;
  top: 120%;
  right: 0%;
  margin-top: 7px;
  width: 150px;
  height: auto;
  background-color: var(--white);
  box-shadow: var(--shadow-code-box);
  transform: rotateX(-15deg);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  /* transition: 0.25s; */
  opacity: 0;
  transform-origin: 0 0;
  visibility: hidden;
}

.swift-header .app__menu .dotmenu__icon .dotmenu__wrapper.active {
  visibility: visible;
  opacity: 1;
  transform: rotateX(0deg);
}

.swift-header .app__menu .dotmenu__icon .dotmenu__wrapper > span {
  width: 100%;
  text-align: center;
  padding: 8px 0;
  transition: 0.3s;
}

.swift-header .app__menu .dotmenu__icon .dotmenu__wrapper > span > a {
  color: var(--font-primary);
  text-decoration: none;
}

.swift-header .app__menu .dotmenu__icon .dotmenu__wrapper > span:hover > a {
  color: var(--font-primary);
}

.swift-header__top {
  width: 100%;
  height: 100%;
  /* overflow-x: scroll; */
  /* background-color: salmon; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 30px;
}

.swift-header__top::-webkit-scrollbar {
  display: none;
}

.swift-header__top .top__stocks {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.swift-header__top .top__stocks__menu {
  display: none;
}

.swift-header__top .top__stocks__menu__mobile {
  display: block;
}

.swift-header__top .top__stocks .upper__stock {
  display: flex;
  column-gap: 8px;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  width: 280px;
}

.upper__stock .upper__stock__info {
  display: flex;
}

.upper__stock .upper__stock__price {
  display: flex;
  column-gap: 8px;
}

.upper__stock .upper__stock__change__wrapper {
  display: flex;
  column-gap: 8px;
}

.upper__stock .upper__stock__change__value,
.upper__stock .upper__stock__change {
  font-weight: 700;
}

.swift-header__top .top__stocks .upper__stock.loader {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.swift-header .header-options {
  display: flex;
  flex-direction: row;
}

.swift-header .header-options .brand-logo{
  display: none;
}

.swift-header .header-options .header-options-list{
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.header-options-menu {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  width: 25px;
  height: 25px;
}

.header-options-menu.hide {
  border: none;
}

.header-options-menu .account-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--font-primary);
  background-color: #e8e8e8;
}

.header-options-menu .accounts-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  top: 110%;
  right: 0;
  background-color: white;
  width: 340px;
  box-shadow: 1px 3px 5px #e8e8e8;
  padding: 10px 5px;
  border-radius: 15px;
  z-index: 100000;
}

.accounts-wrapper .accounts-list-loader {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  padding: 0 15px;
}

.accounts-wrapper .accounts-list {
  width: 100%;
}

.accounts-list .account-row {
  width: 100%;
  display: flex;
  column-gap: 5px;
  padding: 10px 4px;
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
}

.accounts-list .account-row:hover {
  background-color: #fafafa;
}

.account-row .account-icon {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-row .account-icon .account-profile {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--font-primary);
  border: 1px solid var(--font-primary);
}

.account-row .account-icon span {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.account-row .account-info {
  width: 85%;
  display: flex;
  flex-direction: column;
  row-gap: 1px;
}

.account-row .account-info .account-name {
  font-size: 12px;
  font-weight: 600;
}

.account-row .account-info .account-role {
  font-size: 11px;
  font-weight: 500;
}

.accounts-wrapper .logout-button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  padding: 10px 15px;
  border-radius: 5px;
}

.accounts-wrapper .logout-button > p {
  font-size: 13px;
  font-weight: 700;
}

.accounts-wrapper .logout-button:hover {
  background-color: #f9f9f9;
}

.header-options .dotmenu-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  top: 105%;
  right: 0;
  background-color: white;
  width: 120px;
  box-shadow: 1px 3px 5px #e8e8e8;
  padding: 15px 15px;
  border-radius: 5px;
  z-index: 100000;
}

.header-options .dotmenu-wrapper a {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  text-decoration: none;
}

.header-options-menu .menu-common {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.header-options-menu .menu-common .menu-common-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding: 8px 15px;
  border-radius: 5px;
  align-items: center;
  justify-content: flex-start;
}

.header-options-menu .menu-common .menu-common-row:hover {
  background-color: #f9f9f9;
}

.header-options-menu .menu-common .menu-common-row span {
  font-size: 12px;
  font-weight: 600;
}

.header-options-menu .menu-line {
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
}
/* broking history popup */
.notifications-popup {
  width: 900px;
  height: 600px;
  background-color: var(--white);
  border-radius: 5px;
  padding: 15px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  position: relative;
}

.notifications-popup .notifications-popup-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}

.notifications-popup .notifications-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.notifications-header .notifications-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.notifications-header .notifications-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.notifications-popup .notifications-body {
  padding: 10px 0 20px;
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  position: relative;
}

.notifications-body .notifications-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.notifications-body .notifications-empty > p {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.notifications-body .notifications-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.notifications-body .notification-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  padding: 10px 10px 10px 2px;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
}

.notifications-body .notification-row.hide {
  display: none;
}

.notifications-body .notification-row:nth-child(1) {
  padding-top: 0;
}

.notifications-body .notification-row .subject {
  flex: 5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.notifications-body .notification-row .time {
  flex: 1;
  font-size: 11px;
  font-weight: 600;
  text-align: right;
}

.notifications-body .notification-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: var(--white);
  padding: 0 15px 0 0;
}

.notifications-body .notification-box .back {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 5px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}

.notifications-body .notification-box .back span {
  font-size: 12px;
  font-weight: 700;
}

.notifications-body .notification-box .subject {
  font-size: 13px;
  font-weight: 600;
}

.notifications-body .notification-box .time {
  font-size: 12px;
  font-weight: 600;
}

.notifications-body .notification-box .attachments {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.notifications-body .notification-box .attachments > p {
  font-size: 12px;
  font-weight: 600;
}

.notifications-body .notification-box .attachments .attachments-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
}

.notifications-body .notification-box .attachments .attachments-list a {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  cursor: pointer;
}

.notifications-body .notification-box .body {
  font-size: 12px;
  font-weight: 500;
  padding: 0px 0px 10px 0;
  width: 100%;
}

.notifications-body .notification-box .body > div {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

@media only screen and (max-width: 768px) {
  .swift-header {
    min-height: 30px;
    max-height: 30px;
    padding: 0px 10px;
  }

  .swift-header.active{
    min-height: 135px;
    max-height: 135px;
    border-bottom: 1px solid #e8e8e8;
  }

  .swift-header .stock__search__icons__wrapper {
    display: none;
  }

  .swift-header__top {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    row-gap: 4px;
    height: 100%;
  }

  .swift-header__top .top__stocks {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;
    display: none;
  }

  .swift-header__top .top__stocks.active{
    display: flex;
  }

  .swift-header__top .top__stocks .upper__stock {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .upper__stock .upper__stock__info {
    display: flex;
    flex-direction: column;
  }

  .upper__stock .upper__stock__info .upper__stock__symbol {
    font-size: 14px;
    font-weight: 600;
  }

  .upper__stock .upper__stock__price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 2px;
  }

  .upper__stock .upper__stock__price .upper__stock__value {
    font-size: 14px;
    font-weight: 600;
  }

  .upper__stock .upper__stock__change__wrapper {
    display: flex;
    column-gap: 8px;
  }

  .upper__stock .upper__stock__change__value,
  .upper__stock .upper__stock__change {
    font-size: 13px;
    font-weight: 600;
  }

  .swift-header .header-options{
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .swift-header .header-options .brand-logo{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swift-header .header-options .brand-logo img{
    width: 34px;
  }
  

  .header-options-menu{
    border: none;
  }

  .swift-header .header-options .header-options-list{
    display: flex;
    flex-direction: row;
    column-gap: 16px;
  }


  .notifications-popup{
    width: 100%;
    height: 100%;
  }

  .notifications-header .notifications-title{
    font-size: 15px;
  }

  .notifications-body .notification-row {
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 4px;
  }

  .notifications-body .notification-row .subject{
    font-size: 14px;
    font-weight: 600;
  }

  .notifications-body .notification-row .time{
    text-align: left;
    font-size: 13px;
    font-weight: 500;
  }

}
