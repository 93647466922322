.rupee__class {
  font-family: "Lato", sans-serif !important;
}

.positive {
  color: var(--main-green);
  /* display: flex; */
}

.negative {
  color: var(--main-red);
  /* display: flex; */
}

.neutral {
  color: var(--font-primary);
  display: flex;
}

body {
  width: 100%;
  height: 100%;
  position: fixed;
  color: var(--font-primary);
}

#root {
  width: 100%;
  height: 100%;
}

.app {
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}

.app__onboard {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app__onboard p {
  font-size: 14px;
  font-weight: bold;
  margin-top: 15px !important;
}

.app .compare__limit__popup,
.app .indicator__limit__popup {
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px 15px 0px 0;
  max-width: 400px;
  height: 60px;
  background-color: var(--white);
  box-shadow: rgba(17, 17, 26, 0.2) 2px 2px 16px;
  z-index: 100000;
  border-radius: 3px;
  transition: 0.4s;
  display: flex;
}

.app .compare__limit__popup.active,
.app .indicator__limit__popup.active {
  /* display: flex; */
  top: 3%;
}

.app .compare__limit__popup .compare__limit__sideline,
.app .indicator__limit__popup .indicator__limit__sideline {
  position: absolute;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: var(--main-blue);
}

.app .compare__limit__popup .compare__limit__content,
.app .indicator__limit__popup .indicator__limit__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 15px;
}

.app .compare__limit__popup .compare__limit__content .compare__limit__text,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__text {
  margin-right: 20px;
}

.app .compare__limit__popup .compare__limit__content .compare__limit__text h6,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__text
  h6 {
  font-size: 14px;
  color: var(--font-primary);
}

.app .compare__limit__popup .compare__limit__content .compare__limit__text p,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__text
  p {
  font-size: 12px;
  font-weight: 600;
}

.app .compare__limit__popup .compare__limit__content .compare__limit__close,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 3px;
}

.app .compare__limit__popup .compare__limit__content .compare__limit__close img,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__close
  img {
  width: 12px;
  height: 12px;
}

.app
  .compare__limit__popup
  .compare__limit__content
  .compare__limit__close:hover,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__close:hover {
  background-color: #f3f3f3;
}

.app__back__blur {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: #87888850;
  /* background-color: #00A0E3; */
  z-index: 10000;
  display: none;
}

.app__back__blur.active {
  display: block;
}

/*app__footer*/
.app__footer {
  width: 100%;
  display: flex;
  align-items: center;
  align-items: center;
}

.app__footer__menu__icon {
  display: none;
  margin-left: 15px;
}

.app__footer__icon > div > div {
  width: 45px !important;
  height: 45px !important;
}

.app__footer.app__footer__zoom {
  height: 0;
  display: none;
}

.app__footer > div {
  cursor: pointer;
}

.app__footer span {
  font-size: 12px;
  color: var(--font-primary);
  margin: 0 8px;
  font-weight: 900;
  letter-spacing: -0.2px;
  cursor: pointer;
}

.app__footer .app__logout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 70px;
  cursor: pointer;
}

.app__footer .app__logout img {
  width: 22px;
  transform: rotate(180deg);
  margin-right: 4px;
}

.app__footer .app__logout p {
  font-weight: bold;
  font-size: 14px;
  color: var(--font-primary);
}

.app__footer .app__footer__menu {
  width: 100%;
  height: 100%;
}

.bound__labels {
  padding: 4px 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.price__bounds__outer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 300px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.price__bounds__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.PriceUpAndLowBounds {
  position: relative;
  width: 10px;
  background-color: #ffffff;
}

.PriceUpAndLowBounds .pul__inner {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #470985;
  border-radius: 10px;
  animation: create 0.5s linear forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.label__up {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.label__down {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes create {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  * {
    -webkit-tap-highlight-color: transparent;
  }

  .app {
    height: 100dvh;
  }
  
  .app__onboard {
    height: 100dvh;
  }

}
