.admin-dashboard-stats-wrapper {
  padding: 5px;
  width: 500px;
}
.admin-dashboard-stats-container {
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}
.admin-dashboard-stats-title {
  font-size: 15px;
  font-weight: 600;
}
.admin-dashboard-stats-data {
  display: flex;
  flex-direction: column;
}
.admin-dashboard-stats-data-row {
  font-size: 13px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.admin-dashboard-stats-data-object {
  display: flex;
  flex-direction: column;
  width: 200px;
  row-gap: 0px;
}

.admin-dashboard-stats-data-title {
  font-size: 12px;
  font-weight: 500;
}

.admin-dashboard-stats-data-value {
  font-size: 20px;
  font-weight: 600;
}
