.backoffice__client__wrapper {
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backoffice__client {
    width: 90%;
    height: 90%;
    min-width: 1000px;
    min-height: 700px;
    border-radius: 3px;
}

/* backoffice client details */
.bo__client__details {
    width: 100%;
    height: 10%;
    /* border: 1px solid #e8e8e8; */
    margin-bottom: 10px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bo__client__details>p {
    font-size: 20px;
    color: var(--font-primary);
    /* font-weight: 700; */
    text-transform: uppercase;
}

/* backoffice client data */
.bo__data {
    width: 100%;
    height: 90%;
    display: flex;
    overflow: hidden;
    box-shadow: 1px 3px 5px #e8e8e8, 0px -1px 2px #e8e8e8;
}

.bo__data .bo__menu {
    width: 15%;
    min-width: 150px;
    height: 100%;
    box-shadow: 1px 3px 5px #e8e8e8;
    margin-right: 2px;
}

.bo__menu .bo__menu__item {
    width: 100%;
    padding: 12px 10px;
    cursor: pointer;
    font-size: 13px;
    color: var(--font-primary);
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;
    transition: 0.3s;
}

.bo__menu .bo__menu__item.active {
    background-color: var(--main-blue);
    color: var(--white);
}

.bo__menu .bo__menu__item:hover {
    background-color: #f4f4f4;
}

.bo__menu .bo__menu__item.active:hover {
    background-color: var(--main-blue);
}

.bo__menu .bo__menu__item.selected {
    background-color: var(--main-blue);
    color: white;
}

.bo__data .bo__table {
    width: 85%;
    height: 100%;
    position: relative;
    overflow-y: scroll;
}

.bo__table .bo__table__empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bo__table .bo__table__empty .bo__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bo__table__empty .bo__empty img {
    width: 140px;
}

.bo__table__empty .bo__empty p {
    font-size: 14px;
    color: var(--font-primary);
}

.bo__table table {
    width: 100%;
}

.bo__table table thead {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #f6f6f6;
}

.bo__table table thead tr {
    width: 100%;
}

.bo__table table thead tr th {
    padding: 10px 0;
    font-size: 11px;
    color: var(--font-primary);
    font-weight: 700;
    min-width: 100px;
}

.bo__table table tbody tr {
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
    /* border-bottom: 1px solid #e8e8e8; */
    box-shadow: 0px 1px 1px #e8e8e8;
}



.bo__table table tbody tr:hover {
    background-color: #fcfcfc;
}

.bo__table table tbody tr td {
    padding: 12px 0;
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 500;
    text-align: center;
    box-shadow: 1px 0px 1px #e8e8e8;
}


.bo__table table tbody tr td img {
    width: 20px;
}