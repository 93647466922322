.order-status
{
    width: 100%;
    height: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.order-status .order-status-header
{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.order-status .order-status-header>img
{
    width: 30px;
    cursor: pointer;
}

.order-status .order-status-body
{
    width: 100%;
    height: 90%;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.order-status-body .order-status-title
{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    color: var(--font-primary);
}

.order-status-body .order-status-description
{
    font-size: 13px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--font-primary);
    text-align: center;
}

.order-status-body .order-status-exchange {
    font-size: 12px;
    color: var(--main-red);
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
}
.order-status-body .order-status-buttons
{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.order-status-body .order-status-buttons>div
{
    width: 200px;
    height: 40px;
    border: 1px solid var(--font-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    color: var(--font-primary);
    font-size: 13px;
    font-weight: 600;
    margin: 5px 0;
    cursor: pointer;
}

.checkmark__icon
{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    margin-bottom: 2%;
    box-shadow: inset 0px 0px 0px var(--main-green);
    animation: fillcirclesuccess .6s ease-in-out .9s forwards, scalecircle .6s ease-in-out 1s both;
}

.order-icon.success .checkmark__icon
{
    animation: fillcirclesuccess .6s ease-in-out .9s forwards, scalecircle .6s ease-in-out 1s both;
}

.order-icon.failure .checkmark__icon
{
    animation: fillcirclefailure .6s ease-in-out .9s forwards, scalecircle .6s ease-in-out 1s both;
}


.checkmark__circle__inner
{
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 8;
    stroke-miterlimit: 10;
    fill: none !important;
    animation: strokecircle 0.9s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    stroke: var(--main-green);
}

.order-icon.success .checkmark__circle__inner
{
    stroke: var(--main-green);
}


.order-icon.failure .checkmark__circle__inner
{
    stroke: var(--main-red);
}

.checkmark__check__inner
{
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: strokecircle 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}


@keyframes strokecircle {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scalecircle {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fillcirclesuccess {
    0%,100% {
        box-shadow: inset 0px 0px 0px 30px var(--main-green);
    }
}

@keyframes fillcirclefailure {
    0%,100% {
        box-shadow: inset 0px 0px 0px 30px var(--main-red);
    }
}


@media only screen and (max-width:768px)
{
    
}