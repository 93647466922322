@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
:root {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: 500;

  color-scheme: light dark;
  color: #192b40;
  background-color: #FFFFFF;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #e8e8e8;
}

::-webkit-scrollbar-thumb:hover {
  background: #e8e8e8;
}

.create-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  overflow-x: scroll;
}

.create-table th {
  width: 150px;
}

.create-table thead td {
  color: var(--font-primary);
  font-weight: 500;
  font-size: 12px;
}

.create-table tr {
  width: 80px;
}

.create-table tr td:first-child {
  padding: 0px 10px;
  text-align: left;
  /* font-weight: 500; */
  min-width: 120px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: var(--white);
}

.create-table tbody tr {
  height: 20px;
}

.create-table tbody td,
.create-table thead td {
  padding-bottom: 0 !important;
  padding-top: 2px !important;
  padding-left: 15px !important;
  padding-right: 15px;
  height: 10px;
  border: none;
  padding: 0 30px;
  font-size: 11px;
  font-weight: 500;
  color: var(--font-primary);
}

.create-table tbody td:not(:first-child) {
  border: 1px solid #ccc;
  border-bottom-style: 1px solid;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}

.GlobalScrollBar {
  overflow: scroll;
  scroll-behavior: smooth;
  overflow-wrap: break-word;
  color: black;
}

.create-table td.cell__bold {
  font-weight: 700;
  color: var(--font-primary);
}

.create-table td.cell__blue {
  /* font-weight: 700; */
  color: var(--main-blue);
}

.create-table td.cell__normal {
  font-weight: 500;
}
.rupee__class {
  font-family: "Lato", sans-serif !important;
}

.positive {
  color: var(--main-green);
  /* display: flex; */
}

.negative {
  color: var(--main-red);
  /* display: flex; */
}

.neutral {
  color: var(--font-primary);
  display: flex;
}

body {
  width: 100%;
  height: 100%;
  position: fixed;
  color: var(--font-primary);
}

#root {
  width: 100%;
  height: 100%;
}

.app {
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}

.app__onboard {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app__onboard p {
  font-size: 14px;
  font-weight: bold;
  margin-top: 15px !important;
}

.app .compare__limit__popup,
.app .indicator__limit__popup {
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px 15px 0px 0;
  max-width: 400px;
  height: 60px;
  background-color: var(--white);
  box-shadow: rgba(17, 17, 26, 0.2) 2px 2px 16px;
  z-index: 100000;
  border-radius: 3px;
  transition: 0.4s;
  display: flex;
}

.app .compare__limit__popup.active,
.app .indicator__limit__popup.active {
  /* display: flex; */
  top: 3%;
}

.app .compare__limit__popup .compare__limit__sideline,
.app .indicator__limit__popup .indicator__limit__sideline {
  position: absolute;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: var(--main-blue);
}

.app .compare__limit__popup .compare__limit__content,
.app .indicator__limit__popup .indicator__limit__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 15px;
}

.app .compare__limit__popup .compare__limit__content .compare__limit__text,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__text {
  margin-right: 20px;
}

.app .compare__limit__popup .compare__limit__content .compare__limit__text h6,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__text
  h6 {
  font-size: 14px;
  color: var(--font-primary);
}

.app .compare__limit__popup .compare__limit__content .compare__limit__text p,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__text
  p {
  font-size: 12px;
  font-weight: 600;
}

.app .compare__limit__popup .compare__limit__content .compare__limit__close,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 3px;
}

.app .compare__limit__popup .compare__limit__content .compare__limit__close img,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__close
  img {
  width: 12px;
  height: 12px;
}

.app
  .compare__limit__popup
  .compare__limit__content
  .compare__limit__close:hover,
.app
  .indicator__limit__popup
  .indicator__limit__content
  .indicator__limit__close:hover {
  background-color: #f3f3f3;
}

.app__back__blur {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: #87888850;
  /* background-color: #00A0E3; */
  z-index: 10000;
  display: none;
}

.app__back__blur.active {
  display: block;
}

/*app__footer*/
.app__footer {
  width: 100%;
  display: flex;
  align-items: center;
  align-items: center;
}

.app__footer__menu__icon {
  display: none;
  margin-left: 15px;
}

.app__footer__icon > div > div {
  width: 45px !important;
  height: 45px !important;
}

.app__footer.app__footer__zoom {
  height: 0;
  display: none;
}

.app__footer > div {
  cursor: pointer;
}

.app__footer span {
  font-size: 12px;
  color: var(--font-primary);
  margin: 0 8px;
  font-weight: 900;
  letter-spacing: -0.2px;
  cursor: pointer;
}

.app__footer .app__logout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 70px;
  cursor: pointer;
}

.app__footer .app__logout img {
  width: 22px;
  transform: rotate(180deg);
  margin-right: 4px;
}

.app__footer .app__logout p {
  font-weight: bold;
  font-size: 14px;
  color: var(--font-primary);
}

.app__footer .app__footer__menu {
  width: 100%;
  height: 100%;
}

.bound__labels {
  padding: 4px 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.price__bounds__outer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 300px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.price__bounds__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.PriceUpAndLowBounds {
  position: relative;
  width: 10px;
  background-color: #ffffff;
}

.PriceUpAndLowBounds .pul__inner {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #470985;
  border-radius: 10px;
  animation: create 0.5s linear forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.label__up {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.label__down {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes create {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  * {
    -webkit-tap-highlight-color: transparent;
  }

  .app {
    height: 100dvh;
  }
  
  .app__onboard {
    height: 100dvh;
  }

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: -0.3px;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-family: 'Open Sans', sans-serif;
    /* font-family: 'Manrope', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Lato', sans-serif; */
}

:root {
  --main-blue: #470985;
  --main-green: #36BEAD;
  --main-green-light: #36beae35;
  --main-red: #FF3888;
  --main-red-light: #ff388835;
  --main-brand: #3179F5;
  --light-grey: #EAEAEA;
  --medium-grey: #BBBBBB;
  --light-white: #FEFEFE;
  --white: #FFFFFF;
  --font-primary: rgba(1, 22, 39, 1);
  --font-secondary: #666666;
  --shadow-primary: #F2F2F2;
  --shadow-code: 0px 0px 5px #f5f5f5;
  --shadow-code-box: 0px 0px 5px #e2e2e2;
  --text-color: rgba(1, 22, 39, 1);
  --text-color-blur: rgba(1, 22, 39, 1);
  --green-color: #67D098;
  --light-text-color: #CCCCCC;
  --light-box-shadow: 0px 0px 2px #fafafa
}


::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e8e8e8;
}

::-webkit-scrollbar-thumb:hover {
  background: #e8e8e8;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
  -webkit-box-shadow: 0 0 0px 1000px var(--white) inset;
  -webkit-text-fill-color: rgba(1, 22, 39, 1);
  -webkit-text-fill-color: var(--font-primary);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  touch-action: manipulation;
}
/* input box */

.swift-input-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 3px;
  row-gap: 3px;
  height: 80px;
}

.swift-input-box.hide {
  opacity: 0;
}

.swift-input-box p {
  font-size: 12px;
  font-weight: 500;
}

.swift-input-box.disabled p {
  color: #ccc;
}

.swift-input-box .input-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.swift-input-box .input-header .primary-text {
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.swift-input-box .input-header .secondary-text {
  font-size: 12px;
  font-weight: 500;
}

.swift-input-box .input-header .primary-text:hover {
  color: rgb(120, 120, 120);
}

.swift-input-box input {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
  margin-top: 5px;
}

.swift-textarea-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 3px;
  row-gap: 3px;
  height: 140px;
}

.swift-textarea-box textarea {
  width: 100%;
  background-color: transparent;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
  margin-top: 5px;
  padding-top: 5px;
  height: 100px;
  resize: none;
}

.swift-textarea-box p {
  font-size: 12px;
  font-weight: 500;
}

.swift-input-box input::-webkit-input-placeholder, .swift-textarea-box textarea::-webkit-input-placeholder {
  font-weight: 500;
}

.swift-input-box input:-ms-input-placeholder, .swift-textarea-box textarea:-ms-input-placeholder {
  font-weight: 500;
}

.swift-input-box input::placeholder,
.swift-textarea-box textarea::placeholder {
  font-weight: 500;
}

.swift-input-box.disabled input,
.swift-textarea-box.disabled textarea {
  border: 1px solid #ccc;
  color: #ccc;
}

.swift-input-box input:focus,
.swift-textarea-box textarea:focus {
  border-color: #d8d8d8;
}

.swift-input-box .error-text {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  line-height: 12px;
}

.swift-input-box .error-text.hide {
  opacity: 0;
}

/* input box */

/* select box */
.swift-select-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 3px;
  row-gap: 3px;
  height: 80px;
}

.swift-select-box.hide {
  opacity: 0;
}

.swift-select-box p {
  font-size: 12px;
  font-weight: 500;
}

.swift-select-box select {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-select-box input::-webkit-input-placeholder {
  font-weight: 500;
}

.swift-select-box input:-ms-input-placeholder {
  font-weight: 500;
}

.swift-select-box input::placeholder {
  font-weight: 500;
}

.swift-select-box .error-text {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  line-height: 12px;
}

.swift-select-box .error-text.hide {
  opacity: 0;
}

/* select box */

/* button */

.swift-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  min-width: 150px;
  padding: 10px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.3s;
  color: var(--font-primary);
  cursor: pointer;
  background-color: #ffffff;
}

.swift-button.disabled {
  border-color: #ccc;
  color: #ccc;
  cursor: not-allowed;
}

.swift-button.hide {
  opacity: 0;
}

.swift-button:hover {
  background-color: #f5f5f5;
}

.swift-button p {
  font-size: 13px;
  font-weight: 500;
}

/* button */

/* icon button */

.swift-button-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  min-width: 150px;
  min-height: 40px;
  padding: 10px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.3s;
  color: var(--font-primary);
  cursor: pointer;
  background-color: #ffffff;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.swift-button-icon:hover {
  background-color: #f5f5f5;
}

.swift-button-icon.loading,
.swift-button-icon.loading:hover {
  cursor: not-allowed;
  background-color: #ffffff;
}

.swift-button-icon.success,
.swift-button-icon.success:hover {
  cursor: not-allowed;
  background-color: #ffffff;
}

.swift-button-icon.failure,
.swift-button-icon.failure:hover {
  cursor: not-allowed;
  background-color: #ffffff;
}

.swift-button-icon.disabled {
  border-color: #e8e8e8;
  color: #ccc;
  cursor: not-allowed;
}

.swift-button-icon.hide {
  opacity: 0;
}


.swift-button-icon .button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swift-button-icon p {
  font-size: 13px;
  font-weight: 500;
}

/* icon button */

/* custom date */

.swift-date-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 3px;
  row-gap: 3px;
  height: 80px;
}

.swift-date-wrapper input {
  width: 100%;
  height: 40px;
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-date-title {
  font-size: 12px;
  font-weight: 500;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1);
  margin: 0px;
  margin-right: 10px !important;
  cursor: pointer;
}

/* For CustomActionIconButton.jsx */

.swift-button-icon .circle {
  stroke-dasharray: 55;
  stroke-dashoffset: -55;
  animation: circle 0.4s linear;
  animation-fill-mode: forwards;
}

.swift-button-icon .tick {
  stroke-dasharray: 35;
  stroke-dashoffset: 35;
  animation: tick 1s ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}

@keyframes tick {
  from {
    stroke-dashoffset: 35;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes circle {
  0% {
    stroke-dashoffset: -55;
    fill: none;
  }
  99%{
    fill: none;
  }
  100% {
    stroke-dashoffset: 0;
  }
}


.swift-button-icon .cross {
  stroke-dasharray: 45;
  stroke-dashoffset: 45;
  animation: drawCross 1s ease-out forwards;
  animation-delay: 0.4s;
}

@keyframes drawCross {
  from {
    stroke-dashoffset: 45;
  }
  to {
    stroke-dashoffset: 0;
  }
}


/* swift info message */

.swift-info-message{
  position: relative;
  height: 100%;
}

.swift-info-message svg{
  cursor: pointer;
}

.swift-info-message .swift-info-message-box{
  position: fixed;
  z-index: 1000000;
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 5px;
  min-width: 100px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color-blur);
}


@media only screen and (max-width: 768px){
  .swift-input-box p {
    font-size: 14px;
    font-weight: 600;
  }

  .swift-input-box input{
    font-size: 15px;
  }

  .swift-button-icon p{
    font-size: 14px;
  }

  .swift-button p{
    font-size: 14px;
    font-weight: 600;
  }
}

.app__hero__page {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 20px;
}
.app__hero__loader{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.first>* {
    color: var(--font-primary);
    font-family: 'Open Sans', sans-serif;
    letter-spacing: -0.5px;
}

.first {
    width: 85%;
    margin: auto;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.firstone {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 80px;
    font-weight: 700;
    letter-spacing: -1px;
    color: rgba(1, 22, 39, 0.7);
}

.firsttag {
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
}

.firsttwo {
    font-size: 20px;
    font-weight: 300;
}

.firstthree {
    font-size: 20px;
    font-weight: 900;
    width: 50%;
    margin: auto;
    text-align: left;
    margin-top: 4%;
    margin-left: 0;
    letter-spacing: -1px;
}

.touch {
    position: fixed;
    right: 0;
    margin: 10px;
    margin-right: 0px;
    box-shadow: 1px 3px 5px #e8e8e8;
    padding: 10px 30px;
    border-radius: 40px 0px 0 40px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: -1px;
    font-size: 14px;
    background-color: #ffffff;
}

.touch a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--font-primary) !important;
    text-decoration: none !important;
}

.touch:hover {
    box-shadow: 1px 3px 5px #ccc;
}

.touch a:hover {
    font-weight: 900;
    letter-spacing: -1px;
}

.touch img {
    width: 25px;
    margin-right: 10px;
}

.logo img {
    position: fixed;
    top: 5%;
    width: 60px;
}

.process {
    width: 800px;
    margin: 3em auto;
    margin-left: 0;
    margin-top: 1%;
    cursor: default;
}

.process-items {
    display: table;
    margin: 0 0 10px;
    padding: 0;
    list-style-type: none;
    color: var(--font-primary);
    font-size: 18px;
    text-align: center;
}

.process li {
    display: table-cell;
    width: 25%;
    vertical-align: bottom;
    padding: 0 .5em;
    transform: scale(1) translateY(40px);
    transform-origin: bottom center;
    transition: transform .5s;
    font-size: 12px;
    letter-spacing: -1px;
    font-weight: 700;
    color: rgba(1, 22, 39, 0.90);
    opacity: 0.80;
}

.process .active {
    transform: scale(1.2) translateY(0);
}

.process em {
    display: block;
    margin-top: .5em;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
}

.processmobile {
    display: none;
}

.login {
    margin-top: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.login a {
    color: var(--font-primary);
    text-decoration: none;
}

@media only screen and (max-width:900px) {

    .first {
        width: 90%;
        margin: auto;
        margin-top: 10%;
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .firstone {
        font-size: 3em;
        font-weight: 900;
        letter-spacing: -1px;
        margin-top: 30%;
    }

    .firsttag {
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        width: 75%;
    }

    .firsttwo {
        font-size: 20px;
        font-weight: 300;
        width: 75%;
        margin-top: 5%;
    }

    .firstthree {
        font-size: 16px;
        font-weight: 900;
        width: 100%;
        margin: auto;
        text-align: left;
        margin-top: 5%;
        margin-left: 0;
        letter-spacing: -1px;
    }

    .process {
        display: none;
    }

    .processmobile {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5%;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        margin: 1%;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 900;
        letter-spacing: -1px;
    }

    .card span {
        font-size: 12px;
        font-weight: 300;
    }

    .logo img {
        position: fixed;
        top: 5%;
        width: 35px;
    }


}
.app__notfound {
    width: 100%;
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
}

.notfound__content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notfound__content a{
    color: var(--font-primary);
    text-decoration: none;
}

.notfound__content .brand{
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -1px;
    color: rgba(1, 22, 39, 0.7);
}

.notfound__content .notfound__heading {
    font-size: 130px;
    font-weight: 300;
    letter-spacing: -2px;
}

.notfound__content .notfound__title {
    margin-bottom: 10px;
}

.notfound__content .notfound__title>p {
    font-size: 34px;
    color: var(--font-primary);
    font-weight: 500;
    text-align: center;
}

.notfound__content>p {
    color: #404040;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
}

.notfound__content .notfound__buttons {
    margin-top: 40px;
}

.notfound__content .notfound__buttons>button {
    border: 1px solid #404040;
    background-color: transparent;
    border-radius: 3px;
    font-size: 16px;
    color: var(--font-primary);
    padding: 15px 50px;
    font-weight: 500;
    transition: 0.3s;
}

.notfound__content .notfound__buttons>button a {
    color: var(--font-primary);
    text-decoration: none;
}

.notfound__content .notfound__buttons>button a:visited {
    text-decoration: none;
}

.notfound__content .notfound__buttons>button:hover{
    background-color: #f5f5f5;
}



@media only screen and (max-width:768px) {
    .app__notfound {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
    }

    .notfound__content {
        width: 100%;
    }

    .notfound__content .notfound__heading {
        font-size: 100px;
    }
    
    .notfound__content .notfound__title {
        margin-bottom: 30px;
    }
    
    .notfound__content .notfound__title>p {
        font-size: 24px;
    }
    
    .notfound__content>p {
        font-size: 14px;
        margin-bottom: 2px;
        font-weight: 500;
    }
}
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #cccccc;
  color: #cccccc;
  box-shadow: 9999px 0 0 -5px #cccccc;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #cccccc;
  color: #cccccc;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #cccccc;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #cccccc;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #cccccc;
  }

  30% {
    box-shadow: 9984px 0 0 2px #cccccc;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #cccccc;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #cccccc;
  }

  30% {
    box-shadow: 9999px 0 0 2px #cccccc;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #cccccc;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #cccccc;
  }

  30% {
    box-shadow: 10014px 0 0 2px #cccccc;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #cccccc;
  }
}
/*app header*/
.swift-header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 100px;
  -webkit-column-gap: 100px;
  grid-column-gap: 100px;
  column-gap: 100px;
}

.swift-header.swift-header__zoom {
  height: 0;
  display: none;
}

.swift-header .search__tabs {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 5px;
  grid-column-gap: 25px;
  -webkit-column-gap: 25px;
          column-gap: 25px;
  border-bottom: 1px solid #f5f5f5;
}

.swift-header .search__tabs .s__tab {
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--font-primary);
  background-color: var(--white);
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}

.swift-header .search__tabs .s__tab.active {
  font-weight: 700;
}

.swift-header .stock__search__icons__wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--light-box-shadow);
}

.swift-header .stock__search {
  width: 450px;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
}

.swift-header .stock__search .stock__search__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px 0 15px;
}

.swift-header .stock__search .stock__search__icon img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.swift-header .stock__search input {
  border: none;
  outline: none;
  width: 100%;
  height: 90%;
  padding-left: 2px;
  color: var(--font-primary);
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase !important;
  background-color: transparent;
}

.swift-header .stock__search input::-webkit-input-placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-header .stock__search input:-ms-input-placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-header .stock__search input::placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-header .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: 0%;
  margin-top: 3px;
  z-index: 10000;
  border-radius: 5px;
  max-height: 350px;
  /* overflow-y : scroll; */
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
  /* display: none; */
}

.stock__suggestions .suggestion__stocks {
  overflow-y: scroll;
  max-height: 300px;
}

.swift-header .stock__search .stock__suggestions.active {
  display: block;
}

.swift-header .stock__search .stock__suggestions .search__loader {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swift-header .stock__search .stock__suggestions .search__loader p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  padding: 0 !important;
  display: flex;
  color: var(--font-primary);
}

.swift-header .stock__search .stock__suggestions p {
  padding: 10px 10px 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.suggestion__stocks .suggestion__empty {
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.swift-header .stock__search .stock__suggestions p.active {
  background-color: #dbd9d9;
}

.swift-header .stock__search .stock__suggestions p span.search__highlight {
  /* color: #c5c5c5; */
  font-weight: 500;
}

.swift-header .stock__search .stock__suggestions p > span:nth-child(1) {
  flex: 4 1;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swift-header .stock__search .stock__suggestions p > span:nth-child(2) {
  flex: 5 1;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
}

.swift-header .stock__search .stock__suggestions p > span:nth-child(3) {
  flex: 1 1;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  color: var(--font-primary);
}

.swift-header .stock__search .stock__suggestions p:hover {
  background-color: #f9f9f9;
}

.swift-header .app__menu {
  display: flex;
  align-items: center;
  /* margin-left: 20px; */
  height: 100%;
  /* background-color: #888888; */
}

.swift-header .app__menu__mobile__icon {
  display: none;
  justify-content: center;
  align-items: center;
}

.swift-header .app__menu__mobile__icon.hidden {
  display: none;
}

.swift-header .app__menu__mobile__icon > img {
  width: 20px;
}

.swift-header .app__suggestion__close__icon {
  display: none;
}

.swift-header .app__menu__mobile {
  display: none;
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: var(--white);
  width: 100%;
  height: 100%;
  z-index: 1000000;
}

.swift-header .app__menu__mobile.active {
  display: block;
}

.swift-header .app__menu span {
  font-size: 12px;
  margin: 0 10px;
  letter-spacing: -0.2px;
  font-weight: 500;
  color: var(--font-primary);
  cursor: pointer;
}

.swift-header .app__menu span:hover {
  font-weight: 600;
}

.swift-header .app__menu span#account-code {
  border: 1px solid #e8e8e8;
  padding: 4px 10px;
  border-radius: 3px;
}

.swift-header .app__menu span.active {
  color: var(--main-blue);
}

.swift-header .app__menu span.dotmenu__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.swift-header .app__menu .dotmenu__icon .dotmenu__wrapper {
  position: absolute;
  top: 120%;
  right: 0%;
  margin-top: 7px;
  width: 150px;
  height: auto;
  background-color: var(--white);
  box-shadow: var(--shadow-code-box);
  transform: rotateX(-15deg);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  /* transition: 0.25s; */
  opacity: 0;
  transform-origin: 0 0;
  visibility: hidden;
}

.swift-header .app__menu .dotmenu__icon .dotmenu__wrapper.active {
  visibility: visible;
  opacity: 1;
  transform: rotateX(0deg);
}

.swift-header .app__menu .dotmenu__icon .dotmenu__wrapper > span {
  width: 100%;
  text-align: center;
  padding: 8px 0;
  transition: 0.3s;
}

.swift-header .app__menu .dotmenu__icon .dotmenu__wrapper > span > a {
  color: var(--font-primary);
  text-decoration: none;
}

.swift-header .app__menu .dotmenu__icon .dotmenu__wrapper > span:hover > a {
  color: var(--font-primary);
}

.swift-header__top {
  width: 100%;
  height: 100%;
  /* overflow-x: scroll; */
  /* background-color: salmon; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.swift-header__top::-webkit-scrollbar {
  display: none;
}

.swift-header__top .top__stocks {
  display: flex;
  flex-direction: row;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.swift-header__top .top__stocks__menu {
  display: none;
}

.swift-header__top .top__stocks__menu__mobile {
  display: block;
}

.swift-header__top .top__stocks .upper__stock {
  display: flex;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  width: 280px;
}

.upper__stock .upper__stock__info {
  display: flex;
}

.upper__stock .upper__stock__price {
  display: flex;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
}

.upper__stock .upper__stock__change__wrapper {
  display: flex;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
}

.upper__stock .upper__stock__change__value,
.upper__stock .upper__stock__change {
  font-weight: 700;
}

.swift-header__top .top__stocks .upper__stock.loader {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.swift-header .header-options {
  display: flex;
  flex-direction: row;
}

.swift-header .header-options .brand-logo{
  display: none;
}

.swift-header .header-options .header-options-list{
  display: flex;
  flex-direction: row;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.header-options-menu {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  width: 25px;
  height: 25px;
}

.header-options-menu.hide {
  border: none;
}

.header-options-menu .account-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--font-primary);
  background-color: #e8e8e8;
}

.header-options-menu .accounts-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  grid-row-gap: 6px;
  row-gap: 6px;
  top: 110%;
  right: 0;
  background-color: white;
  width: 340px;
  box-shadow: 1px 3px 5px #e8e8e8;
  padding: 10px 5px;
  border-radius: 15px;
  z-index: 100000;
}

.accounts-wrapper .accounts-list-loader {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  padding: 0 15px;
}

.accounts-wrapper .accounts-list {
  width: 100%;
}

.accounts-list .account-row {
  width: 100%;
  display: flex;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  padding: 10px 4px;
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
}

.accounts-list .account-row:hover {
  background-color: #fafafa;
}

.account-row .account-icon {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-row .account-icon .account-profile {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--font-primary);
  border: 1px solid var(--font-primary);
}

.account-row .account-icon span {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.account-row .account-info {
  width: 85%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 1px;
  row-gap: 1px;
}

.account-row .account-info .account-name {
  font-size: 12px;
  font-weight: 600;
}

.account-row .account-info .account-role {
  font-size: 11px;
  font-weight: 500;
}

.accounts-wrapper .logout-button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  padding: 10px 15px;
  border-radius: 5px;
}

.accounts-wrapper .logout-button > p {
  font-size: 13px;
  font-weight: 700;
}

.accounts-wrapper .logout-button:hover {
  background-color: #f9f9f9;
}

.header-options .dotmenu-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  grid-row-gap: 6px;
  row-gap: 6px;
  top: 105%;
  right: 0;
  background-color: white;
  width: 120px;
  box-shadow: 1px 3px 5px #e8e8e8;
  padding: 15px 15px;
  border-radius: 5px;
  z-index: 100000;
}

.header-options .dotmenu-wrapper a {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  text-decoration: none;
}

.header-options-menu .menu-common {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.header-options-menu .menu-common .menu-common-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  padding: 8px 15px;
  border-radius: 5px;
  align-items: center;
  justify-content: flex-start;
}

.header-options-menu .menu-common .menu-common-row:hover {
  background-color: #f9f9f9;
}

.header-options-menu .menu-common .menu-common-row span {
  font-size: 12px;
  font-weight: 600;
}

.header-options-menu .menu-line {
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
}
/* broking history popup */
.notifications-popup {
  width: 900px;
  height: 600px;
  background-color: var(--white);
  border-radius: 5px;
  padding: 15px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  position: relative;
}

.notifications-popup .notifications-popup-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}

.notifications-popup .notifications-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.notifications-header .notifications-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.notifications-header .notifications-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.notifications-popup .notifications-body {
  padding: 10px 0 20px;
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  position: relative;
}

.notifications-body .notifications-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.notifications-body .notifications-empty > p {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.notifications-body .notifications-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.notifications-body .notification-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  padding: 10px 10px 10px 2px;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
}

.notifications-body .notification-row.hide {
  display: none;
}

.notifications-body .notification-row:nth-child(1) {
  padding-top: 0;
}

.notifications-body .notification-row .subject {
  flex: 5 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.notifications-body .notification-row .time {
  flex: 1 1;
  font-size: 11px;
  font-weight: 600;
  text-align: right;
}

.notifications-body .notification-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: var(--white);
  padding: 0 15px 0 0;
}

.notifications-body .notification-box .back {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}

.notifications-body .notification-box .back span {
  font-size: 12px;
  font-weight: 700;
}

.notifications-body .notification-box .subject {
  font-size: 13px;
  font-weight: 600;
}

.notifications-body .notification-box .time {
  font-size: 12px;
  font-weight: 600;
}

.notifications-body .notification-box .attachments {
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.notifications-body .notification-box .attachments > p {
  font-size: 12px;
  font-weight: 600;
}

.notifications-body .notification-box .attachments .attachments-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.notifications-body .notification-box .attachments .attachments-list a {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  cursor: pointer;
}

.notifications-body .notification-box .body {
  font-size: 12px;
  font-weight: 500;
  padding: 0px 0px 10px 0;
  width: 100%;
}

.notifications-body .notification-box .body > div {
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
}

@media only screen and (max-width: 768px) {
  .swift-header {
    min-height: 30px;
    max-height: 30px;
    padding: 0px 10px;
  }

  .swift-header.active{
    min-height: 135px;
    max-height: 135px;
    border-bottom: 1px solid #e8e8e8;
  }

  .swift-header .stock__search__icons__wrapper {
    display: none;
  }

  .swift-header__top {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    grid-row-gap: 4px;
    row-gap: 4px;
    height: 100%;
  }

  .swift-header__top .top__stocks {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-row-gap: 10px;
    row-gap: 10px;
    display: none;
  }

  .swift-header__top .top__stocks.active{
    display: flex;
  }

  .swift-header__top .top__stocks .upper__stock {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .upper__stock .upper__stock__info {
    display: flex;
    flex-direction: column;
  }

  .upper__stock .upper__stock__info .upper__stock__symbol {
    font-size: 14px;
    font-weight: 600;
  }

  .upper__stock .upper__stock__price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-row-gap: 2px;
    row-gap: 2px;
  }

  .upper__stock .upper__stock__price .upper__stock__value {
    font-size: 14px;
    font-weight: 600;
  }

  .upper__stock .upper__stock__change__wrapper {
    display: flex;
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
            column-gap: 8px;
  }

  .upper__stock .upper__stock__change__value,
  .upper__stock .upper__stock__change {
    font-size: 13px;
    font-weight: 600;
  }

  .swift-header .header-options{
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .swift-header .header-options .brand-logo{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swift-header .header-options .brand-logo img{
    width: 34px;
  }
  

  .header-options-menu{
    border: none;
  }

  .swift-header .header-options .header-options-list{
    display: flex;
    flex-direction: row;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
  }


  .notifications-popup{
    width: 100%;
    height: 100%;
  }

  .notifications-header .notifications-title{
    font-size: 15px;
  }

  .notifications-body .notification-row {
    flex-direction: column;
    justify-content: flex-start;
    grid-row-gap: 4px;
    row-gap: 4px;
  }

  .notifications-body .notification-row .subject{
    font-size: 14px;
    font-weight: 600;
  }

  .notifications-body .notification-row .time{
    text-align: left;
    font-size: 13px;
    font-weight: 500;
  }

}

.index__select__popup {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000000;
  width: 350px;
  height: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  background-color: var(--white);
  transform: translateX(100%);
  animation: slide 0.2s linear forwards;
  padding: 10px 0px 5px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.index__select__popup.hide{
  display: none;
}

.index__select__loading{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.index__select__popup .index__select__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 20px;
}

.index__select__popup .index__select__header > p {
  font-size: 16px;
  font-weight: 600;
  color: var(--font-primary);
}

.index__select__popup .index__select__header > div{
  cursor: pointer;
}

.index__select__popup .index__select__header .index__list__total {
  font-size: 10px;
  font-weight: 500;
  color: #b8b8b8;
  margin-top: 0px;
  display: inline-block;
}

.index__select__header .index__select__close {
  cursor: pointer;
}

.index__select__header .index__select__close img {
  width: 30px;
  height: 30px;
}

.index__select__popup .index__select__container {
  width: 100%;
  height: calc(100% - 50px - 80px);
  overflow-y: scroll;
  border-radius: 5px;
}

.index__select__popup .index__select__container .index_row{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 14px 20px;
  cursor: pointer;
  background-color: #ffffff;
  height: 60px;
}

.index__select__container .index_row:hover{
  background-color: #fbfbfb;
  cursor: pointer;
}

.index__select__popup .index__select__container .index_row.sticky{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.index__select__popup .index__select__container .separator{
  width: 100%;
  height: 0.5px;
  background-color: #a8a8a8;
}

.index__select__container .index_row>div{
  flex: 2 1;
  text-align: left;
  font-weight: 500;
  font-size: 11px;
}

/* .index__select__container .index_row:first-child{
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
} */
/* 
.index__select__container .index_row:first-child>div{
  font-size: 11px;
  font-weight: 700;
} */

.index__select__container .index_row .index_row_left{
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.index__select__container .index_row .index_row_right{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.index__select__container .index_row .reorder{
  cursor: -webkit-grab;
  cursor: grab;
}

.index__select__container .index_row .name{
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
}

.index__select__container .index_row .price{
  font-size: 12px;
  font-weight: 600;
}

.index__select__container .index_row .change{
  font-size: 11px;
  font-weight: 600;
}

.index__select__container .index_row .action{
  text-align: center;
}

.index__select__container .index_row .pin_button span{
  background-color: #f5f5f5;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 600;
  transition: 0.3s;
}

.index__select__container .index_row .pin_button span:hover{
  background-color: #e8e8e8;
}

.index__select__container .index__select__item {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-bottom: 0.1px solid #f3f3ff;
}

.index__select__item .index__check {
  width: 10%;
  /* background-color: #e0e0e0; */
  height: 100%;
  display: flex;
  align-items: center;
}

.index__select__item .index__name {
  width: 60%;
  /* background-color: #bebebe; */
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
}

.index__select__item .index__options {
  width: 30%;
  /* background-color: #e7e7e7; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.index__select__item .index__options .index__option__wrapper {
  margin: 0 10px;
  padding: 2px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.index__select__item .index__options .index__option__wrapper:hover {
  background-color: #f0f0f0;
}

.index__select__item .index__options .index__option__wrapper img {
  width: 20px;
  height: 20px;
}

.index__select__popup .index__select__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  padding: 0px 15px 5px;
  height: 80px;
}

.index__select__popup .index__select__footer button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 10px 0px;
  flex: 1 1;
  transition: 0.3s;
  color: var(--font-primary);
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.index__select__popup .index__select__footer button:hover {
  background-color: #f5f5f5;
}

.index__select__popup .index__select__footer button.disabled {
  background-color: #eaeaea;
  box-shadow: none;
  cursor: not-allowed;
  color: #b0b0b0;
}

.index__select__popup .index__select__footer button.disabled:hover {
  color: #b0b0b0;
}

@media only screen and (max-width: 768px) {
  .index__select__popup{
    width: 100%;
    height: 100%;
    box-shadow: none;
  }

  .index__select__popup .index__select__header{
    padding: 0 15px;
  }

  .index__select__popup .index__select__container .index_row{
    height: 70px;
    padding: 0 15px;
  }

  .index__select__container .index_row .reorder{
    display: flex;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    cursor: default;
  }

  .index__select__container .index_row .reorder.mobile>div{
    cursor: pointer;
  }

  .index__select__container .index_row .name{
    font-size: 14px;
  }

  .index__select__container .index_row .price{
    font-size: 14px;
  }

  .index__select__container .index_row .change{
    font-size: 13px;
  }

  .index__select__popup .index__select__footer button {
    font-size: 15px;
    font-weight: 600;
  }

}

.custom-alert-box {
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translateX(-50%);
  width: 450px;
  box-shadow: 2px 2px 10px #e8e8e8;
  z-index: 1000000000;
  border-radius: 5px;
  transition: 0.4s;
  display: flex;
  border-radius: 50px;
  background-color: #a8a8a8;
}

.custom-alert-box.active {
  /* display: flex; */
  top: 3%;
}

.custom-alert-box .custom-alert-box-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50px;
}

.custom-alert-box .custom-alert-sideline {
  position: absolute;
  left: 0px;
  width: 5px;
  height: 100%;
}

.custom-alert-box .custom-alert-content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 25px;
}

.custom-alert-box .custom-alert-content .custom-alert-text {
  margin-right: 10px;
  width: 90%;
}

.custom-alert-box .custom-alert-content .custom-alert-text h6 {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.custom-alert-box .custom-alert-content .custom-alert-text p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  word-wrap: break-word;
  margin-top: 5px;
  /* text-transform: uppercase; */
}

.custom-alert-box .custom-alert-content .custom-alert-close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-radius: 3px;
}

.custom-alert-box .custom-alert-bottom-line {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.05;
  border-radius: 50px;
}

@keyframes animateLine {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@media only screen and (max-width: 768px) {
  .custom-alert-box {
    width: 95%;
  }
}

.confirm-box {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000000;
    border-radius: 5px;
    transition: 0.4s;
    display: flex;
    justify-content: center;
    background-color: rgba(140, 140, 140, 0.50);
}

.confirm-box .confirm-box-container {
    margin-top: 100px;
    min-width: 400px;
    max-width: 500px;
    background-color: var(--white);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 5px;
    padding: 15px 20px 20px;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    position: relative;
}

.confirm-box-container .confirm-box-loader {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.45);
}

.confirm-box-container .confirm-box-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.confirm-box-header .confirm-box-title {
    font-size: 13px;
    font-weight: 600;
    color: var(--font-primary);
}

.confirm-box-header .confirm-box-close {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.confirm-box-container .confirm-box-body {
    margin-top: 15px;
}

.confirm-box-body .confirm-box-description {
    font-size: 13px;
}

.confirm-box-container .confirm-box-footer {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
}

.confirm-box-footer button {
    padding: 8px 30px;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
    background-color: var(--white);
    font-size: 12px;
    font-weight: 700;
    color: var(--font-primary);
}

.confirm-box-footer button:hover {
    background-color: #f5f5f5;
}


/* responsive ui */

@media only screen and (max-width: 768px){
    .confirm-box .confirm-box-container{
        min-width: 95%;
        max-width: 95%;
        padding: 10px 10px;
    }

    .confirm-box-header .confirm-box-title{
        font-size: 15px;
        font-weight: 700;
    }

    .confirm-box-body .confirm-box-description{
        font-size: 15px;
    }

    .confirm-box-footer button{
        font-size: 14px;
    }

}
.swift-modal {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000000000;
}

.swift-modal .swift-modal-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

@media only screen and (max-width: 768px) {
  .swift-modal {
    padding-top: 0% !important;
  }

  .swift-modal .swift-modal-box {
    width: 100%;
    height: 100%;
  }
}

.swift-footer {
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.swift-footer .copyright-text {
  font-size: 12px;
  font-weight: 500;
}

.swift-footer .swift-social {
  display: flex;
  flex-direction: row;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
}

.swift-footer .swift-social svg {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .swift-footer {
    display: none;
  }
}

.customer-search-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-row-gap: 3px;
  row-gap: 3px;
}

.customer-search-wrapper .search-title {
  font-size: 12px;
  font-weight: 500;
}

.customer-search-wrapper .customer-search-box {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  border-radius: 5px;
  position: relative;
}

.customer-search-box input {
  flex: 5 1;
  height: 40px;
  border: none;
  background-color: transparent;
  outline: none;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.customer-search-box input::-webkit-input-placeholder {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.customer-search-box input:-ms-input-placeholder {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.customer-search-box input::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.customer-search-box .filter-icon {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-search-wrapper .customer-search-box .customer-search-result {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 10000;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0px;
  row-gap: 0px;
  padding: 6px 0;
  max-height: 250px;
  overflow-y: scroll;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 8px 0px;
}

.customer-search-box .customer-search-result .result-row {
  cursor: pointer;
  padding: 8px 0 8px 12px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
}

.customer-search-box .customer-search-result .result-row:hover {
  background-color: #f9f9f9;
}

.customer-search-box .customer-search-result .result-row > span {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.2px;
}

.customer-search-box .customer-search-result .result-row > p {
  font-size: 12px;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .customer-search-wrapper .search-title {
    font-size: 14px;
    font-weight: 500;
  }

  .customer-search-box input{
    font-size: 14px;
  }

  .customer-search-box input::-webkit-input-placeholder{
    font-size: 14px;
    font-weight: 500;
  }

  .customer-search-box input:-ms-input-placeholder{
    font-size: 14px;
    font-weight: 500;
  }

  .customer-search-box input::placeholder{
    font-size: 14px;
    font-weight: 500;
  }

  .customer-search-box .customer-search-result .result-row > span{
    font-size: 12px;
  }

  .customer-search-box .customer-search-result .result-row > p{
    font-size: 14px;
  }
}

.swift-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-client {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.welcome-message {
  font-size: 26px;
  font-weight: 300;
  line-height: 26px;
  color: var(--light-text-color);
}

.swift-dashboard .dashboard-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.swift-dashboard .dashboard-loading > p {
  font-size: 14px;
  font-weight: 600;
}

.swift-dashboard .dashboard-content {
  width: 100%;
  display: flex;
  height: 93%;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  justify-content: space-between;
}

.dashboard-content .dashboard-left {
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.swift-dashboard-news-mobile {
  display: none;
}

.swift-dashboard-news {
  width: 400px;
  height: 100%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 0;
  box-shadow: var(--light-box-shadow);
}

.swift-dashboard-news .dashboard-box-title {
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.swift-dashboard-news .dashboard-box-title .back-icon {
  display: none;
}

.swift-dashboard-news .dashboard-box-title .title {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-dashboard-news .dashboard-news-content {
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-news-content .news-menu {
  width: 100%;
  height: 8%;
  display: flex;
  align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  padding: 0 15px;
}

.dashboard-news-content .news-menu > p {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.dashboard-news-content .news-menu > p.active {
  color: var(--font-primary);
  font-weight: 800;
}

.dashboard-news-content .news-content {
  height: 100%;
}

.dashboard-news-content .dashboard-news-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  padding-top: 60px;
}

.dashboard-news-content .dashboard-news-loading > p {
  font-size: 12px;
  font-weight: 600;
}

.dashboard-news-content .dashboard-news-google,
.dashboard-news-content .dashboard-news-twitter {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 0px 5px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
}

.dashboard-news-content .dashboard-news-google.hide,
.dashboard-news-content .dashboard-news-twitter.hide {
  display: none;
}

.dashboard-news-google .google-feeds-row {
  width: 100%;
  padding: 5px 0px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 2px;
  row-gap: 2px;
  border-bottom: 1px solid #f5f5f5;
}

.dashboard-news-google .google-feeds-row > p {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--font-primary);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-news-google .google-feeds-row > p:nth-child(1) {
  cursor: pointer;
}

.dashboard-news-google .google-feeds-row > p:nth-child(1):hover {
  color: #000000;
}

.dashboard-news-google .google-feeds-row > p > span {
  font-weight: 500;
  font-size: 11px;
  color: var(--text-color-blur);
}

/* .dashboard-news-content .dashboard-news-twitter {
    width: 100%;
    height: 48%;
} */

.dashboard-news-twitter .twitter-feeds-row {
  width: 100%;
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  border-bottom: 1px solid #f5f5f5;
}

.twitter-feeds-row .twitter-row-profile {
  width: 10%;
}

.twitter-feeds-row .twitter-row-profile img {
  width: 100%;
  border-radius: 50%;
}

.twitter-feeds-row .twitter-row-content {
  width: 85%;
}

.twitter-row-content .twitter-header-row {
  width: 100%;
  display: flex;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
}

.twitter-row-content .twitter-header-row > span.name {
  font-weight: 700;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

.twitter-row-content .twitter-header-row > span.time,
.twitter-row-content .twitter-header-row > span.screen-name {
  color: #71767b;
  font-size: 13px;
}

.twitter-row-content .twitter-header-row > span.link {
  cursor: pointer;
  margin-left: 5px;
}

.twitter-row-content .twitter-row-text {
  width: 100%;
  font-size: 13px;
}

.twitter-row-content .twitter-row-text .twitter-text-hashtag,
.twitter-row-content .twitter-row-text a {
  font-weight: 500;
  color: #1da1f2;
  text-decoration: none;
}

.swift-dashboard-top {
  height: 100%;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.swift-dashboard-top .dashboard-box-title {
  width: 100%;
  height: 20px;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-dashboard-top .dashboard-business-title h3 {
  font-size: 18px;
  font-weight: 700;
}

.swift-dashboard-top .dashboard-business-title p {
  font-size: 18px;
  font-weight: 300;
}

.swift-dashboard-top .dashboard-data-snapshot {
  width: 100%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 15px;
  height: 35%;
  position: relative;
  box-shadow: var(--light-box-shadow);
}

.swift-dashboard-top .dashboard-data-snapshot #broking-history {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 15px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.dashboard-data-snapshot .dashboard-snapshot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.dashboard-snapshot .data-snapshot-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-row-gap: 15px;
  row-gap: 15px;
}

.dashboard-snapshot .data-snapshot-row .snap-data-box {
  display: flex;
  flex-direction: column;
  grid-row-gap: 2px;
  row-gap: 2px;
  flex: 3 1;
}

.dashboard-data-snapshot .data-snapshot-row .snap-data-box:first-child {
  flex: 4 1;
}

.snap-data-box .snap-box-title {
  font-size: 11px;
  font-weight: 500;
}

.snap-data-box .snap-box-value-box {
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.snap-data-box .snap-box-value {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.snap-data-box .snap-box-value.hero {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.snap-data-box .snap-box-value.hero-huge {
  font-size: 24px;
  line-height: 35px;
}

.snap-data-box .snap-box-subtext {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.snap-data-box .snap-box-subtext .change {
  display: flex;
  flex-direction: row;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  font-size: 14px;
  font-weight: 600;
}

.swift-dashboard-top .customer-overview {
  display: flex;
  flex-direction: column;
  grid-row-gap: 50px;
  row-gap: 50px;
  padding-left: 5px;
}

.swift-dashboard-top .customer-overview .snap-data-box {
  padding: 15px;
  width: 325px;
  border-radius: 10px;
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
}

.swift-dashboard-top .customer-overview .snap-box-title {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.swift-dashboard-top .customer-overview .snap-box-title a {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  color: var(--font-primary);
  text-decoration: none;
}

.swift-dashboard-top .dashboard-data-charts {
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: row;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.swift-dashboard-top .top-clients {
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  padding: 10px 0px;
  width: 100%;
  border-radius: 15px;
  height: 63%;
  box-shadow: var(--light-box-shadow);
}

.swift-dashboard-top .clients-wrapper {
  justify-self: flex-end;
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0px;
  row-gap: 0px;
  overflow-y: scroll;
  padding: 10px 5px 0 5px;
}

.swift-dashboard-top .dash-box-title {
  font-size: 12px;
  font-weight: 600;
  padding: 0 15px;
}

.clients-wrapper .clients-wrapper-header {
  width: 100%;
  padding: 0 5px 0 10px;
  display: flex;
}

.clients-wrapper .clients-wrapper-header > div {
  font-size: 12px;
  font-weight: 700;
}

.clients-wrapper .client-row > div:nth-child(1) {
  flex: 6 1;
}

.clients-wrapper .client-row > div:nth-child(2) {
  flex: 4 1;
}

.clients-wrapper .client-row > div:nth-child(3) {
  flex: 4 1;
}

.clients-wrapper .client-row > div:nth-child(4) {
  flex: 4 1;
}

.clients-wrapper .top-client-row {
  width: 100%;
  display: flex;
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #f5f5f5;
}

.top-client-row .client-name {
  font-size: 12px;
  font-weight: 500;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-client-row .client-role {
  font-size: 10px;
  font-weight: 500;
}

.top-client-row .client-data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}

.top-client-row .client-data .client-info {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.top-client-row .client-data .client-info p {
  font-size: 12px;
  font-weight: 500;
  line-height: 13px;
}

.top-client-row .client-investment > p,
.top-client-row .client-value > p,
.top-client-row .client-cash > p {
  font-size: 12px;
  font-weight: 500;
}

.revenue-trend {
  width: 500px;
  height: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.revenue-trend .dash-box-title {
  font-size: 12px;
  font-weight: 800;
}

.revenue-trend .dash-box-subtitle {
  font-size: 10px;
  font-weight: 500px;
  line-height: 12px;
  margin-top: 5px;
}

.revenue-trend .revenue-chart-wrapper {
  height: 80%;
}

.swift-dashboard-bottom {
  width: 420px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dash-bottom-box {
  width: 100%;
  height: 460px;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
}

.swift-dashboard-bottom .client-search {
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  box-shadow: var(--light-box-shadow);
  border-radius: 15px;
  padding: 10px 15px;
  height: 30%;
}

.client-search .client-search-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.client-search .client-name {
  font-size: 13px;
  font-weight: 600;
}

.client-search .client-search-header .client-search-wrapper {
  display: flex;
  flex-direction: column;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.client-search-header .client-search-wrapper p {
  font-size: 12px;
  font-weight: 700;
}

.client-search-header .client-search-wrapper span {
  font-size: 12px;
  font-weight: 500;
}

.client-search .client-search-data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  grid-column-gap: 2px;
  -webkit-column-gap: 2px;
          column-gap: 2px;
  margin-top: 10px;
}

.client-search-data .client-search-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
  align-items: center;
  margin-top: 25px;
}

.client-search-data .client-search-empty > p {
  font-size: 13px;
  font-weight: 500;
}

.client-search-data .search-data-box-row {
  display: flex;
  grid-column-gap: 2px;
  -webkit-column-gap: 2px;
          column-gap: 2px;
  width: 100%;
  margin-top: 4px;
}

.client-search-data .search-data-box {
  flex: 2 1;
}

.client-search-data .search-data-box-row .search-data-box:nth-child(1) {
  flex: 3 1;
}

.client-search-data .search-data-box-row .search-data-box:nth-child(2) {
  flex: 4 1;
}

.client-search-data .search-data-box.data-box-email {
  width: auto;
}

.client-search-data .search-data-box span {
  font-size: 11px;
  font-weight: 500;
}

.client-search-data .search-data-box p {
  font-size: 12px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  margin-top: -2px;
  color: #000000;
}

.tasks-wrapper {
  width: 100%;
  height: 68%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  box-shadow: var(--light-box-shadow);
  border-radius: 15px;
  padding: 15px 10px;
}

.tasks-wrapper .tasks-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.tasks-wrapper .dash-bottom-box-title {
  font-size: 12px;
  font-weight: 600;
}

.tasks-wrapper .tasks-content {
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  height: 90%;
  padding-top: 20px;
  padding-right: 10px;
  overflow-y: scroll;
}

.tasks-wrapper .tasks-content .tasks-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.tasks-wrapper .tasks-content .tasks-loader p {
  font-size: 13px;
  font-weight: 500;
}

.tasks-content .top-task-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.tasks-content .task-content-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.tasks-content .task-content-empty > p {
  font-size: 13px;
  font-weight: 500;
}
.top-task-row .top-task-data {
  width: 80%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0px;
  row-gap: 0px;
}

.top-task-data .task-dates {
  display: flex;
  flex-direction: row;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  font-size: 11px;
  font-weight: 300;
}

.top-task-data .task-description {
  font-size: 12px;
  font-weight: 500;
  word-break: break-all;
}

.top-task-row .top-task-action {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

/* broking history popup */
.broker-history-popup {
  width: 550px;
  height: 600px;
  background-color: var(--white);
  border-radius: 5px;
  padding: 15px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  position: relative;
}

.broker-history-popup .broker-history-popup-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}

.broker-history-popup .broker-history-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.broker-history-header .broker-history-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.broker-history-header .broker-history-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.broker-history-popup .broker-history-body {
  padding: 10px 0 20px;
}

.broker-history-body .broker-history-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.broker-history-body .broker-history-empty > p {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.broker-history-body .broker-history-table {
  width: 100%;
  height: 520px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  overflow-y: scroll;
}

.broker-history-body .broker-history-table table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.broker-history-table table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.broker-history-table table tfoot {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-end: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 -1px 1px #f5f5f5;
}

.broker-history-table table thead tr td,
.broker-history-table table tfoot tr td {
  text-align: left;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
}

.broker-history-table table tbody tr td {
  text-align: left;
  padding: 8px 10px 8px 10px;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: var(--font-primary);
}

/*             ===========================================Task Popup Css ======================= */

.swift-superuser-task-popup {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 15px 20px 15px;
  width: 450px;
  height: 550px;
}

.swift-superuser-task-popup .task-popup-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  padding-top: 100px;
}

.swift-superuser-task-popup .task-popup-loader p {
  font-size: 13px;
  font-weight: 500;
}

.swift-superuser-task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swift-superuser-task-title {
  font-size: 13px;
  font-weight: 600;
}
.swift-superuser-task-body {
  padding-top: 30px;
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.swift-superuser-task-submit-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}
@media only screen and (max-width: 768px) {
  .swift-dashboard .dashboard-loading {
    border: none;
  }

  .swift-dashboard {
    justify-content: flex-start;
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .welcome-message {
    font-size: 22px;
  }

  .swift-dashboard .dashboard-loading > p {
    font-size: 16px;
  }

  .swift-dashboard-top {
    width: 100%;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .dashboard-client {
    padding: 0 10px;
  }

  .swift-dashboard .dashboard-content {
    flex-direction: column;
    overflow-y: scroll;
    justify-content: flex-start;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .swift-dashboard .dashboard-content::-webkit-scrollbar {
    display: none;
  }

  .swift-dashboard-top .customer-overview {
    grid-row-gap: 10px;
    row-gap: 10px;
    padding-left: 0;
    padding: 0 10px;
  }

  .dashboard-content .dashboard-left {
    flex-direction: column;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
  }

  .swift-dashboard-top .customer-overview .snap-box-title {
    font-weight: 700;
    font-size: 14px;
  }

  .snap-data-box .snap-box-value.hero-huge {
    font-size: 26px;
  }

  .swift-dashboard-top .customer-overview .snap-data-box {
    border-top: none;
    width: 100%;
    padding: 5px 0;
    border-bottom: none;
  }

  .swift-dashboard-news-mobile {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    grid-row-gap: 15px;
    row-gap: 15px;
    padding-bottom: 15px;
  }

  .swift-dashboard-news-mobile > p {
    font-size: 26px;
    font-weight: 600;
    color: var(--font-secondary);
  }

  .swift-dashboard-news-mobile .news-button-row {
    display: flex;
    align-items: center;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .swift-dashboard-news-mobile .news-button {
    display: flex;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    cursor: pointer;
    align-items: center;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: #f1f1f1;
    border: 1px solid #e8e8e8;
  }

  .swift-dashboard-news-mobile .news-button > p {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  .swift-dashboard-news {
    width: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    transition: 0.2s;
    border: none;
  }

  .swift-dashboard-news.active {
    left: 0;
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    z-index: 10000;
  }

  .dashboard-news-content .dashboard-news-google,
  .dashboard-news-content .dashboard-news-twitter {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .dashboard-news-google .google-feeds-row {
    border: none;
    padding: 20px 0;
    border-bottom: 1px solid #e8e8e8;
  }

  .swift-dashboard-news .dashboard-box-title {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    padding: 0 10px;
    height: 30px;
  }

  .swift-dashboard-news .dashboard-box-title .back-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swift-dashboard-news .dashboard-box-title .title {
    font-size: 18px;
  }

  .swift-dashboard-news .dashboard-news-content {
    height: calc(100% - 60px);
  }

  .dashboard-news-google .google-feeds-row > p {
    font-size: 16px;
  }

  .dashboard-news-google .google-feeds-row > p > span {
    font-size: 13px;
  }

  .swift-dashboard-top .dashboard-data-snapshot {
    height: auto;
  }

  .swift-dashboard-bottom {
    width: 100%;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .swift-dashboard-top .top-clients {
    height: auto;
  }

  .swift-dashboard-bottom .client-search {
    width: 100%;
    height: auto;
  }

  .broker-history-popup {
    width: 100%;
    height: 100%;
  }
}

.stock__details__popup__overlay {
  position: fixed;
  inset: 0;
  background: #87888850;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
}
.stock__details__popup {
  z-index: 1000000;
  width: 700px;
  height: 450px;
  min-width: 700px;
  border-radius: 5px;
  transform: translateY(8px);
  background-color: var(--white);
  animation: getup 0.1s linear forwards;
  padding: 10px 20px;
}

.stock__details__popup.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.stock__details__popup.loading>p{
  font-size: 14px;
  font-weight: 600;
}

@keyframes getup {
  0% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.sd__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
}

.sd__header .sd__heading {
  font-size: 14px;
  font-weight: 500;
  color: var(--font-primary);
}

.sd__header .sd__heading > span {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

.sd__header .sd__close {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  cursor: pointer;
}

.sd__header .sd__close > img {
  width: 30px;
}

.sd__body .sd__tables {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 20px;
}
.sd__body .sd__tables .buyers__table,
.sd__body .sd__tables .sellers__table {
  width: 48%;
  border: 1px solid #f5f5f5;
  min-height: 100px;
  border-radius: 15px;
}

.sd__body .sd__tables .buyers__table table,
.sd__body .sd__tables .sellers__table table {
  width: 100%;
}

.sd__body .sd__tables table thead{
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    inset-block-start: 0;
    width: 100%;
    background-color: var(--white);
    box-shadow: 0 1px 1px #f5f5f5;
}

/* .sd__body .sd__tables .sellers__table {
    color: var(--main-red)
}
.sd__body .sd__tables .buyers__table {
    color: var(--main-green)
} */

.sd__body .sd__tables .buyers__table table thead tr,
.sd__body .sd__tables .sellers__table table thead tr {
  border-bottom: 1px solid #e8e8e8;
}

.sd__body .sd__tables .buyers__table table thead th,
.sd__body .sd__tables .sellers__table table thead th {
    text-align: center;
    padding: 4px 10px 4px 10px;
    font-weight: 700;
    font-size: 11px;
    line-height: 20px;
}

.sd__body .sd__tables .buyers__table table tbody,
.sd__body .sd__tables .sellers__table table tbody {
  width: 100%;
}

.sd__body .sd__tables .buyers__table table tbody td,
.sd__body .sd__tables .sellers__table table tbody td {
    text-align: center;
  font-weight: 500;
  font-size: 12px;
  padding: 4px 10px 4px 10px;
}

.sd__body .sd__tables .buyers__table table tbody td.buy_quantity {
  color: var(--main-green);
  font-weight: 600;
}

.sd__body .sd__tables .sellers__table table tbody td.sell_quantity {
  color: var(--main-red);
  font-weight: 600;
}
.sd__body .sd__prices__wrapper {
  display: flex;
  justify-content: space-between;
}

.sd__body .sd__prices {
  width: 48%;
  margin-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.sd__body .sd__prices .sd__row {
  width: 100%;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
}

.sd__prices .sd__row .sd__row__title {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.sd__prices .sd__row .sd__row__value {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.sd__prices .sd__row .sd__row__value.buy_quantity {
  color: var(--main-green);
}

.sd__prices .sd__row .sd__row__value.sell_quantity {
  color: var(--main-red);
}

@media only screen and (max-width: 768px) {
  .stock__details__popup {
    min-width: 100%;
    min-height: 100%;
    padding: 5px 10px;
  }
  .stock__details__popup .sd__wrapper .sd__container {
    width: 100%;
    height: 100%;
    padding: 10px 10px 20px;
  }

  .sd__header .sd__heading {
    font-size: 14px;
  }

  .sd__header .sd__heading > span {
    font-size: 10px;
  }

  .sd__header .sd__close > img {
    width: 30px;
  }

  .sd__body .sd__tables {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .sd__body .sd__tables .buyers__table,
  .sd__body .sd__tables .sellers__table {
    width: 100%;
    border-radius: 3px;
    min-height: 130px;
    margin-bottom: 10px;
  }

  .sd__body .sd__tables .buyers__table table thead th,
  .sd__body .sd__tables .sellers__table table thead th {
    font-size: 12px;
  }

  .sd__body .sd__tables .buyers__table table tbody td,
  .sd__body .sd__tables .sellers__table table tbody td {
    font-size: 12px;
  }

  .sd__body .sd__prices__wrapper {
    flex-direction: column;
  }

  .sd__body .sd__prices {
    width: 100%;
  }

  .sd__prices .sd__row .sd__row__title {
    font-size: 12px;
  }

  .sd__prices .sd__row .sd__row__value {
    font-size: 12px;
  }
}

.order-popup {
  z-index: 1000000;
  width: 1120px;
  height: 650px;
  border-radius: 5px;
  transform: translateY(8px);
  box-shadow: 0 0 14px 0 rgb(15 17 17 / 10%);
  /* box-shadow: 1px 3px 5px #e8e8e8; */
  background-color: var(--white);
  animation: getup 0.1s linear forwards;
}

@keyframes getup {
  0% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.order-popup.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-header {
  width: 100%;
  height: 60px;
  padding: 0 20px;
}

.order-title {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
}

.order-title .title-name {
  display: flex;
  align-items: center;
}

.title-name .title-action {
  width: 85px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  margin-right: 10px;
  letter-spacing: 0px;
  background-color: #e8e8e8;
}

.title-name .title-action.buy {
  background-color: var(--main-green-light);
}

.title-name .title-action.sell {
  background-color: var(--main-red-light);
}

.title-name .title-symbol {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.order-title .title-search {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.order-title .title-search > img {
  width: 16px;
}

.order-title .title-search.active {
  position: absolute;
  right: 0;
  background-color: var(--white);
  width: 100%;
  height: 90%;
  transition: 0.1s;
}

.title-search .stock-search {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.stock-search .stock-search-header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.stock-search-header .search-area {
  width: 80%;
  display: flex;
  align-items: center;
}

.search-area > img {
  width: 20px;
  margin-right: 20px;
}

.search-area input {
  width: 95%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  text-transform: uppercase;
  background-color: transparent;
}

.search-area input::-webkit-input-placeholder {
  font-size: 14px;
  color: #cccccc;
  font-weight: 500;
  text-transform: capitalize;
}

.search-area input:-ms-input-placeholder {
  font-size: 14px;
  color: #cccccc;
  font-weight: 500;
  text-transform: capitalize;
}

.search-area input::placeholder {
  font-size: 14px;
  color: #cccccc;
  font-weight: 500;
  text-transform: capitalize;
}

.stock-search-header .search-close {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stock-search-header .search-close > img {
  width: 30px;
}

.stock-search .stock-container {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  max-height: 350px;
  background-color: var(--white);
  z-index: 100000;
  border-radius: 3px;
  overflow-y: scroll;
  /* box-shadow: 1px 1px 5px #e8e8e8; */
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
}

.stock-search .stock-container::-webkit-scrollbar {
  display: none;
}

.stock-search .stock-container .search-loader {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stock-search .stock-container .search-loader > p {
  margin-top: 10px !important;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.stock-search .stock-container > p {
  width: 100%;
  display: flex;
  padding: 10px 25px;
  font-size: 12px;
  transition: 0.3s;
  border-bottom: 1px solid #f5f5f5;
}

.stock-search .stock-container > p:hover {
  background-color: #f3f3f3;
}

.stock-search .stock-container > p > span:nth-child(1) {
  flex: 3 1;
  font-weight: 600;
}

.stock-search .stock-container > p > span:nth-child(2) {
  flex: 7 1;
  font-size: 11px;
  font-weight: 500 !important;
}

.stock-search .stock-container > p > span:nth-child(3) {
  flex: 1 1;
  font-weight: 600;
  text-align: right;
}

.stock-search .stock-container > p .search-highlight {
  /* background-color: #b7e8ff; */
  font-weight: 600;
}

.order-side {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.order-side-buy,
.order-side-sell {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s;
  padding: 0 20px;
  position: relative;
  background-color: #fafafa;
  /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px; */
  font-size: 12px;
}

.order-side-buy {
  border-radius: 60px 0px 0px 60px;
}

.order-side-sell {
  border-radius: 0px 60px 60px 0px;
}

.order-side-buy:hover,
.order-side-sell:hover {
  background-color: #fbfbfb;
}

.order-side-buy.active {
  background-color: var(--main-green-light);
}

.order-side-sell.active {
  background-color: var(--main-red-light);
}

.order-side-sell {
  align-items: flex-end;
}

.order-side-buy .order-side-line,
.order-side-sell .order-side-line {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  border-radius: 10px;
}

/* .order-side-buy .order-side-line
{
    display: none;
    background-color: #d2d2d2;
}

.order-side-sell .order-side-line
{
    display: none;
    background-color: #909090;
    }
    
    */

.order-side-buy.active .order-side-line,
.order-side-sell.active .order-side-line {
  display: block;
}

.order-side-difference {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  font-weight: 700;
  background-color: var(--white);
  border: 1px solid #f3f3f3;
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  z-index: 10000;
}

.order-body {
  width: 100%;
  height: calc(100% - 60px - 80px);
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.order-body .order-setup-section {
  width: 60%;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.order-body .order-section-separator,
.order-type-body .order-section-separator {
  height: 100%;
}

.order-body .order-section-separator div,
.order-type-body .order-section-separator div {
  width: 0.5px;
  height: 100%;
  background-color: #e8e8e8;
}

.order-body .order-depth-section {
  width: 40%;
  padding: 8px 0;
}

.order-body .order-depth-section .order-market{
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}


.order-body .order-body-row {
  display: flex;
  align-items: center;
  margin: 12px 0;
}

.order-body-row.portfolio > span {
  font-size: 10px;
  margin-left: 10px;
}

.order-body .order-body-row.customers {
  width: 300px;
}
.order-body-row.portfolio .new-portfolio {
  font-size: 11px;
  font-weight: 600;
  width: 200px;
  height: 30px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  color: var(--font-primary);
  margin-left: 10px;
  cursor: pointer;
}

.order-body-row.portfolio .new-portfolio > input {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding-left: 10px;
}

.order-body .order-body-section {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.order-body .order-body-section .order-body-loading {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  z-index: 1000;
}

.order-body-row .funds {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
}

.order-body-row .funds img {
  width: 20px;
}

.order-body-row .funds span {
  font-size: 14px;
  margin-left: 10px;
}

.order-body-row.order-box {
  flex-wrap: wrap;
}

.order-body-row .order-type-box {
  width: 100px;
  height: 35px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #909090;
  border-radius: 35px;
  font-size: 12px;
  font-weight: 500;
  color: #909090;
  cursor: pointer;
  transition: 0.2s;
}

.order-body-row .order-type-box.active {
  border: 1px solid var(--font-primary);
  color: var(--font-primary);
  font-weight: 700;
}

.order-body-row .order-error {
  width: 100%;
}

.order-body-row .order-error p {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary) !important;
}

.order-body-row .order-value {
  width: 170px;
  margin-right: 20px;
}

.order-body-row .order-value > p:nth-child(1) {
  font-size: 12px;
  font-weight: 500;
  color: #909090;
}

.order-body-row .order-value > p:nth-child(2) {
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
}

.order-body-section .exit-position {
  display: flex;
  flex-direction: row;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  margin-top: 20px;
}

.order-body-section .exit-position > p {
  font-size: 14px;
  font-weight: 600;
}

.order-body .order-info-button{
  display: none;
}

.order-body .order-market-depth,
.order-type-body .order-market-depth {
  width: 100%;
  display: flex;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
}


.order-market-depth.loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-market-depth .order-market-offers,
.order-market-depth .order-market-bids {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.order-market-depth .order-market-offers > p,
.order-market-depth .order-market-bids > p {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-secondary);
  padding: 0px 0 4px;
}

.order-market-depth table {
  flex: 1 1;
}

.order-market-depth .separator {
  width: 1px;
  background-color: #e8e8e8;
}

.order-market-depth table tr td {
  text-align: center;
  padding: 6px 8px 6px 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.order-market-depth table tr td:nth-child(1),
.order-market-depth table tr td:nth-child(2) {
  text-align: left;
}

.order-market-depth table tr td:nth-child(3) {
  text-align: right;
}

.order-market-depth table thead tr td {
  font-weight: 700;
  font-size: 11px;
}

.order-market-extra {
  width: 100%;
  display: flex;
  padding: 15px 10px;
  grid-column-gap: 25px;
  -webkit-column-gap: 25px;
          column-gap: 25px;
  background-color: #fafafa;
  border-radius: 15px;
}

.order-market-extra .market-extra-column {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.order-market-extra .market-extra-column > p {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-secondary);
}

.order-market-extra .market-extra-column > p > span:last-child {
  color: var(--font-primary);
}

.order-footer {
  padding: 0 20px;
  width: 100%;
  height: 80px;
}

.order-footer .order-footer-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f5f5f5;
}

.order-footer .order-error p {
  font-size: 12px;
  font-weight: 600;
  color: var(--main-red) !important;
}

.order-footer .order-info p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}
.order-footer .order-buttons {
  display: flex;
}

.order-footer .order-buttons .order-button {
  width: 90px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
}

.order-footer .order-buttons .order-button:hover {
  opacity: 0.9;
}

.order-button.buy {
  background-color: var(--main-green);
  color: var(--white);
}

.order-button.buy.disabled,
.order-button.sell.disabled {
  opacity: 0.7;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: not-allowed;
}

.order-button.buy.disabled:hover,
.order-button.sell.disabled:hover {
  opacity: 0.7;
}

.order-button.sell {
  background-color: var(--main-red);
  color: var(--white);
}

.order-button.close {
  background-color: var(--white);
  color: var(--font-primary);
  border: 1px solid var(--font-primary);
}

/* success popup */

.custom-success-box {
  position: fixed;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1000000000;
  background: rgba(140, 140, 140, 0.5);
}

.custom-success-box .order-success-popup {
  width: 450px;
  padding: 15px 0 30px;
  background-color: var(--white);
  box-shadow: 0 0 14px 0 rgb(15 17 17 / 10%);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row-gap: 3px;
  row-gap: 3px;
}

.order-success-popup .order-success-title {
  font-size: 16px;
  font-weight: 600;
}

.order-success-popup .order-success-subtitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-secondary);
}

.order-success-popup > button {
  margin-top: 25px;
  background: var(--white);
  border: 1px solid var(--font-primary);
  cursor: pointer;
  width: 110px;
  height: 35px;
  border-radius: 5px;
  color: var(--font-primary);
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .order-popup {
    z-index: 1000000;
    width: 100%;
    height: 100%;
    position: fixed;
  }

  .order-header {
    padding: 0 10px;
    height: 50px;
  }

  .title-name .title-action {
    width: 60px;
    height: 22px;
    border-radius: 25px;
    font-size: 11px;
    margin-right: 10px;
  }

  .title-name .title-symbol {
    font-size: 14px;
  }

  .order-title .title-search > img {
    width: 20px;
  }

  .search-area input {
    font-size: 14px;
    font-weight: 600;
  }

  .stock-search-header .search-close {
    width: 10%;
  }

  .stock-search-header .search-close > img {
    width: 30px;
  }

  .stock-search .stock-container > p {
    padding: 10px 15px;
    font-size: 12px;
  }

  .stock-search .stock-container > p > span:nth-child(2) {
    flex: 7 1;
    font-size: 11px;
    font-weight: 600 !important;
  }

  .order-side {
    width: 100%;
    height: 50%;
  }

  .order-side-buy,
  .order-side-sell {
    font-size: 14px;
    grid-row-gap: 6px;
    row-gap: 6px;
  }

  .order-side-buy > span,
  .order-side-sell > span {
    margin-bottom: -5px;
  }

  .order-side-buy .order-side-line,
  .order-side-sell .order-side-line {
    height: 2px;
  }

  .order-side-difference {
    font-size: 14px;
    width: 70px;
    height: 25px;
  }

  .order-body .order-body-section {
    padding: 5px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 15px;
    row-gap: 15px;
    align-items: flex-start;
  }

  .order-body {
    width: 100%;
    flex-direction: column;
    height: auto;
    overflow: scroll;
    height: calc(100% - 120px);
    padding: 0 10px;
  }

  .order-body .order-setup-section {
    width: 100%;
    grid-row-gap: 25px;
    row-gap: 25px;
  }

  .order-side {
    height: 55px;
  }

  .order-body .order-body-row {
    margin: 0px;
    flex-wrap: wrap;
  }

  .order-body .order-body-row.order-config {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .order-body-row .order-value {
    width: 100%;
    margin: 0;
  }

  .order-body-row .order-value > p:nth-child(1) {
    font-size: 13px;
  }

  .order-body-row .order-value > p:nth-child(2) {
    font-size: 15px;
  }

  .order-body-row.buy,
  .order-body-row.sell {
    flex-wrap: wrap;
    margin-top: 0px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }

  .order-body-row.portfolio {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-body-row.portfolio > span {
    font-size: 10px;
    margin-left: 10px;
  }

  .order-body-row.portfolio .custom-select {
    width: 240px !important;
    height: 45px !important;
  }

  .order-body-row.portfolio .new-portfolio {
    font-size: 11px;
    font-weight: 600;
    width: 240px;
    height: 45px;
    margin-left: 0;
    margin-top: 10px;
  }

  .order-body-row .order-type-box {
    width: 120px;
    height: 40px;
    margin-right: 0px;
    font-size: 14px;
  }

  .order-body-row.order-box {
    justify-content: space-between;
  }

  .order-body-row.order-box .price-box {
    width: 48%;
    margin-bottom: 0;
  }

  .order-body-row .order-error {
    display: block;
  }

  .order-body-row .order-error>p{
    font-size: 14px;
  }

  .order-footer {
    position: fixed;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    height: 12%;
    padding: 0 10px;
  }

  .order-footer .order-error {
    display: none;
  }

  .order-footer .order-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: auto;
  }

  .order-footer .order-buttons .order-button {
    width: 150px;
    height: 45px;
    font-size: 16px;
    margin-left: 0px;
  }

  .order-body .order-section-separator {
    display: none;
  }

  .order-body .order-depth-section {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    inset: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10000;
    top: 100%;
  }

  .order-body .order-depth-section.active {
    top: 0;
  }

  .order-body .order-depth-section .order-market{
    grid-row-gap: 0;
    row-gap: 0;
  }

  .order-market-depth .separator{
    display: none;
  }

  .order-market-extra {
    background-color: white;
    flex-direction: column;
    border-radius: 0;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .order-market-extra .market-extra-column > p{
    font-size: 15px;
    color: var(--font-primary);
    font-weight: 600;
  }

  .order-body .order-info-button {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
  }

  .order-body .order-body-market-depth-button {
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    color: var(--font-primary);
  }

  .order-body .order-market-depth,
  .order-type-body .order-market-depth {
    flex-direction: row;
    grid-row-gap: 10px;
    row-gap: 10px;
    background-color: white;
    padding: 0px 0 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .order-market-depth .order-market-offers > p,
  .order-market-depth .order-market-bids > p {
    display: none;
  }

  .order-market-depth .order-market-offers,
  .order-market-depth .order-market-bids {
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .order-market-depth table {
    border: none;
    border-radius: 5px;
  }

  .order-market-depth table thead tr td {
    font-size: 13px;
  }

  .order-market-depth table tr td {
    font-size: 14px;
    padding: 6px 6px;
  }
}

.price__digit
{
    overflow: hidden;
    margin: 0px;
}

.price__digit__column
{
  display : flex;
  flex-direction : column;
  transition : 1s;
}

.digit__box
{
  display: flex;
  justify-content: center;
  align-items: center;
}
.price-box {
  width: 170px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.price-box-title {
  width: 100%;
}

.price-box-title .label {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  letter-spacing: -0.5px;
}

.price-box.disabled .price-box-title .label {
  color: #b5b5b5;
}

.price-box.invalid .price-box-title .label {
  color: var(--main-red);
}

.price-box-title .helper {
  font-size: 11px;
  color: var(--font-primary);
  margin-left: 3px;
  font-weight: 500;
}

.price-box.disabled .helper {
  color: #b5b5b5;
}

.price-box.invalid .helper {
  color: var(--main-red);
}

.price-box-content {
  width: 100%;
  height: 35px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 0 2px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  position: relative;
}

.price-box.disabled .price-box-content {
  border: 1px solid #e8e8e8;
}

.price-box.invalid .price-box-content {
  border: 1px solid var(--main-red);
}

.price-box-content .price-box-value {
  /* height: 100%; */
  width: 80%;
}

.price-box-content .price-box-value input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: none;
  padding-left: 10px;
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
  background-color: transparent;
}

.price-box-content .price-box-value input::-webkit-outer-spin-button,
.price-box-content .price-box-value input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.price-box.disabled .price-box-content .price-box-value input {
  color: #b5b5b5;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  cursor: default;
  background-color: transparent;
  -moz-appearance: textfield;
}

.price-box-content .price-box-value > span {
  padding-left: 10px;
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
}

.price-box.disabled .price-box-content .price-box-value > span {
  color: #b5b5b5;
}

.price-box-options {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-box-calculator {
  position: absolute;
  right: 0;
  top: 105%;
  width: 60%;
  height: 120px;
  background-color: var(--white);
  z-index: 10000;
  border-radius: 3px;
  box-shadow: 1px 3px 5px #e8e8e8;
  display: flex;
}

.price-box-calculator .calculator-col {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #e8e8e8;
}

.price-box-calculator .calculator-col > span {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s;
}

.price-box-calculator .calculator-col > span:hover {
  background-color: #f7f7f7;
}

.price-box-calculator .calculator-col > span > img {
  width: 16px;
}

@media only screen and (max-width: 768px) {
  .price-box {
    width: 170px;
    margin-right: 0px;
  }

  .price-box-title .label {
    font-size: 14px;
  }

  .price-box-title .helper {
    font-size: 14px;
  }

  .price-box-content {
    width: 100%;
    height: 45px;
  }

  .price-box-content .price-box-value {
    width: 80%;
  }

  .price-box-content .price-box-value input {
    font-size: 15px;
  }

  .price-box-content .price-box-value > span {
    font-size: 14px;
  }

  .price-box-calculator {
    position: absolute;
    right: 0;
    top: 105%;
    width: 100%;
    height: 160px;
    background-color: var(--white);
    z-index: 10000;
    border-radius: 3px;
    box-shadow: 1px 3px 5px #e8e8e8;
    display: flex;
  }
}


.custom-checkbox
{
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    box-sizing: border-box;
    transition: background-color .35s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}


.custom-checkbox.disabled
{
    background-color: #f5f5f5;
    border: 1px solid #d8d8d8;
    /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.15); */
    cursor: auto;
}
.sp__click__overlay {
  position: fixed;
  bottom: 0%;
  left: 0;
  background-color: rgba(189, 195, 199, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  animation: slideup 0.05s linear forwards;
}

@keyframes slideup {
  0% {
    bottom: -100%;
    left: 0%;
  }

  100% {
    bottom: 0%;
    left: 0%;
  }
}

.sp__click__wrapper {
  width: 100%;
  flex-grow: 1;
}

.sp__click__options {
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--white);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 2%);
  border-radius: 5px;
}

.sp__click__stock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #f3f3f3;
}

.sp__click__options .sp__click__divs {
  padding: 5px 20px;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.sp__click__options .sp__click__divs > span {
  margin-right: 10px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 12px;
  padding: 3px 0;
  font-weight: 500;
}

.sp__click__divs > span > img {
  width: 20px;
}

.sp__click__divs > span.buy__div {
  background-color: var(--main-green);
  color: var(--white);
}

.sp__click__divs > span.sell__div {
  background-color: var(--main-red);
  color: var(--white);
}

.sp__click__options .sp__click__divs > p {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.sp__hover__options {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: auto;
  z-index: 10;
}

.sp__hover__options .sp__hover__buttons {
  width: 45px;
  height: 25px;
  margin: 0px 1px 0px 3px;
  border-radius: 15px;
  background-color: #e8e8e8;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sp__hover__options .sp__hover__buttons > span {
  font-size: 13px;
  font-weight: 700;
}

.sp__hover__options .sp__hover__buttons:hover {
  background-color: #f5f5f5;
}

.sp__hover__options .sp__hover__buttons.buy__button,
.sp__hover__options .sp__hover__buttons.sell__button {
  font-weight: bold;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: 1px solid;
  letter-spacing: 0.2px;
}

.sp__hover__options .sp__hover__buttons.buy__button {
  border-color: var(--main-green);
  color: var(--main-green);
}

.sp__hover__options .sp__hover__buttons.sell__button {
  border-color: var(--main-red);
  color: var(--main-red);
}

.sp__hover__options .sp__hover__buttons > img {
  width: 16px;
}

.cash__position__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.cash__position {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border-radius: 15px;
  padding: 0 0px;
  overflow-y: hidden;
  border: 1px solid #f5f5f5;
}

.cash__position__zoom {
  padding: 0px 5px 0;
  border: none;
}

.cash__position .cp__watchlist__header {
  display: flex;
  justify-content: space-between;
  height: 8%;
}

.cash__position .cp__data {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px 5px;
  border-radius: 5px;
  flex-grow: 1;
}

.cash__position .cp__data .cp__watchlist {
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cash__position .cp__data .cp__watchlist .cp__watchlist__text {
  display: flex;
  flex-direction: column;
}

.cash__position .cp__data .cp__watchlist__loading {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cash__position .cp__data .cp__watchlist__loading > p {
  margin-top: 10px !important;
  font-size: 14px !important;
  color: var(--font-primary);
  font-weight: 500;
}

.cash__position .cp__dropdown {
  position: relative;
  display: flex;
  /* justify-content: center; */
  width: 100%;
}

.cash__position__wrapper .cp__watchlist__close {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px 3px 0 0;
  margin-right: 5px;
}

.cash__position__wrapper .cp__watchlist__close img {
  width: 30px;
}

.cash__position .cp__dropdown .custom-select {
  width: 100% !important;
  height: 45px !important;
  box-shadow: none;
}

.cash__position .cp__dropdown .custom-select .custom-select-container {
  min-width: 100% !important;
}

.cash__position .custom-select .custom-select-container .custom-select-option {
  height: 40px !important;
  display: flex;
  align-items: center;
}

.cash__position .cp__dropdown .cp__dropdown__icon {
  padding: 4px;
  margin-right: 4px;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}

.cash__position .cp__dropdown .cp__dropdown__options {
  position: absolute;
  width: 100%;
  background-color: var(--white);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.02);
  top: 100%;
  z-index: 100;
  display: none;
}

.cash__position .cp__dropdown .cp__dropdown__options p {
  padding: 8px 0px 8px 12px;
  cursor: pointer;
  margin: 0px 0px 2px 0px !important;
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.cash__position .cp__dropdown .cp__dropdown__options p:hover {
  background-color: #f2f2f2;
}

.cash__position .portfolio__cashposition {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  margin-top: 5px;
  width: 94%;
}

.cash__position .cashposition__portfolio__loading {
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cash__position .cashposition__portfolio__loading > p {
  font-size: 12px !important;
  font-weight: 600;
  color: var(--font-primary);
}

.cash__position .cp__title {
  display: flex;
  align-items: center;
  flex: 1 1;
}

.cash__position .cp__title img {
  width: 20px;
}

.cash__position .cp__title span {
  padding-left: 6px;
  font-size: 12px;
  /* font-weight: 600; */
  color: var(--font-primary);
}

.cash__position .cp__value {
  /* padding-left: 20px; */
  margin-top: -2px;
  color: var(--font-primary);
  flex: 1 1;
  text-align: right;
}

.cash__position .cp__value span {
  font-size: 14px;
  font-weight: 500;
}

.cash__position .cashposition__portfolio {
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #19E683; */
}

.cash__position .cp__deatils__row {
  width: 94%;
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.cp__deatils__row .cp__deatils__title,
.cp__deatils__row .cp__deatils__value {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.cp__deatils__row .cp__deatils__title img {
  width: 20px;
}

.cp__deatils__row .cp__deatils__title p {
  font-size: 12px !important;
  color: var(--font-primary);
  /* font-weight: 600; */
  margin-left: 6px !important;
}

.cp__deatils__row .cp__deatils__value {
  justify-content: flex-end;
}

.cp__deatils__row .cp__deatils__value span {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.cash__position .cp__portfolio__details .cp__portfolio__edit,
.cash__position .cp__watchlist .cp__watchlist__edit {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* padding: 6px 10px; */
  border-radius: 2px;
  transition: 0.3s;
  /* box-shadow: 0 0 2px rgb(0 0 0 / 10%); */
}

.cash__position .cp__portfolio__details .cp__portfolio__edit img,
.cash__position .cp__watchlist .cp__watchlist__edit img {
  width: 16px;
  margin-right: 4px;
}

.cash__position .cp__portfolio__details .cp__portfolio__edit span,
.cash__position .cp__watchlist .cp__watchlist__edit span {
  font-size: 10px;
  font-weight: 500;
  color: var(--font-primary);
}

.cash__position .cp__watchlist .cp__watchlist__text > p {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.cash__position .cp__watchlist .cp__watchlist__text .cash-value {
  margin-top: 20px;
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  align-items: center;
  height: 20px;
}

.cash__position .cp__watchlist .cp__watchlist__text .cash-value > p {
  font-size: 13px;
  font-weight: 500;
}

.cash__position .cp__watchlist .cp__watchlist__text .cash-value > p > span {
  font-weight: 500;
  font-size: 28px;
}

.cash__position .cp__portfolio .cp__income {
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
  display: flex;
  align-items: flex-end;
}

.cash__position .cp__portfolio__value .cp__change {
  font-size: 9px;
  color: var(--main-green);
  font-weight: 700;
}

.cash__position .cp__portfolio__value .cp__change span {
  font-size: 12px;
}

.cash__position .cp__shares__container {
  margin-top: 5px;
  position: relative;
  width: 96%;
  height: 90%;
  overflow-y: scroll;
}

.cash__position .cp__shares__container.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  padding-top: 30px;
}

.cash__position .cp__shares__container.empty > img {
  width: 40%;
  margin-bottom: 20px;
}

.cp__shares__container .empty__watchlist {
  font-size: 14px;
  text-align: center;
  color: var(--font-primary);
  font-weight: 500;
}

.cp__shares__container .create__watchlist {
  padding: 10px 15px;
  border-radius: 35px;
  color: var(--font-primary);
  font-size: 12px;
  margin-top: 0;
  letter-spacing: 0;
  cursor: pointer;
  background-color: var(--white);
  border: 1px solid #e8e8e8;
  font-weight: 700;
}

.cash__position .cp__shares__container::-webkit-scrollbar {
  display: none;
}

.cash__position .share__profile__wrapper {
  margin-bottom: 0px;
  transition: 0.3s;
  padding: 10px 0px 10px 0px;
  position: relative;
  margin: 5px 0;
  background-color: var(--white);
  border-bottom: 1px solid #f5f5f5;
  cursor: -webkit-grab;
  cursor: grab;
}

.cash__position .share__profile__wrapper.loading-box {
  background-color: #fcfcfc;
  height: 50px;
  animation: blink 2s linear infinite;
}

@keyframes blink {
  0%,
  100% {
    background-color: #fcfcfc;
  }
  50% {
    background-color: #f3f3f3;
  }
}

.cash__position .share__profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .cash__position .share__profile__wrapper:hover
{
  background-color: #fbfbfb;
}

*/
.cash__position .share__profile .sp__name {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
  flex: 8 1;
}

.cash__position .share__profile .cp__price {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
}

.cash__position .share__profile .cp__change {
  font-size: 13px;
  font-weight: 600;
  flex: 3 1;
}

.cash__position .share__profile .cp__change__per {
  font-size: 12px;
  font-weight: 600;
  flex: 2 1;
}

.cash__position .share__profile .cp__icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1;
}

.cash__position .share__profile .sp__profile {
  /* flex: 5; */
  display: flex;
  padding-left: 0px;
  /* align-items: center; */
}

.cash__position .share__profile .sp__drag {
  flex: 2 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: -webkit-grab;
  cursor: grab;
}

.cash__position .share__profile .sp__options {
  flex: 2 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.cash__position .share__profile .sp__options__list {
  position: absolute;
  top: 105%;
  right: 0%;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 120px;
  z-index: 1000;
  padding: 8px 0px;
}

.cash__position .share__profile .sp__options__list > p {
  font-size: 11px;
  font-weight: 500;
  padding: 4px 10px;
  transition: 0.3s;
}

.cash__position .share__profile .sp__options__list > p:hover {
  background-color: #f8f8f8;
}

.cash__position .share__profile .sp__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 8 1;
  grid-row-gap: 3px;
  row-gap: 3px;
}

.cash__position .share__profile .sp__price {
  display: flex;
  flex-direction: column;
  grid-row-gap: 3px;
  row-gap: 3px;
}

.cash__position .share__profile .sp__fullname {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-blur);
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 12px;
}

.cash__position .sp__quantity {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  color: var(--font-primary);
}

.cash__position .sp__quantity .sp__quantity__value {
  padding-left: 6px;
}

.cash__position .sp__quantity .sp__average {
  display: flex;
  justify-content: flex-end;
  letter-spacing: 0.4px;
}

.cash__position .sp__quantity img {
  width: 16px;
  transform: rotateY(180deg);
}

.cash__position .sp__price {
  justify-content: flex-end;
}

.cash__position .sp__price .sp__income {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  height: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: "Lato", sans-serif !important;
}

.cash__position .share__profile .sp__price .sp__change {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column-gap: 6px;
  -webkit-column-gap: 6px;
          column-gap: 6px;
}

.cash__position .sp__price .cp__change {
  font-weight: 600;
  font-size: 12px;
}

.cash__position .sp__remove {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 10px;
}

.cash__position .sp__remove span {
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 3px;
}

.cash__position .sp__remove span:hover {
  background-color: #bce8ff;
}

.cash__position .share__profile .sp__quantity {
  font-size: 14px;
  font-weight: 700;
  color: var(--font-primary);
  flex: 1 1;
  display: flex;
  align-items: center;
}

.cash__position .share__profile .sp__quantity .sp__quantity__value {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.cash__position .share__profile .sp__quantity .sp__quantity__value img {
  width: 18px;
  transform: rotateY(180deg);
}

.cash__position .share__profile .sp__quantity .sp__average {
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
  width: 50%;
}

.dl-list {
  max-height: 410px !important;
}

.watchlist__popup__overlay {
  position: fixed;
  inset: 0;
  background: #87888850;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
}

.watchlist__popup {
  position: absolute;
  width: 500px;
  height: 600px;
  min-width: 500px;
  background: var(--white);
  border-radius: 5px;
  transform: translateY(8px);
  animation: getup 0.1s linear forwards;
  transition: 0.3s;
}

@keyframes getup {
  0% {
    transform: translateY(8px);
  }

  100% {
    transform: translateY(0px);
  }
}

.watchlist__popup .watclist__popup__header {
  height: 10%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
}

.watchlist__popup .watclist__popup__header > p {
  margin: 0 !important;
  font-size: 13px !important;
  font-weight: 600;
  color: var(--font-primary);
}

.watchlist__popup .watclist__popup__header > span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s;
}

.watchlist__popup .watclist__popup__header > span:hover {
  background-color: #f6f6f6;
}

.watchlist__popup .watclist__popup__header > span > img {
  width: 30px;
}

.watchlist__popup .watchlist__popup__body {
  width: 100%;
  height: calc(100% - 60px);
  padding: 10px 0px;
}

.watchlist__popup__body .stock__search__wrapper {
  padding: 0 20px;
}

.watchlist__popup__body .stock__search {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border: 1px solid #e8e8e8;
  border-radius: 40px;
}

.watchlist__popup__body .stock__search > img {
  width: 16px;
}

.watchlist__popup__body .stock__search > input {
  width: 90%;
  margin-left: 15px;
  font-weight: 600;
  letter-spacing: -0.5px;
  color: var(--font-primary);
  border: none;
  outline: none;
  background-color: transparent;
  text-transform: uppercase;
}

.watchlist__popup__body .stock__search > input::-webkit-input-placeholder {
  color: #888888;
  letter-spacing: 0.2px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.watchlist__popup__body .stock__search > input:-ms-input-placeholder {
  color: #888888;
  letter-spacing: 0.2px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.watchlist__popup__body .stock__search > input::placeholder {
  color: #888888;
  letter-spacing: 0.2px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.watchlist__popup__body .stock__suggestions {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  padding-top: 10px;
}

.watchlist__popup__body .stock__suggestions .stock__suggestions__loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 15px;
  row-gap: 15px;
  padding-top: 100px;
}

.watchlist__popup__body .stock__suggestions .stock__suggestions__loader > span {
  color: var(--font-primary);
  font-weight: 600;
  font-size: 13px;
}

.stock__suggestions .stock__suggestions__loader .stock__suggestions__empty {
  color: var(--font-primary);
  font-weight: 600;
  font-size: 13px;
}

.watchlist__popup__body .stock__suggestions > p {
  width: 100%;
  padding: 10px 20px !important;
  margin: 2px 0 !important;
  display: flex;
  transition: 0.3s;
  cursor: pointer;
}

.watchlist__popup__body .stock__suggestions > p:hover {
  background-color: #f7f7f7;
}

.watchlist__popup__body .stock__suggestions > p .search__highlight {
  font-weight: 600;
}

.watchlist__popup__body .stock__suggestions > p > span:nth-child(1) {
  flex: 3 1;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.watchlist__popup__body .stock__suggestions > p > span:nth-child(2) {
  flex: 7 1;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.watchlist__popup__body .stock__suggestions > p > span:nth-child(3) {
  flex: 1 1;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.edit__portfolio__name {
  background: #fff;
  margin: 10px 0 0 0;
}

.edit__portfolio__name input {
  outline: none;
  border: none;
  box-shadow: 2px 2px 2px #eeecec, -2px -2px 2px #ebe9e9;
  margin-right: 4px;
  padding-left: 1ch;
  font-size: 12px;
  font-weight: 600;
}

.edit__portfolio__name button {
  font-size: 11px;
  font-weight: 900;
  margin: 4px;
  padding: 2px 3px 2px 3px;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {


  .cash__position {
    border-radius: 5px;
    border: none;
  }

  .cash__position .cp__data {
    padding: 0px 0;
  }

  .cash__position .cp__data .cp__watchlist {
    width: 100%;
    height: 25px;
  }

  .cash__position .cp__data .cp__watchlist__loading > p{
    font-size: 16px !important;
  }

  .cash__position .cp__watchlist .cp__watchlist__text > p{
    font-size: 16px;
  }

  .cash__position .cp__watchlist .cp__watchlist__text .cash-value{
    display: none;
  }

  .cash__position .cp__shares__container {
    padding: 0px 0px 0px;
    position: relative;
    width: 100%;
    height: calc(100% - 25px);
    overflow-y: scroll;
  }

  .cash__position .share__profile__wrapper {
    padding: 10px 0px;
  }

  .cash__position .share__profile .sp__name {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: var(--font-primary);
  }

  .cash__position .share__profile .sp__fullname {
    width: 200px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: var(--font-primary);
  }

  .cash__position .sp__quantity {
    font-size: 12px;
  }

  .cash__position .sp__quantity .sp__quantity__value {
    flex: 3 1;
    padding-left: 12px;
  }

  .cash__position .sp__quantity img {
    width: 14px;
  }

  .cash__position .sp__price .cp__change > div {
    margin-right: 2px;
  }

  .cash__position .share__profile .cp__price {
    font-size: 16px;
    line-height: 20px;
  }

  .cash__position .sp__price .cp__change span {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }

  .cash__position .share__profile .cp__change__per {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }


  .cash__position .share__profile .sp__quantity {
    font-size: 14px;
    font-weight: 700;
    color: var(--font-primary);
    flex: 1 1;
    display: flex;
    align-items: center;
  }

  .cash__position .share__profile .sp__quantity .sp__quantity__value {
    flex: 1 1;
    display: flex;
    align-items: center;
  }

  .cash__position .share__profile .sp__quantity .sp__quantity__value img {
    width: 18px;
    transform: rotateY(180deg);
  }

  .cash__position .share__profile .sp__quantity .sp__average {
    font-size: 12px;
    font-weight: 700;
    color: var(--font-primary);
    width: 50%;
  }

  .sp__hover__options{
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
  }
  
  .sp__hover__options .sp__hover__buttons {
    width: 60px;
    height: 35px;
    border-radius: 20px;
  }
  
  .sp__hover__options .sp__hover__buttons > span {
    font-size: 14px;
    font-weight: 700;
  }
  
  .sp__hover__options .sp__hover__buttons > img {
    width: 18px;
    height: 18px;
  }

  .dl-list {
    max-height: 410px !important;
  }

  .watchlist__popup {
    width: 100%;
    min-width: auto;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* top: 50%; */
    /* transform: translate(-50%,-50%); */
  }

  .watchlist__popup .watclist__popup__header {
    height: 60px;
    width: 100%;
    padding: 0 10px;
  }

  .watchlist__popup .watclist__popup__header > p {
    margin: 0;
    font-size: 16px !important;
  }

  .watchlist__popup .watchlist__popup__body {
    width: 100%;
  }

  .watchlist__popup__body .stock__search__wrapper {
    padding: 0 10px;
  }

  .watchlist__popup__body .stock__search {
    height: 50px;
  }

  .watchlist__popup__body .stock__suggestions .stock__suggestions__loader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .watchlist__popup__body .stock__suggestions {
    overflow-y: auto;
  }

  .watchlist__popup__body .stock__suggestions > p {
    padding: 10px 5px !important;
  }

  .watchlist__popup .watclist__popup__header > span > img {
    width: 30px;
  }

  .watchlist__popup__body .stock__search > img {
    width: 14px;
  }

  .watchlist__popup__body .stock__search > input {
    font-size: 16px;
    margin-left: 10px;
    font-weight: 600;
    text-decoration: none;
  }

  .watchlist__popup__body .stock__search > input::-webkit-input-placeholder{
    font-size: 16px;
    letter-spacing: -0.5px;
  }

  .watchlist__popup__body .stock__search > input:-ms-input-placeholder{
    font-size: 16px;
    letter-spacing: -0.5px;
  }

  .watchlist__popup__body .stock__search > input::placeholder{
    font-size: 16px;
    letter-spacing: -0.5px;
  }

  .watchlist__popup__body
    .stock__suggestions
    .stock__suggestions__loader
    > span {
    font-size: 14px;
    font-weight: 600;
  }

  .stock__suggestions .stock__suggestions__loader .stock__suggestions__empty {
    font-size: 12px;
  }

  .watchlist__popup__body .stock__suggestions > p > span:nth-child(1) {
    flex: 4 1;
    font-size: 14px;
    font-weight: 600;
  }

  .watchlist__popup__body .stock__suggestions > p > span:nth-child(2) {
    flex: 6 1;
    font-size: 12px;
    font-weight: 500;
    width: 100px;
  }

  .watchlist__popup__body .stock__suggestions > p > span:nth-child(3) {
    flex: 1 1;
    font-size: 14px;
    font-weight: 600;
  }
}

.menu__section
{
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 1px 3px 5px #e8e8e8;
  /* animation: slideUp .2s linear forwards; */
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  padding: 0 50px;
}

@keyframes slideUp{
    0%
    {
        transform: translateY(100%);
    }
    100%{
        transform: translateY(0%);
    }
}

.menu__section .menu__section__wrapper 
{
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  padding: 40px 10px 0;
}

.menu__section .menu__section__wrapper .menu__section__close
{
  
  position: absolute;
  right: 1%;
  top: 1%;
  margin-left: 4px;
  cursor: pointer;
}

.menu__section .menu__section__wrapper .menu__section__close img
{
  width: 30px;
}

.menu__section .menu__content
{
  width: 100%;
  height: 100%;
}

@media only screen and (max-width : 768px)
{

  .menu__section
  {
    width: 100%;
    height: 100%;
    /* background-color: #cccccc; */
    z-index: 1000000;
    padding: 0 10px;
  } 

  .menu__section .menu__section__wrapper 
  {
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    padding: 40px 2px 0;
  }

  .menu__section .menu__section__wrapper .menu__section__close
  {    
    right: 5%;
    top: 3%;
  }

  .menu__section .menu__section__wrapper .menu__section__close img
  {
    width: 30px;
  }

  .menu__section .menu__content
  {
    overflow-y: scroll;
  }

  @media only screen and (max-width:768px)
  {
    .menu__section .menu__section__wrapper .menu__section__close
    {
      top:1%;
      right: 1%;
    }
  }

}

/* hover tooltip */
.react-stockcharts-tooltip-content
{
  -webkit-filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.1));
          filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.1));
}

.react-stockcharts-indicator-options
{
  pointer-events: all;
  cursor: pointer;
}

.react-stockchart-indicator-box
{
  fill: #f0f0f0;
  /* -webkit-filter: drop-shadow( 0px 0px 4px rgba(0, 0, 0, .2)); */
  /* filter: drop-shadow( 0px 0px 4px rgba(0, 0, 0, .2)); */
  cursor: pointer;

}

.react-stockcharts-tooltip,.react-stockcharts-indicator-tooltip
{
  pointer-events: all;
  cursor: pointer;
}

.react-stockcharts-x-axis{
  opacity: 1;
  fill: white;
}

/* .app__body__left .react-stockcharts-x-axis
{
  display: none;
} */

.app__body__left__zoom .react-stockcharts-x-axis
{
  display: block;
}


/* recharts components */
.recharts-legend-item 
{ 
  font-size: 10px; 
}

.custom-tooltip
{
  background-color: #fffffff0;
    padding: 5px 20px;
    box-shadow: 0px 0px 8px #e8e8e8;
  border-radius: 3px;
}

.custom-tooltip .custom-label
{
  font-size: 12px !important;
  margin-bottom: 5px!important;
  font-weight: 500;
}

/*interactive setting popup */
.react-stockcharts-indicator-setting
{
  -webkit-filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.1));
          filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.1));
  pointer-events: all;
  cursor: pointer;
}



/*rsuite components*/
/* .confidence__slider .rs-slider-mark
{
  top: -13px!important;
}

.confidence__slider .rs-slider-last-mark
{
  top: auto!important;
  bottom: -8px!important;
}

*/ .confidence__slider {
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  height: 300px;
  align-items: center;
}

.confidence__slider .slider__wrapper {
  width: 2px;
  height: 100%;
  background-color: #e8e8e8;
  border-radius: 5px;
  position: relative;
}

.slider__wrapper .slider__point {
  width: 10px;
  height: 10px;
  background-color: #e8e8e8;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider__wrapper .slider__point.active {
  background-color: var(--font-primary);
}

.confidence__slider .slider__marks {
  height: 105%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.confidence__slider .slider__marks .mark__text {
  font-weight: 300;
  font-size: 12px;
  color: var(--font-primary);
}

.confidence__slider .slider__marks .mark__text.active {
  font-weight: 500;
}

/* react-confirm-alert*/
.react-confirm-alert-overlay
{
  z-index: 1000!important;
}

.react-confirm-alert-body
{
  font-family: 'Open Sans', sans-serif;
}

.react-confirm-alert-body h1
{
  font-size: 26px!important;
  color: var(--font-primary) !important;
}

.react-confirm-alert-button-group > button
{
  background-color: #404040!important;
  border-radius: 3px!important;
}

/*checkmark */

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--main-green);
  fill: none;
  animation: stroke 0.9s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  margin-bottom: 2%;
  box-shadow: inset 0px 0px 0px var(--main-green);
  animation: fill .6s ease-in-out .9s forwards, scale .6s ease-in-out 1s both
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--main-green);
  }
}
.strategy-content {
  height: 97%;
}

/*app body*/
.app__body {
  overflow: hidden;
  padding: 0px 0px 0px;
  height: 100%;
}

.app__body.hide {
  display: none;
}

.app__body__top__zoom {
  height: 0;
  display: none;
}

.app__body__bottom {
  width: 100%;
  height: 100%;
  display: flex;
  /* align-items: center; */
  /* position: relative; */
  justify-content: space-between;
}

.app__body__bottom__zoom {
  height: 100%;
  padding: 5px 10px;
}

.app__body.app__body__zoom {
  height: 100%;
  padding: 0px;
}

.app__body__bottom .app__body__footer {
  position: absolute;
  z-index: 10000;
  bottom: 0.5%;
  left: -7%;
}

.app__body__bottom .app__body__logout {
  position: absolute;
  left: 101%;
  bottom: 1%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.app__body__bottom .app__body__logout > div {
  display: flex;
  align-items: center;
}

.app__body__bottom .app__body__logout img {
  width: 20px;
  transform: rotate(180deg);
  margin-right: 5px;
}

.app__body__bottom .app__body__logout span {
  font-size: 12px !important;
  font-weight: bold !important;
  letter-spacing: -0.5px;
  color: var(--font-primary);
}

.app__body .app__body__left {
  width: 74%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.app__body .app__body__left.app__body__left__zoom {
  width: 75%;
  height: 100%;
}

.app__body .app__body__left .Indicator__popup,
.app__body .app__body__left .Interactive__popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 1px 1px 5px 1px #cccccc;
  z-index: 1000;
  width: 40%;
  height: 70%;
  border-radius: 5px;
  overflow-x: hidden;
  display: none;
  z-index: 100000;
}

.app__body .app__body__left .Indicator__popup .Indicator__title__name,
.Stock__watch__popup .Stock__watch__title__name,
.app__body .app__body__left .Interactive__popup .Interactive__title__name {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  font-size: 18px;
  letter-spacing: 0px;
  font-weight: bold;
  font-size: 14px;
  border-bottom: 0.5px solid #eeeeee;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__title__name
  #Indicator__close,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__title__name
  #Interactive__close,
.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__title__name
  #Stock__watch__close {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__title__name
  #Indicator__close:hover,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__title__name
  #Interactive__close:hover,
.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__title__name
  #Stock__watch__close:hover {
  background-color: #f5f5f5;
}

.app__body .app__body__left .Indicator__popup .Indicator__options,
.app__body .app__body__left .Interactive__popup .Interactive__options {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  position: relative;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options::-webkit-scrollbar,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options::-webkit-scrollbar,
.app__body
  .business__news__section
  .business__news__wrapper
  .business__news__box::-webkit-scrollbar,
.credit__ratings::-webkit-scrollbar,
.business__chart::-webkit-scrollbar {
  width: 5px;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options::-webkit-scrollbar-track,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options::-webkit-scrollbar-track,
.app__body
  .business__news__section
  .business__news__wrapper
  .business__news__box::-webkit-scrollbar-track,
.credit__ratings::-webkit-scrollbar-track,
.business__chart::-webkit-scrollbar-track {
  display: none;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options::-webkit-scrollbar-thumb,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options::-webkit-scrollbar-thumb,
.app__body
  .business__news__section
  .business__news__wrapper
  .business__news__box::-webkit-scrollbar-thumb,
.credit__ratings::-webkit-scrollbar-track,
.business__chart::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 10px;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options::-webkit-scrollbar-thumb:hover,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options::-webkit-scrollbar-thumb:hover,
.app__body
  .business__news__section
  .business__news__wrapper
  .business__news__box::-webkit-scrollbar-thumb:hover,
.credit__ratings::-webkit-scrollbar-track,
.business__chart::-webkit-scrollbar-track {
  cursor: pointer;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block {
  position: relative;
  width: 100%;
  border-bottom: 0.5px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block:hover {
  background-color: #f5f5f5;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block
  .indicator__option__title {
  width: 100%;
  height: 100%;
  padding: 10px 30px 10px 30px;
  /* background-color: #00A0E3; */
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block
  p {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block
  > div {
  display: flex;
  align-items: center;
}

/* 
.app__body .app__body__left .Indicator__popup .Indicator__options .Indicator__option__block>div button 
{
  border: none;
  outline: none;
  background-color: #eeeeee;
  box-shadow: 0 2px 3px rgba(0,0,0,.25);
  margin-right: 20px;
  border-radius: 2px;
  width: 130px;
  height: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
} */

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block
  > div
  span {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block
  > div
  span:hover {
  font-weight: bold;
  background-color: #470985;
  color: #ffffff;
}

.app__body .app__body__left .Indicator__popup .Indicator__info {
  position: absolute;
  /* background-color: #470985;
  */
  background-color: #fbfbfb;
  box-shadow: -2px 0 4px #cccccc;
  top: 0%;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  transform: translateX(100%);
  transition: 0.3s;
  z-index: 1999;
}

.app__body .app__body__left .Indicator__popup .Indicator__info.active {
  transform: translateX(0%);
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__name {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 0.5px solid #eeeeee;
  position: relative;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__name
  span#Indicator__Info__close {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffff5;
  cursor: pointer;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__data {
  padding: 10px 30px;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__data
  > div {
  margin-bottom: 20px;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__data
  > div
  .Indicator__info__data__title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__data
  > div
  .Indicator__info__data__value {
  font-size: 14px;
  font-weight: 500;
}

.app__body .app__body__left .Stock__watch__popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 1px 1px 5px 1px #cccccc;
  z-index: 100000;
  width: 40%;
  height: 80%;
  border-radius: 5px;
  overflow: hidden;
  display: none;
}

.app__body .app__body__left .Stock__watch__popup .Stock__watch__container {
  width: 100%;
  height: 85%;
  overflow-y: scroll;
  position: relative;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__suggessted {
  width: 100%;
  height: 100%;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__suggessted__loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__section {
  background-color: #f1f2f6;
  padding: 5px 30px;
  font-size: 12px;
  font-weight: 900;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item {
  width: 100%;
  padding: 8px 30px 8px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 0.5px solid #eeeeee;
  cursor: pointer;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item.loader {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item:hover {
  background-color: #f5f5f5;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item:hover
  .Stock__watch__exchange__section
  > div {
  background-color: #ffffff;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__name {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__fullname {
  font-size: 10px;
  font-weight: 600;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__exchange__section {
  display: flex;
  align-items: center;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__exchange {
  font-weight: bold;
  font-size: 12px;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__exchange__section
  > div {
  background-color: #f1f2f6;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 3px;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__exchange__section
  .Stock__watch__change__per {
  margin-right: 15px;
  font-size: 14px;
  font-weight: bold;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container::-webkit-scrollbar {
  width: 2px;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container::-webkit-scrollbar-track {
  /* display: none; */
  background: #f4f7f9;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 3px;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block {
  width: 100%;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  > p {
  padding: 5px 30px;
  background-color: #f8f8f8;
  font-size: 12px;
  font-weight: bold;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  .Interactive__tool {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 10px 30px;
  cursor: pointer;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  .Interactive__tool:hover {
  background-color: #eeeeee;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  > div
  img {
  width: 20px;
  margin-right: 10px;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  > div
  p {
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  > div
  > p:hover {
  background-color: #eeeeee;
}

.app__body .app__body__left .Indicator__popup.active,
.app__body .app__body__left .Interactive__popup.active,
.app__body .app__body__left .Stock__watch__popup.active {
  display: block;
}

.app__body .app__body__left .chart__container {
  width: 100%;
  height: 90%;
  border-radius: 15px;
  position: relative;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-row-gap: 5px;
  row-gap: 5px;
  border: 1px solid #f5f5f5;
  padding: 0px 0px 10px 0px;
}

.app__body .app__body__left .chart__container.chart__container__zoom {
  height: 84%;
  border-radius: 0px;
  border-top: none;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options {
  flex-direction: column;
  width: 100%;
  height: 10%;
  display: none;
  flex: 1 1;
}

.app__body
  .app__body__left
  .chart__container.chart__container__zoom
  .chart__container__stock__options {
  height: 7%;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 2%), -3px -3px 10px rgb(0 0 0 / 2%);
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options.active {
  display: flex;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  /* background-color: #19E683; */
  /* border: 1px solid #eeeeee; */
  z-index: 100;
  display: flex;
  align-items: center;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  height: 100%;
  padding: 0px 15px;
  /* border: 1px solid #eeeeee; */
  position: relative;
  cursor: pointer;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block:hover {
  background-color: #f7f7f7;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  img {
  width: 28px;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  img#stock-watch-arrow {
  width: 15px;
  height: 15px;
  transform: rotate(0deg);
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  img#stock-watch-arrow.active {
  transform: rotate(180deg);
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  span#chart__type__icon {
  width: 18px;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__stock__name {
  padding: 0 30px;
  font-size: 16px;
  cursor: pointer;
}

.app__body .chart__options .chart__option__block .stock__chart__types {
  background-color: #ffffff;
  width: 200px;
  position: absolute;
  top: 100%;
  left: 0%;
  margin-top: 4px;
  z-index: 100;
  border-radius: 2px;
  padding: 5px 5px 0 5px;
  box-shadow: 1px 3px 5px #e8e8e8;
  transition: 0.2s;
  display: none;
}

.app__body .chart__options .chart__option__block .stock__chart__types.active {
  display: block;
  border-radius: 3px;
  transition: 0.3s;
}

.app__body .chart__options .chart__option__block .stock__chart__types > div {
  display: flex;
  align-items: center;
  padding: 8px 0 8px 10px;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #f7f7f7;
}

.app__body
  .chart__options
  .chart__option__block
  .stock__chart__types
  > div.active {
  background-color: #f7f7f7;
}

.app__body
  .chart__options
  .chart__option__block
  .stock__chart__types
  > div:hover {
  background-color: #f7f7f7;
}

.app__body
  .chart__options
  .chart__option__block
  .stock__chart__types
  > div
  span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  .stock__chart__types
  > div
  img {
  width: 16px;
}

.app__body .chart__options .chart__option__block #chart__type__icon {
  width: 20px;
  height: 20px;
}

.app__body .app__body__left .chart__container .stock__info__chart {
  position: relative;
  height: calc(100% - 30px);
}

.app__body
  .app__body__left
  .chart__container.chart__container__zoom
  .stock__info__chart {
  height: 87%;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 2%), -3px -3px 10px rgb(0 0 0 / 2%);
  margin-top: 5px;
  border-radius: 5px;
}

.app__body .app__body__left .chart__container .chart__container__stock__info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 10px 80px 0 20px;
}

.app__body .app__body__left .chart__container .stock__info {
  z-index: 10;
  padding-left: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  display: flex;
  background-color: white;
}

.app__body .app__body__left .chart__container .stock__info .stock__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.app__body .app__body__left .chart__container .stock__info .stock__details p {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .chart__container
  .stock__info
  .stock__details
  .stock__type {
  width: auto;
  height: auto;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.app__body
  .app__body__left
  .chart__container
  .stock__info
  .stock__details
  .stock__type
  img {
  width: 14px;
  margin-right: 4px;
}

.app__body
  .app__body__left
  .chart__container
  .stock__info
  .stock__details
  .stock__type
  p {
  font-size: 11px;
  font-weight: 500;
  color: var(--font-primary);
}

.app__body .app__body__left .chart__container .stock__info #stock__full__name {
  font-size: 10px;
  color: var(--font-primary);
  font-weight: 500;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__purchase {
  display: flex;
  flex-direction: row;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
  align-items: flex-start;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details {
  display: flex;
  align-items: center;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details p {
  font-size: 16px;
  font-weight: bold;
  margin-right: 2px;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details .price__decimals {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 20px;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details .price__fraction {
  color: var(--font-primary);
  font-size: 14px;
  font-weight: bold;
  align-self: flex-start;
  margin-left: 0px;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details span.stock__performance {
  font-size: 12px;
  color: #470985;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details p span.clock {
  margin: 0 3px;
  font-size: 12px;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details {
  font-size: 12px;
  font-weight: bold;
}

.app__body .app__body__left .chart__container .stock__purchase {
  z-index: 10;
  display: flex;
  align-items: center;
  grid-column-gap: 60px;
  -webkit-column-gap: 60px;
          column-gap: 60px;
}

.app__body .app__body__left .chart__container .stock__purchase.zoom {
  right: 6%;
}

.app__body .app__body__left .chart__container .stock__purchase .buy__sell__box {
  display: flex;
  flex-direction: row;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  align-items: center;
  background-color: var(--white);
}

.app__body
  .app__body__left
  .chart__container
  .stock__purchase
  .buy__sell__box
  > div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body .app__body__left .chart__container .stock__purchase .currency {
  font-size: 12px;
  font-weight: 500;
}

.squareoff__stock {
  margin-left: 5px;
  border: 1px solid var(--main-blue);
  padding: 3px 10px;
  color: var(--main-blue);
  border-radius: 3px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body .app__body__left .chart__container .stock__purchase > div:hover img {
  transform: scale(1.2);
}

.app__body .app__body__left .chart__container .stock__purchase > div img {
  width: 12px;
  height: 12px;
  transition: 0.3s;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__change {
  display: flex;
  flex-direction: row;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.app__body .app__body__left .chart__container .stock__info .stock__price__change div {
  margin-left: 0px;
}

.app__body .app__body__left .chart__container .stock__chart {
  width: 100%;
  height: 100%;
  position: relative;
}

.app__body .app__body__left .chart__container .stock__chart.stock__chart__blur .react-stockchart {
  opacity: 0.7;
}

.app__body .app__body__left .chart__container .stock__chart.stock__chart__blur .stock__chart__pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.app__body .app__body__left .chart__container .stock__chart.stock__chart__load {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app__body .app__body__left .chart__container .stock__chart.stock__chart__load p {
  margin-top: 10px !important;
  font-weight: 500;
  font-size: 14px;
}

.app__body .app__body__left .chart__container .chart__range {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 10px;
}

.app__body .app__body__left .chart__container .chart__range .chart__range__left {
  display: flex;
}

.app__body .app__body__left .chart__container.chart__container__zoom .chart__range {
  height: 6%;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 2%), -3px -3px 10px rgb(0 0 0 / 2%);
  margin-top: 5px;
}

.app__body .app__body__left .chart__container .chart__range .chart__range__value {
  font-size: 12px;
  margin: 0 5px 0 0;
  color: var(--font-primary);
  font-weight: 500;
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 3px;
  transition: 0.3s;
}

.app__body .app__body__left .chart__container .chart__range .active__range {
  color: var(--font-primary);
  background-color: none;
  font-weight: 800;
  font-size: 12px;
}

.app__body .app__body__left .chart__container .chart__range .chart__range__right{
  display: flex;
  align-items: flex-end;
}

.app__body .app__body__left .chart__container .chart__range .chart__range__right span {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  padding: 4px 20px;
  border-radius: 20px;
  border: 1px solid #e8e8e8;
}

.app__body .app__body__left .chart__container .chart__range .chart__range__right span a {
  color: var(--font-primary);
  text-decoration: none;
}

.app__body .app__body__left .chart__container .chart__zoom {
  position: absolute;
  right: 0%;
  bottom: 0%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.app__body .app__body__left .chart__container .chart__zoom img {
  width: 30px;
}

.app__body .app__body__left .key__statistics {
  width: 100%;
  height: 12%;
  min-height: 60px;
  border: 1px solid #f5f5f5;
  background-color: var(--white);
  border-radius: 15px;
  padding: 5px 15px 5px 15px;
  position: relative;
}

.app__body .app__body__left .key__statistics.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body .app__body__left .key__statistics.key__statistics__zoom {
  height: 15%;
  border-radius: 0px;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 2%), -3px -3px 10px rgb(0 0 0 / 2%);
}

.app__body .app__body__left__zoom .key__statistics {
  height: 15%;
  border-radius: 0px;
}

.app__body .app__body__left .key__statistics .ks__title {
  color: var(--font-primary);
  font-size: 14px !important;
  letter-spacing: -0.5px;
  font-weight: 500;
}

.app__body .app__body__left .key__statistics .ks__title > p {
  letter-spacing: -0.5px;
}

.app__body .app__body__left .key__statistics .ks__container {
  width: 100%;
  height: 100%;
}

.app__body .app__body__left .key__statistics .ks__container .ks__container__half,
.app__body .app__body__left .key__statistics .ks__container .ks__container__full {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app__body .app__body__left .key__statistics .ks__container .ks__container__mobile {
  display: none;
}

.app__body .app__body__left .key__statistics .ks__container .ks__slot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 25%;
}

.app__body .app__body__left .key__statistics .ks__container .statistics .ks__name {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: bold;
}

.app__body .app__body__left .key__statistics .ks__container .statistics .ks__value {
  font-size: 14px;
  color: var(--font-primary);
  font-weight: 600;
  display: flex;
  align-items: center;
}

.app__body .app__body__left .key__statistics .ks__container .ks__slot .statistics .ks__value .range__line {
  position: relative;
  width: 30px;
  height: 2px;
  background-color: var(--main-blue);
  margin: 0 8px;
}

.ks__value .range__line::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  width: 8px;
  height: 8px;
  transform: translate(-50%, -50%);
  background-color: var(--main-blue);
  border-radius: 50%;
}

.ks__value .range__line::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0%;
  width: 8px;
  height: 8px;
  transform: translate(50%, -50%);
  background-color: var(--main-blue);
  border-radius: 50%;
}

.app__body .app__body__left .key__statistics .ks__container .ks__container__full {
  padding: 0 20px 0 0;
}

.app__body .app__body__left .key__statistics .ks__container .statistics .ks__name {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 500;
}

.app__body .app__body__left .key__statistics .ks__container .ks__container__full .ks__slot .statistics {
  margin: 3px 0;
}

.app__body .app__body__right {
  width: 25%;
  height: 100%;
}

.app__body .app__body__right.app__body__right__zoom {
  width: 25%;
  margin-left: 5px;
}

/* responsive ui */

@media only screen and (max-width: 768px) {

  .strategy-content {
    height: calc(100% - 50px);
    padding: 0 10px;
  }
  
  .app__body .app__body__left {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    transition: 0.4s;
    background-color: white;
    z-index: 10000;
    padding: 0 10px;
  }

  .app__body .app__body__left.active{
    top: 0px;
    justify-content: flex-start;
    padding: 0px 0px;
  }

  .app__body .app__body__left .chart__mobile__header{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 10px 10px 0;
  }

  .chart__mobile__header .chart__close{
    cursor: pointer;
  }

  .app__body .app__body__left .chart__container .chart__container__stock__info{
    padding: 0px 0px;
    align-items: flex-start;
    position: static;
    position: initial;
    padding: 0 10px;
    flex-direction: column;
  }

  .app__body .app__body__left .chart__container .stock__info{
    width: 100%;
    justify-content: space-between;
  }

  .app__body .app__body__left .chart__container .stock__info .stock__details p{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .app__body .app__body__left .chart__container .stock__info .stock__price__purchase{
    flex-direction: column;
    align-items: flex-end;
  }

  .app__body .app__body__left .chart__container .stock__info .stock__price__details .price__decimals{
    font-size: 20px;
    line-height: 24px;
  }

  .app__body .app__body__left .chart__container .stock__info .stock__price__change{
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .app__body .app__body__left .chart__container .stock__info #stock__full__name{
    font-size: 13px;
    line-height: 20px;
  }

  .app__body .app__body__left .chart__container{
    height: 450px;
    border: none;
    justify-content: flex-end;
  }

  .app__body .app__body__left .chart__container .stock__info__chart{
    height: 400px;
  }

  .app__body .app__body__left .chart__container .chart__range .chart__range__right span{
    border: none;
    padding: 0 5px;
  }

  .app__body .app__body__left .chart__container .chart__range{
    padding: 0 10px 0 4px;
  }

  .app__body .app__body__left .chart__container .chart__range .chart__range__value{
    font-size: 14px;
    line-height: 16px;
  }

  .app__body .app__body__left .key__statistics{
    height: auto;
    border: none;
    border-top: 1px solid #e8e8e8;
    border-radius: 0;
    padding: 10px 10px;
  }

  .app__body .app__body__left .key__statistics .ks__container .ks__container__half{
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .app__body .app__body__left .key__statistics .ks__container .statistics .ks__name{
    font-size: 13px;
  }

  .app__body .app__body__left .key__statistics .ks__container .statistics .ks__value{
    font-size: 15px;
  }

  .app__body .app__body__right{
    width: 100%;
  }

}

.business__news__section {
  width: 100%;
  background-color: transparent;
  z-index: 1000;
  border-radius: 3px;
}

.business__news__section.hide {
  display: none;
}

.business__news__section.open {
  /* transform: translateY(0%); */
  height: 100%;
}

.business__news__section .business__news__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 16px;
  font-weight: 600;
}

.business__news__section .business__news__wrapper .business__news__content {
  width: 100%;
  height: 100%;
  /* box-shadow: 0px 0px 3px #f1f1f1; */
  /* display: none; */
  border-radius: 8px;
}

.business__news__section .business__news__wrapper .business__container {
  width: 100%;
  height: 100%;
  padding: 0 0px;
  display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
}

.business__news__section .business__news__wrapper .business__container__loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.business__news__section .business__news__wrapper .business__container__loader p {
  margin-top: 15px !important;
  font-size: 12px;
  color: var(--font-primary);
}

.business__news__section .business__news__wrapper .business__container__empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* background-color: #470985; */
}

.business__news__section .business__news__wrapper .business__container__empty.hide {
  display: none;
}

.business__news__section .business__news__wrapper .business__container__empty .container__header {
  position: absolute;
  width: 100%;
  height: 10%;
  top: 0;
  left: 0;
}

.business__container__empty .bn__empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.business__container__empty .bn__empty p {
  font-weight: 500 !important;
  font-size: 13px !important;
  margin-top: 20px !important;
}

.business__container__empty .bn__empty .bn__empty__image {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* width:  250px; */
  /* height: 250px; */
}

.business__container__empty .bn__empty .bn__empty__image img {
  height: 100%;
}

.business__news__section .business__news__wrapper .bn__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 240px;
  background-color: var(--white);
  right: 0%;
  top: -1%;
  transform: translateY(-100%);
  padding: 5px 10px;
  /* box-shadow: -2px 2px 2px var(--shadow-primary), -2px -2px 2px var(--shadow-primary); */
  /* box-shadow: -1px -1px 2px #f3f3f3; */
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  opacity: 1;
  z-index: 100000;
}

.business__news__section .business__news__wrapper .bn__title>div {
  width: 50%;
}

.business__news__section .business__news__wrapper .bn__title>div:nth-child(1)>p:first-child {
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
}

.business__news__section .business__news__wrapper .bn__title>div:nth-child(1)>p:last-child {
  width: 100%;
  font-size: 9px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--font-secondary);
  font-weight: 500;
}

.business__news__section .business__news__wrapper .bn__title>div:nth-child(2) {
  align-self: flex-end;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.business__news__section .business__news__wrapper .bn__title>div:nth-child(2)>p {
  align-self: flex-end;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: -1px;
  color: var(--font-primary);
  cursor: pointer;
}

.business__news__section .business__news__wrapper .bn__close {
  position: absolute;
  top: 2%;
  right: 3%;
  transform: translate(50%, 50%);
  display: block;
  cursor: pointer;
}

.business__news__section .business__news__wrapper .bn__close img {
  width: 30px;
}

.business__news__section .business__news__wrapper .bn__close.active {
  display: block;
}

/*Buisness Wrapper Menu */
.container__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  grid-row-gap: 15px;
  row-gap: 15px;
  height: 12%;
}

.container__header .stock__info {
  z-index: 10;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  height: 35px;
}

.container__header .stock__info .stock__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.container__header .stock__info .stock__details p {
  font-size: 13px;
  font-weight: 700;
}


.container__header .stock__info .stock__details .stock__type {
  width: auto;
  height: auto;
  border-radius: 15px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.container__header .stock__info .stock__details .stock__type img {
  width: 14px;
  margin-right: 6px;
}

.container__header .stock__info .stock__details .stock__type p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.container__header .stock__info #stock__full__name {
  font-size: 10px;
  color: var(--font-primary);
  font-weight: 500;
}

.container__header .stock__info .stock__price__purchase {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.container__header .stock__info .stock__price__details {
  display: flex;
  align-items: center;
}

.container__header .stock__info .stock__price__details p span {
  font-size: 16px;
  font-weight: 700;
  margin-right: 2px;
}

.container__header .stock__info .stock__price__details span.price__decimals {
  color: var(--font-primary);
  font-size: 24px;
  font-weight: 900;
}

.container__header .stock__info .stock__price__details span.price__fraction {
  color: var(--font-primary);
  font-size: 14px;
  font-weight: 700;
  align-self: flex-start;
  margin-left: 2px;
}

.container__header .stock__info .stock__price__details span.stock__performance {
  font-size: 12px;
  color: #470985;
}

.container__header .stock__info .stock__price__details p span.clock {
  margin: 0 3px;
  font-size: 12px;
}

.container__header .stock__info .stock__purchase {
  display: flex;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  margin-left: 20px;
}

.container__header .stock__info .stock__purchase>div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container__header .stock__info .stock__purchase>div span {
  text-transform: capitalize;
  font-weight: 500;
}


.container__header .stock__info .stock__price__change {
  display: flex;
  flex-direction: row;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  color: var(--font-primary);
}

.container__header .stock__info .stock__price__change div {
  margin-left: 0px;
  font-weight: 600;
}

.container__header .stock__info .stock__links {
  display: flex;
  align-items: center;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  margin-left: 30px;
}


.container__header .stock__info .stock__links .stock__link__box {
  display: flex;
  align-items: center;
  grid-column-gap: 2px;
  -webkit-column-gap: 2px;
          column-gap: 2px;
  cursor: pointer;
}

.container__header .stock__info .stock__links .stock__link__box>a {
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
  color: var(--font-primary);
  display: flex;
  align-items: center;
  grid-column-gap: 2px;
  -webkit-column-gap: 2px;
          column-gap: 2px;
}

.business__news__section .business__news__wrapper .business__news__menu {
  display: flex;
  align-items: center;
}

.business__news__section .business__news__wrapper .business__news__menu .menu__row {
  display: flex;
  grid-column-gap: 35px;
  -webkit-column-gap: 35px;
          column-gap: 35px;
  padding-top: 0px;
  align-self: center;
  list-style: none;
}

.business__news__section .business__news__wrapper .business__news__menu .menu__row li {
  padding: 0;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0px;
  cursor: pointer;
}

.business__news__section .business__news__wrapper .business__news__menu .menu__row li:hover {
  font-weight: 500;
}

.business__news__section .business__news__wrapper .business__news__menu .menu__row.business__menu li {
  font-size: 13px;
}


.business__news__section .business__news__wrapper .business__news__menu .menu__row.business__menu li.active-nav-0 {
  font-weight: 700;
}

.business__news__section .business__news__wrapper .business__news__menu .menu__row.business__menu li.active-nav-0:hover {
  font-weight: 700;
}

.business__news__section .business__news__wrapper .business__news__box {
  width: 100%;
  height: 88%;
  padding: 0px 0px 0;
  overflow: hidden;
}

.business__news__section .business__news__wrapper .business__news__box .bn__stock__overview__loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business__news__section .business__news__wrapper .business__news__box__loader {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.business__news__section .business__news__wrapper .business__news__box__loader p {
  margin-top: 15px !important;
  font-size: 14px !important;
  color: var(--font-primary);
}

.price__decimals {
  font-size: 14px;
  color: var(--font-primary);
  font-weight: 700;
}

.price__fraction {
  color: var(--font-primary);
  font-size: 14px;
  font-weight: 500;
  align-self: flex-start;
  margin-left: 2px;
}


/* Technicals Section */

.bn__stock__technicals {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bn__stock__technicals .bn__stock__views{
  width: 80%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0px;
  row-gap: 0px;
}

.bn__stock__technicals .bn__stock__target{
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;
  row-gap: 30px;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view {
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0px;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view.small .bn__stock__slider__wrapper {
  width: 100%;
}


.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__view__title,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__view__title {
  font-size: 12px !important;
  font-weight: 600;
  padding-top: 8px;
  color: var(--font-primary);
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__slider,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__slider {
  padding-left: 15px;
  margin: auto 0;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__slider .stock__view__label,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__slider .stock__view__label {
  font-size: 12px;
  font-weight: 600;
  text-align: right;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__text,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__text {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__text p,
.bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__text p {
  font-size: 11px;
  font-weight: 500;
  display: flex;
  color: #909090;
  margin-top: -2px;
}

.bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__text p>span {
  color: #e51a4b;
}

.bn__stock__tech__view__final{
  width: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 5px 10px;
}

.bn__stock__tech__view__final .bn__stock__view__title{
  font-size: 12px;
  font-weight: 600;
}

.bn__stock__tech__view__final .bn__stock__text{
  font-size: 11px;
  font-weight: 500;
  color: #909090;
  margin-top: -2px;
}

.bn__stock__tech__view__final .stock__view__label{
  margin-top: 6px;
  font-size: 14px;
  font-weight: 700;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price {
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 10px 10px;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__genie {
  width: 100%;
}


.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__genie p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value {
  width: 100%;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value .bn__stock__target__empty {
  text-align: center;
  font-size: 11px !important;
  color: var(--main-red)
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p {
  font-size: 12px;
  line-height: 16px;
  color: var(--font-primary);
  font-weight: 500;
  display: flex;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p>span.span__bold {
  font-size: 14px;
  color: var(--font-primary);
  font-weight: 700;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p .bn__stock__target__change.negative {
  color: var(--main-red);
  font-size: 12px;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p .bn__stock__target__change.positive {
  color: var(--main-green);
  font-size: 12px;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p span.bn__stock__target__predictibility {
  font-size: 12px !important;
  font-weight: 500;
  color: var(--font-primary);
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p.bn__stock__target__predictibility.positive span {
  color: var(--main-green);
  font-size: 12px;
}

.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p.bn__stock__target__predictibility.negative span {
  color: var(--main-red);
  font-size: 12px;
}


.bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p.bn__stock__target__predictibility span {
  font-size: 11px !important;
  font-weight: 600;
}


.financials__table thead td {
  text-align: left;
}

.financials__table tr {
  height: 35px !important;
  /* border: 0.5px solid black!important; */
}

/* .financials__table tbody tr:nth-child(odd)
{
  background-color: #fafafa;
}

.financials__table tbody tr:nth-child(even)
{
  background-color: #ffffff;
} */

/* .financials__table tbody tr
{
  border-bottom: 0.5px solid #EAEAEA;
} */

.financials__table thead td {
  font-weight: 600;
  color: #000000;
}

.financials__table td {
  text-align: left;
  color: black;
  border: none !important;
  letter-spacing: -0.2px;
}

.financials__table td.cell__bold {
  /* font-weight: 700; */
  color: var(--font-primary);
}

.financials__table td.cell__blue {
  /* font-weight: 700; */
  color: var(--main-blue);
}

.financials__table td.cell__normal {
  font-weight: 500;
}


.financials__table__data {
  overflow-y: scroll;
}

.credit__ratings {
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  min-width: 80%;
  color: var(--font-primary);
}

.credit__ratings .credit__loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.credit__ratings .credit__empty {
  width: 100%;
  height: 70%;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.credit__ratings .credit__empty p {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: var(--font-primary);
}

.credit__ratings .credit__title {
  font-size: 13px !important;
  color: var(--font-primary);
  font-weight: 700 !important;
  margin-bottom: 10px !important;
}

.bn__stock__credit .bn__credit__block {
  margin: 0px 0 8px 0;
}

.bn__stock__credit .bn__credit__block .bn__credit__title {
  font-size: 12px !important;
  color: var(--font-primary);
  font-weight: 700 !important;
  text-decoration: none;
}

.bn__stock__credit .bn__credit__block .bn__credit__text {
  font-size: 12px !important;
  color: var(--font-primary);
  font-weight: 500 !important;
  margin-top: -2px !important;
}

.bn__stock__financial__chart {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.financials__table__data__loader {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media only screen and (max-width: 768px) {
  .business__news__section {
    border-radius: 3px;
    z-index: 100000;
  }

  .business__news__section .business__news__wrapper {
    font-size: 12px;
    font-weight: 600;
  }

  .business__news__section .business__news__wrapper .business__news__content {
    width: 100%;
    height: 100%;
  }

  .business__news__section .business__news__wrapper .business__container {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    padding: 0 10px;
  }

  .business__news__section .business__news__wrapper .business__container__loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .business__news__section .business__news__wrapper .business__container__loader p {
    margin-top: 10px !important;
    font-size: 12px;
    font-weight: 700;
    color: var(--font-primary);
  }

  .business__news__section .business__news__wrapper .bn__title {
    width: 220px;
    right: 2%;
    top: 0%;
    transform: translateY(-100%);
    padding: 9px 10px;
    border-radius: 30px;
    display: none;
  }

  .business__news__section .business__news__wrapper .bn__title>div {
    width: 50%;
  }

  .business__news__section .business__news__wrapper .bn__title>div:nth-child(1)>p:first-child {
    font-size: 11px;
    font-weight: 700;
  }

  .business__news__section .business__news__wrapper .bn__title>div:nth-child(1)>p:last-child {
    width: 100%;
    font-size: 8px;
  }

  .business__news__section .business__news__wrapper .bn__title>div:nth-child(2)>p {
    align-self: center;
    font-size: 11px;
    font-weight: 700;
    color: var(--font-primary);
    cursor: pointer;
  }

  .business__news__section .business__news__wrapper .bn__close {
    position: absolute;
    top: 2%;
    right: 6%;
    transform: translate(50%, 50%);
    margin-left: 4px;
    display: block;
    cursor: pointer;
    z-index: 10000;
  }

  .business__news__section .business__news__wrapper .bn__close img {
    width: 30px;
  }

  .business__news__section .business__news__wrapper .bn__close.active {
    display: block;
  }



  /*Buisness Wrapper Menu */
  .container__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    /* background-color: #BBBBBB; */
    height: 20%;
  }

  .container__header .stock__info {
    z-index: 10;
    padding-top: 10px;
    flex: 1 1;
  }

  .container__header .stock__info .stock__details {
    display: flex;
  }

  .container__header .stock__info .stock__details p {
    font-size: 14px;
    /* font-weight: 700; */
    color: var(--font-primary);
  }


  .container__header .stock__info .stock__details .stock__type {
    width: auto;
    height: auto;
    border-radius: 0px;
    margin: 0 0px;
    margin-left: 30px;
  }

  .container__header .stock__info .stock__details .stock__type img {
    width: 12px;
  }

  .container__header .stock__info .stock__details .stock__type p {
    font-size: 10px;
  }

  .container__header .stock__info #stock__full__name {
    font-size: 10px;
    /* color: var(--font-secondary); */
    /* font-weight: 600; */
  }

  .container__header .stock__info .stock__price__purchase {
    display: flex;
    /* align-items: center; */
    margin-top: 0px;
  }

  .container__header .stock__info .stock__price__details {
    display: flex;
    align-items: center;
  }

  .container__header .stock__info .stock__price__details p span {
    font-size: 12px;
    font-weight: 700;
    margin-right: 2px;
  }

  .container__header .stock__info .stock__price__details span.stock__performance {
    font-size: 10px;
    color: var(--font-primary);
  }

  .container__header .stock__info .stock__price__details p span.clock {
    margin: 0 3px;
    font-size: 12px;
  }

  .container__header .stock__info .stock__purchase {
    display: flex;
    /* margin-left: 30px; */
  }

  .container__header .stock__info .stock__price__change {
    display: flex;
    font-size: 12px;
    margin-top: 0;
    color: var(--font-primary);
  }

  .container__header .stock__info .stock__price__change>div {
    margin-left: 1px;
  }



  .business__news__section .business__news__wrapper .business__news__menu {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: none; */
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .business__news__section .business__news__wrapper .business__news__menu .row {
    display: none;
  }

  .business__news__section .business__news__wrapper .business__news__menu .menu__row {
    display: flex;
    padding: 5px 0;
    align-self: center;
    list-style: none;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }



  .business__news__section .business__news__wrapper .business__news__menu .menu__row li {
    padding: 0;
    font-size: 12px;
    margin: 0 4px;
    cursor: pointer;
  }

  .business__news__section .business__news__wrapper .business__news__menu .menu__row.business__menu li {
    font-size: 14px;
  }

  .business__news__section .business__news__wrapper .business__news__menu .menu__row.business__menu li>div>span {
    font-size: 8px !important;
    display: none;
  }

  .business__news__section .business__news__wrapper .business__news__box {
    width: 100%;
    height: 80%;
    padding: 10px 0px 20px;
    overflow-y: scroll;
    /* background-color: #BBBBBB; */
    overflow-x: hidden;
    margin-top: 10px;
  }

  .price__decimals {
    font-size: 24px;
    color: var(--font-primary);
  }

  .price__fraction {
    color: var(--font-primary);
    font-size: 12px;
    align-self: flex-start;
    margin-left: 2px;
  }


  /* Technicals Section */

  .bn__stock__technicals {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .bn__stock__technicals .bn__stock__views,
  .bn__stock__technicals .bn__stock__target {
    width: 100%;
    height: 100%;
  }

  .bn__stock__technicals .bn__stock__target {
    padding-bottom: 20px;
  }

  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view {
    width: 100%;
    height: 100px;
    display: flex;
  }

  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper {
    width: 75%;
    padding: 2px 5px;
  }

  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper .MuiSlider-root,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper .MuiSlider-root {
    width: 180px !important;
  }

  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view.small .bn__stock__slider__wrapper {
    width: 100%;
  }


  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__view__title,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__slider__wrapper .bn__stock__view__title {
    font-size: 12px !important;
  }


  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__text,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__text {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bn__stock__technicals .bn__stock__views .bn__stock__tech__view .bn__stock__text p,
  .bn__stock__technicals .bn__stock__target .bn__stock__tech__view .bn__stock__text p {
    font-size: 10px !important;
    margin: 5px 0 !important;
    text-align: center;
  }


  .bn__stock__technicals .bn__stock__target .bn__stock__target__price {
    padding: 5px 2px;
    min-height: 250px;
    text-align: center;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__genie {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__genie img {
    width: 50px;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__genie p {
    font-size: 14px !important;
    margin-top: 10px !important;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value .bn__stock__target__empty {
    text-align: center;
    font-size: 11px !important;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p {
    text-align: center;
    font-size: 11px !important;
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p>span {
    font-size: 18px !important;
  }


  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p.bn__stock__target__predictibility {
    font-size: 11px !important;
    font-weight: 600;
    color: var(--font-primary);
  }

  .bn__stock__technicals .bn__stock__target .bn__stock__target__price .bn__stock__target__value p.bn__stock__target__predictibility span {
    font-size: 11px !important;
    font-weight: 600;
  }


  .financials__table thead td {
    text-align: left;
  }

  .financials__table tr {
    height: 35px !important;
    /* border: 0.5px solid black!important; */
  }

  .financials__table thead td {
    font-weight: 600;
  }

  .financials__table td {
    text-align: left;
    color: black;
    border: none !important;
  }

  .financials__table td.cell__bold {
    color: var(--font-primary);
  }

  .financials__table td.cell__blue {
    color: var(--main-blue);
  }



  .financials__table__data {
    overflow-y: scroll;
  }

  .credit__ratings .credit__title {
    font-size: 12px !important;
  }

  .bn__stock__credit .bn__credit__block {
    margin: 0px 0 8px 0;
  }

  .bn__stock__credit .bn__credit__block .bn__credit__title {
    font-size: 11px !important;
  }

  .bn__stock__credit .bn__credit__block .bn__credit__text {
    font-size: 11px !important;
    font-weight: 500 !important;
  }

  .bn__stock__financial__chart {
    width: 100%;
    min-height: 100px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .financials__table__data__loader {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.smallcase__configure__popup
{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000000;
    width: 100%;
    height: 100%;
}
.smallcase__configure__popup .sc__popup__overlay
{
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.60);
    display: flex;
    justify-content: center;
    align-items: center;
}
.smallcase__configure__popup .sc__popup__overlay .sc__popup__content
{
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    box-shadow: 0 0 8px #e8e8e8;
    min-width: 500px;
    height: 60%;
    min-height: 200px;
    border-radius: 5px;
    padding: 0px 20px;
    animation: getup 0.1s linear forwards;
}

@keyframes getup
{
    0%
    {
        transform: translateY(8px);
    }
    100%
    {
        transform: translateY(0px);
    }
}

.sc__popup__content .sc__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;
    border-bottom: 1px solid #f3f3f3;
}

.sc__popup__content .sc__header .heading>p
{
    color: var(--font-primary);
    font-weight: 500;
    font-size: 14px;
}

.sc__popup__content .sc__header .close
{
    cursor: pointer;
}

.sc__popup__content .sc__header .close img
{
    width: 30px;
}

.sc__popup__content .sc__body
{
    width: 100%;
    height: 70%;
}

.sc__popup__content .sc__body .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}
.sc__popup__content .sc__body .row .custom-select .custom-select-container {
    max-height: 200px;
    overflow: scroll;
}
.sc__popup__content .sc__body .row input {
    height: 40px;
    width: 240px;
    border-radius: 3px;
    border: none;
    outline: none;
    border: 1px solid #e8e8e8;
    padding: 0px 10px;
    font-size: 12px;
}

.sc__popup__content .sc__body .row>span,
.sc__popup__content .sc__body .row>div>p
{
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 500;
}

.sc__popup__content .sc__footer {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.sc__popup__content .sc__footer .apply__button {
    display: flex;
    justify-content: center;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    font-size: 12px;
    padding: 8px 15px;
    color: var(--font-primary);
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
}

@media only screen and (max-width:768px)
{

   
    .smallcase__configure__popup .sc__popup__overlay .sc__popup__content
    {
        min-width: 350px;
        width: 95%;
        height: 60%;
        min-height: 400px;
    }

    
    .sc__popup__content .sc__header .heading>p
    {
        font-weight: 500;
    }
      
    .sc__popup__content .sc__body .row input {
        height: 40px;
        width: 240px;
    }

}
.custom-select {
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row-gap: 3px;
  row-gap: 3px;
  width: 100%;
}

.custom-select .select-title {
  font-size: 12px;
  font-weight: 500;
}

.custom-select .custom-select-header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  height: 40px;
  position: relative;
  cursor: pointer;
}

.custom-select .custom-select-header .custom-select-title {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.custom-select .custom-select-header .custom-width-select-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.custom-select .custom-select-header .custom-width-select-title > p {
  width: 60%;
  background-color: #404040;
  border-radius: 10px;
}

.custom-select .custom-select-container {
  /* padding: 0px 3px; */
  width: 100%;
  position: absolute;
  top: 105%;
  left: 0%;
  border-radius: 3px;
  z-index: 1000;
  border: 1px solid #f5f5f5;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 8px 0px;
  background-color: var(--white);
  padding: 10px 4px 10px;
}
.custom-select .overflow {
  max-height: 175px;
  overflow-y: auto;
}

.custom-select .custom-select-container .custom-select-option {
  display: block;
  font-size: 12px;
  font-weight: 600;
  padding: 12px 12px 12px 16px;
  border-radius: 3px;
  cursor: pointer;
  border-bottom: 1px solid #f5f5f5;
}

.custom-select .custom-select-container .custom-width-select-option {
  font-size: 13px;
  font-weight: 600;
  padding: 5px 10px;
  margin: 1px 0;
  border-radius: 3px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-select .custom-select-container .custom-width-select-option > p {
  border-radius: 10px;
  transition: 0.3s;
}

.custom-select .custom-select-container .custom-select-option.option-selected,
.custom-select
  .custom-select-container
  .custom-width-select-option.option-selected {
  color: var(--font-primary);
}

.custom-select
  .custom-select-container
  .custom-width-select-option.option-selected
  > p {
  background-color: #ffffff;
}

.custom-select
  .custom-select-container
  .custom-select-option.option-selected:hover,
.custom-select
  .custom-select-container
  .custom-width-select-option.option-selected:hover {
  background-color: #f9f9f9;
}

.custom-select
  .custom-select-container
  .custom-width-select-option.option-selected:hover
  > p {
  background-color: #ffffff;
}

.custom-select .custom-select-container .custom-select-option:hover {
  background-color: #f9f9f9;
  color: var(--font-primary);
}

.custom-select .custom-select-container .custom-width-select-option:hover {
  background-color: #f9f9f9;
  color: var(--font-primary);
}



@media only screen and (max-width: 768px){

  .custom-select{
    grid-row-gap: 8px;
    row-gap: 8px;
  }

  .custom-select .select-title{
    font-size: 14px;
    font-weight: 500;
  }

  .custom-select .custom-select-header .custom-select-title{
    font-size: 14px !important;
  }

  .custom-select .custom-select-container .custom-select-option {
    font-size: 14px;
  }

}
.smallcase__strategy__details {
  position: relative;
}

.smallcase__container {
  width: 100%;
  height: 100%;
}

.smallcase__container.loader{
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallcase__container .smallcase__header {
  width: 100%;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
}

.smallcase__header .smallcase__title > img {
  width: 20px;
}

.smallcase__header .smallcase__title > span {
  margin-left: 0px;
  font-size: 16px;
  color: var(--font-primary);
  font-weight: 700;
}

.smallcase__header .smallcase__subtext {
  font-size: 20px;
  font-weight: 300;
}

.smallcase__header .smallcase__menu {
  display: flex;
}

.smallcase__header .smallcase__menu .smallcase__stratergy__button {
  background-color: var(--white);
  padding: 7px 15px 7px 15px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #e8e8e8;
  color: var(--font-primary);
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
  margin-left: 10px;
}

.smallcase__header .smallcase__menu .smallcase__stratergy__button:hover {
  opacity: 0.9;
}

.smallcase__content__wrapper {
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  height: 100%;
}

.smallcase__left {
  width: 340px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.smallcase__strategies__content {
  width: 100%;
  height: 100%;
  padding: 10px 0px;
}

.smallcase__personal__strategies {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.smallcase__public__strategies {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.personal__strategies__header,
.smallcase__public__strategies .public__strategies__header {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  height: 20px;
}

.personal__strategies__header {
  margin-top: 0px;
  justify-content: space-between;
  padding: 20px 15px 15px;
}

.personal__strategies__header>p{
  font-size: 14px;
  font-weight: 600;
}

.personal__strategies__header > div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.personal__strategies__header .strategy__menu {
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.personal__strategies__header .strategy__menu > p,
.public__strategies__header .strategy__menu > p {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.personal__strategies__header .strategy__menu > p.active,
.public__strategies__header .strategy__menu > p.active {
  font-weight: 700;
}

.smallcase__personal__strategies .personal__strategies__content,
.smallcase__public__strategies .public__strategies__content {
  width: 100%;
  /* height: calc(100% - 25px); */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.smallcase__personal__strategies .personal__strategies__content {
  height: 100%;
}

.smallcase__left .smallcase__strategy__wrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-radius: 5px;
  padding: 0px 10px 15px 10px;
  display: flex;
  flex-direction: column;
}

.smallcase__left .smallcase__public__strategies .smallcase__strategy__wrapper {
  height: 85%;
}

.smallcase__search__wrapper {
  padding: 0px 10px;
  width: 100%;
}

.smallcase__search__wrapper .smallcase__search__box {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 3px;
  border: 1px solid #f5f5f5;
}

.smallcase__search__wrapper .smallcase__search__box > img {
  width: 16px;
}

.smallcase__search__wrapper .smallcase__search__box > input {
  width: 85%;
  height: 100%;
  border: none;
  outline: none;
  margin-left: 0px;
  font-size: 12px;
  font-weight: 600;
  padding-left: 0px;
  background-color: transparent;
  color: var(--font-primary);
}

.smallcase__search__wrapper .smallcase__search__box > input::-webkit-input-placeholder {
  color: #808080;
  font-weight: 500;
}

.smallcase__search__wrapper .smallcase__search__box > input:-ms-input-placeholder {
  color: #808080;
  font-weight: 500;
}

.smallcase__search__wrapper .smallcase__search__box > input::placeholder {
  color: #808080;
  font-weight: 500;
}

.smallcase__right {
  width: 960px;
  padding: 0px 0px;
  height: 100%;
  overflow-y: scroll;
}

.smallcase__right .smallcase__strategy__wrapper,
.smallcase__right .smallcase__strategy__details {
  border-radius: 5px;
  overflow-y: scroll;
  height: 100%;
}

.smallcase__right .smallcase__strategy__wrapper {
  width: 30%;
}

.smallcase__right .smallcase__strategy__wrapper::-webkit-scrollbar {
  display: none;
}

.smallcase__right .smallcase__strategy__details {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 15px 15px;
}

.smallcase__strategy__details .strategy__section {
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.smallcase__right .smallcase__strategy__details.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  grid-row-gap: 5px;
  row-gap: 5px;
  padding-top: 100px;
}

.smallcase__right .smallcase__strategy__details.loader > p {
  font-size: 14px;
  font-weight: 600;
}

.smallcase__right .smallcase__strategy__details.loader > h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--font-primary);
}

.smallcase__right .smallcase__strategy__details.loader > span {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.smallcase__right .smallcase__strategy__details.loader .create__smallcase {
  padding: 8px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  color: var(--font-primary);
  margin-top: 15px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.smallcase__strategy__wrapper .smallcase__strategy__card {
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
}

.smallcase__strategy__card .strategy__info,
.smallcase__strategy__card .strategy__data,
.smallcase__strategy__card .strategy__options {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px;
}

.smallcase__strategy__card .strategy__data {
  flex-direction: column;
  align-items: flex-start;
}

.strategy__icon__box {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.strategy__icon__box img {
  width: 20px;
}

.strategy__icon__box span {
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
}

.strategy__info .strategy__headings__box {
  width: 100%;
  padding: 10px 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.strategy__info .strategy__headings__box:hover {
  background-color: #fafafa;
}

.strategy__headings__box .strategy__heading {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
  cursor: pointer;
}

.strategy__headings__box .strategy__news {
  font-size: 11px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.strategy__data .strategy__data__table {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: 0 0 2px #e8e8e8; */
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 5px 10px;
}

.strategy__data__table > div .title {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.strategy__data__table > div .value {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin-top: 5px !important;
  color: var(--font-primary);
}

.smallcase__strategy__card .strategy__options {
  justify-content: space-between;
  margin-top: 20px;
}

.smallcase__strategy__card .strategy__options > p {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.smallcase__strategy__card .strategy__options > p:hover {
  color: var(--font-primary);
}

.smallcase__strategy__card .strategy__options .strategy__buy__button {
  background-color: var(--white);
  padding: 6px 18px 6px 18px;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  transition: 0.3s;
  cursor: pointer;
}

.smallcase__strategy__card .strategy__options .strategy__buy__button:hover {
  background-color: #f8f8f8;
}

.smallcase__strategy__details .startegy__details__close {
  display: none;
}

.smallcase__strategy__details .strategy__details__box {
  width: 100%;
}

.smallcase__strategy__details .strategy__name__wrapper {
  display: flex;
  justify-content: space-between;
}

.smallcase__strategy__details .strategy__configure {
  /* border: solid 1px #e8e8e8; */
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  color: var(--font-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.strategy__details__box .details__header {
  display: flex;
  align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.strategy__details__box .details__header > img {
  width: 20px;
}

.strategy__details__box .details__header > span {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.strategy__details__box .details__header .full__portfolio {
  font-size: 14px;
  font-style: italic;
  cursor: pointer;
}

.strategy__details__box .details__content > p {
  font-size: 14px;
  font-weight: 500;
  color: var(--font-primary);
}

.strategy__details__box .details__content.strategy__name > p {
  color: var(--font-primary);
}

.strategy__details__box .details__content.details__chart {
  margin-top: 10px;
}

.strategy__details__box .strategy__stocks {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.strategy__details__box .stocks__table {
  width: 100%;
  border-radius: 15px;
  margin-top: 10px;
  border: 1px solid #f5f5f5;
}

.strategy__details__box .stocks__table table {
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  border-collapse: collapse;
}

.strategy__details__box .stocks__table table tr td {
  font-size: 11px;
  font-weight: 500;
  padding: 8px 12px;
}

.strategy__details__box .stocks__table table thead tr td {
  font-weight: 700;
}

.strategy__details__box .stocks__table table thead tr {
  border-bottom: 1px solid #f5f5f5;
}

.strategy__details__box .stocks__table table tr td:nth-child(2) {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.strategy__details__box .stocks__table table tr td:nth-child(5) {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.strategy__details__box .stocks__table table tbody tr td:nth-child(5) {
  text-transform: uppercase;
}

.strategy__details__box .strategy__stocks .stock__logo__name {
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  margin: 10px 0px 0px 0px;
}

.strategy__stocks .stock__logo__name .logo__image {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f5f5f5;
  border-radius: 50%;
  overflow: hidden;
}

.strategy__stocks .stock__logo__name .logo__image img {
  width: 100%;
  object-fit: contain;
}

.strategy__stocks .stock__logo__name .logo__image span {
  font-size: 16px;
  font-weight: 700;
  font-family: "Satisfy", cursive;
}

.strategy__stocks .stock__logo__name span {
  font-size: 11px;
  color: var(--font-primary);
  border-radius: 3px;
  font-weight: 500;
}

.smallcase__strategy__details .strategy__charts {
  width: 100%;
  display: flex;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
}

.strategy__charts .strategy__details__box {
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
  padding: 10px 0 20px;
  border-radius: 15px;
  width: 580px;
}

.strategy__charts .strategy__details__box .details__header {
  text-align: center;
}

.strategy__details__box .strategy__sector__chart {
  width: 100%;
  height: 300px;
  border-radius: 5px;
}

.details__content.details__chart .strategy__data__table {
  display: flex;
  justify-content: space-around;
  box-shadow: 0px 1px 3px #e8e8e8, 0px -1px 1px #f7f7f7;
  border-radius: 3px;
  padding: 5px 0px;
}

.strategy__chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #f5f5f5;
  padding: 10px 15px;
  margin-top: 20px;
  border-radius: 5px;
  height: 350px;
}

.smallcase__strategy__container {
  width: 100%;
  height: 100%;
  font-size: 12px;
}

.smallcase__strategy__header {
  width: 100%;
  padding-bottom: 0px;
}

.smallcase__strategy__header > span {
  font-size: 13px;
  font-weight: 800;
}

.smallcase__strategy__header .strategy__name__icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.smallcase__strategy__header .strategy__options {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-column-gap: 25px;
  -webkit-column-gap: 25px;
          column-gap: 25px;
}

.smallcase__strategy__header .strategy__options > span {
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s;
  color: var(--font-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.smallcase__strategy__header .strategy__options > span:hover {
  color: var(--font-primary);
}

.smallcase__strategy__header .strategy__name {
  font-size: 14px;
  font-weight: 700;
}

.smallcase__strategy__header .strategy__info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.strategy__info .strategy__text {
  width: 95%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.strategy__info .strategy__text p {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  grid-row-gap: 2px;
  row-gap: 2px;
}

.strategy__info .strategy__text p > span {
  font-size: 13px;
  font-weight: 600;
}

.strategy__info .strategy__numbers {
  width: 260px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
}

.strategy__info .strategy__numbers > div {
  display: flex;
  flex-direction: row;
}

.strategy__info .strategy__numbers > div .numbers__box:nth-child(1) {
  flex: 4 1;
}

.strategy__info .strategy__numbers > div .numbers__box:nth-child(2) {
  flex: 2 1;
}

.strategy__numbers > div .numbers__box span {
  font-size: 12px;
  font-weight: 500;
}

.strategy__numbers > div .numbers__box p {
  font-size: 16px;
  font-weight: 800;
  margin-top: -3px;
}

.smallcase__strategy__body__wrapper {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.smallcase__strategy__body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  margin-top: 5px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.smallcase__strategy__body.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.smallcase__strategy__body.loader .loading__text {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary) !important;
}

.smallcase__strategy__body .strategy__title {
  font-size: 12px;
  font-weight: 700;
}

.smallcase__strategy__body .smallcase__strategy__form {
  width: 30%;
  height: 90%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 15px;
}

.smallcase__strategy__body .smallcase__strategy__right {
  width: 70%;
  height: 100%;
}

.smallcase__strategy__body .smallcase__strategy__table {
  width: 100%;
  height: 90%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

.smallcase__strategy__body .smallcase__strategy__form .form__row {
  width: 100%;
  display: flex;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.smallcase__strategy__form .strategy__select__container {
  padding: 0 0px;
}

.strategy__select__container .strategy__select__header {
  display: flex;
  align-items: center;
}

.strategy__select__container .strategy__select__header > img {
  width: 20px;
}

.strategy__select__container .strategy__select__header > span {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 500;
  text-transform: capitalize;
}

.strategy__select__container .strategy__select__box {
  margin-top: 5px;
}

.strategy__select__container .strategy__text__box,
.strategy__select__container .strategy__input__box {
  width: 450px;
  margin-top: 5px;
  border-radius: 3px;
  border: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.strategy__select__container .strategy__text__box textarea {
  width: 100%;
  border: none;
  outline: none;
  min-height: 100px;
  resize: none;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  padding-top: 5px;
  background-color: transparent;
  padding-left: 10px;
}

.strategy__select__container .strategy__input__box input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  min-height: 40px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  background-color: transparent;
}

.strategy__select__container .strategy__text__box textarea::-webkit-input-placeholder, .strategy__select__container .strategy__input__box input::-webkit-input-placeholder {
  font-weight: 500;
  color: #909090;
}

.strategy__select__container .strategy__text__box textarea:-ms-input-placeholder, .strategy__select__container .strategy__input__box input:-ms-input-placeholder {
  font-weight: 500;
  color: #909090;
}

.strategy__select__container .strategy__text__box textarea::placeholder,
.strategy__select__container .strategy__input__box input::placeholder {
  font-weight: 500;
  color: #909090;
}

.strategy__select__container .errSpan {
  color: var(--font-primary);
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  display: block;
}

.strategy__select__container .errSpan.hide {
  opacity: 0;
}

.smallcase__strategy__container .smallcase__strategy__footer {
  margin-top: 5px;
  padding: 5px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  width: 100%;
}

.smallcase__strategy__footer .strategy__footer__button {
  background-color: var(--white);
  border: 1px solid #f5f5f5;
  padding: 5px 25px 5px 25px;
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
}

.smallcase__strategy__footer .strategy__footer__button:hover {
  opacity: 0.9;
}

.strategy__select__box .custom-select {
  width: 300px !important;
  box-shadow: none;
  border-radius: 3px;
}

.strategy__select__box .custom-select .custom-select-container {
  min-width: 100% !important;
}

.strategy__portfolio__table__wrapper {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.strategy__portfolio__table__wrapper .strategy__portfolio__table {
  width: 100%;
  height: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  overflow-y: scroll;
}

.strategy__portfolio__table table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.strategy__portfolio__table table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.strategy__portfolio__table table thead td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
}

.strategy__portfolio__table table tbody td {
  text-align: left;
  padding: 8px 10px 8px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.strategy__portfolio__table table thead td.symbol {
  width: 160px;
}

/* .strategy__portfolio__table table thead td.exchange{
    width: 140px;
} */

.strategy__portfolio__table table thead td.remove {
  width: 80px;
  text-align: right;
}

.strategy__portfolio__table table tbody td .input-box {
  width: 140px;
  height: 35px;
  display: flex;
  flex-direction: row;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0 5px;
}

.strategy__portfolio__table table tbody td .input-box input {
  width: 100px;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 6px 0 6px 4px;
  cursor: text;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s;
  color: var(--font-primary);
  border: 1px solid #f5f5f5;
  text-align: center;
  border-radius: 5px;
}

/* .strategy__portfolio__table table tbody td .input-box .input-arrows{
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0px;
} */

.strategy__portfolio__table table tbody td .input-box span {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f5f5f5;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.strategy__portfolio__table table tbody td input:focus {
  border: 1px solid #d8d8d8;
}

.strategy__portfolio__table table td:first-child {
  font-weight: 600;
}

.strategy__portfolio__table table td:last-child {
  text-align: center;
}

.strategy__portfolio__table table td span.remove {
  cursor: pointer;
}

.strategy__portfolio__table table td span.remove img {
  width: 12px;
}

.strategy__portfolio__table .strategy__portfolio__table__header,
.strategy__portfolio__table .strategy__portfolio__table__body,
.strategy__portfolio__table .strategy__portfolio__table__footer {
  width: 100%;
}

.strategy__portfolio__table .strategy__portfolio__table__header {
  height: 10%;
}

.strategy__portfolio__table .strategy__portfolio__table__footer {
  height: 10%;
}

.strategy__portfolio__table .strategy__portfolio__table__body {
  height: 90%;
  overflow-y: scroll;
}

.strategy__portfolio__table__header .table__row,
.strategy__portfolio__table__body .table__row,
.strategy__portfolio__table__footer .table__row {
  width: 100%;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}

.strategy__portfolio__table__header .edit__table__row,
.strategy__portfolio__table__body .edit__table__row,
.strategy__portfolio__table__footer .edit__table__row {
  width: 100%;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.strategy__portfolio__table__header .table__row,
.strategy__portfolio__table__header .edit__table__row {
  border-bottom: 1px solid #f5f5f5;
  font-size: 12px;
}

.strategy__portfolio__table__footer .table__row,
.strategy__portfolio__table__footer .edit__table__row {
  font-size: 12px;
  border-top: 1px solid #f5f5f5;
}

.strategy__portfolio__table__header .table__row > div,
.strategy__portfolio__table__footer .table__row > div,
.strategy__portfolio__table__header .edit__table__row > div,
.strategy__portfolio__table__footer .edit__table__row > div {
  font-weight: 500;
}

.strategy__portfolio__table__body .table__row:hover,
.strategy__portfolio__table__body .edit__table__row:hover {
  background-color: #fcfcfc;
  border-radius: 3px;
}

.strategy__portfolio__table__header .table__row > div:nth-child(1),
.strategy__portfolio__table__body .table__row > div:nth-child(1),
.strategy__portfolio__table__footer .table__row > div:nth-child(1) {
  flex: 4 1;
  font-size: 11px;
  font-weight: 700;
}

.strategy__portfolio__table__header .edit__table__row > div:nth-child(1),
.strategy__portfolio__table__body .edit__table__row > div:nth-child(1),
.strategy__portfolio__table__footer .edit__table__row > div:nth-child(1) {
  flex: 4 1;
  padding: 5px 0;
  color: var(--font-primary);
  font-size: 12px;
  font-weight: 500;
}

.strategy__portfolio__table__header .table__row > div:nth-child(2),
.strategy__portfolio__table__body .table__row > div:nth-child(2),
.strategy__portfolio__table__footer .table__row > div:nth-child(2) {
  flex: 4 1;
  font-size: 11px;
  font-weight: 700;
}

.strategy__portfolio__table__header .edit__table__row > div:nth-child(2),
.strategy__portfolio__table__body .edit__table__row > div:nth-child(2),
.strategy__portfolio__table__footer .edit__table__row > div:nth-child(2) {
  flex: 4 1;
  /* background-color: #d4e300; */
}

.strategy__portfolio__table__header .table__row > div:nth-child(3),
.strategy__portfolio__table__body .table__row > div:nth-child(3),
.strategy__portfolio__table__footer .table__row > div:nth-child(3) {
  flex: 5 1;
  font-size: 11px;
  font-weight: 700;
  /* display: flex; */
  /* justify-content: center; */
}

.strategy__portfolio__table__header .edit__table__row > div:nth-child(3),
.strategy__portfolio__table__body .edit__table__row > div:nth-child(3),
.strategy__portfolio__table__footer .edit__table__row > div:nth-child(3) {
  flex: 2 1;
}

.strategy__portfolio__table__header .table__row > div:nth-child(4),
.strategy__portfolio__table__body .table__row > div:nth-child(4),
.strategy__portfolio__table__footer .table__row > div:nth-child(4) {
  flex: 1 1;
  display: flex;
  justify-content: center;
}

.strategy__portfolio__table__header .edit__table__row > div:nth-child(4),
.strategy__portfolio__table__body .edit__table__row > div:nth-child(4),
.strategy__portfolio__table__footer .edit__table__row > div:nth-child(4) {
  flex: 4 1;
  /* display: flex; */
  /* justify-content: center; */
}

.strategy__portfolio__table__header .edit__table__row > div:nth-child(5),
.strategy__portfolio__table__body .edit__table__row > div:nth-child(5),
.strategy__portfolio__table__footer .edit__table__row > div:nth-child(5) {
  flex: 1 1;
  display: flex;
  justify-content: center;
}

.strategy__portfolio__table__body .table__row > div > input,
.strategy__portfolio__table__body .edit__table__row > div > input {
  width: 50%;
  height: 100%;
  border: none;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  outline: none;
  background-color: transparent;
  padding: 6px 0 6px 10px;
  cursor: text;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s;
  color: var(--font-primary);
}

.strategy__portfolio__table__body .table__row > div .stock__remove,
.strategy__portfolio__table__body .edit__table__row > div .stock__remove {
  padding: 5px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.strategy__portfolio__table__body .table__row > div .stock__remove:hover,
.strategy__portfolio__table__body .edit__table__row > div .stock__remove:hover {
  background-color: #bce8ff;
}

.strategy__portfolio__table__body .table__row > div .stock__remove img {
  width: 10px;
  height: 10px;
}

.strategy__portfolio__table__body .table__row > div > input:focus,
.strategy__portfolio__table__body .edit__table__row > div > input:focus {
  border: 1px solid #d8d8d8;
}

.strategy__portfolio__table .strategy__table__stock {
  display: flex;
  flex-direction: column;
}

.strategy__table__stock .strategy__table__stock__code {
  font-size: 11px;
  color: var(--font-primary);
  font-weight: 600;
}

.strategy__table__stock .strategy__table__stock__name {
  max-width: 200px;
  color: var(--font-primary);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.strategy__portfolio__table__wrapper .strategy__performance__buttons {
  display: flex;
  width: 100%;
}

.strategy__portfolio__table__wrapper .strategy__performance__buttons > div {
  padding: 7px 15px 7px 15px;
  font-size: 12px;
  margin: 0 10px 0 0;
  font-weight: 700;
  color: var(--font-primary);
  background-color: var(--white);
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
}

.strategy__portfolio__table__wrapper
  .strategy__performance__buttons
  > div:hover {
  opacity: 0.9;
}

.strategy__portfolio__table__wrapper
  .strategy__performance__buttons
  > div
  > img {
  width: 10px;
  margin-left: 4px;
}

.strategy__portfolio__stock__wrapper {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.strategy__portfolio__stock__wrapper .strategy__portfolio__stock__options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.strategy__portfolio__stock__wrapper .stock__search__icon img {
  width: 14px;
}

.strategy__portfolio__stock__wrapper > img {
  width: 60%;
}

.strategy__portfolio__stock__wrapper .stock__search {
  width: 500px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  position: relative;
}

.strategy__portfolio__stock__wrapper .stock__search .stock__search__icon {
  width: 20px;
  margin-right: 10px;
}

.strategy__portfolio__stock__wrapper .stock__search input {
  width: 90%;
  height: 100%;
  font-size: 12px;
  text-transform: uppercase;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--font-primary);
  font-weight: 600;
}

.strategy__portfolio__stock__wrapper .stock__search input::-webkit-input-placeholder {
  text-transform: capitalize;
}

.strategy__portfolio__stock__wrapper .stock__search input:-ms-input-placeholder {
  text-transform: capitalize;
}

.strategy__portfolio__stock__wrapper .stock__search input::placeholder {
  text-transform: capitalize;
}

.strategy__portfolio__stock__wrapper .stock__suggestions {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  background-color: white;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 5px 0px;
  z-index: 1000;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0px;
  grid-row-gap: 0px;
  row-gap: 0px;
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
}

.strategy__portfolio__stock__wrapper .stock__suggestions .search__loader {
  height: 200px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.strategy__portfolio__stock__wrapper .stock__suggestions .search__loader > p {
  margin-top: 10px !important;
  font-size: 14px;
  font-weight: 500;
}

.strategy__portfolio__stock__wrapper .stock__suggestions > p {
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.strategy__portfolio__stock__wrapper .stock__suggestions > p:hover {
  background-color: #f5f5f5;
}

.strategy__portfolio__stock__wrapper
  .stock__suggestions
  > p
  > span:nth-child(1) {
  flex: 4 1;
  font-size: 12px;
  font-weight: 500;
}

.strategy__portfolio__stock__wrapper
  .stock__suggestions
  > p
  > span:nth-child(2) {
  flex: 9 1;
  font-size: 12px;
}

.strategy__portfolio__stock__wrapper
  .stock__suggestions
  > p
  > span:nth-child(3) {
  flex: 1 1;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
}

.strategy__portfolio__stock__wrapper
  .stock__suggestions
  > p
  > span
  .search__highlight {
  font-weight: 500;
}

.strategy__portfolio__stock__options {
  display: flex;
  flex-direction: row;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.strategy__portfolio__stock__options > span {
  background-color: var(--white);
  color: var(--font-secondary);
  padding: 7px 5px 7px 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
}

.strategy__portfolio__stock__options > span > a {
  color: var(--font-secondary);
  text-decoration: none;
}

.strategy__portfolio__stock__options > span:hover,
.strategy__portfolio__stock__options > span a:hover {
  color: var(--font-primary);
}

.smallcase__success__page {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.smallcase__success__page .success__message {
  font-size: 14px;
  color: var(--font-primary);
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 25px;
}

.smallcase__container__empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 30px;
  row-gap: 30px;
  padding-top: 100px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.smallcase__container__empty .create__smallcase__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.smallcase__container__empty .create__smallcase__content > p {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.smallcase__container__empty > div.create__smallcase {
  margin-top: 50px;
}

.smallcase__container__empty > div.create__smallcase button {
  padding: 10px 18px;
  border-radius: 3px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: var(--main-blue);
  color: var(--white);
  font-weight: 500;
  transition: 0.3s;
}

.smallcase__container__empty > div.create__smallcase button:hover {
  opacity: 0.9;
}

.backtest__container {
  height: 90%;
}

.backtest__container .backtest__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backtest__header > span {
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 800;
}

.backtest__header > div {
  background-color: var(--white);
  font-size: 14px;
  font-weight: 500;
  color: var(--font-primary);
  border-radius: 20px;
  /* box-shadow: 0 2px 5px rgb(0 0 0 / 05%); */
  transition: 0.3s;
  cursor: pointer;
}

.backtest__header > div:hover {
  opacity: 0.9;
}

.backtest__header > div > span {
  margin-left: 5px;
}

.backtest__container .backtest__wrapper {
  margin-top: 10px;
  width: 800px;
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border: 1px solid #f5f5f5;
  padding: 10px 15px;
}

.backtest__wrapper .backtest__stocks__wrapper {
  margin-bottom: 20px;
}

.backtest__wrapper .backtest__stocks__wrapper > p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.backtest__wrapper .backtest__stocks {
  width: 100%;
  display: flex;
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
          column-gap: 12px;
  flex-wrap: wrap;
}

.backtest__wrapper .backtest__stocks .stock__info {
  padding: 6px 12px;
  background-color: #f0f0f0;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  min-width: 60px;
  text-align: center;
}

.backtest__stocks .stock__logo__name {
  display: flex;
  align-items: center;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.backtest__stocks .stock__logo__name .logo__image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backtest__stocks .stock__logo__name .logo__image img {
  width: 100%;
  object-fit: contain;
}

.backtest__stocks .stock__logo__name span {
  font-size: 12px;
  font-weight: 600;
}

.backtest__wrapper .backtest__extra__chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.backtest__extra__chart .strategy__details__box {
  width: 100%;
  background-color: #fafafa;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.backtest__wrapper .backtest__chart,
.backtest__wrapper .backtest__data {
  width: 100%;
}

.backtest__wrapper .backtest__chart {
  border-radius: 5px;
}

.backtest__wrapper .backtest__chart .backtest__chart__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.backtest__wrapper .backtest__chart > p {
  margin-bottom: 10px !important;
  align-self: flex-start;
  font-size: 14px;
  font-weight: 600;
}

.backtest__wrapper .backtest__chart .backtest__chart__wrapper {
  width: 100%;
  height: 400px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 5px 10px;
}

.backtest__wrapper .backtest__data {
  border-radius: 5px;
  margin-top: 20px;
}

.backtest__wrapper .backtest__data .backtest__data__table {
  margin-bottom: 20px;
}

.backtest__data__table .table__wrapper {
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  padding: 5px 5px;
}

.backtest__data__table .data__table__title {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.backtest__data__table table {
  width: 100%;
}

.backtest__data__table tbody tr th {
  font-weight: 500;
  font-size: 12px;
  width: 100px;
  text-align: left;
  color: var(--font-primary);
}

.backtest__data__table thead tr td {
  font-size: 11px;
  padding-bottom: 5px;
  font-weight: 600;
  color: var(--font-primary);
}

.backtest__data__table table thead tr {
  border-bottom: 1px solid #e8e8e8;
}

.backtest__data__table tbody tr td {
  font-weight: 500;
  font-size: 11px;
  padding: 5px 0;
  color: var(--font-primary);
}

.backtest__container .backtest__buttons {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0px;
}

.backtest__buttons .backtest__save {
  background-color: var(--white);
  padding: 7px 15px 7px 15px;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #f5f5f5;
  color: var(--font-primary);
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
  margin: 0 20px 0 0;
}

.backtest__buttons .backtest__create {
  background-color: var(--white);
  padding: 7px 15px 7px 15px;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #f5f5f5;
  color: var(--font-primary);
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
  margin: 0 20px 0 0;
}

.backtest__buttons .backtest__save:hover {
  opacity: 0.9;
}

@media only screen and (max-width: 768px) {
  .smallcase__container {
    width: 100%;
    height: 100%;
  }

  .smallcase__container .smallcase__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .smallcase__header .smallcase__title > img {
    width: 14px;
  }

  .smallcase__header .smallcase__title > span {
    letter-spacing: -0.2px;
  }

  .smallcase__header .smallcase__menu {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
  }

  .smallcase__header .smallcase__menu .smallcase__stratergy__button {
    padding: 0;
    font-size: 12px;
    margin-left: 0;
    margin-right: 10px;
    margin-top: 10px;
    border: none;
  }

  .smallcase__search__wrapper {
    padding: 0 0px;
    width: 100%;
    /* margin-top: 15px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .smallcase__search__wrapper .smallcase__search__box {
    width: 100%;
    height: 45px;
  }

  .smallcase__search__wrapper .smallcase__search__box > img {
    width: 14px;
  }

  .smallcase__search__wrapper .smallcase__search__box > input {
    font-size: 12px;
  }

  .smallcase__container .smallcase__right {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 2px;
    margin-top: 10px;
  }

  .smallcase__right .smallcase__strategy__wrapper,
  .smallcase__right .smallcase__strategy__details {
    width: 100%;
    padding: 10px 10px;
    overflow-y: scroll;
    min-height: 250px;
    margin-bottom: 10px;
    margin-bottom: 10px;
    /* background-color: #ebebeb; */
  }

  .smallcase__right .smallcase__strategy__wrapper {
    height: 100%;
    padding: 0;
    margin-top: 10px;
  }

  .smallcase__strategy__details .strategy__name__wrapper {
    margin-top: 20px;
  }

  .smallcase__right .smallcase__strategy__details {
    /* display: none; */
    position: absolute;
    inset: 0;
    background-color: var(--white);
    border: none;
  }

  .smallcase__right .smallcase__strategy__details.loader {
    display: none;
  }

  .smallcase__strategy__wrapper .smallcase__strategy__card {
    width: 100%;
    padding: 10px 10px 0;
    margin-bottom: 10px;
  }

  .smallcase__strategy__card .strategy__info,
  .smallcase__strategy__card .strategy__data,
  .smallcase__strategy__card .strategy__options {
    margin: 0px 0px 10px;
  }

  .strategy__icon__box {
    width: 30%;
  }

  .strategy__icon__box img {
    width: 12px;
  }

  .strategy__icon__box span {
    font-size: 10px;
  }

  .strategy__info .strategy__headings__box {
    width: 70%;
    padding-right: 5px;
  }

  .strategy__headings__box .strategy__heading {
    font-size: 12px;
  }

  .strategy__headings__box .strategy__news {
    font-size: 11px;
    color: var(--font-primary);
    font-weight: 500;
  }

  .strategy__data .strategy__data__table {
    width: 70%;
  }

  .strategy__data__table > div .title {
    font-size: 12px;
    color: var(--font-primary);
  }

  .strategy__data__table > div .value {
    font-size: 11px;
    font-weight: 500;
    color: var(--font-primary);
  }

  .smallcase__strategy__card .strategy__options {
    justify-content: space-between;
    margin-top: 15px;
  }

  .smallcase__strategy__card .strategy__options > p {
    font-size: 12px;
  }

  .smallcase__strategy__card .strategy__options .strategy__buy__button {
    background-color: var(--white);
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    border: none;
  }

  .smallcase__strategy__details .startegy__details__close {
    display: flex;
    justify-content: flex-end;
  }

  .smallcase__strategy__details .startegy__details__close > img {
    width: 30px;
  }

  .smallcase__strategy__details .strategy__details__box {
    width: 100%;
    margin-bottom: 15px;
    padding: 0 10px;
  }

  .strategy__details__box .details__header {
    display: flex;
    align-items: center;
  }

  .strategy__details__box .details__header > img {
    width: 16px;
  }

  .strategy__details__box .details__header > span {
    font-size: 12px;
  }

  .strategy__details__box .details__content > p {
    font-size: 12px;
    font-weight: 500;
    margin-top: 4px;
    color: var(--font-primary);
  }

  .strategy__chart {
    height: 250px;
  }

  .smallcase__strategy__container {
    width: 100%;
    height: 100%;
    padding: 0px 10px 0;
  }

  .smallcase__strategy__container .smallcase__strategy__header {
    width: 100%;
    padding: 0px 0px;
  }

  .smallcase__strategy__header > span {
    font-size: 13px;
  }

  .smallcase__strategy__header > div {
    padding: 15px 0px 15px 15px;
    font-size: 13px;
    box-shadow: none;
  }

  .smallcase__strategy__header > div > img {
    display: none;
  }

  .smallcase__strategy__body__wrapper {
    width: 100%;
    /* height: 75%; */
    min-height: 450px;
    padding: 0 0px;
    margin-top: 0px;
    transition: 0.3s;
  }

  .smallcase__strategy__body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5px;
  }

  .smallcase__strategy__body .smallcase__strategy__form {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 10px 0;
  }

  .smallcase__strategy__body .smallcase__strategy__image {
    display: none;
  }

  .smallcase__strategy__form .strategy__select__container {
    padding: 0 10px;
  }

  .strategy__select__container .strategy__select__header > img {
    width: 16px;
  }

  .strategy__select__container .strategy__select__header > span {
    margin-left: 6px;
    font-size: 12px;
  }

  .strategy__select__container .strategy__select__box {
    margin-top: 5px;
  }

  .strategy__select__container
    .strategy__select__box
    .custom-select
    .custom-select-title,
  .strategy__select__container
    .strategy__select__box
    .custom-select
    .custom-select-option {
    font-size: 11px !important;
  }

  .strategy__select__container .strategy__text__box,
  .strategy__select__container .strategy__input__box {
    margin-top: 5px;
  }

  .strategy__select__container .strategy__text__box textarea {
    width: 95%;
    height: 100%;
    font-size: 10px;
  }

  .strategy__select__container .strategy__input__box input {
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding-left: 10px;
    font-size: 10px;
  }

  .strategy__select__container .strategy__text__box textarea::-webkit-input-placeholder, .strategy__select__container .strategy__input__box input::-webkit-input-placeholder {
    color: var(--font-primary);
  }

  .strategy__select__container .strategy__text__box textarea:-ms-input-placeholder, .strategy__select__container .strategy__input__box input:-ms-input-placeholder {
    color: var(--font-primary);
  }

  .strategy__select__container .strategy__text__box textarea::placeholder,
  .strategy__select__container .strategy__input__box input::placeholder {
    color: var(--font-primary);
  }

  .smallcase__strategy__container .smallcase__strategy__footer {
    margin-top: 20px;
    width: 100%;
    padding: 5px 0px;
  }

  .smallcase__strategy__footer .strategy__footer__button {
    padding: 10px 40px 10px 40px;
    box-shadow: none;
    font-size: 12px;
  }

  .smallcase__strategy__footer .strategy__footer__button > img {
    display: none;
  }

  .strategy__portfolio__table__wrapper {
    width: 100%;
    padding: 0px 0;
    margin-top: 20px;
  }

  .strategy__portfolio__table__wrapper .strategy__portfolio__table {
    width: 100%;
    height: 90%;
  }

  .strategy__portfolio__table .strategy__portfolio__table__header,
  .strategy__portfolio__table .strategy__portfolio__table__body,
  .strategy__portfolio__table .strategy__portfolio__table__footer {
    width: 100%;
  }

  .strategy__portfolio__table .strategy__portfolio__table__body {
    height: 75%;
    overflow-y: scroll;
  }

  .strategy__portfolio__table__header .table__row,
  .strategy__portfolio__table__body .table__row,
  .strategy__portfolio__table__footer .table__row {
    width: 100%;
    padding: 4px 2px;
  }

  .strategy__portfolio__table__header .edit__table__row,
  .strategy__portfolio__table__body .edit__table__row,
  .strategy__portfolio__table__footer .edit__table__row {
    width: 100%;
    font-size: 10px;
    padding: 4px 2px;
  }

  .strategy__portfolio__table__footer .table__row,
  .strategy__portfolio__table__footer .edit__table__row {
    margin-top: 10px;
    border-top: 1px solid #e8e8e8;
  }

  .strategy__portfolio__table__header .table__row > div,
  .strategy__portfolio__table__footer .table__row > div,
  .strategy__portfolio__table__header .edit__table__row > div,
  .strategy__portfolio__table__footer .edit__table__row > div {
    font-weight: 700;
  }

  .strategy__portfolio__table__body .table__row:hover,
  .strategy__portfolio__table__body .edit__table__row:hover {
    background-color: #fcfcfc;
    border-radius: 3px;
  }

  .strategy__portfolio__table__header .table__row > div:nth-child(1),
  .strategy__portfolio__table__body .table__row > div:nth-child(1),
  .strategy__portfolio__table__footer .table__row > div:nth-child(1) {
    font-size: 11px;
  }

  .strategy__portfolio__table__header .edit__table__row > div:nth-child(1),
  .strategy__portfolio__table__body .edit__table__row > div:nth-child(1),
  .strategy__portfolio__table__footer .edit__table__row > div:nth-child(1) {
    flex: 2 1;
    font-size: 10px;
    padding-left: 5px;
  }

  .strategy__portfolio__table__header .table__row > div:nth-child(2),
  .strategy__portfolio__table__body .table__row > div:nth-child(2),
  .strategy__portfolio__table__footer .table__row > div:nth-child(2) {
    font-size: 11px;
  }

  .strategy__portfolio__table__header .edit__table__row > div:nth-child(2),
  .strategy__portfolio__table__body .edit__table__row > div:nth-child(2),
  .strategy__portfolio__table__footer .edit__table__row > div:nth-child(2) {
    flex: 2 1;
    font-size: 10px;
  }

  .strategy__portfolio__table__header .table__row > div:nth-child(3),
  .strategy__portfolio__table__body .table__row > div:nth-child(3),
  .strategy__portfolio__table__footer .table__row > div:nth-child(3) {
    flex: 3 1;
    font-size: 11px;
  }

  .strategy__portfolio__table__header .table__row > div:nth-child(4),
  .strategy__portfolio__table__body .table__row > div:nth-child(4),
  .strategy__portfolio__table__footer .table__row > div:nth-child(4) {
    flex: 3 1;
  }

  .strategy__portfolio__table__header .edit__table__row > div:nth-child(3),
  .strategy__portfolio__table__body .edit__table__row > div:nth-child(3),
  .strategy__portfolio__table__footer .edit__table__row > div:nth-child(3) {
    flex: 1.5 1;
    font-size: 10px;
  }

  .strategy__portfolio__table__header .edit__table__row > div:nth-child(4),
  .strategy__portfolio__table__body .edit__table__row > div:nth-child(4),
  .strategy__portfolio__table__footer .edit__table__row > div:nth-child(4) {
    padding-right: 5px;
    flex: 1.5 1;
  }

  .strategy__portfolio__table__header .edit__table__row > div:nth-child(5),
  .strategy__portfolio__table__body .edit__table__row > div:nth-child(5),
  .strategy__portfolio__table__footer .edit__table__row > div:nth-child(5) {
    flex: 1 1;
    padding-right: 5px;
  }

  .strategy__portfolio__table__body
    .edit__table__row
    > div:nth-child(5)
    > span {
    font-size: 9px !important;
    font-weight: 500 !important;
  }

  .strategy__portfolio__table__body .table__row > div > input,
  .strategy__portfolio__table__body .edit__table__row > div > input {
    width: 100%;
    border-width: 1px;
  }

  .strategy__portfolio__table__body .table__row > div .stock__remove,
  .strategy__portfolio__table__body .edit__table__row > div .stock__remove {
    padding: 5px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .strategy__portfolio__table__body .table__row > div .stock__remove:hover,
  .strategy__portfolio__table__body
    .edit__table__row
    > div
    .stock__remove:hover {
    background-color: #bce8ff;
  }

  .strategy__portfolio__table__body .table__row > div .stock__remove img {
    width: 10px;
    height: 10px;
  }

  .strategy__portfolio__table__body .table__row > div > input:focus,
  .strategy__portfolio__table__body .edit__table__row > div > input:focus {
    border-bottom: 1px solid var(--font-primary);
  }

  .strategy__portfolio__table .strategy__table__stock {
    display: flex;
    flex-direction: column;
  }

  .strategy__table__stock .strategy__table__stock__code {
    font-size: 9px;
    font-weight: 700;
  }

  .strategy__table__stock .strategy__table__stock__name {
    max-width: 50px;
    font-size: 9px;
  }

  .strategy__portfolio__table__wrapper .strategy__performance__buttons {
    display: flex;
    width: 100%;
  }

  .strategy__portfolio__table__wrapper .strategy__performance__buttons > div {
    padding: 6px 12px 6px 12px;
    font-size: 11px;
  }

  .strategy__portfolio__stock__wrapper {
    width: 100%;
    /* justify-content: center; */
    padding: 5px 0;
  }

  .strategy__portfolio__stock__wrapper .strategy__portfolio__stock__options {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .strategy__portfolio__stock__options .stock__search {
    width: 100%;
    height: 35px;
    padding: 0 5px !important;
    border-radius: 3px;
    border: 1px solid #e8e8e8;
  }

  .strategy__portfolio__stock__options .stock__search > input {
    font-size: 12px;
  }

  .strategy__portfolio__stock__options .stock__suggestions {
    max-height: 200px !important;
  }

  .strategy__portfolio__stock__options .stock__suggestions .search__loader {
    height: 200px !important;
  }

  .strategy__portfolio__stock__options .stock__suggestions > p {
    padding: 6px 10px !important;
  }

  .strategy__portfolio__stock__options
    .stock__suggestions
    > p
    > span:nth-child(1) {
    font-size: 10px;
  }

  .strategy__portfolio__stock__options
    .stock__suggestions
    > p
    > span:nth-child(2) {
    font-size: 8px;
  }

  .strategy__portfolio__stock__options
    .stock__suggestions
    > p
    > span:nth-child(3) {
    font-size: 10px;
  }

  .strategy__portfolio__stock__options .strategy__stock__buttons {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
  }

  .strategy__portfolio__stock__options .strategy__stock__buttons > div {
    margin: 0px 15px 0px 0px;
    font-size: 11px;
  }

  .strategy__portfolio__stock__options .strategy__stock__buttons > div:hover {
    background-color: #f8f8f8;
  }

  .smallcase__success__page {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .smallcase__success__page .success__message {
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
  }

  .backtest__container .backtest__header {
    width: 100%;
    padding: 5px 10px;
  }

  .backtest__header > span {
    font-size: 11px;
  }

  .backtest__header > div {
    font-size: 11px;
  }

  .backtest__container .backtest__wrapper {
    margin-top: 10px;
    padding: 0 2%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .backtest__wrapper .backtest__chart,
  .backtest__wrapper .backtest__data {
    width: 100%;
  }

  .backtest__wrapper .backtest__chart {
    padding: 10px 2px;
    font-size: 8px;
  }

  .backtest__wrapper .backtest__chart .backtest__chart__wrapper > p {
    margin-bottom: 20px !important;
    font-size: 11px;
    padding: 0 5px;
  }

  .backtest__wrapper .backtest__data {
    border-radius: 5px;
    padding: 10px 5px;
  }

  .backtest__wrapper .backtest__data .backtest__data__table {
    margin-bottom: 10px;
  }

  .backtest__data__table .data__table__title {
    font-size: 11px;
    margin-bottom: 10px;
  }

  .backtest__data__table > div:nth-child(2) {
    overflow-x: scroll;
  }

  .backtest__data__table table {
    width: 100%;
  }

  .backtest__data__table thead tr td {
    font-size: 10px;
  }

  .backtest__data__table tbody tr th {
    width: 70px;
    padding: 0 60px 0 0;
    font-size: 10px;
  }

  .backtest__data__table tbody tr td {
    font-weight: 500;
    min-width: 100px;
  }

  .backtest__container .backtest__buttons {
    margin-top: 10px;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .backtest__buttons .backtest__save {
    padding: 6px 10px 6px 10px;
    font-size: 10px;
  }
}

.value-select-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
}

.value-select-wrapper .value-select-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    padding: 8px 8px;
    font-size: 12px;
    font-weight: 600;
}

.value-select-content .value-select-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.value-select-wrapper .value-select-options-wrapper{
    position: absolute;
    top: 105%;
    left: 0%;
    width: 100%;
}

.value-select-wrapper .value-select-options {
    position: fixed;
    background-color: white;
    box-shadow: 0px 0px 0px #f8f8f8;
    border: 1px solid #f5f5f5;
    border-radius: 3px;
    z-index: 1000;
    padding: 6px 6px;
}

.value-select-options .value-select-option {
    font-size: 11px;
    font-weight: 600;
    padding: 10px 4px;
    border-bottom: 1px solid #f5f5f5;
    width: 100%;
}

.value-select-options .value-select-option:hover {
    background-color: #fafafa;
}
.portfolio__container__loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.portfolio__container__loader > p {
  font-size: 14px;
  font-weight: 500;
}

.portfolio-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.portfolio-header .portfolio-title {
  font-size: 18px;
  color: rgba(1, 22, 39, 0.9) 98;
  /* color: var(--font-primary); */
  font-weight: 700;
}

.portfolio-header .portfolio-subtext {
  font-size: 20px;
  color: var(--font-primary);
  font-weight: 300;
  margin-top: -2px;
}

.portfolio__blur {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.85);
}

.portfolio__container {
  width: 100%;
  height: 90%;
  padding: 0 0px;
}

.strategy-content .portfolio__container {
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
}

.portfolio__container.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.portfolio__container.hide {
  display: none;
}

.portfolio__container.loader p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  color: var(--font-primary);
}

.portfolio__container.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  grid-row-gap: 0px;
  row-gap: 0px;
}

.portfolio__container.empty p {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-top: 8px !important;
  color: var(--font-primary);
  font-weight: 500 !important;
}

.strategy-content .portfolio__options {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0px 0 10px;
}

.strategy-content .portfolio__options.strategy .portfolio__search__bar {
  width: 500px;
}

.strategy-content .portfolio__options.full {
  height: 70px;
}

.portfolio__options .portfolio__selection {
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.portfolio__selection .portfolio__customer {
  width: 300px;
}

.portfolio__selection .portfolio__select {
  width: 200px;
}

.portfolio__container .portfolio__options .custom-select {
  box-shadow: none;
}

.portfolio__container .portfolio__options .custom-select .custom-select-title {
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px !important;
}

.portfolio__container .portfolio__options .custom-select .custom-select-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px !important;
}

.portfolio__container .portfolio__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.portfolio__container .portfolio__menu .portfolio__update {
  height: 40px;
  width: 550px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
}

.portfolio__search__bar {
  display: flex;
  align-items: flex-end;
  width: 340px;
}

.portfolio__search__bar .stock__search {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  width: 100%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  position: relative;
  border-radius: 5px;
  margin-left: 10px;
  padding: 0 10px;
}

.portfolio__search__bar .stock__search__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio__search__bar .stock__search img {
  width: 16px;
}

.portfolio__search__bar .stock__search input {
  border: none;
  outline: none;
  width: 100%;
  height: 90%;
  padding: 12px 10px;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase !important;
  background: transparent;
}

.portfolio__search__bar .stock__search input::-webkit-input-placeholder {
  color: #808080;
  text-transform: capitalize;
}

.portfolio__search__bar .stock__search input:-ms-input-placeholder {
  color: #808080;
  text-transform: capitalize;
}

.portfolio__search__bar .stock__search input::placeholder {
  color: #808080;
  text-transform: capitalize;
}

.portfolio__search__bar .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: -0.5%;
  margin-top: 3px;
  z-index: 1000;
  border-radius: 5px;
  max-height: 250px;
  overflow-y: scroll;
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
  /* display: none; */
}

.portfolio__search__bar .stock__suggestions .search__loader {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio__search__bar .stock__search .stock__suggestions .search__loader p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  display: flex;
  color: var(--font-primary);
}

.portfolio__search__bar .stock__search .stock__suggestions p {
  padding: 8px 20px 8px 20px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.portfolio__search__bar .stock__search .stock__suggestions p.active {
  background-color: #dbd9d9;
}

.portfolio__search__bar
  .stock__search
  .stock__suggestions
  p
  span.search__highlight {
  font-weight: 500;
}

.portfolio__search__bar
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(1) {
  flex: 3 1;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.portfolio__search__bar
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(2) {
  flex: 7 1;
  font-size: 11px;
  font-weight: 500;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
  letter-spacing: -5px;
}

.portfolio__search__bar
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(3) {
  flex: 1 1;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  color: var(--font-primary);
}

.portfolio__search__bar .stock__search .stock__suggestions p:hover {
  background-color: #f7f7f7;
}

.strategy-content .portfolio__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  padding: 0px 0px;
}

.portfolio__header .portfolio__info__box {
  background-color: transparent;
  padding: 2px 0px;
  border-radius: 4px;
  width: auto;
  align-items: center;
  justify-content: space-between;
  display: flex;
  transition: 0.3s;
  /* width: 110px; */
}

.portfolio__info__box .portfolio__info__title {
  width: 30%;
  display: flex;
  justify-content: center;
}

.portfolio__info__box .portfolio__info__title img {
  width: 24px;
}

.portfolio__info__box .portfolio__info__title span {
  font-size: 12px;
  color: var(--font-primary);
  display: inline-block;
  margin-left: 5px;
  align-self: flex-end;
}

.portfolio__header .portfolio__info__box .portfolio__info__value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4px;
}

.portfolio__header .portfolio__info__box .portfolio__info__value > div {
  display: flex;
}

.portfolio__header .portfolio__info__box .portfolio__info__value > div > span {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.portfolio__info__head {
  font-size: 11px;
  font-weight: 500;
}

.portfolio__header
  .portfolio__info__box
  .portfolio__info__value
  .portfolio__info__name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
}

.portfolio__header
  .portfolio__info__box
  .portfolio__info__value
  .portfolio__trade__value {
  font-size: 12px;
  display: inline-block;
  margin-left: 5px;
}

.portfolio__info__box .portfolio__info__value .portfolio__info__value__change {
  display: flex;
}

.portfolio__info__box .portfolio__info__value .portfolio__change__value span {
  font-size: 14px;
}

.portfolio__info__box .portfolio__info__value .portfolio__change__per {
  margin-left: 5px;
}

.portfolio__info__box .portfolio__info__value .portfolio__change__per span {
  font-size: 14px !important;
}

.portfolio__container .portfolio__table__container {
  height: 100%;
  padding: 0 0px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.portfolio__container .portfolio__table__container.hide {
  display: none;
}

.portfolio__container .portfolio__table {
  height: calc(100% - 80px);
}

.portfolio__container .portfolio__table.full {
  height: 100%;
}

.portfolio__table.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.portfolio__table.loader p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  color: var(--font-primary);
}

.portfolio__container .portfolio__table .empty__table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
}

.portfolio__container .portfolio__table .empty__table > p {
  font-size: 13px;
  font-weight: 600;
}

.portfolio__container .portfolio__table .table__content {
  width: 100%;
  max-height: 100%;
  border-radius: 15px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  overflow-y: scroll;
}

.portfolio__container .portfolio__table table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.portfolio__container .portfolio__table table thead {
  /* background-color: #fcfcfc; */
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.portfolio__container .portfolio__table table thead tr td {
  text-align: left;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
}

.portfolio__container .portfolio__table table thead tr td.ordertype {
  width: 150px;
}

.portfolio__container .portfolio__table table thead tr td.name {
  width: 180px;
}

.portfolio__container .portfolio__table table thead tr td.return.shrink,
.portfolio__container .portfolio__table table thead tr td.today.shrink,
.portfolio__container .portfolio__table table thead tr td.order.shrink {
  width: 70px;
}

.portfolio__container .portfolio__table table thead tr td.portweight.shrink,
.portfolio__container .portfolio__table table thead tr td.quantity.shrink,
.portfolio__container .portfolio__table table thead tr td.tradevol.shrink {
  width: 120px;
}

.portfolio__container .portfolio__table table thead tr td.dynamicfield {
  display: none;
}

.portfolio__container .portfolio__table table thead tr td.addorder,
.portfolio__container .portfolio__table table thead tr td.removeorder,
.portfolio__container .portfolio__table table thead tr td.deleteorder {
  width: 40px;
}

.portfolio__container .portfolio__table table thead tr td.currprice,
.portfolio__container .portfolio__table table thead tr td.quantity,
.portfolio__container .portfolio__table table thead tr td.order {
  font-weight: 700;
}

.portfolio__container .portfolio__table table thead tr td.quantity {
  width: 70px;
}

.portfolio__container .portfolio__table table thead tr td.buyprice,
.portfolio__container .portfolio__table table thead tr td.currprice,
.portfolio__container .portfolio__table table thead tr td.cost,
.portfolio__container .portfolio__table table thead tr td.currvalue {
  width: 120px;
}

.portfolio__container .portfolio__table table thead tr td.order {
  width: 150px;
}

.portfolio__container .portfolio__table table thead tr td.tradevol {
  width: 100px;
}

.portfolio__container .portfolio__table table tbody tr {
  cursor: default;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid #f5f5f5;
}

.portfolio__container .portfolio__table table tbody tr td {
  /* vertical-align: top; */
  padding: 15px 0;
}

.portfolio__container .portfolio__table table tbody tr td > div {
  padding: 0px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.portfolio__container .portfolio__table table tbody tr td > div.ordertype-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column-gap: 6px;
  -webkit-column-gap: 6px;
          column-gap: 6px;
  cursor: pointer;
}

.portfolio__container
  .portfolio__table
  table
  tbody
  tr
  td
  > div.ordertype-box
  p {
  color: var(--text-color-blur);
  font-size: 12px;
}

.portfolio__container .portfolio__table table tbody tr td.portweight > div {
  display: flex;
  align-items: center;
}

.portfolio__container .portfolio__table table tbody tr td.portweight span {
  font-size: 10px;
}

.portfolio__container .portfolio__table table tbody tr td.currprice,
.portfolio__container .portfolio__table table tbody tr td.quantity,
.portfolio__container .portfolio__table table tbody tr td.order {
  font-weight: 700;
}

.portfolio__container .portfolio__table table tbody tr td.order .input-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 2px;
  padding: 0 10px;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.portfolio__container .portfolio__table table tbody tr td.order-item-broker .value-select-content{
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
}

.portfolio__container
  .portfolio__table
  table
  tbody
  tr
  td.order
  .input-box
  input {
  width: 90%;
  background-color: transparent;
  color: var(--font-primary);
  border: none;
  outline: none;
  border-radius: 3px;
  padding: 0px 0px 0px;
  font-size: 13px;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
}

/* .portfolio__container .portfolio__table table tbody tr td.order .input-box .input-arrows{
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 0px;
    row-gap: 0px;
} */

.portfolio__container
  .portfolio__table
  table
  tbody
  tr
  td.order
  .input-box
  span {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
  background-color: #f5f5f5;
  font-size: 10px;
}

.portfolio__container
  .portfolio__table
  table
  tbody
  tr
  td.order
  .input-box
  span:first-child {
  margin-right: 5px;
  font-weight: 600;
}

.portfolio__container .portfolio__table table tfoot {
  box-shadow: 0px -1px 1px #f5f5f5, 0px 5px 5px #f5f5f5;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  bottom: 0;
  background-color: var(--white);
}

.portfolio__container .portfolio__table table tfoot tr td {
  font-size: 14px;
  padding: 12px 10px 12px;
  color: var(--font-primary);
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 20px;
}

.portfolio__container .portfolio__table table tbody .portfolio__stock__code {
  display: flex;
  align-items: center;
}

.portfolio__container
  .portfolio__table
  table
  tbody
  .portfolio__stock__code
  .stock__status {
  width: 5px;
  height: 5px;
  background-color: var(--main-blue);
  border-radius: 100%;
}

.portfolio__container .portfolio__table table tbody .portfolio__stock__code .stock__code {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  /* margin-left: 3px; */
}

.portfolio__container .portfolio__table table tbody .portfolio__stock__name {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-blur);
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.portfolio__container .portfolio__table .increase-circle {
  color: var(--font-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 3px;
  width: 100%;
}

/* 
.portfolio__container .portfolio__table .increase-circle.positive
{
    background-color: var(--main-blue);
}

.portfolio__container .portfolio__table .increase-circle.negative
{
    background-color: var(--main-red);
} */

.portfolio__container .portfolio__table .increase-circle > img {
  width: 100%;
}

.portfolio__container .trade__value__section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.portfolio__container .trade__value {
  display: flex;
  grid-column-gap: 30px;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.portfolio__container .trade__value p {
  border-radius: 5px;
  padding: 0px 0px;
  font-size: 12px;
  font-weight: 500;
}

.portfolio__container .total__trade__value {
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  color: var(--font-primary);
  font-weight: 700;
  margin: 5px 10px 0 0;
}

.portfolio__container .total__trade__value > span {
  margin-left: 4px;
}

.portfolio__buttons {
  /* position: absolute; */
  width: 100%;
  height: 60px;
  /* top: calc(100% - 60px); */
  /* bottom: 3%;
    left: 0%; */
  padding: 0 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.portfolio__buttons button {
  /* margin: 0 10px 0 10px; */
  /* border : 1px solid #e8e8e8; */
  background-color: var(--white);
  /* padding: 8px 15px 8px 15px; */
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 500;
  border-radius: 3px;
  transition: 0.3s;
  border: none;
  outline: none;
}

.portfolio__buttons button:hover {
  font-weight: 500;
}

.portfolio__analysis__wrapper {
  width: 100%;
  height: calc(100% - 0px);
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  padding: 0px 15px;
}

.portfolio__analysis__wrapper > p {
  font-size: 13px;
  font-weight: 600;
}

.portfolio__analysis__wrapper.hide {
  display: none;
}

.portfolio__analysis__wrapper__blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.85);
}

.portfolio__analysis__wrapper__blur.hide {
  display: none;
}

.portfolio__analysis__wrapper.loader {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 0px;
  row-gap: 0px;
}

.portfolio__analysis__wrapper.hide {
  display: none;
}

.portfolio__analysis__wrapper.loader > p {
  font-size: 14px;
  color: var(--font-primary);
  margin-top: 10px !important;
  font-weight: 500;
}

.portfolio__analysis__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1%;
}

.portfolio__analysis__header > span {
  color: var(--font-primary);
  font-size: 14px;
  font-weight: 500;
}

.portfolio__analysis__header > div {
  font-size: 12px;
  cursor: pointer;
  color: var(--font-primary);
}

.portfolio__analysis__options {
  padding: 0 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.portfolio__download__button {
  background-color: var(--white);
  color: var(--font-primary);
  border: 1px solid #f5f5f5;
  font-size: 12px;
  font-weight: 700;
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 35px;
  padding: 0 10px;
  border-radius: 5px;
}

.portfolio__download__button .button__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio__analysis__container {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  padding: 0 0px;
  margin-top: 0px;
}

.portfolio__analysis__container .portfolio__charts {
  width: 100%;
}

.portfolio__analysis__container .portfolio__data {
  width: 45%;
  height: 100%;
  overflow-y: scroll;
  padding: 0 10px;
}

.portfolio__analysis__options .portfolio__info {
  display: flex;
  align-items: center;
  margin-top: 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.portfolio__analysis__options .portfolio__info .portfolio__detail__box {
  display: flex;
  flex-direction: column;
}

.portfolio__info .portfolio__detail__box > span:first-child {
  font-size: 12px;
  font-weight: 500;
}

.portfolio__info .portfolio__detail__box > span:last-child {
  font-size: 12px;
  font-weight: 600;
}

.portfolio__charts .portfolio__charts__nav {
  width: 100%;
  margin-top: 0px;
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;
  justify-content: space-between;
}

.portfolio__charts .portfolio__charts__nav > span {
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
}

.portfolio__charts .portfolio__charts__nav .benchmark__select {
  width: 260px;
}

/* .portfolio__charts .portfolio__charts__nav .custom-select {
    width: 160px !important;
    height: 35px !important;
    box-shadow: none;
}

.portfolio__charts .portfolio__charts__nav .custom-select .custom-select-header .custom-select-title {
    font-size: 11px !important;
}

.custom-select .custom-select-container .custom-select-option {
    font-size: 11px !important;
}

*/
.portfolio__charts .portfolio__charts__baseline,
.portfolio__charts .portfolio__charts__pie {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.portfolio__charts .portfolio__charts__pie .strategy__sector__chart {
  width: 100%;
  height: 100%;
}

.portfolio__charts .portfolio__charts__wrapper {
  width: 90%;
  overflow-y: scroll;
  display: flex;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
}

.portfolio__charts .portfolio__charts__wrapper::-webkit-scrollbar {
  display: none;
}

.portfolio__charts .portfolio__charts__wrapper .charts {
  width: 620px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 10px 0px;
}

.portfolio__charts .portfolio__charts__wrapper .charts > p {
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px !important;
  color: var(--font-primary);
  font-weight: 600;
}

.portfolio__analysis__container .portfolio__data > div {
  margin-bottom: 20px;
}

.portfolio__analysis__container .portfolio__data > div .table__heading {
  font-size: 13px;
  color: var(--font-primary);
  margin-bottom: 10px;
  font-weight: 600;
}

.portfolio__data .table__wrapper {
  border-radius: 3px;
  border: 1px solid #f5f5f5;
  padding: 5px 5px;
}

.portfolio__data table thead tr {
  border-bottom: 1px solid #e8e8e8;
}

.portfolio__data table thead tr td {
  font-size: 12px;
  padding: 0px 12px 5px;
  color: var(--font-primary);
  font-weight: 600;
}

.portfolio__data table tbody tr th {
  font-weight: 500;
  font-size: 12px;
  width: 100px;
  text-align: left;
  padding: 5px 5px;
  color: var(--font-primary);
}

.portfolio__data table tbody tr td {
  font-weight: 500;
  font-size: 12px;
  padding: 5px 12px;
  color: var(--font-primary);
}

@media only screen and (max-width: 768px) {
  .portfolio__container {
    width: 100%;
    height: 100%;
    padding: 10px 0px;
  }

  .portfolio__container__loader > p{
    font-size: 16px;
    font-weight: 600;
  }

  .portfolio__container.loader {
    min-height: 300px;
  }

  .portfolio__container.loader p {
    font-size: 12px !important;
  }

  .portfolio__container .portfolio__options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .portfolio__select {
    width: 100%;
  }

  .portfolio__container .portfolio__options .custom-select {
    width: 100% !important;
    height: 45px !important;
  }

  .portfolio__container
    .portfolio__options
    .custom-select
    .custom-select-title {
    width: 200px;
    font-size: 11px !important;
  }

  .portfolio__container
    .portfolio__options
    .custom-select
    .custom-select-option {
    font-size: 11px !important;
    width: 100% !important;
  }

  .portfolio__container .portfolio__menu {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .portfolio__container .portfolio__menu .portfolio__update {
    margin-top: 10px;
    margin-left: 0px;
    height: 45px;
    width: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
  }

  .portfolio__container .portfolio__menu .portfolio__update .stock__search {
    padding: 0 5px;
  }

  .portfolio__container .portfolio__menu .portfolio__update .stock__search img {
    width: 14px;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    input {
    font-size: 10px;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions {
    border-radius: 5px;
    max-height: 250px;
    /* display: none; */
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    .search__loader {
    width: 100%;
    height: 250px;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    .search__loader
    p {
    font-size: 11px !important;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    p {
    padding: 4px 20px 4px 20px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    p
    > span:nth-child(1) {
    flex: 3 1;
    font-size: 10px;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    p
    > span:nth-child(2) {
    flex: 7 1;
    font-size: 8px;
    width: 160px;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    p
    > span:nth-child(3) {
    flex: 1 1;
    font-size: 10px;
  }

  
  .strategy-content .portfolio__options{
    height: auto;
    display: inline;
    display: initial;
  }

  .strategy-content .portfolio__header {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 10px;
    row-gap: 10px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
  }

  .portfolio__header .portfolio__info__box {
    display: flex;
    padding: 0px 0px;
    border-radius: 3px;
    margin: 0px 0;
    box-shadow: none;
    border: none;
  }

  .portfolio__info__box .portfolio__info__title img {
    width: 20px;
  }

  .portfolio__info__box .portfolio__info__title span {
    font-size: 12px;
    align-self: center;
  }

  .portfolio__info__head {
    font-size: 14px;
    font-weight: 500;
    color: var(--font-secondary);
  }

  .portfolio__info__box .portfolio__info__title {
    width: 5%;
    display: flex;
    justify-content: center;
    display: none;
  }

  .portfolio__header .portfolio__info__box .portfolio__info__value {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .portfolio__header .portfolio__info__box .portfolio__info__value > div > span {
    font-size: 16px;
    font-weight: 700;
  }

  .portfolio__header
    .portfolio__info__box
    .portfolio__info__value
    .portfolio__trade__value {
    font-size: 12px;
  }

  .portfolio__info__box
    .portfolio__info__value
    .portfolio__info__value__change {
    display: flex;
  }

  .portfolio__info__box .portfolio__info__value .portfolio__change__value span {
    font-size: 12px;
    margin-right: 2px;
    letter-spacing: -2px;
  }

  .portfolio__info__box .portfolio__info__value .portfolio__change__per span {
    font-size: 12px !important;
  }

  .strategy-content .portfolio__options.strategy .portfolio__search__bar{
    display: none;
  }

  .portfolio__container .trade__value__section{
    display: none;
  }

  .portfolio__analysis__wrapper {
    width: 100%;
    height: auto;
    padding: 0 10px 20px;
  }

  .portfolio__container .title{
    font-size: 12px;
    font-weight: 600;
    color: var(--font-secondary);
  }

  .portfolio__container .portfolio__table {
    margin: 0px auto 0;
    width: 100%;
    max-height: auto;
    padding: 0 0px;
  }

  .portfolio__container .portfolio__table table thead {
    top: 0%;
    z-index: 1;
    height: 30px;
    width: 100%;
  }

  .portfolio__container .portfolio__table table thead tr th {
    text-align: left;
    font-weight: 700;
    font-size: 10px;
  }

  .portfolio__container .portfolio__table table tbody {
    height: 90%;
    /* background-color: #666666; */
  }

  .portfolio__container .portfolio__table table tbody tr {
    cursor: default;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select {
    box-shadow: none;
    width: 80px !important;
    height: 25px !important;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select-header {
    padding: 0 3px !important;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select-header img {
    width: 14px;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select-title,
  .portfolio__container .portfolio__table table thead tr th .custom-select-option {
    font-size: 9px !important;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select-option {
    padding: 6px 8px !important;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select-container {
    min-width: 110% !important;
    height: 100px;
    overflow-y: scroll;
  }

  .portfolio__container .portfolio__table table thead tr th,
  .portfolio__container .portfolio__table table tbody tr td {
    display: none;
    font-size: 10px;
  }

  .portfolio__container .portfolio__table table thead tr th.active,
  .portfolio__container .portfolio__table table tbody tr td.active {
    display: table-cell;
    padding: 10px 2px;
    max-width: 80px;
    font-size: 11px;
  }

  .portfolio__container .portfolio__table table tbody tr:last-child {
    background-color: #ffffff;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0%;
    left: 0%;
    height: 30px;
    font-size: 9px;
  }

  .portfolio__container .portfolio__table table tbody tr:last-child td {
    font-size: 11px;
    font-weight: 700;
  }

  .portfolio__container .portfolio__table table tbody .portfolio__stock__code {
    display: flex;
    align-items: center;
  }

  .portfolio__container .portfolio__table table tbody .portfolio__stock__code .stock__status {
    width: 5px;
    height: 5px;
    background-color: var(--main-blue);
    border-radius: 100%;
  }

  .portfolio__container .portfolio__table table tbody .portfolio__stock__code .stock__code {
    font-size: 11px;
  }

  .portfolio__container .portfolio__table table tbody .portfolio__stock__name {
    width: 50px;
    font-size: 9px;
  }

  .portfolio__container .portfolio__table .increase-circle {
    font-size: 18px;
    width: 70%;
  }

  .portfolio__container .portfolio__table .portfolio-table-row{
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 2px;
    row-gap: 2px;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0;
  }

  .portfolio__container .portfolio__table .portfolio-table-row .table-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .portfolio__container .portfolio__table .portfolio-table-row .table-row:nth-child(2),
  .portfolio__container .portfolio__table .portfolio-table-row .table-row:nth-child(3){
    font-size: 13px;
    color: var(--font-secondary);
    font-weight: 500;
  }

  .portfolio__container .portfolio__table .portfolio-table-row .table-row>div{
    display: flex;
    align-items: center;
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
  }

  .portfolio__container .portfolio__table .portfolio-table-row .table-row:nth-child(1) span{
    font-weight: 700;
    font-size: 15px;
  }

  .portfolio__container .total__trade__value {
    display: flex;
    justify-content: flex-end;
    font-size: 9px;
    font-weight: 700;
    margin: 3px 10px 0 0;
  }

  .portfolio__buttons {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .portfolio__buttons > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .portfolio__buttons > div:first-child button {
    margin: 4px 0;
    font-weight: 700;
    padding: 0;
    width: 180px;
    height: 40px;
  }

  .portfolio__buttons > div:last-child {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .portfolio__buttons button {
    margin: 0px 0;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
  }

  .portfolio__analysis__header {
    margin-top: 20px;
  }

  .portfolio__analysis__header > div {
    font-size: 12px;
    /* padding: 4px 20px; */
    border-radius: 3px;
  }

  .portfolio__analysis__options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .portfolio__analysis__options .portfolio__download__button {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
  }

  .portfolio__analysis__options .portfolio__info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .portfolio__analysis__options
    .portfolio__info
    .portfolio__info__left
    > div
    > img {
    width: 16px;
  }

  .portfolio__analysis__options
    .portfolio__info
    .portfolio__info__left
    > div
    > span {
    font-size: 11px;
    margin-left: 5px;
  }

  .portfolio__analysis__options .portfolio__info .portfolio__info__left > span {
    font-size: 11px;
  }

  .portfolio__analysis__options .portfolio__info .portfolio__info__right {
    margin-left: 0px;
  }

  .portfolio__analysis__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0%;
  }

  .portfolio__analysis__container .portfolio__charts {
    width: 100%;
    overflow-y: scroll;
  }

  .portfolio__analysis__container .portfolio__data {
    width: 100%;
    height: auto;
  }

  .portfolio__charts .portfolio__charts__nav > span {
    font-size: 14px;
  }

  .portfolio__charts .portfolio__charts__wrapper {
    height: 100%;
    overflow-y: scroll;
  }

  .portfolio__charts .portfolio__charts__wrapper .charts {
    padding: 5px 5px;
  }

  .portfolio__charts .portfolio__charts__wrapper .charts > p {
    width: 100%;
    font-size: 14px !important;
  }

  .portfolio__analysis__container .portfolio__data {
    padding: 0px 5px;
  }

  .portfolio__analysis__container .portfolio__data > div {
    margin-bottom: 10px;
  }

  .portfolio__analysis__container .portfolio__data > div .table__heading {
    font-size: 14px;
  }

  .portfolio__data .table__wrapper {
    padding: 5px 5px;
    overflow-x: scroll;
  }

  .portfolio__data table thead tr td {
    font-size: 12px;
  }

  .portfolio__data table tbody tr th {
    font-size: 12px;
    min-width: 100px;
  }

  .portfolio__data table tbody tr td {
    font-size: 12px;
    min-width: 100px;
  }

  .portfolio__container .portfolio__table .table__content{
    border: none;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }

  .portfolio__container .portfolio__table .table__content .content__row{
    display: flex;
    flex-direction: column;
    grid-row-gap: 0px;
    row-gap: 0px;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0 10px;
  }

  .portfolio__container .portfolio__table .table__content .content__row .cont__row{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .portfolio__container .portfolio__table .table__content .content__row .cont__row>div{
    display: flex;
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
  }

  .portfolio__container .portfolio__table .table__content .content__row .cont__row:nth-child(1){
    font-size: 14px;
    font-weight: 700;
  }
}

.fadeOut{
    opacity:0;
    transition: opacity 1s;
  
  }
  .fadeIn{
    opacity:1;
    transition: opacity 1s 0.1s;
  }
.apply__smallcase__container .smallcase__container__loading {
    width: 100%;
    height: 100%;
    grid-row-gap: 10px;
    row-gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;
}

.apply__smallcase__container .smallcase__container__loading>p {
    font-size: 14px;
    font-weight: 600;
}
.apply__smallcase__container .capital__selection__input {
    margin: 0 0px;
    display: flex;
    align-items: center;
    position: relative;
}

.capital__selection__input .custom__capital {
    height: 35px;
    width: 200px;
    border-radius: 3px;
    outline: none;
    box-shadow: none;
    border: 1px solid #e8e8e8;
    padding: 0px 10px;
    font-size: 11px;
    color: var(--font-primary);
}

.capital__selection__input .currency__symbol {
    position: absolute;
    font-size: 11px;
    left: 5px;
}

.min__cash__hint {
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 600;
    margin-top: 0px;
    display: flex;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    align-items: center;
}

.already__applied {
    font-size: 12px;
    font-weight: 500;
}

.apply__smallcase__container .custom-select .custom-select-container {
    max-height: 200px;
    overflow: scroll;
}

.portfolio__selection .custom__portfolio {
    margin: 0 20px;
}

.apply__smallcase__container {
    width: 100%;
    height: 100%;
    padding: 0px 0px;
    position: relative;
    display: flex;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.apply__smallcase__container .apply__smallcase__success {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.apply__smallcase__success .success__message {
    margin-top: 14px;
    font-weight: 500;
    color: var(--font-primary);
}

.apply__smallcase__success .success__info {
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
}

.apply__smallcase__success .success__info a {
    text-decoration: none;
    color: var(--font-primary);
    font-weight: 700;
}

.apply__smallcase__heading>span {
    position: absolute;
    right: 1%;
    top: 1%;
    margin-left: 4px;
    cursor: pointer;
}

.apply__smallcase__heading>span>img {
    width: 30px;
}

.apply__smallcase__content {
    /* height: 35%; */
    display: flex;
    flex-direction: column;
    width: 340px;
    height: 100%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 15px 15px;
}

.apply__smallcase__content .portfolio__customer {
    width: 100%;
    height: 80px;
}

.apply__smallcase__content .customer__options {
    display: flex;
    flex-direction: column;
}

.cash__position__value {
    font-size: 12px;
    font-weight: 500;
    color: var(--font-primary);
}

.selection__wrapper {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: flex-start;
    margin: 5px 0;
    width: 100%;
    height: 80px;
}

.selection__wrapper .selection__label {
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 500;
    width: 140px;
}

.portfolio__selection input {
    width: 200px;
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
    outline: none;
    /* border: 1px solid #e8e8e8; */
    border-radius: 3px;
    color: var(--font-primary);
}

.portfolio__selection input::-webkit-input-placeholder {
    color: var(--font-primary) !important;
}

.portfolio__selection input:-ms-input-placeholder {
    color: var(--font-primary) !important;
}

.portfolio__selection input::placeholder {
    color: var(--font-primary) !important;
}

.portfolio__selection>span {
    margin: 0 10px;
    font-size: 12px;
}

.smallcase__info {
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
    row-gap: 5px;
}

.cash__wrapper {
    display: flex;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
}

.apply__smallcase__content div button {
    margin: 0 10px 0 0px;
    background-color: var(--white);
    border: 1px solid #e8e8e8;
    padding: 7px 15px 7px 15px;
    font-size: 11px;
    font-weight: 700;
    color: var(--font-primary);
    border-radius: 3px;
    transition: 0.3s;
}

.smallcase__portfolio__stocks.empty {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #f5f5f5;
    border-radius: 3px;
    width: 1000px;
    height: 100%;
}

.smallcase__portfolio__stocks.empty>img {
    width: 140px;
}

.smallcase__portfolio__stocks.empty>p {
    text-align: center;
    font-size: 14px;
    margin-top: 15px !important;
    font-weight: 500;
}

.smallcase__portfolio__stocks {
    height: 100%;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
}

.smallcase__portfolio__stocks .smallcase__portfolio__table {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #f5f5f5;
    border-radius: 15px;
}

.smallcase__portfolio__stocks table {
    width: 100%;
    border-collapse: collapse;
}

.smallcase__portfolio__stocks table thead {
    /* position: sticky; */
    /* inset-block-start: 0; */
    /* z-index: 1; */
    width: 100%;
    background-color: var(--white);
    box-shadow: 0 1px 1px #f5f5f5;
}


.smallcase__portfolio__stocks table thead tr td {
    text-align: left;
    padding: 5px 10px 5px 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}

.smallcase__portfolio__stocks table thead tr td:first-child {
    width: 150px;
}

.smallcase__portfolio__stocks table tbody tr{
    border-bottom: 1px solid #f8f8f8;
}

.smallcase__portfolio__stocks table tbody tr td {
    padding: 10px 10px;
    font-size: 13px;
    text-align: left;
    display: table-cell !important;
    font-weight: 500;
}

.smallcase__portfolio__stocks table tbody tr td:nth-child(2),
.smallcase__portfolio__stocks table tbody tr td:nth-child(7),
.smallcase__portfolio__stocks table tbody tr td:nth-child(8),
.smallcase__portfolio__stocks table tbody tr td:nth-child(10) {
    font-weight: 700;
}

.smallcase__portfolio__stocks table tbody .portfolio__stock__code {
    display: flex;
    align-items: center;
}

.smallcase__portfolio__stocks table tbody .portfolio__stock__code .stock__status {
    width: 5px;
    height: 5px;
    background-color: var(--main-blue);
    border-radius: 100%;
}

.smallcase__portfolio__stocks table tbody .portfolio__stock__code .stock__code {
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 600;
}

.smallcase__portfolio__stocks table tbody .portfolio__stock__name {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--font-primary);
    font-size: 9px;
    font-weight: 500;
    letter-spacing: -0.2px;
}

.smallcase__portfolio__stocks table tbody .remove__stock {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.apply__smallcase__container .apply__smallcase__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.apply__smallcase__footer .smallcase__values {
    display: flex;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
}

.apply__smallcase__footer .smallcase__values p {
    border-radius: 5px;
    padding: 0px 0px;
    font-size: 11px;
    font-weight: 700;
}

.apply__smallcase__container .apply__smallcase__button button {
    background-color: var(--white);
    border: 1px solid #e8e8e8;
    padding: 8px 15px 8px 15px;
    font-size: 12px;
    font-weight: 500;
    color: var(--font-primary);
    border-radius: 3px;
    transition: 0.3s;
}

.load__smallcase__btn {
    width: 140px;
    height: 35px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 3px;
    border: 1px solid #e8e8e8;
    color: var(--font-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}


.smallcase__customer__table {
    border: none;
    border-radius: 15px;
    width: calc(100% - 340px);
    height: 100%;
    position: relative;
}

.smallcase__customer__table.full{
    width: 100%;
}

.smallcase__customer__table .scheme-data-wrapper{
    width: 100%;
    height: 90%;
    overflow-y: scroll;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 0px 0px 10px;
}

.scheme-data-wrapper .scheme-row{
    display: flex;
    width: 100%;
    align-items: center;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    border-bottom: 1px solid #f5f5f5;
    padding: 10px 15px 10px;
}

.scheme-data-wrapper .scheme-row .scheme-row-box{
    flex: 3 1;
    height: 40px;
    display: flex;
    align-items: center;
}

.scheme-data-wrapper .scheme-row .scheme-row-box.strategy{
    flex: 4 1;
}

.scheme-data-wrapper .scheme-row .scheme-row-box.arrow,
.scheme-data-wrapper .scheme-row .scheme-row-box.remove{
    flex: 1 1;
    cursor: pointer;
}

.scheme-data-wrapper .scheme-row .scheme-row-box span{
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
}

.scheme-data-wrapper .scheme-row:nth-child(1) .scheme-row-box span{
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
}

.smallcase__customer__table .customer__table__empty {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 15px;
}

.smallcase__customer__table .customer__table__empty>p {
    text-align: center;
    font-size: 14px;
    margin-top: 15px !important;
    font-weight: 500;
}

.smallcase__customer__table .customer__table__wrapper.hide{
    display: none;
}

.smallcase__customer__table .customer__table__wrapper {
    width: auto;
    overflow-y: scroll;
    border: 1px solid #f5f5f5;
    border-radius: 15px;
    display: block;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
}

.customer__table__wrapper table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.customer__table__wrapper table thead {
    position: -webkit-sticky;
    position: sticky;
    inset-block-start: 0;
    z-index: 1;
    width: 100%;
    background-color: var(--white);
    box-shadow: 0 1px 1px #f5f5f5;
}


.customer__table__wrapper table thead tr td {
    text-align: left;
    padding: 5px 15px 5px 15px;
    font-weight: 700;
    font-size: 11px;
    line-height: 20px;
}

.customer__table__wrapper table tbody tr td {
    padding: 10px 15px;
    font-size: 13px;
    text-align: left;
    display: table-cell !important;
    font-weight: 500;
}

.customer__table__wrapper table td.customer__name {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .customer__table__wrapper table td.customer__portfolio,.customer__table__wrapper table td.customer__strategy{
    width: 180px;
} */
.customer__table__wrapper table td.customer__remove {
    width: 60px;
    cursor: pointer;
}

.customer__table__wrapper table td.customer__investment input {
    width: 100%;
    height: 25px;
    background-color: transparent;
    border: 1px solid #f5f5f5;
    border-radius: 3px;
    padding-left: 5px;
    color: var(--font-primary);
    font-weight: 600;
    font-size: 11px;
    outline: none;
}

.customer__table__wrapper table td.customer__investment input:focus {
    border-color: #d8d8d8;
}

.smallcase__customer__table .customer__smallcase__buttons {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.smallcase__customer__table .customer__smallcase__buttons button {
    padding: 10px 20px;
    background-color: transparent;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    color: var(--font-primary);
}

.smallcase__customer__table .smallcase__customer__loading {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 15px;
    row-gap: 15px;
    background-color: rgba(255, 255, 255, 0.90);
    border: 1px solid #f5f5f5;
    border-radius: 15px;
    padding-top: 100px;
    z-index: 10000;
}

.smallcase__customer__table .smallcase__customer__loading>p {
    font-size: 14px;
    font-weight: 600;
}

.smallcase__customer__table .customer__stocks__table{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 0px;
    overflow-y: scroll;
}

.smallcase__customer__table .customer__stocks__table .customer__stocks__row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px 10px;
    border-bottom: 1px solid #f5f5f5;
}

.customer__stocks__row .stocks__row__config{
    display: flex;
    align-items: center;
    grid-column-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    width: 100%;
}

.customer__stocks__table .customer__stocks__row .config__box {
    display: flex;
    flex-direction: column;
    grid-column-gap: 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    flex: 5 1;
}

.customer__stocks__row .config__box span {
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
}

.customer__stocks__row .config__box.name{
    flex: 6 1;
}

.customer__stocks__row .config__box.name span,.customer__stocks__row .config__box.strategy span{
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customer__stocks__row .config__box.arrow, .customer__stocks__row .config__box.remove {
    flex: 2 1;
    cursor: pointer;
}

.customer__stocks__row:nth-child(1) .config__box span {
    font-weight: 700;
}

.customer__stocks__table .stocks__container__wrapper{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    width: 100%;
}

.customer__stocks__table .stocks__container__wrapper.hide{
    display: none;
}

.stocks__container__wrapper .stocks__options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.stocks__container__wrapper .stocks__options>p {
    font-size: 12px;
    font-weight: 700;
}

.stocks__container__wrapper .customer__stock__search {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f5f5f5;
    width: 280px;
    padding: 0 10px;
    border-radius: 5px;
}

.customer__stock__search input {
    background-color: transparent;
    height: 100%;
    width: 80%;
    outline: none;
    color: var(--font-primary);
    font-weight: 600;
    font-size: 12px;
    padding: 10px 0px;
    border: none;
    outline: none;
}

.customer__stock__search .filter-icon {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.select-search-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-row-gap: 3px;
  row-gap: 3px;
}

.select-search-wrapper .search-title {
  font-size: 12px;
  font-weight: 500;
}

.select-search-wrapper .select-search-box {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  border-radius: 5px;
  position: relative;
}

.select-search-box input {
  flex: 5 1;
  height: 40px;
  border: none;
  background-color: transparent;
  outline: none;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.select-search-box input::-webkit-input-placeholder {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.select-search-box input:-ms-input-placeholder {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.select-search-box input::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.select-search-box .filter-icon {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-search-wrapper .select-search-box .select-search-result {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 10000;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0px;
  row-gap: 0px;
  padding: 6px 0;
  max-height: 250px;
  overflow-y: scroll;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 8px 0px;
}

.select-search-box .select-search-result .result-row {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  padding: 12px 0 12px 12px;
  border-bottom: 1px solid #f5f5f5;
}

.select-search-box .select-search-result .result-row:hover {
  background-color: #f9f9f9;
}
.swift-transaction-management .strategy-content {
  position: relative;
}

.trasaction-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.trasaction-header .trasaction-title {
  font-size: 18px;
  /* color: var(--font-primary); */
  color: rgba(1, 22, 39, 0.9) 98;
  font-weight: 700;
}

.orders-wrapper {
  height: 85%;
  padding: 0 0px;
}

.orders-wrapper.full {
  height: 95%;
}

.orders-wrapper .orders-menu {
  display: flex;
  padding: 0 0px;
}

.orders-wrapper .orders-menu .orders-menu-option {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  border: 1px solid var(--white);
  padding: 8px 20px;
  border-radius: 3px;
  transition: 0.3s;
}

.orders-menu .orders-menu-option.active {
  border-color: #e8e8e8;
}

.orders-wrapper .orders-menu .orders-menu-option > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders-menu .orders-menu-option svg {
  width: 18px;
  height: 18px;
  fill: var(--font-primary);
}

.orders-menu .orders-menu-option span {
  font-size: 12px;
  color: var(--font-primary);
  margin-left: 5px;
  font-weight: 700;
}

.orders-wrapper .table-container,
.orders-wrapper .table-loader {
  background-color: var(--white);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding: 0px 0px 5px;
  overflow-y: scroll;
  padding: 10px 15px;
  border: 1px solid #f5f5f5;
}

.orders-wrapper .table-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
}

.orders-wrapper .table-loader > p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px !important;
}

.orders-wrapper .order-trades-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.orders-wrapper .order-trades-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
}

.orders-wrapper .order-trades-config {
  display: flex;
  align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  width: 100%;
}

.orders-wrapper .order-trades-config .expand-collapse-box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.orders-wrapper .order-trades-config .config-box {
  display: flex;
  flex-direction: column;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  flex: 4 1;
}

.orders-wrapper .order-trades-config .config-box.name span {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orders-wrapper .order-trades-config .config-box.arrow,
.orders-wrapper .order-trades-config .config-box.select,
.orders-wrapper .order-trades-config .config-box.remove {
  flex: 1 1;
}

.orders-wrapper .order-trades-config .config-box.remove {
  cursor: pointer;
}

.order-trades-config .config-box span {
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
}

.order-trades-config .config-box.trade span,
.order-trades-config .config-box.sell span,
.order-trades-config .config-box.buy span {
  font-weight: 600;
}

.order-trades-config .config-box select {
  background-color: transparent;
  color: var(--font-primary);
  border: 1px solid #f5f5f5;
  outline: none;
  font-size: 12px;
  border-radius: 5px;
  padding: 2px 4px;
}

/* 
.orders-wrapper .order-trades-config .config-box.count {
  flex: 2;
}

.orders-wrapper .order-trades-config .config-box.sell,
.orders-wrapper .order-trades-config .config-box.buy,
.orders-wrapper .order-trades-config .config-box.trade{
  flex: 3;
} */

.order-trades-section:nth-child(1) .order-trades-config .config-box span {
  font-weight: 700;
}

.order-trades-header .config-box.execute {
  flex: 5 1;
}

.order-trades-header .config-box.execute button {
  border: none;
  outline: none;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 16px;
  transition: 0.3s;
}

.order-trades-header .config-box.execute button:hover {
  background-color: #f1f1f1;
}

.orders-wrapper .order-trades-container-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 100%;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.orders-wrapper .order-trades-container-wrapper.hide {
  display: none;
}

.orders-wrapper .order-trades-options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.orders-wrapper .order-trades-options > p {
  font-size: 12px;
  font-weight: 700;
}

.orders-wrapper .order-trades-search {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f5f5f5;
  width: 280px;
  padding: 0 10px;
  border-radius: 5px;
}

.order-trades-search input {
  background-color: transparent;
  height: 100%;
  width: 80%;
  outline: none;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  padding: 10px 0px;
  border: none;
  outline: none;
}

.order-trades-search input::-webkit-input-placeholder {
  font-weight: 500;
}

.order-trades-search input:-ms-input-placeholder {
  font-weight: 500;
}

.order-trades-search input::placeholder {
  font-weight: 500;
}

.order-trades-search .filter-icon {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.orders-wrapper .order-trades-container,
.orders-wrapper .orders-history-container,
.orders-wrapper .orders-files-container,
.orders-wrapper .orders-transactions-container {
  border-radius: 15px;
  width: 100%;
  padding: 0px 0px 5px;
  overflow-y: scroll;
  padding: 0 0px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  position: relative;
}

.orders-wrapper .orders-history-container,
.orders-wrapper .orders-transactions-container {
  height: 100%;
  overflow-y: scroll;
}

.orders-history-container .orders-history-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
}

.orders-history-container .orders-history-loading > p {
  font-size: 14px;
  font-weight: 600;
}

.orders-wrapper .order-trades-container table,
.orders-wrapper .orders-history-container table,
.orders-wrapper .orders-transactions-container table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.order-container-empty,
.orders-history-empty,
.orders-transactions-empty {
  width: 100%;
  min-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-container-empty > img,
.orders-history-empty > img,
.orders-files-empty > img,
.orders-transactions-empty > img {
  width: 100px;
}

.order-container-empty > div,
.orders-history-empty > div,
.orders-files-empty > div,
.orders-transactions-empty > div {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-container-empty > div > p,
.orders-history-empty > div > p,
.orders-files-empty > div > p,
.orders-transactions-empty > div > p {
  font-size: 18px !important;
  color: var(--font-primary);
  letter-spacing: -0.5px;
  font-weight: 600;
}

.order-container-empty > div > span,
.orders-history-empty > div > span,
.orders-files-empty > div > span,
.orders-transactions-empty > div > span {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  color: var(--font-primary);
}

.order-trades-container table thead,
.orders-history-container table thead,
.orders-transactions-container table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.order-trades-container table thead tr td,
.orders-history-container table thead tr td,
.orders-transactions-container table thead tr td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.order-trades-container table thead tr td:nth-child(1) {
  width: 200px;
}

.order-trades-container.orders table thead tr td:nth-child(10) {
  width: 40px;
}

.order-trades-container.trades table thead tr td:nth-child(11) {
  width: 60px;
}

.order-trades-container.trades table td.order-item-remove,
.order-trades-container.trades table td.order-item-type {
  cursor: pointer;
}

.orders-history-container table tr td:nth-child(1) {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orders-history-container table thead tr td:nth-child(5),
.orders-history-container table thead tr td:nth-child(6),
.orders-history-container table thead tr td:nth-child(7),
.orders-history-container table thead tr td:nth-child(10) {
  width: 100px;
}

/* .order-trades-container table thead tr th:nth-child(8)
{
  text-align: center;
} */

.order-trades-container table tbody,
.orders-history-container table tbody,
.orders-transactions-container table tbody {
  width: 100%;
}

.order-trades-container table tbody tr,
.orders-history-container table tbody tr,
.orders-transactions-container table tbody tr {
  cursor: auto;
  cursor: initial;
  transition: 0.3s;
  border-bottom: 1px solid #f5f5f5c5;
}

.order-trades-container table tbody tr:hover,
.orders-history-container table tbody tr:hover,
.orders-transactions-container table tbody tr:hover {
  background-color: #fcfcfc;
}

.order-trades-container table tbody tr td,
.orders-history-container table tbody tr td,
.orders-transactions-container table tbody tr td {
  padding: 14px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.order-trades-container table tbody tr td.order-item-stock-name {
  position: relative;
}

.order-trades-container table tbody tr td .order-item-error {
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: 60%;
  transform: translateY(-50%);
  background-color: var(--main-red);
  border-radius: 10px;
}

.orders-transactions-container table tr td.status {
  width: 100px;
}

.orders-transactions-container table thead tr td.modify,
.orders-transactions-container table thead tr td.delete {
  width: 50px;
}

.orders-transactions-container table tbody tr td.modify,
.orders-transactions-container table tbody tr td.delete {
  cursor: pointer;
}

.order-trades-container table tbody tr td.order-item-type {
  cursor: pointer;
}

.order-trades-container table tbody tr td select {
  background-color: transparent;
  color: var(--font-primary);
  border: 1px solid #f5f5f5;
  outline: none;
  font-size: 12px;
  border-radius: 5px;
  padding: 2px 4px;
}

/* .order-trades-container table tbody tr td:nth-child(1) {
  width: 180px;
}

*/
.order-trades-container table tbody tr td .stock-info {
  display: flex;
  flex-direction: column;
}

.order-trades-container table tbody tr td .stock-info .stock-name {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
}

.order-trades-container table tbody tr td .stock-info .stock-fullname {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-left: 8px; */
  color: var(--font-primary);
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.2px;
}

.order-trades-container table tbody tr td .order-item-type-box {
  display: flex;
  align-items: center;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.order-trades-container table tbody tr td:nth-child(9) {
  min-width: 60px;
}

.order-trades-container table tbody tr td.order-item-remove {
  padding: 8px 10px;
  cursor: pointer;
}

.order-trades-container table tr td.order-item-remove {
  width: 60px;
}

.order-trades-container table tbody tr td .modify-order,
.order-trades-container table tbody tr td .cancel-order {
  color: var(--white);
  border-radius: 3px;
  transition: 0.3s;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
}

.order-trades-container table tbody tr td .modify-order > img,
.order-trades-container table tbody tr td .cancel-order img {
  width: 18px;
}

.order-trades-container table tbody tr td .modify-order:hover,
.order-trades-container table tbody tr td .cancel-order:hover {
  border: 2px solid #404040;
}

.order-trades-container table tbody tr td .buy {
  color: var(--main-green);
  font-weight: 500;
}

.order-trades-container table tbody tr td .sell {
  color: var(--main-red);
  font-weight: 500;
}

.order-trades-container table tbody tr td .complete,
.order-trades-container table tbody tr td .cancelled,
.order-trades-container table tbody tr td .pending,
.order-trades-container table tbody tr td .rejected {
  border-radius: 2px;
  padding: 4px 0;
  font-weight: 500;
  display: flex;
  /* justify-content: center; */
}

/* order files */

.orders-wrapper .orders-files-loading,
.orders-wrapper .orders-files-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
}

.orders-wrapper .orders-files-loading > p {
  font-size: 14px;
  font-weight: 600;
}

.orders-wrapper .orders-files-container {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  height: 100%;
  overflow-y: hidden;
}

.orders-files-container .orders-files-data {
  width: 25%;
  height: 100%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 0px;
}

.orders-files-container .orders-files-data > p {
  font-size: 13px;
  font-weight: 700;
  height: 5%;
  padding: 0 15px;
}

.orders-files-data .orders-file-info {
  width: 100%;
  height: 95%;
  overflow-y: scroll;
  padding: 0 10px;
}

.orders-file-info .orders-file-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 5px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.orders-file-info .orders-file-row > p {
  font-size: 13px;
  font-weight: 600;
}

.orders-file-info .orders-file-row > span {
  font-size: 11px;
  font-weight: 500;
  margin-top: -5px;
}

.orders-file-info .orders-file-row:hover {
  background-color: #fafafa;
}

.orders-files-container .orders-files-preview {
  width: 75%;
  height: 100%;
  border-radius: 15px;
  padding: 15px 15px;
  overflow-y: scroll;
  position: relative;
}

.orders-files-container .orders-files-preview.active {
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
}

.orders-files-preview .files-loading{
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 20px;
  row-gap: 20px;
  background-color: rgba(255, 255, 255, 0.75);
}

.orders-files-preview .files-loading p{
  font-size: 14px;
}

.orders-files-preview .files-preview-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.orders-files-preview .files-preview-header > p {
  font-size: 13px;
  font-weight: 600;
}

.orders-files-preview .files-preview-header > div {
  font-size: 13px;
  cursor: pointer;
  transition: 0.3s;
  color: var(--font-secondary);
  cursor: pointer;
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.orders-files-preview .files-preview-header > div a,
.orders-files-preview .files-preview-header > div span {
  text-decoration: none;
  color: var(--font-primary);
  background-color: #f1f1f1;
  border: 1px solid #e8e8e8;
  padding: 8px 20px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
}

.orders-files-preview .files-preview-table {
  width: 100%;
  border-radius: 15px;
  border: 1px solid #f5f5f5;
  margin-top: 20px;
  overflow-y: scroll;
}

.orders-files-preview .files-preview-table table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.files-preview-table table thead {
  border-bottom: 1px solid #f5f5f5;
}

.files-preview-table table thead td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
}

.files-preview-table table tbody tr {
  border-bottom: 1px solid #f5f5f5c5;
}

.files-preview-table table tbody td {
  text-align: left;
  padding: 10px 10px 10px 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.files-preview-table tbody td:nth-child(2) {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-preview-table tbody tr td .stock-name-code {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.files-preview-table tbody tr td .stock-name-code > p {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  line-height: 14px;
}

.files-preview-table tbody tr td .stock-name-code > span {
  width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.2px;
  line-height: 12px;
}

.files-preview-table thead tr td.name {
  width: 150px;
}

.files-preview-table thead tr td.side,
.files-preview-table thead tr td.quantity,
.files-preview-table thead tr td.type {
  width: 90px;
}

.orders-execution {
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.orders-wrapper .order-execution-result {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100000;
  display: flex;
  justify-content: center;
}

.order-execution-result .execution-result-box {
  margin-top: 40px;
  width: 450px;
  height: 500px;
  padding: 0px 25px;
  background-color: white;
  box-shadow: 1px 3px 5px #e8e8e8;
}

.execution-result-box .result-header {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-header > p {
  font-size: 13px;
  font-weight: 700;
}

.result-header .result-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.execution-result-box .result-table {
  width: 100%;
  height: 430px;
  overflow-y: scroll;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
}

.result-table table {
  width: 100%;
  max-height: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.result-table table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.result-table table tr td {
  font-size: 12px;
  font-weight: 500;
  padding: 10px 12px;
}

.result-table table thead tr td {
  font-weight: 700;
}

.result-table table tbody tr td {
  vertical-align: top;
}

.result-table table tbody tr td a {
  color: var(--font-primary);
  background-color: var(--white);
  border: 1px solid var(--font-primary);
  padding: 4px 12px;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
}

.orders-entry-wrapper {
  width: 440px;
  height: 95%;
  padding: 0px 15px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.orders-entry-wrapper .loading-wrapper {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.orders-entry-wrapper > p {
  font-size: 14px;
  font-weight: 500;
}

.orders-entry-wrapper .entry-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
  padding-top: 15px;
  position: relative;
}

.orders-entry-wrapper .entry-box .entry-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  position: absolute;
  padding-top: 100px;
  background-color: hsla(0, 0%, 100%, 0.85);
  z-index: 1000;
  font-size: 14px;
  font-weight: 600;
}

.orders-entry-wrapper .entry-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.orders-entry-wrapper .entry-row.submit {
  justify-content: flex-end;
}

.orders-entry-wrapper .entry-box .swift-date-wrapper,
.orders-entry-wrapper .entry-box .swift-input-box {
  height: auto;
}

.orders-entry-wrapper .entry-box .entry-box-half {
  width: 50%;
}

.orders-entry-wrapper .entry-stock-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 3px;
  row-gap: 3px;
}

.orders-entry-wrapper .entry-stock-search .search-title {
  font-size: 12px;
  font-weight: 500;
}

.entry-stock-search .search-wrapper {
  width: 100%;
  position: relative;
}

.entry-stock-search .search-wrapper input {
  width: 100%;
  height: 40px;
  border: none;
  background-color: transparent;
  outline: none;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  text-transform: uppercase;
}

.entry-stock-search .search-wrapper input:focus {
  border-color: #d8d8d8;
}

.entry-stock-search .search-wrapper input::-webkit-input-placeholder {
  text-transform: none;
  text-transform: initial;
}

.entry-stock-search .search-wrapper input:-ms-input-placeholder {
  text-transform: none;
  text-transform: initial;
}

.entry-stock-search .search-wrapper input::placeholder {
  text-transform: none;
  text-transform: initial;
}

.entry-stock-search .search-wrapper .entry-stock-suggessions {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  height: 200px;
  max-height: 200px;
  background-color: white;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 5px 5px;
  z-index: 1000;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0px;
  row-gap: 0px;
}

.search-wrapper .entry-stock-suggessions .suggession-row {
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.search-wrapper .entry-stock-suggessions .suggession-row:hover {
  background-color: #f5f5f5;
}

.entry-stock-suggessions .suggession-row span:nth-child(1) {
  flex: 4 1;
  font-size: 12px;
  font-weight: 500;
}

.entry-stock-suggessions .suggession-row span:nth-child(2) {
  flex: 9 1;
  font-size: 12px;
}

.entry-stock-suggessions .suggession-row span:nth-child(3) {
  flex: 1 1;
  font-size: 12px;
  font-weight: 500;
}

.managed-holdings {
  width: 100%;
  height: 100%;
  position: relative;
}

.managed-holdings.loader,
.managed-holdings.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 5px;
  row-gap: 5px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.managed-holdings.loader > p,
.managed-holdings.empty > p {
  font-size: 16px;
  font-weight: 600;
}

.managed-holdings.empty > span {
  font-size: 14px;
}

.managed-holdings .managed-holdings-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.managed-holdings .managed-holdings-loading > p {
  font-weight: 14px;
  font-weight: 600;
}

.managed-holdings .managed-holdings-table {
  width: 100%;
  height: calc(100% - 60px);
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.managed-holdings table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.managed-holdings table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.managed-holdings table tr td:nth-child(1) {
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.managed-holdings table thead tr td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.managed-holdings table tbody tr {
  cursor: auto;
  cursor: initial;
  transition: 0.3s;
  border-bottom: 1px solid #f5f5f5c5;
}

.managed-holdings table tbody tr td {
  padding: 14px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.managed-holdings table tr td.apply {
  width: 120px;
}

.managed-holdings table tr td button.apply-btn {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  background-color: #f5f5f5;
  padding: 6px 20px;
  border-radius: 20px;
  color: var(--font-primary);
  border: 1px solid #e8e8e8;
}

.managed-holdings .managed-holdings-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.order-type-popup {
  width: 900px;
  height: 580px;
  background-color: #ffffff;
  padding: 10px 20px;
  position: relative;
  border-radius: 5px;
  cursor: auto;
  cursor: initial;
}

.order-type-popup .order-type-loader {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}

.order-type-popup .order-type-header {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 12px;
  border-bottom: 1px solid #f5f5f5;
}

.order-type-popup .order-type-footer {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-top: 1px solid #f5f5f5;
}

.order-type-header .order-stock-info {
  display: flex;
  align-items: center;
  height: 100%;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.order-type-header .order-stock-info .order-buysell {
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0px;
  background-color: #e8e8e8;
}

.order-type-header .order-stock-info .order-buysell.buy {
  background-color: var(--main-green-light);
}

.order-type-header .order-stock-info .order-buysell.sell {
  background-color: var(--main-red-light);
}

.order-type-header .order-stock-info > p {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.order-type-header .order-stock-close {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-type-popup .order-type-body {
  width: 100%;
  height: calc(100% - 50px - 60px);
  display: flex;
  flex-direction: row;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.order-type-popup .order-type-body .order-type-section {
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
  width: 50%;
  padding: 10px 0;
}

.order-type-body .order-depth-section {
  width: 50%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.order-type-body .order-customer-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.order-type-body .order-customer-info .customer-info-box {
  display: flex;
  flex-direction: column;
  grid-row-gap: -2px;
  row-gap: -2px;
}

.order-customer-info .customer-info-box span {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.order-customer-info .customer-info-box p {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.order-type-body .order-type-select-wrapper {
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.order-type-select-wrapper > p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.order-type-select-wrapper .order-type-select {
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.order-type-popup .order-type-select span {
  width: 100px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-secondary);
  cursor: pointer;
  transition: 0.2s;
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
}

.order-type-popup .order-type-select span.active {
  color: var(--font-primary);
  font-weight: 700;
  background-color: #f5f5f5;
}

.order-type-body .order-type-info-wrapper {
  display: flex;
  flex-direction: column;
  grid-row-gap: 4px;
  row-gap: 4px;
}

.order-type-info-wrapper > p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.order-type-info-wrapper > p > span {
  font-weight: 600;
}

.order-type-popup .order-type-input-wrapper {
  width: 100%;
  display: flex;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.order-type-input-wrapper .order-type-input {
  flex: 1 1;
}

.order-type-input .price-box {
  width: 100%;
}

.order-type-popup .error-text {
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.order-type-popup .submit-button {
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.order-type-popup .order-type-body .note {
  font-size: 13px;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .orders-wrapper.full {
    height: 100%;
  }

  .orders-wrapper .table-loader > p {
    font-size: 16px;
  }

  .orders-history-container .orders-history-loading > p {
    font-size: 16px;
  }

  .orders-wrapper .orders-transactions-container,
  .orders-wrapper .orders-history-container {
    border: none;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }

  .orders-wrapper .orders-history-container::-webkit-scrollbar {
    width: 0;
  }

  .orders-wrapper .orders-transactions-container .transaction-row,
  .orders-wrapper .orders-history-container .orders-history-row {
    display: flex;
    flex-direction: column;
    grid-row-gap: 2px;
    row-gap: 2px;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0 10px;
  }

  .orders-wrapper .orders-transactions-container .transaction-row .tran-row,
  .orders-wrapper .orders-history-container .orders-history-row .history-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  }

  .orders-wrapper
    .orders-transactions-container
    .transaction-row
    .tran-row:nth-child(1),
  .orders-wrapper
    .orders-transactions-container
    .transaction-row
    .tran-row:nth-child(3),
  .orders-wrapper
    .orders-history-container
    .orders-history-row
    .history-row:nth-child(1),
  .orders-wrapper
    .orders-history-container
    .orders-history-row
    .history-row:nth-child(3) {
    font-size: 13px;
    color: var(--font-secondary);
  }

  .orders-wrapper
    .orders-transactions-container
    .transaction-row
    .tran-row:nth-child(2),
  .orders-wrapper
    .orders-history-container
    .orders-history-row
    .history-row:nth-child(2) {
    font-weight: 700;
  }

  .orders-wrapper
    .orders-transactions-container
    .transaction-row
    .tran-row
    > div,
  .orders-wrapper
    .orders-history-container
    .orders-history-row
    .history-row
    > div {
    display: flex;
    align-items: center;
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
  }

  .orders-wrapper .table-container,
  .orders-wrapper .table-loader {
    border: none;
    padding: 0;
    border-radius: 0;
  }

  .orders-wrapper .table-container {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .orders-execution {
    display: none;
  }

  .orders-wrapper .order-trades-section {
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  .orders-wrapper .order-trades-header {
    padding: 0px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
  }

  .orders-wrapper .order-trades-config {
    display: flex;
    flex-direction: column;
    grid-row-gap: 8px;
    row-gap: 8px;
    width: 100%;
  }

  .orders-wrapper .order-trades-config .trades-config-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .orders-wrapper .order-trades-config .trades-config-row > div {
    display: flex;
    align-items: center;
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
  }

  .orders-wrapper .order-trades-config .trades-config-row > div > p {
    font-size: 14px;
  }

  .orders-wrapper .order-trades-config .trades-config-row > div > span {
    font-size: 14px;
    font-weight: 700;
  }

  .orders-wrapper .order-trades-config .trades-config-row > div.mobile {
    align-items: flex-end;
  }

  .orders-wrapper .order-trades-container-wrapper {
    width: 100%;
    height: 100%;
    background-color: white;
    inset: 0;
    padding: 0;
  }

  .orders-wrapper .order-trades-container-wrapper.hide {
    display: inline;
    display: initial;
  }

  .orders-wrapper .order-trades-container {
    border: none;
    padding: 10px 0px;
    border-radius: 0;
  }

  .orders-wrapper .order-trades-container .order-trades-container-header {
    padding: 6px 0 6px;
    width: 100%;
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .orders-wrapper .order-trades-container .order-trades-container-header > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .orders-wrapper .order-trades-container .order-trades-container-header > p {
    font-size: 16px;
    font-weight: 700;
  }

  .orders-wrapper .order-trades-container .orders-row-container {
    display: flex;
    flex-direction: column;
    grid-row-gap: 6px;
    row-gap: 6px;
    border-bottom: 1px solid #e8e8e8;
    padding: 14px 0px;
  }

  .orders-wrapper .order-trades-container .order-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .orders-wrapper .order-trades-container .order-row > div {
    display: flex;
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
  }

  .orders-wrapper .order-trades-container .order-row > div > span {
    font-size: 14px;
  }

  .orders-wrapper .order-trades-container .order-row:nth-child(2) {
    font-weight: 700;
  }
}

.family-portfolios {
  width: 100%;
  height: 100%;
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  padding-top: 10px;
}

.family-portfolios .family-portfolios-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  margin-top: 100px;
}

.family-portfolios .family-portfolios-loading > p {
  font-size: 14px;
  font-weight: 600;
}

.family-portfolios-left {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.family-portfolios-left .family-portfolios-header {
  height: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 0px;
}

.family-portfolios-left .family-portfolios-header > p {
  font-size: 13px;
  font-weight: 700;
}

.family-portfolios-header .family-portfolios-add {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.family-portfolios-left .family-portfolios-wrapper {
  width: 100%;
  height: 90%;
  padding: 0px 10px 0px;
  overflow-y: scroll;
}

.family-portfolios-left .family-portfolios-empty {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.family-portfolios-empty > p {
  font-size: 13px;
  font-weight: 600;
}

.family-portfolios-empty > button {
  padding: 8px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  color: var(--font-primary);
  background-color: var(--white);
  margin-top: 15px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.family-portfolios-wrapper .family-portfolio-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 5px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.family-portfolios-wrapper .family-portfolio-card:hover {
  background-color: #fafafa;
}

.family-portfolio-card .family-card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.family-portfolio-card .family-card-header > p {
  font-size: 13px;
  font-weight: 600;
}

.family-portfolio-card .family-card-header > span {
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.family-portfolio-card .family-card-details {
  font-size: 11px;
  font-weight: 500;
  margin-top: -5px;
}

.family-portfolios-right {
  width: 78%;
  height: 100%;
  height: 100%;
  overflow-y: scroll;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.family-portfolios-right .family-portfolio-details-default {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.family-portfolio-details-default > h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--font-primary);
}

.family-portfolio-details-default > p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.family-portfolio-details-default > button {
  padding: 8px 20px;
  border: 1px solid #e8e8e8;
  background-color: var(--white);
  border-radius: 3px;
  color: var(--font-primary);
  margin-top: 15px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.family-portfolio-details {
  width: 100%;
  height: 100%;
  padding: 0 0;
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.family-portfolio-details .family-details-section {
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 25px;
  row-gap: 25px;
  border-radius: 15px;
}
.family-portfolio-details .family-portfolio-customers-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.family-portfolio-customers-empty > p {
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}

.family-portfolio-customers-empty > button {
  padding: 8px 20px;
  border: 1px solid #e8e8e8;
  background-color: var(--white);
  border-radius: 3px;
  color: var(--font-primary);
  margin-top: 15px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.family-portfolio-details .family-portfolio-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  padding-top: 100px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.family-portfolio-details .family-portfolio-loading > p {
  font-size: 14px;
  font-weight: 600;
}

.family-portfolio-details .family-name-options {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
}

.family-portfolio-details .family-name-options .family-name {
  font-size: 16px;
  font-weight: 800;
}

.family-portfolio-details .family-name-options .family-options {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.family-name-options .family-options > span {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  background-color: #f5f5f5;
  padding: 6px 16px;
  border-radius: 20px;
  color: var(--font-primary);
}

.family-name-options .family-options > span:hover {
  color: var(--font-primary);
}

.family-portfolio-details .family-details-title {
  font-size: 12px;
  font-weight: 500;
}

.family-portfolio-details .family-portfolio-customers {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.family-portfolio-customers .customers-header {
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.family-portfolio-customers .customers-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  margin-top: 10px;
}

.family-portfolio-customers .customers-wrapper .customer-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  border: 1px solid #e8e8e8;
  border-radius: 30px;
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.customers-wrapper .customer-box .customer-name {
  font-size: 12px;
  font-weight: 600;
}

.customers-wrapper .customer-box .customer-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.family-portfolios-right .family-portfolio-config {
  width: 100%;
  display: flex;
}

.family-portfolio-config .family-config-box {
  width: 200px;
}

.family-portfolio-config .family-config-box > span {
  font-size: 14px;
  font-weight: 700;
}

.family-portfolios-right .family-portfolio-stocks {
  width: 100%;
}

.family-portfolio-stocks .family-stocks-table {
  width: 100%;
  border-radius: 15px;
  border: 1px solid #f5f5f5;
  margin-top: 10px;
}

.family-stocks-table table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.family-stocks-table table thead {
  border-bottom: 1px solid #f5f5f5;
}

.family-stocks-table thead tr td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
}

.family-stocks-table thead tr td.name {
  width: 155px;
}

.family-stocks-table thead tr td.quantity,
.family-stocks-table thead tr td.weight {
  width: 80px;
}

.family-stocks-table thead tr td.returns,
.family-stocks-table thead tr td.today {
  width: 80px;
}

.family-stocks-table tbody tr {
  border-bottom: 1px solid #f5f5f5c5;
}

.family-stocks-table tbody tr td {
  text-align: left;
  padding: 12px 10px 12px 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.family-stocks-table tbody tr td .stock-name-code {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.family-stocks-table tbody tr td .stock-name-code > p {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  line-height: 14px;
}

.family-stocks-table tbody tr td .stock-name-code > span {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.2px;
  line-height: 12px;
}

.family-portfolios-right .family-portfolio-charts {
  width: 100%;
  display: flex;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  padding: 0 15px;
  padding-bottom: 20px;
}

.family-portfolio-charts .family-sector,
.family-portfolio-charts .family-market {
  width: 580px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.family-portfolio-details .strategy__sector__chart {
  width: 100%;
  height: 300px;
}

.strategy__sector__chart ul::-webkit-scrollbar {
  width: 5px;
}

.strategy__sector__chart ul::-webkit-scrollbar-track {
  background: #ffffff;
}

.strategy__sector__chart ul::-webkit-scrollbar-thumb {
  background: #e8e8e8;
  border-radius: 10px;
}

/* rename family portfolio */
.family-rename-popup {
  width: 450px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--white);
  border-radius: 5px;
  padding: 15px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  position: relative;
}

.family-rename-popup .family-rename-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.family-rename-header .family-rename-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.family-rename-header .family-rename-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.family-rename-popup .family-rename-body {
  padding: 10px 0 20px;
}

.family-rename-popup .family-rename-footer {
  display: flex;
  justify-content: flex-end;
}

/* new family portfolio */
.family-new-popup {
  width: 450px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--white);
  border-radius: 5px;
  padding: 15px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  position: relative;
}

.family-new-popup .family-new-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.family-new-header .family-new-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.family-new-header .family-new-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.family-new-popup .family-new-body {
  padding: 10px 0 20px;
}

.family-new-popup .family-new-footer {
  display: flex;
  justify-content: flex-end;
}

/* family customer add */
.family-customer-popup {
  position: relative;
  width: 450px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--white);
  border-radius: 5px;
  padding: 15px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

.family-customer-popup .family-customer-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.family-customer-header .family-customer-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.family-customer-header .family-customer-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.family-customer-popup .family-customer-body {
  padding: 10px 0 20px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.family-customer-popup .family-customer-footer {
  display: flex;
  justify-content: flex-end;
}

.family-rename-popup .family-rename-popup-loader,
.family-new-popup .family-new-popup-loader,
.family-customer-popup .family-customer-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}

/* responsive ui */

@media only screen and (max-width: 768px) {
  .family-portfolios {
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
    padding-top: 0;
  }

  .family-portfolios-left {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0 10px;
    height: 120px;
  }

  .family-portfolios-left .family-portfolios-header {
    padding: 0px 0px 0px;
    height: 30px;
  }

  .family-portfolios-left .family-portfolios-header > p{
    font-size: 15px;
  }

  .family-portfolio-details-default > h2{
    font-size: 22px;
  }

  .family-portfolio-details-default > p{
    font-size: 15px;
  }

  .family-portfolio-details-default > button {
    font-size: 16px;
    margin-top: 40px;
  }

  .family-portfolios-left .family-portfolios-wrapper {
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    height: 80px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    overflow-y: visible;
    overflow-y: initial;
    overflow-x: scroll;
    padding: 5px 5px;
  }

  .family-portfolios-left .family-portfolios-empty{
    display: none;
  }

  .family-portfolios-left .family-portfolios-wrapper::-webkit-scrollbar {
    display: none;
  }

  .family-portfolios-wrapper .family-portfolio-card {
    width: 200px;
    height: 100%;
    flex-shrink: 0;
    border: 1px solid #e8e8e8;
  }

  .family-portfolios-right {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .family-portfolios-right::-webkit-scrollbar{
    display: none;
  }

  .family-portfolios-right .family-portfolio-details-default {
    border: none;
    justify-content: flex-start;
  }

  .family-new-popup {
    width: 100%;
    height: 100%;
    padding: 15px 10px 20px;
  }

  .family-portfolio-details .family-details-section {
    padding: 0 10px;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .family-portfolio-details .family-name-options {
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    margin-bottom: 0;
  }

  .family-portfolio-details .family-name-options .family-options {
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .family-name-options .family-options > span{
    border-radius: 10px;
    font-size: 13px;
    line-height: 15px;
  }

  .family-portfolio-details .family-details-title{
    font-size: 14px;
    font-weight: 500;
  }

  .family-portfolio-config .family-config-box > span{
    font-size: 16px;
  }

  .customers-wrapper .customer-box .customer-name{
    font-size: 13px;
  }

  .family-portfolios-right .family-portfolio-config {
    display: grid;
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-template-columns: auto auto;
  }

  .family-portfolio-config .family-config-box {
    width: auto;
  }

  .family-portfolio-stocks .family-stocks-table {
    border: none;
  }

  .family-portfolio-stocks .family-stocks-table .family-stocks-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 2px;
    grid-row-gap: 2px;
    row-gap: 2px;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0;
  }

  .family-portfolio-stocks .family-stocks-table .family-stocks-row .fs-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  }

  .family-portfolio-stocks .family-stocks-table .family-stocks-row .fs-row > div {
    display: flex;
    align-items: center;
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
  }

  .family-portfolio-stocks .family-stocks-table .family-stocks-row .fs-row:nth-child(1){
    font-weight: 700;
  }

  .family-portfolio-stocks .family-stocks-table .family-stocks-row .fs-row:nth-child(2),
  .family-portfolio-stocks .family-stocks-table .family-stocks-row .fs-row:nth-child(3){
    font-size: 13px;
    color: var(--font-secondary);
    font-weight: 500;
  }

  .family-portfolios-right .family-portfolio-charts{
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    padding: 0 10px;
  }

  .family-portfolio-charts .family-sector, .family-portfolio-charts .family-market {
    width: 100%;
    padding: 15px 0;
  }

  .family-portfolio-details .strategy__sector__chart{
    height: 400px;
  }

  .family-customer-popup,.family-rename-popup{
    width: 100%;
    height: 100%;
    padding: 15px 10px 20px;
  }

  .family-new-header .family-new-title{
    font-size: 15px;
    font-weight: 700;
  }

}

.reports-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.reports-header .reports-title {
  font-size: 18px;
  /* color: var(--font-primary); */
  color: rgba(1, 22, 39, 0.9) 98;
  font-weight: 700;
}

.reports-header .reports-subtext {
  font-size: 20px;
  font-weight: 300;
}

.report-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  position: relative;
}

.report-dashboard .report-dashboard-left {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.report-dashboard-left .report-generate {
  height: 100%;
  padding: 5px 10px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.report-generate .report-generate-header {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.report-generate-header .report-generate-title {
  font-weight: 600;
  font-size: 14px;
}

.report-generate .report-generate-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-gap: 20px;
  row-gap: 20px;
  padding-top: 10px;
  height: calc(100% - 50px);
  position: relative;
}

.report-generate-content .report-generate-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  position: absolute;
  padding-top: 50px;
  background-color: hsla(0, 0%, 100%, 0.85);
  z-index: 1000;
}

.report-generate-content .report-generate-loading p {
  font-size: 13px;
  font-weight: 600;
}

.report-generate .report-section-customer {
  width: 100%;
}

.report-generate .report-generate-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.report-generate-options .report-section-type {
  width: 100%;
}

.report-generate-options .report-generate-year {
  width: 100%;
}

.report-dashboard .report-section-button {
  margin-top: 10px;
  align-self: flex-end;
}

.report-dashboard-right .report-history {
  height: 100%;
  width: 370px;
  padding: 5px 0px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.report-history .report-history-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.report-history .report-history-loading > p {
  font-size: 13px;
  font-weight: 600;
}

.report-history-content .report-history-empty {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.report-history-content .report-history-empty > p {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

.report-history .report-history-header {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 10px;
}

.report-history .report-history-title {
  font-weight: 600;
  font-size: 14px;
}

.report-history .report-history-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
  overflow-y: scroll;
  padding: 10px 10px 0;
  height: calc(100% - 20px);
}

.report-history .report-history-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.report-history-row .history-left {
  display: flex;
  flex-direction: column;
}

.report-history-row .history-left .report-customer {
  font-size: 13px;
  font-weight: 500;
}

.report-history-row .history-left .report-date {
  font-size: 10px;
  font-weight: 500;
  color: #b8b8b8;
}

.report-history-row .history-right {
  display: flex;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.report-history-row .history-right .report-type {
  font-size: 12px;
  font-weight: 600;
}

.report-history-row .history-right .report-download {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.report-dashboard .report-dashboard-right {
  height: 100%;
  width: 75%;
  display: flex;
  justify-content: space-between;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.report-dashboard-right .report-scheduler-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: hsla(0, 0%, 100%, 0.85);
  z-index: 10000;
  display: flex;
  justify-content: center;
}

.report-scheduler {
  width: 440px;
  padding: 15px 10px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 20px;
  height: 500px;
  background-color: var(--white);
}

.report-scheduler .report-scheduler-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-scheduler .report-scheduler-title {
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  height: 20px;
}

.report-scheduler-header .report-scheduler-close {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-scheduler .report-scheduler-content {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 30px);
}

.report-scheduler .report-scheduler-inputs {
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.report-scheduler .report-section-button {
  align-self: flex-end;
}

.report-dashboard-right .report-schedule {
  width: 600px;
  height: 100%;
  padding: 5px 10px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.report-schedule .report-schedule-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.report-schedule .report-schedule-loading > p {
  font-size: 13px;
  font-weight: 600;
}

.report-schedule .report-schedule-header {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.report-schedule-header .report-schedule-title {
  font-weight: 600;
  font-size: 14px;
}

.report-schedule-header .report-schedule-add {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.report-schedule .report-schedule-content {
  width: 100%;
  padding-top: 10px;
  height: calc(100% - 50px);
}

.report-schedule-content .report-schedule-empty {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.report-schedule-content .report-schedule-empty > p {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

.report-schedule-content .report-schedule-customer {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f5f5f5;
  width: 280px;
  padding: 0 10px;
  border-radius: 5px;
}

.report-schedule-customer input {
  background-color: transparent;
  height: 100%;
  width: 80%;
  outline: none;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  padding: 10px 0px;
  border: none;
  outline: none;
}

.report-schedule-customer .filter-icon {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.report-schedule-content .report-schedule-table {
  width: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  margin-top: 20px;
  max-height: 90%;
  overflow-y: scroll;
}

.report-schedule-table table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.report-schedule-table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.report-schedule-table table thead tr td {
  font-size: 11px;
  padding: 6px 10px;
  font-weight: 700;
}

.report-schedule-table table thead tr td.name {
  width: 160px;
}

.report-schedule-table table thead tr td:last-child {
  width: 40px;
}

.report-schedule-table table tbody tr {
  border-bottom: 1px solid #f5f5f5c5;
}

.report-schedule-table table tbody tr td {
  font-size: 13px;
  padding: 10px 10px;
  font-weight: 500;
}

.report-schedule-table table tbody tr td:nth-child(1) {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.report-schedule-table table tbody tr td:nth-child(3) {
  font-weight: 500;
}

.report-schedule-table table tbody tr td:last-child {
  width: 40px;
  padding: 6px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* responsive ui */

@media only screen and (max-width: 768px) {
  .report-dashboard {
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .report-dashboard .report-dashboard-left{
    width: 100%;
  }
  
  .report-dashboard-left .report-generate{
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
    padding-bottom: 10px;
  }

  .report-generate-header .report-generate-title,.report-schedule-header .report-schedule-title,.report-history .report-history-title{
    font-size: 15px;
    font-weight: 600;
  }


  .report-dashboard .report-dashboard-right{
    width: 100%;
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .report-dashboard-right .report-schedule{
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .report-scheduler{
    padding: 15px 15px;
  }

  .report-generate .report-generate-content{
    padding-top: 20px;
  }

  .report-schedule .report-schedule-content{
    padding: 0;
    height: auto;
    max-height: 300px;
  }

  .report-schedule-content .report-schedule-customer{
    display: none;
  }

  .report-schedule-content .report-schedule-empty{
    padding-top: 40px;
  }

  .report-schedule-content .report-schedule-empty > p,.report-history-content .report-history-empty > p {
    font-size: 15px;
  }

  .report-schedule-content .report-schedule-table{
    margin-top: 10px;
  }

  .report-dashboard-right .report-history{
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .report-history .report-history-header{
    padding: 0;
  }

  .report-history .report-history-content{
    padding: 0 0px 0 0;
    margin-top: 10px;
    height: auto;
    height: 100%;
  }

  .report-history .report-history-content::-webkit-scrollbar{
    display: none;
  }

  .report-scheduler{
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: 1000;
    background-color: white;
  }

  .report-scheduler .report-scheduler-content{
    justify-content: flex-start;
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .report-schedule-content .report-schedule-table{
    border: none;
  }

  .report-schedule-table .report-schedule-row{
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 2px;
    row-gap: 2px;
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;
  }

  .report-schedule-table .report-schedule-row>div{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .report-schedule-table .report-schedule-row>div:nth-child(1){
    font-weight: 700;
  }

  .report-schedule-table .report-schedule-row>div:nth-child(2){
    font-weight: 500;
    font-size: 13px;
  }

  .report-schedule-table .report-schedule-row>div:nth-child(3){
    justify-content: flex-end;
    font-weight: 600;
    font-size: 13px;
  }


  .report-history-row .history-left .report-customer{
    font-size: 14px;
    font-weight: 600;
  }

  .report-history-row .history-left .report-date{
    font-size: 12px;
    color: var(--font-primary);
  }

  .report-history-row .history-right .report-type{
    display: none;
  }

}

.client-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.client-header .client-title {
    font-size: 18px;
    /* color: var(--font-primary); */
    color: rgba(1, 22, 39, 0.90)98;
    font-weight: 700;
}

.client-strategy-content {
    width: 100%;
    height: 100%;
    display: flex;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.client-strategy-content .client-add {
    width: 450px;
    height: 100%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
    position: relative;
}

.client-strategy-content .client-add .title,
.client-strategy-content .client-kyc-table .title {
    font-size: 13px;
    font-weight: 700;
    height: 5%;
}

.client-strategy-content .client-customers-table .title {
    font-size: 13px;
    font-weight: 700;
    height: 5%;
}

.client-add .client-add-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    background-color: #f9f9f950;
    z-index: 1000;
}

.client-strategy-content .client-kyc-table {
    width: 700px;
    height: 100%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
}

.client-strategy-content .client-customers-table {
    width: 900px;
    height: 100%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
}

.client-kyc-table .kyc-table {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #f5f5f5;
    border-radius: 15px;
    max-height: 95%;
    overflow-y: scroll;
}

.client-customers-table .customers-table {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #f5f5f5;
    border-radius: 15px;
    max-height: 95%;
    overflow-y: scroll;
}

.client-kyc-table .kyc-table.loading,
.client-kyc-table .kyc-table.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    border: none;
}

.client-customers-table .customers-table.loading,
.client-customers-table .customers-table.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    border: none;
}


.client-kyc-table .kyc-table.loading>p,
.client-kyc-table .kyc-table.empty>p {
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
}

.client-customers-table .customers-table.loading>p,
.client-customers-table .customers-table.empty>p {
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
}

.brokers-table.empty>p {
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
}

.kyc-table table {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
}

.kyc-table table thead,
.customers-table table thead {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    inset-block-start: 0;
    background-color: white;
    box-shadow: 0 1px 1px #f5f5f5;
}

.kyc-table table tr td,
.customers-table table tr td {
    font-size: 12px;
    font-weight: 500;
    padding: 8px 10px;
}

.kyc-table table tr td a,
.customers-table table tr td a {
    color: var(--font-primary);
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
}

.kyc-table table thead tr td,
.customers-table table thead tr td {
    font-weight: 700;
}


.brokers-table table {
    width: 100%;
    font-size: 13px;
    font-weight: 500;
}

.brokers-table table thead {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    inset-block-start: 0;
    background-color: white;
    box-shadow: 0 1px 1px #f5f5f5;
}

.brokers-table table thead tr td {
    font-weight: 700;
}

.brokers-table table tr td,
.brokers-table table tr td {
    font-size: 13px;
    font-weight: 500;
    padding: 8px 10px;
}

.swift-superuser-customer-popup {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    width: 90vw;
    height: 90vh;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
}

.swift-superuser-customer-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.swift-superuser-customer-popup-body {
    display: flex;
    flex-direction: column;
    grid-row-gap: 25px;
    row-gap: 25px;
}

.swift-superuser-customer-popup-body-row {
    display: flex;
    grid-column-gap: 2px;
    -webkit-column-gap: 2px;
            column-gap: 2px;
    font-size: 14px;
}

.swift-superuser-customer-popup-body-row>span {
    font-weight: 600;
}

.swift-superuser-customer-popup-btn {
    color: #011627;
    font-size: 12px;
    width: 130px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px;
    border: none;
    border-radius: 35px;
    background-color: #f0f0f0;
    cursor: pointer;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.customers-table .customers-table-view-btn {
    color: #011627;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    background-color: #f0f0f0;
    cursor: pointer;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.roles-management {
    width: 100%;
    height: 100%;
}

.roles-management .roles-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.roles-header .roles-title {
    font-size: 18px;
    /* color: var(--font-primary); */
    color: rgba(1, 22, 39, 0.90)98;
    font-weight: 700;
}

.roles-header .roles-subtext {
    font-size: 20px;
    font-weight: 300;
}

.roles-management .roles-data {
    width: 100%;
    height: 95%;
    margin-top: 10px;
    display: flex;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.roles-management .roles-data.hide {
    display: none;
}

.roles-data .role-addition {
    width: 520px;
    height: 100%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px 15px;
    position: relative;
}

.role-addition .addition-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    background-color: #f9f9f950;
    z-index: 1000;
}

.role-addition .title {
    font-size: 13px;
    font-weight: 700;
}

.role-addition .role-addition-content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
}

.role-addition-content .role-addition-row {
    display: flex;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
}

.role-addition .role-addition-footer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.role-addition .role-addition-footer .addition-error {
    font-size: 12px;
    font-weight: 600;
}

.roles-data .roles-users-table {
    width: 650px;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px 15px;
}

.roles-users-table .title {
    font-size: 13px;
    font-weight: 700;
}

.roles-users-table .users-table {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #f5f5f5;
    padding: 0px 5px;
    border-radius: 5px;
    max-height: 90%;
    overflow-y: scroll;
}

.roles-users-table .users-table table {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
}

.roles-users-table .users-table table thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0%;
    background-color: white;
}

.users-table table thead td {
    font-weight: 700;

}

.users-table table tr td {
    max-width: 150px;
    word-wrap: break-word;
    text-align: left;
    padding: 4px 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users-table table tr td.center {
    text-align: center;
}

.roles-users-table .users-loading,
.roles-users-table .users-empty {
    width: 100%;
    min-height: 120px;
    max-height: 350px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.roles-management .roles-customers {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
}

.roles-management .roles-customers.hide {
    display: none;
}

.roles-customers .roles-customers-header {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.roles-customers-header .roles-customers-search {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f5f5f5;
    width: 280px;
    padding: 0 10px;
    border-radius: 5px;
}

.roles-customers-header .roles-customers-search input {
    background-color: transparent;
    height: 100%;
    width: 80%;
    outline: none;
    color: var(--font-primary);
    font-weight: 600;
    font-size: 12px;
    padding: 10px 0px;
    border: none;
    outline: none;
}

.roles-customers-header .roles-customers-search .filter-icon {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.roles-customers .roles-customers-content {
    width: 100%;
    height: 90%;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    border-radius: 15px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.roles-customers .roles-customer-loading-content {
    width: 100%;
    height: 100%;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 6px;
    row-gap: 6px;
    margin-top: 10px;
}

.roles-customer-loading-content .message {
    font-size: 16px;
    font-weight: 500;
}

.roles-customer-loading-content .roles-customers-add {
    padding: 10px 20px;
    background-color: var(--font-primary);
    color: var(--white);
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 30px;
    transition: 0.3s;
}

.roles-customer-loading-content .roles-customers-add:hover {
    opacity: 0.95;
}

.roles-customer-loading-content .roles-customers-add a {
    color: var(--white);
    text-decoration: none;
}

.roles-customers-content .customer-content-header {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    inset-block-start: 0;
    background-color: white;
}

.roles-customers-content .cust-config {
    flex: 4 1;
    padding: 6px 15px;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.roles-customers-content .customer-content-header .cust-config {
    font-weight: 700;
}

.roles-customers-content .cust-config.name {
    flex: 6 1;
}

.roles-customers-content .cust-config.kyc {
    flex: 3 1;
}


.roles-customers-content .cust-config.arrow {
    flex: 1 1;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.roles-customers-content .cust-config a {
    color: var(--font-primary);
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
}

.roles-customers-content .cust-config.broker {
    flex: 2 1;
}

.roles-customers-content .cust-config.broker button {
    background-color: transparent;
    color: var(--font-primary);
    border: none;
    outline: none;
    padding: 4px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 11px;
    font-weight: 700;
    transition: 0.3s;
}

.roles-customers-content .cust-config.broker button:hover {
    opacity: 0.95;
}

.roles-customers-content .customer-content-body {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.customer-content-body .customer-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
}

.customer-content-body .customer-row .customer-info {
    width: 100%;
    display: flex;
    padding: 2px 0;
}

.customer-content-body .customer-row .customer-broker-info {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.customer-content-body .customer-row .customer-broker-info.hide {
    display: none;
}

.customer-row .customer-broker-info .broker-info-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
}

.customer-broker-info .broker-info-empty>p {
    font-size: 12px;
    font-weight: 700;
}

.customer-broker-info .customer-broker-table {
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    padding: 0 4px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.customer-broker-info .customer-broker-table table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.customer-broker-table table thead tr td {
    font-size: 12px;
    font-weight: 700;
    padding: 8px 10px;
}

.customer-broker-table table tbody tr td {
    font-size: 12px;
    font-weight: 500;
    padding: 8px 10px;
}
.funds-management{
  width: 100%;
  height: 100%;
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.funds-management .funds-title{
  font-weight: 600;
  font-size: 14px;
}

.funds-management .funds-request{
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  width: 350px;
  height: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  padding: 5px 10px;
}

.funds-request .funds-request-wrapper{
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
  margin-top: 10px;
}

.funds-request .funds-process-button{
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  color: var(--font-secondary);
}

.funds-request-wrapper .funds-wrapper-loader{
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.funds-request-wrapper .funds-request-box.loader{
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.funds-request-wrapper .funds-request-box.info-box{
  width: 100%;
  padding: 10px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
  border: 1px solid #f0f0f0;
}


.funds-request-wrapper .funds-request-box.info-box p{
  font-size: 13px;
  font-weight: 500;
}

.funds-request-wrapper .funds-request-box.info-box p span{
  font-weight: 600;
}

.funds-request .funds-request-button{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.funds-history{
  width: 1000px;
  border: 1px solid #f5f5f5;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  border-radius: 15px;
}

.funds-history.loader{
  align-items: center;
  padding: 60px;
}

.funds-history-table {
  width: 100%;
  max-height: 500px;
  border-radius: 15px;
  overflow-y: scroll;
  padding: 0 0px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  position: relative;
}

.funds-history-table.empty{
  width: 100%;
  height: 100%;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}

.funds-history-table.empty>p{
  font-size: 14px;
  font-weight: 600;
}

.funds-history-table.loader{
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.funds-history table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.funds-history table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.funds-history table thead td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.funds-history table tbody {
  width: 100%;
}

.funds-history table tbody td {
  padding: 14px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.funds-history table tbody tr {
  cursor: auto;
  cursor: initial;
  transition: 0.3s;
  border-bottom: 1px solid #f5f5f5c5;
}

.funds-history table td.customer{
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.funds-history table td.utr{
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* funds process popup */
.funds-process{
  width: 500px;
  height: 600px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.funds-process .funds-process-header{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid #e8e8e8;
}

.funds-process .funds-process-header-mobile{
  display: none;
}

.funds-process .funds-process-header>p{
  font-size: 13px;
  font-weight: 600;
}

.funds-process .funds-process-header .funds-process-close{
  cursor: pointer;
}

.funds-process .funds-process-body{
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
  padding: 10px 15px;
}

.funds-process .funds-process-body .funds-process-section{
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.funds-process .funds-process-body .funds-process-title{
  font-size: 13px;
  font-weight: 600;
}

.funds-process .funds-process-body .funds-process-info{
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.funds-process .funds-process-body .funds-process-info p{
  font-size: 13px;
  font-weight: 500;
}

.funds-process .funds-process-body .funds-process-info>div{
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.funds-process .funds-process-body .funds-process-info>div span{
  font-weight: 600;
}


/* responsive ui */

@media only screen and (max-width: 768px){
  .funds-management{
    display: flex;
    flex-direction: column;
  }

  .funds-management .funds-request-popup{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  .funds-management .funds-request{
    background-color: white;
    width: 100%;
    border-radius: 0;
    padding: 10px 10px;
  }

  .funds-request .funds-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .funds-request .funds-header>div{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .funds-request .funds-title{
    font-weight: 700;
  }

  .funds-history{
    width: 100%;
    border: none;
    padding: 0;
  }

  .funds-history .funds-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .funds-management .funds-title{
    font-weight: 700;
    font-size: 15px;
  }

  .funds-history-table{
    border: none;
    border-radius: 0;
  }

  .funds-history-table.empty{
    padding-top: 40px;
  }

  .funds-history-table .history-table-row{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;
    grid-row-gap: 2px;
    row-gap: 2px;
  }

  .funds-history-table .history-table-row .table-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  }

  .funds-history-table .history-table-row .customer-name{
    font-size: 15px;
    font-weight: 600;
  }

  .funds-history-table .history-table-row .table-row:nth-child(1),.funds-history-table .history-table-row .table-row:nth-child(2){
    font-weight: 700;
  }

  .funds-history-table .history-table-row .table-row:last-child{
    font-size: 13px;
    font-weight: 500;
  }

  .funds-process{
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .funds-process .funds-process-header{
    display: none;
  }

  .funds-process .funds-process-header-mobile{
    width: 100%;
    display: flex;
    padding: 10px 10px;
  }


}
.swift-wrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  background: var(--white);
  display: flex;
}

.swift-wrapper-hidden {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 20px;
  row-gap: 20px;
  display: none;
  padding: 80px 10px;
}

.swift-wrapper-hidden .swift-name {
  font-size: 36px;
  font-weight: 800;
  color: var(--font-primary);
  background: linear-gradient(to right, #063970, #192b40);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -1px;
}

.swift-wrapper-hidden .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swift-wrapper-hidden .message {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 0 20px;
}

.swift-wrapper-hidden .message span {
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  display: inline-block;
}

.swift-menu {
  position: absolute;
  width: 50px;
  height: 100%;
  background-color: var(--white);
  padding: 5px 5px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  z-index: 100;
  overflow: hidden;
  transition: 0.2s;
  border-radius: 5px;
  box-shadow: 2px 0px 2px #fafafa;
}

.swift-menu.active {
  width: 200px;
}

.swift-menu .swift-menu-icon {
  display: flex;
  flex-direction: row;
  padding: 8px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.swift-menu .swift-menu-icon .menu-icon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.swift-menu .swift-menu-icon.active {
  background-color: #e8e8e8;
}

.swift-menu .swift-menu-icon .menu-text {
  padding-left: 15px;
  color: var(--font-primary);
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  transition: 0.2s;
  width: 140px;
}
/* 
.swift-menu.active .swift-menu-icon .menu-text{
  display: inline;
  opacity: 1;
} */

.swift-menu .swift-menu-icon.active .menu-text{
  font-weight: 700;
}

.swift-menu .swift-menu-options {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.swift-menu .swift-menu-options-mobile{
  display: none;
}

.swift-menu .swift-menu-options .swift-menu-section {
  display: flex;
  flex-direction: column;
  grid-row-gap: 8px;
  row-gap: 8px;
  width: 100%;
}

.swift-menu-options .swift-menu-section .menu-section-title {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--font-secondary);
  padding-left: 10px;
  opacity: 0;
  letter-spacing: 0px;
  transition: 0.2s;
  text-wrap: nowrap;
}

.swift-menu.active .swift-menu-options .swift-menu-section .menu-section-title{
    opacity: 1;
}

.swift-menu-options .swift-menu-section .menu-section-line{
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
}

.swift-menu-options .swift-menu-section .menu-section-options {
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
  width: 100%;
}

.swift-menu .swift-menu-options .swift-menu-icon:hover {
  background-color: #f8f8f8;
}

.swift-menu .swift-menu-options .swift-menu-icon.active:hover {
  background-color: #e8e8e8;
}

.swift-menu svg {
  cursor: pointer;
  font-size: 20px;
  color: #cccccc;
}

.swift-menu a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  flex: 1 1;
}

.swift-menu a.menu-active svg {
  color: #b937e7;
}

.swift-menu .swift-menu-logo {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.swift-menu .swift-menu-logo img {
  width: 30px;
}

.swift-menu .swift-menu-logo .menu-text {
  font-size: 18px;
  font-weight: 700;
  color: var(--font-primary);
}

.swift-body {
  position: absolute;
  left: 50px;
  width: calc(100% - 50px);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  grid-row-gap: 15px;
  row-gap: 15px;
  justify-content: space-between;
}

.swift-content-wrapper {
  width: 100%;
  height: calc(100% - 40px - 20px - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.swift-content-wrapper.full{
  height: 100%;
}

.swift-content-wrapper .swift-content-data-section {
  width: 100%;
  height: 100%;
  padding-right: 15px;
}

.swift-content-wrapper .swift-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 8px;
  row-gap: 8px;
}

.swift-content-wrapper .swift-submenu-wrapper{
  background-color: white;
}

.swift-content-wrapper .swift-submenu-item{
  display: none;
}

.swift-content-wrapper .swift-submenu {
  display: flex;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  min-height: 15px;
  max-height: 15px;
}

.swift-content-wrapper .swift-submenu .swift-submenu-close{
  display: none;
}

.swift-content-wrapper .swift-submenu .swift-submenu-items{
  width: 100%;
  height: 100%;
}

.swift-content-wrapper .swift-submenu a {
  text-decoration: none;
  color: var(--font-primary);
}

.swift-content-wrapper .swift-submenu p {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
}

.swift-content-wrapper .swift-submenu p.active {
  font-weight: 700;
}

.swift-tooltip {
  font-size: 18px;
}

/* activation box ui */

.activation-box{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 20px;
  row-gap: 20px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  padding-top: 100px;
}

.activation-box .activation-box-sec1{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.activation-box .activation-box-sec1>span{
  font-size: 14px;
  font-weight: 500;
}

.activation-box .activation-box-sec1>p{
  font-size: 18px;
  font-weight: 700;
}

.activation-box .activation-box-sec2{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.activation-box .activation-box-sec2>p{
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.activation-box .activation-box-sec3 button{
  border-radius: 30px;
  cursor: pointer;
  border: none;
  background-color: var(--main-brand);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* hide content ui */

/* @media only screen and (max-width: 1366px) {
  .swift-wrapper {
    display: none;
  }

  .swift-wrapper-hidden {
    display: flex;
  }
} */


.more-management{
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.more-management .title{
  font-size: 18px;
  font-weight: 700;
}

.more-management .more-section{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.more-management .more-section a{
  text-decoration: none;
}

.more-management .more-section .more-section-row{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

.more-management .more-section .more-section-row .row-icon{
  display: flex;
  flex-direction: row;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  align-items: center;
  padding: 15px 0;
}

.more-management .more-section .more-section-row .row-icon p{
  font-size: 15px;
  font-weight: 600;
  color: var(--font-primary);
}

/* responsive ui */

@media only screen and (max-width: 768px){
  .swift-wrapper{
    display: flex;
    flex-direction: column-reverse;
    width: 97%;
    margin: auto;
  }

  .swift-menu{
    width: 100%;
    height: 70px;
    display: flex;
    border-radius: 0;
    border-top: 1px solid #e8e8e8;
    position: static;
    position: initial;
  }

  .swift-content-wrapper .swift-submenu-wrapper{
    position: relative;
    background-color: transparent;
    width: 240px;
    height: 45px;
  }

  .swift-menu.active{
    width: 100%;
  }

  .swift-menu .swift-menu-logo{
    display: none;
  }

  .swift-menu .swift-menu-options {
    display: none;
  }
  
  .swift-menu .swift-menu-options-mobile{
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 0 0px;
  }

  .swift-menu .swift-menu-options-mobile .swift-menu-icon{
    flex-direction: column;
    padding: 0;
    justify-content: center;
  }

  .swift-menu .swift-menu-icon .menu-icon{
    height: auto;
  }

  .swift-menu .swift-menu-options-mobile .swift-menu-icon.active{
    background-color: transparent;
  }

  .swift-menu .swift-menu-options-mobile .swift-menu-icon a{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row-gap: 4px;
    row-gap: 4px;
  }

  .swift-menu .swift-menu-options-mobile .swift-menu-icon .menu-text{
    width: auto;
    padding: 0;
    font-size: 11px;
    font-weight: 600;
  }

  .swift-body{
    width: 100%;
    height: calc(100% - 70px);
    position: static;
    position: initial;
    padding: 5px 0px;
    grid-row-gap: 5px;
    row-gap: 5px;
    justify-content: flex-start;
  }

  .swift-content-wrapper{
    height: calc(100% - 30px - 10px);
    overflow-y: scroll;
  }

  .swift-content-wrapper::-webkit-scrollbar{
    display: none;
  }

  .swift-content-wrapper.active{
    height: calc(100% - 130px - 10px);
  }

  .swift-content-wrapper .swift-content-data-section{
    padding: 0 0px;
  }

  .activation-box{
    border: none;
  }

  .swift-content-wrapper .swift-content{
    grid-row-gap: 16px;
    row-gap: 16px;
    padding: 10px 0;
  }

  .swift-content-wrapper .swift-submenu-item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    border-radius: 40px;
    padding: 0px 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #f0f0f0;
    border: 1px solid #e8e8e8;
  }

  .swift-content-wrapper .swift-submenu-item p{
    font-size: 14px;
    font-weight: 700;
  }

  .swift-content-wrapper .swift-submenu {
    position: absolute;
    top: 110%;
    left: 0%;
    min-width: 100%;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 10px;
    background-color: #f0f0f0;
    z-index: 1000;
    transition: 0.03s;
    padding: 10px 15px;
    border-radius: 20px;
    display: none;
  }

  .swift-content-wrapper .swift-submenu>a{
    padding: 10px 0;
    width: 100%;
    font-size: 14px;
    border-bottom: 0.5px solid rgba(176, 176, 176, 0.3);
  }

  .swift-content-wrapper .swift-submenu>p{
    padding: 10px 0;
    width: 100%;
    font-size: 14px;
    border-bottom: 0.5px solid rgba(176, 176, 176, 0.3);
  }

  .swift-content-wrapper .swift-submenu .swift-submenu-close{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    z-index: 1000;
    top: 10px;
    right: 10px;
  }

  .swift-content-wrapper .swift-submenu.active{
    display: flex;
  }
  
  .swift-content-wrapper .swift-submenu p {
    font-size: 15px;
    line-height: 20px;
  }

}
.app__login__screen {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.app__login__screen a{
    color: var(--font-primary);
    text-decoration: none;
}

.app__login__screen .app__login__process > p {
  margin-top: 10px !important;
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
  letter-spacing: -0.5px;
  text-align: center;
}

.app__login__screen .app__login__process .login__animation {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 160, 227, 0.8);
  border-radius: 50%;
  position: relative;
}

.app__login__screen .app__login__process .login__animation > span {
  position: absolute;
  background-color: rgba(0, 160, 227, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-block;
  animation: animate 1.5s linear infinite;
  animation-delay: calc(0.5s * var(--i));
}

@keyframes animate {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  90% {
    transform: scale(2);
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}

.app__login__screen .app__login__process .login__animation .rocket {
  position: relative;
  animation: rocket 0.1s ease infinite;
}

@keyframes rocket {
  0%,
  100% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(1px);
  }
}

.app__login__screen .app__login__process .login__animation .rocket > img {
  width: 60px;
}

.app__login__screen .app__login__process .login__animation .rocket::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 10px;
  height: 150px;
  background: linear-gradient(white, transparent);
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0.3;
}

.app__login__screen .app__login__noauth,
.app__login__screen .app__login__process {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app__login__screen .app__login__noauth h2 {
  font-size: 130px;
  font-weight: 300;
  letter-spacing: -2px;
}

.app__login__screen .app__login__unauthorized p {
  font-size: 34px;
  color: var(--font-primary);
  font-weight: 500;
  text-align: center;
}

.app__login__screen .app__login__noauth > p {
  color: #404040;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
}

.app__login__screen .brand {
  font-size: 30px!important;
  font-weight: 700!important;
  letter-spacing: -1px;
  background: linear-gradient(
    90deg,
    var(--font-primary),
    var(--main-brand) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.app__login__screen .app__login__button {
    margin-top: 40px;
}

.app__login__screen .app__login__button button{
    border: 1px solid #404040;
    background-color: transparent;
    border-radius: 3px;
    font-size: 16px;
    color: var(--font-primary);
    padding: 10px 50px;
    font-weight: 500;
    transition: .3s;
}

.app__login__screen .app__login__button:hover {
  opacity: 0.9;
}

.app__login__screen .app__login__button button a {
  text-decoration: none;
  color: var(--font-primary);
}

@media only screen and (max-width: 768px) {
  .app__login__screen .app__login__process > p {
    margin-top: 15px !important;
    font-size: 16px;
    font-weight: 600;
  }

  .app__login__screen .app__login__process .login__animation {
    width: 100px;
    height: 100px;
  }

  .app__login__screen .app__login__process .login__animation .rocket > img {
    width: 50px;
  }

  .app__login__screen .app__login__noauth,
  .app__login__screen .app__login__process {
    margin-top: 0px;
    padding: 20px 10px;
  }

  .app__login__screen .app__login__noauth h2 {
    font-size: 60px;
    color: var(--font-primary);
  }

  .app__login__screen .app__login__noauth p {
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px !important;
    margin-bottom: 20px !important;
    color: var(--font-primary);
  }

  .app__login__screen .app__login__button button {
    font-size: 12px;
    font-weight: 500;
  }
}

* {
    letter-spacing: -0.5px;
}
.advisor__smallcase__strategy__details {
    position: relative;
}
.advisor__smallcase__strategy__details.loader
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.advisor__smallcase__strategy__details .advisor__create__smallcase
{
    padding: 8px 20px;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    color: var(--font-primary);
    margin-top: 15px;
    font-size: 12px;
    font-weight: 900;
    cursor: pointer;
}

.advisor__smallcase__strategy__details .advisor__strategy__changes {
    position: absolute;
    right: 10px;
    top: 20px;
    display: flex;
}

.advisor__strategy__changes div {
    padding: 5px 20px;
    border: solid 1px #e8e8e8;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 5px;
    font-size: 12px;
    font-weight: 900;
    color: var(--font-primary);
}



.advisor__smallcase
{
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 1% 10%;
}

.advisor__smallcase__buttons {
    display: flex;
    align-items: center;
}
.advisor__smallcase__button
{
    padding: 8px 16px;
    background-color: white;
    font-size: 12px;
    font-weight: 900;
    border: 1px solid var(--font-primary);
    border-radius: 3px;
    color: var(--font-primary);
    transition: 0.3s;
    cursor: pointer;
    margin: 0 5px;
}

.advisor__smallcase__button:hover
{
    border-color: var(--main-blue);
    color: var(--main-blue);
}

.advisor__smallcase .advisor__smallcase__header
{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.advisor__smallcase__header .advisor__smallcase__title
{
    font-size: 18px;
    font-weight: 900;
    color: var(--font-primary);
}

.advisor__smallcase__search__wrapper
{
    padding: 0 10px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
}

.advisor__smallcase__search__wrapper .advisor__smallcase__search__box
{
    width: 30%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    border-radius: 3px;
    border: 1px solid #e8e8e8;
}

.advisor__smallcase__search__wrapper .advisor__smallcase__search__box>img
{
    width: 16px;
}

.advisor__smallcase__search__wrapper .advisor__smallcase__search__box>input
{
    width: 85%;
    height: 100%;
    border: none;
    outline: none;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 600;
    padding-left: 10px;
}

.advisor__smallcase__search__wrapper .advisor__smallcase__search__box>input::-webkit-input-placeholder
{
    color: #808080;
    font-weight: 500;
}

.advisor__smallcase__search__wrapper .advisor__smallcase__search__box>input:-ms-input-placeholder
{
    color: #808080;
    font-weight: 500;
}

.advisor__smallcase__search__wrapper .advisor__smallcase__search__box>input::placeholder
{
    color: #808080;
    font-weight: 500;
}

.advisor__smallcase .advisor__smallcase__container
{
    width: 100%;
    height: 85%;
    border-radius: 5px;
}

.advisor__smallcase .advisor__smallcase__body
{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.advisor__smallcase__body .advisor__smallcase__strategy__wrapper,
.advisor__smallcase__body .advisor__smallcase__strategy__details
{
    border-radius: 5px;
    padding: 0px 10px;
    overflow-y: scroll;
    height: 85%;
}

.advisor__smallcase__body .advisor__smallcase__strategy__wrapper
{
    width: 30%;
}

.advisor__smallcase__body .advisor__smallcase__strategy__details
{
    width: 65%;
    padding: 20px 15px;
    border: 1px solid #e8e8e8;
}

.advisor__smallcase__strategy__wrapper .advisor__smallcase__strategy__card
{
    width: 100%;
    padding: 5px 15px;
    margin-bottom: 25px;
    border-radius: 3px;
    border: 1px solid #e8e8e8;
}

.advisor__smallcase__strategy__card .advisor__strategy__info,
.advisor__smallcase__strategy__card .advisor__strategy__data,
.advisor__smallcase__strategy__card .advisor__strategy__options
{
    display: flex;
    align-items: center;
    margin: 0px 0px 10px;
}


.advisor__strategy__icon__box
{
    width: 30%;
    display: flex;
    flex-direction: column;
}

.advisor__strategy__icon__box img
{
    width: 20px;
}

.advisor__strategy__icon__box span
{
    font-size: 12px;
    font-weight: 700;
    color: var(--font-primary);
}

.advisor__strategy__info .advisor__strategy__headings__box
{
    width: 70%;
    padding-right: 5px;
}

.advisor__strategy__headings__box .advisor__strategy__heading
{
    font-size: 12px;
    font-weight: 900;
    color: var(--font-primary);
}

.advisor__strategy__headings__box .advisor__strategy__news
{
    font-size: 11px;
    font-weight: 500;
}

.advisor__strategy__data__table
{
    width: 50%;
    display: flex;
    justify-content: space-around;
    box-shadow: 0px 1px 3px #e8e8e8,0px -1px 1px #f7f7f7;
    border-radius: 3px;
    padding: 10px 0px;
}

.advisor__strategy__data__table>div .title
{
    font-size: 12px;
        font-weight: 500;
    color: var(--font-primary);
}

.advisor__strategy__data__table>div .value
{
    font-size: 12px;
        font-weight: 900;
    text-align: center;
    margin-top: 5px !important;
    color: var(--font-primary);
}

.advisor__smallcase__strategy__card .advisor__strategy__options
{
    margin-top: 0px;
    margin-bottom: 0px;
}

.advisor__smallcase__strategy__card .advisor__strategy__options>p
{
    font-size: 12px;
    color: var(--main-blue);
    font-weight: 900;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
}



.advisor__smallcase__strategy__details .advisor__strategy__details__box
{
    width: 100%;
    margin-bottom: 15px;
}

.advisor__strategy__details__box .details__header
{
    display: flex;
    align-items: center;
}

.advisor__strategy__details__box .details__header>img
{
    width: 20px;
}

.advisor__strategy__details__box .details__header>span
{
    font-size: 12px;
    font-weight: 900;
        color: var(--font-primary);
}

.advisor__strategy__details__box .details__content>p
{
    font-size: 12px;
        font-weight: 600;
    color: var(--font-secondary);
}

.advisor__strategy__details__box .details__content.advisor__strategy__name>p
{
    font-size: 12px;
        font-weight: 900;
    color: var(--main-blue);
}

.advisor__strategy__details__box .details__content.details__chart
{
    margin-top: 10px;
}

.advisor__strategy__details__box .advisor__strategy__stocks
{
    display: flex;
    flex-wrap: wrap;
}

.advisor__strategy__details__box .advisor__strategy__stocks>span
{
    font-size: 12px;
    color: var(--font-primary);
    margin: 10px 20px 0px 0;
    padding: 5px 20px;
    border-radius: 50px;
    box-shadow: 1px 3px 5px #e8e8e8;
}

.advisor__strategy__chart
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    box-shadow: 0px 1px 3px #e8e8e8,0px -1px 1px #f7f7f7;
    padding: 10px 15px;
    margin-top: 20px;
    border-radius: 5px;
    min-height: 300px;
}

.advisor__smallcase__strategy__container
{
    width: 100%;
    height: 100%;
}

.advisor__smallcase__strategy__container .advisor__smallcase__strategy__header
{
    width: 100%;
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.advisor__smallcase__strategy__header>span
{
    font-size: 14px;
        font-weight: 900;
        color: var(--font-primary);
}


.advisor__smallcase__strategy__header>div
{
    background-color: var(--white);
    border: 1px solid #e8e8e8;
    padding: 6px 30px 6px 30px;
    font-size: 12px;
    font-weight: 700;
    color: var(--font-primary);
    border-radius: 2px;
    transition: 0.3s;
    cursor: pointer;
}

.advisor__smallcase__strategy__header>div:hover
{
    opacity: 0.90;
}

.advisor__smallcase__strategy__body__wrapper
{
    width: 100%;
    height: 80%;
    margin-top: 10px;
}

.advisor__smallcase__strategy__body
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.advisor__smallcase__strategy__body.loader
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.advisor__smallcase__strategy__body.loader .loading__text
{
    font-size: 14px;
    font-weight: 700;
    color: var(--font-primary)!important;
    margin-top: 8px!important;
}

.advisor__smallcase__strategy__body .advisor__smallcase__strategy__form
{
    width: 45%;
    height: 100%;
    overflow-y: scroll;
    padding: 10px 0;
    /* box-shadow: 1px 3px 5px #e8e8e8; */
    border: 1px solid #e8e8e8;
    border-radius: 5px;
}

.advisor__smallcase__strategy__body .advisor__smallcase__strategy__image
{
    width: 50%;
    height: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #00A0E3; */
}

.advisor__smallcase__strategy__body .advisor__smallcase__strategy__image img
{
    width: 50%;
    /* transform: rotateY(180deg); */
}

.advisor__smallcase__strategy__form .advisor__strategy__select__container
{
    padding: 0 10px;
}

.advisor__strategy__select__container .advisor__strategy__select__header
{
    display: flex;
    align-items: center;
}

.advisor__strategy__select__container .advisor__strategy__select__header>img
{
    width: 20px;
}

.advisor__strategy__select__container .advisor__strategy__select__header>span
{
    margin-left: 10px;
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 700;
    text-transform: capitalize;
}

.advisor__strategy__select__container .advisor__strategy__select__box
{
    margin-top: 5px;
}

.advisor__strategy__select__container .advisor__strategy__text__box,
.advisor__strategy__select__container .advisor__strategy__input__box
{
    width: 100%;
    margin-top: 5px;
    border-radius: 3px;
    border: 1px solid #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.advisor__strategy__select__container .advisor__strategy__text__box textarea
{
    width: 95%;
    height: 100%;
    border: none;
    outline: none;
    min-height: 140px;
    resize: none;
    font-size: 11px;
    font-weight: 600;
    color: var(--font-primary);
    padding-top: 5px;
}

.advisor__strategy__select__container .advisor__strategy__input__box input
{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    min-height: 35px;
    padding-left: 10px;
    font-size: 11px;
    font-weight: 600;
    color: var(--font-primary);
}

.advisor__strategy__select__container .advisor__strategy__text__box textarea::-webkit-input-placeholder, .advisor__strategy__select__container .advisor__strategy__input__box input::-webkit-input-placeholder
{
    color: var(--font-secondary);
}

.advisor__strategy__select__container .advisor__strategy__text__box textarea:-ms-input-placeholder, .advisor__strategy__select__container .advisor__strategy__input__box input:-ms-input-placeholder
{
    color: var(--font-secondary);
}

.advisor__strategy__select__container .advisor__strategy__text__box textarea::placeholder,
.advisor__strategy__select__container .advisor__strategy__input__box input::placeholder
{
    color: var(--font-secondary);
}

.advisor__strategy__select__box .custom-select {
    width: 100%!important;
    box-shadow: none;
    border-radius: 3px;
    border: 1px solid #e8e8e8;
}

.advisor__strategy__select__box .custom-select .custom-select-container
{
    min-width: 100%!important;
}

.advisor__smallcase__strategy__container .advisor__smallcase__strategy__footer
{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.advisor__smallcase__strategy__footer .advisor__strategy__footer__button
{
    background-color: var(--white);
    border: 1px solid #e8e8e8;
    padding: 6px 25px 6px 25px;
    font-size: 12px;
    font-weight: 900;
    color: var(--font-primary);
    border-radius: 3px;
    transition: 0.3s;
    cursor: pointer;
}

.advisor__smallcase__strategy__footer .advisor__strategy__footer__button:hover
{
    opacity: 0.90;
}

.advisor__smallcase .smallcase__footer
{
    width: 100%;
    height: 10%;
}


/* start from here */


.advisor__strategy__portfolio__table__wrapper
{
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* padding: 0px 10px; */
    margin-top: 25px;
}

.advisor__strategy__portfolio__table__wrapper .advisor__strategy__portfolio__table
{
    width: 100%;
    height: 85%;
    /* border-radius: 3px; */
}

.advisor__strategy__portfolio__table .advisor__strategy__portfolio__table__header,
.advisor__strategy__portfolio__table .advisor__strategy__portfolio__table__body,
.advisor__strategy__portfolio__table .advisor__strategy__portfolio__table__footer
{
    width: 100%;
    /* background-color: white; */
}

.advisor__strategy__portfolio__table .advisor__strategy__portfolio__table__header
{
    height: 40px;
        border: 1px solid #e8e8e8;
}

.advisor__strategy__portfolio__table .advisor__strategy__portfolio__table__footer
{
    height: 40px;
        border: 1px solid #e8e8e8;
}

.advisor__strategy__portfolio__table .advisor__strategy__portfolio__table__body
{
    height: 75%;
    /* background-color: #19E683; */
    border: 1px solid #e8e8e8;
    overflow-y: scroll;
}

.advisor__strategy__portfolio__table__header .advisor__table__row,
.advisor__strategy__portfolio__table__body .advisor__table__row,
.advisor__strategy__portfolio__table__footer .advisor__table__row
{
    width: 100%;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
}

.advisor__strategy__portfolio__table__header .advisor__edit__table__row,
.advisor__strategy__portfolio__table__body .advisor__edit__table__row,
.advisor__strategy__portfolio__table__footer .advisor__edit__table__row
{
    width: 100%;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    transition: 0.3s;
}

.advisor__strategy__portfolio__table__header .advisor__table__row,
.advisor__strategy__portfolio__table__header .advisor__edit__table__row
{
    font-size: 12px;
}

.advisor__strategy__portfolio__table__footer .advisor__table__row,
.advisor__strategy__portfolio__table__footer .advisor__edit__table__row
{
    font-size: 12px;
}

.advisor__advisor__strategy__portfolio__table__header .advisor__table__row>div,
.advisor__strategy__portfolio__table__footer .advisor__table__row>div,
.advisor__strategy__portfolio__table__header .advisor__edit__table__row>div,
.advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div
{
    font-weight: 700;
}

.advisor__strategy__portfolio__table__body .advisor__table__row:hover,
.advisor__strategy__portfolio__table__body .advisor__edit__table__row:hover
{
    background-color: #fcfcfc;
    border-radius: 3px;
}

.advisor__strategy__portfolio__table__header .advisor__table__row>div:nth-child(1),
.advisor__strategy__portfolio__table__body .advisor__table__row>div:nth-child(1),
.advisor__strategy__portfolio__table__footer .advisor__table__row>div:nth-child(1)
{
    flex: 4 1;
    font-size: 12px;
}

.advisor__strategy__portfolio__table__header .advisor__edit__table__row>div:nth-child(1),
.advisor__strategy__portfolio__table__body .advisor__edit__table__row>div:nth-child(1),
.advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div:nth-child(1)
{
    flex: 4 1;
    padding: 5px 0;
    color: var(--font-primary);
}

.advisor__strategy__portfolio__table__header .advisor__table__row>div:nth-child(2),
.advisor__strategy__portfolio__table__body .advisor__table__row>div:nth-child(2),
.advisor__strategy__portfolio__table__footer .advisor__table__row>div:nth-child(2)
{
    flex: 4 1;
    font-size: 12px;
}

.advisor__strategy__portfolio__table__header .advisor__edit__table__row>div:nth-child(2),
.advisor__strategy__portfolio__table__body .advisor__edit__table__row>div:nth-child(2),
.advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div:nth-child(2)
{
    flex: 4 1;
    /* background-color: #d4e300; */
}

.advisor__strategy__portfolio__table__header .advisor__table__row>div:nth-child(3),
.advisor__strategy__portfolio__table__body .advisor__table__row>div:nth-child(3),
.advisor__strategy__portfolio__table__footer .advisor__table__row>div:nth-child(3)
{
    flex: 5 1;
    padding-right: 5px;
    font-size: 12px;
    /* display: flex; */
    /* justify-content: center; */
}

.advisor__strategy__portfolio__table__header .advisor__edit__table__row>div:nth-child(3),
.advisor__strategy__portfolio__table__body .advisor__edit__table__row>div:nth-child(3),
.advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div:nth-child(3)
{
    flex: 2 1;
}

.advisor__strategy__portfolio__table__header .advisor__table__row>div:nth-child(4),
.advisor__strategy__portfolio__table__body .advisor__table__row>div:nth-child(4),
.advisor__strategy__portfolio__table__footer .advisor__table__row>div:nth-child(4)
{
    flex: 1 1;
    display: flex;
    justify-content: center;
}

.advisor__strategy__portfolio__table__header .advisor__edit__table__row>div:nth-child(4),
.advisor__strategy__portfolio__table__body .advisor__edit__table__row>div:nth-child(4),
.advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div:nth-child(4)
{
    flex: 4 1;
    /* display: flex; */
    /* justify-content: center; */

}

.advisor__strategy__portfolio__table__header .advisor__edit__table__row>div:nth-child(5),
.advisor__strategy__portfolio__table__body .advisor__edit__table__row>div:nth-child(5),
.advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div:nth-child(5)
{
    flex: 1 1;
    display: flex;
    justify-content: center;

}

.advisor__strategy__portfolio__table__body .advisor__table__row>div>input,
.advisor__strategy__portfolio__table__body .advisor__edit__table__row>div>input
{
    width: 50%;
    height: 100%;
    border: none;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    outline: none;
    background-color: transparent;
    padding: 6px 0 6px 10px;
    cursor: text;
    font-weight: 700;
    transition: 0.advisor__3s;
}

.advisor__strategy__portfolio__table__body .advisor__table__row>div .advisor__stock__remove,
.advisor__strategy__portfolio__table__body .advisor__edit__table__row>div .advisor__stock__remove
{
    padding: 5px;
    cursor: pointer;
    transition: 0.advisor__3s;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.advisor__strategy__portfolio__table__body .advisor__table__row>div .advisor__stock__remove:hover,
.advisor__strategy__portfolio__table__body .advisor__edit__table__row>div .advisor__stock__remove:hover
{
    background-color: #bce8ff;
}

.advisor__strategy__portfolio__table__body .advisor__table__row>div .advisor__stock__remove img
{
    width: 10px;
    height: 10px;
}

.advisor__strategy__portfolio__table__body .advisor__table__row>div>input:focus,
.advisor__strategy__portfolio__table__body .advisor__edit__table__row>div>input:focus
{
    border: 1px solid var(--font-secondary);
    
}


.advisor__strategy__portfolio__table .advisor__strategy__table__stock
{
    display: flex;
    flex-direction: column;
}

.advisor__strategy__table__stock .advisor__strategy__table__stock__code
{
    font-size: 11px;
    color: var(--font-primary);
    font-weight: 700;
}

.advisor__strategy__table__stock .advisor__strategy__table__stock__name
{
    max-width: 200px;
    color: var(--font-primary);
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.advisor__strategy__portfolio__table__wrapper .advisor__strategy__performance__buttons
{
    display: flex;
    width: 100%;
}

.advisor__strategy__portfolio__table__wrapper .advisor__strategy__performance__buttons>div
{
    padding: 7px 15px 7px 15px;
    font-size: 12px;
    margin: 0 10px 0 0;
    font-weight: 700;
    color: var(--font-primary);
    background-color: var(--white);
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    transition: 0.advisor__3s;
    cursor: pointer;
}

.advisor__strategy__portfolio__table__wrapper .advisor__strategy__performance__buttons>div:hover
{
    opacity: 0.advisor__90;
}

.advisor__strategy__portfolio__table__wrapper .advisor__strategy__performance__buttons>div>img
{
    width: 10px;
    margin-left: 4px;
}

.advisor__strategy__portfolio__stock__wrapper
{
    width: 100%;
}

.advisor__strategy__portfolio__stock__wrapper .advisor__strategy__portfolio__stock__options
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}



.advisor__strategy__portfolio__stock__wrapper .advisor__strategy__portfolio__stock__options .stock__search__icon img
{
    width: 16px;
}

.advisor__strategy__portfolio__stock__wrapper>img
{
    width: 60%;
}


.advisor__strategy__portfolio__stock__options .stock__search
{
    width: 450px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    border-radius: 3px !important;
    border: 1px solid #e8e8e8 !important;
    position: relative;
    /* background-color: #666666; */
}

.advisor__strategy__portfolio__stock__options .stock__search .stock__search__icon
{
    width: 20px;
    margin-right: 10px;
}

.advisor__strategy__portfolio__stock__options .stock__search input
{
    width: 90%;
    height: 100%;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    outline: none;
}

.advisor__strategy__portfolio__stock__options .stock__search input::-webkit-input-placeholder
{
    text-transform: capitalize;
}

.advisor__strategy__portfolio__stock__options .stock__search input:-ms-input-placeholder
{
    text-transform: capitalize;
}

.advisor__strategy__portfolio__stock__options .stock__search input::placeholder
{
    text-transform: capitalize;
}

.advisor__strategy__portfolio__stock__options .stock__suggestions
{
    position: absolute;
    top: 110%;
    left: 0%;
    width: 100%;
    background-color: var(--white);
    box-shadow: var(--shadow-code);
    overflow: scroll;
    max-height: 200px!important;
}

.advisor__strategy__portfolio__stock__options .stock__suggestions .search__loader
{
    height: 200px!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.advisor__strategy__portfolio__stock__options .stock__suggestions .search__loader>p
{
    margin-top: 10px!important;
    font-size: 14px;
    font-weight: 500;
}

.advisor__strategy__portfolio__stock__options .stock__suggestions>p
{
    width: 100%;
    display: flex;
    padding: 7px 30px!important;
    cursor: pointer;
}

.advisor__strategy__portfolio__stock__options .stock__suggestions>p>span:nth-child(1)
{
    flex: 3 1;
    font-size: 12px;
    font-weight: 700;
}

.advisor__strategy__portfolio__stock__options .stock__suggestions>p>span:nth-child(2)
{
    flex: 8 1;
    font-size: 12px;
    font-weight: 500;
}

.advisor__strategy__portfolio__stock__options .stock__suggestions>p>span:nth-child(3)
{
    flex: 2 1;
    font-size: 12px;
    font-weight: 700;
    text-align: right;
}

.advisor__strategy__portfolio__stock__options .stock__suggestions>p>span .search__highlight
{
    background-color: #009fe33a;
}

.advisor__strategy__portfolio__stock__options .advisor__strategy__stock__buttons
{
    width: 40%;
    margin-left: 20px;
    display: flex;
    justify-content: flex-start;
}

.advisor__strategy__portfolio__stock__options .advisor__strategy__stock__buttons>div
{
    background-color: var(--white);
    color: var(--font-primary);
    border: 1px solid #e8e8e8;
    padding: 7px 15px 7px 15px;
    font-size: 12px;
    margin: 0 10px;
    font-weight: 700;
    border-radius: 3px;
    transition: 0.advisor__3s;
    cursor: pointer;
}

.advisor__strategy__portfolio__stock__options .advisor__strategy__stock__buttons>div>a
{
    color: var(--font-primary);
    text-decoration: none;
}

.advisor__strategy__portfolio__stock__options .advisor__strategy__stock__buttons>div:hover
{
    opacity: 0.advisor__90;
}

.advisor__smallcase__success__page
{
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.advisor__smallcase__success__page .advisor__success__message
{
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 700;
    margin-top: 10px;
}

.advisor__smallcase__container__empty
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.advisor__smallcase__container__empty .advisor__create__smallcase__content
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
}

.advisor__smallcase__container__empty .advisor__create__smallcase__content>img
{
    width: 120px;
}

.advisor__smallcase__container__empty .advisor__create__smallcase__content>p
{
    font-size: 12px;
    margin-top: -10px !important;
    color: var(--font-secondary);
}

.advisor__smallcase__container__empty>div.advisor__create__smallcase
{
    margin-top: 50px;
}

.advisor__smallcase__container__empty>div.advisor__create__smallcase button
{
    padding: 10px 18px;
    border-radius: 3px;
    font-size: 14px;
    text-transform: uppercase;
    background-color: var(--main-blue);
    color: var(--white);
    font-weight: 500;
    transition: 0.advisor__3s;
}

.advisor__smallcase__container__empty>div.advisor__create__smallcase button:hover
{
    opacity: 0.advisor__90;
}
/* 
.advisor__backtest__container
{
    background-color: #BBBBBB;
} */
.advisor__backtest__container .advisor__backtest__header
{
    width: 100%;
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.advisor__backtest__header>span
{
    color: var(--main-blue);
    font-size: 12px;
    font-weight: 600;
}

.advisor__backtest__header>div
{
    background-color: var(--white);
    padding: 6px 20px 6px 20px;
    font-size: 12px;
    font-weight: 700;
    color: var(--font-primary);
    border-radius: 20px;
    /* box-shadow: 0 2px 5px rgb(0 0 0 / 05%); */
    transition: 0.advisor__3s;
    cursor: pointer;
}

.advisor__backtest__header>div:hover
{
    opacity: 0.advisor__90;
}

.advisor__backtest__header>div>span
{
    margin-left: 5px;
}

.advisor__backtest__container .advisor__backtest__wrapper
{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.advisor__backtest__wrapper .advisor__backtest__chart,
.advisor__backtest__wrapper .advisor__backtest__data
{
    width: 48%;
}

.advisor__backtest__wrapper .advisor__backtest__chart
{
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid #e8e8e8;
}


.advisor__backtest__wrapper .advisor__backtest__chart .advisor__backtest__chart__wrapper
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}


.advisor__backtest__wrapper .advisor__backtest__chart .advisor__backtest__chart__wrapper>p
{
    margin-bottom: 40px !important;
    align-self: flex-start;
    font-size: 12px;
}

.advisor__backtest__wrapper .advisor__backtest__data
{
    border-radius: 5px;    
    padding: 10px 20px;
}

.advisor__backtest__wrapper .advisor__backtest__data .advisor__backtest__data__table
{
    margin-bottom: 20px;
}

.advisor__backtest__data__table .advisor__table__wrapper
{
    border-radius: 3px;
    border: 1px solid #e8e8e8;
    padding: 5px 5px;
}

.advisor__backtest__data__table .advisor__data__table__title
{
    font-size: 12px;
    margin-bottom: 10px;
}

.advisor__backtest__data__table table
{
    width: 100%;
}

.advisor__backtest__data__table  tbody tr th
{
    font-weight: 700;
    font-size: 12px;
    width: 100px;
    text-align: left;
    color: var(--font-primary);
}

.advisor__backtest__data__table thead tr td
{
    font-size: 11px;
    padding-bottom: 5px;
    color: var(--font-primary);
}

.advisor__backtest__data__table table thead tr
{
    border-bottom: 1px solid #e8e8e8;
}

.advisor__backtest__data__table  tbody tr td
{
    font-weight: 500;
    font-size: 11px;
    padding: 5px 0;
    color: var(--font-primary);
}

.advisor__backtest__container .advisor__backtest__buttons
{
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.advisor__backtest__buttons .advisor__backtest__save
{
    background-color: var(--white);
    padding: 7px 15px 7px 15px;
    font-size: 12px;
    font-weight: 700;
    border: 1px solid #e8e8e8;
    color: var(--font-primary);
    border-radius: 3px;
    transition: 0.advisor__3s;
    cursor: pointer;
    margin: 0 20px 0 0;
}

.advisor__backtest__buttons .advisor__backtest__save:hover
{
    opacity: 0.8;
}


@media only screen and (max-width : 768px)
{
    
    .advisor__smallcase__container
    {
        width: 100%;
        height: 100%;
    }

    .advisor__smallcase__container .advisor__smallcase__header
    {
        padding: 15px 10px 0;
    }

    
    .advisor__smallcase__header .advisor__smallcase__title>img
    {
        width: 14px;
    }

    .advisor__smallcase__header .advisor__smallcase__title>span
    {
        margin-left: 5px;
        font-size: 11px;
        letter-spacing: -0.advisor__2px;
    }

    .advisor__smallcase__header .advisor__smallcase__menu .advisor__smallcase__stratergy__button
    {
        padding: 8px 12px 8px 12px;
        font-size: 10px;
        font-weight: 700;
    }

    .advisor__smallcase__search__wrapper
    {
        padding: 0 20px;
        width: 100%;
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .advisor__smallcase__search__wrapper .advisor__smallcase__search__box
    {
        width: 100%;
        height: 35px;
    }

    .advisor__smallcase__search__wrapper .advisor__smallcase__search__box>img
    {
        width: 14px;
    }

    .advisor__smallcase__search__wrapper .advisor__smallcase__search__box>input
    {
        font-size: 11px;
    }

    .advisor__smallcase__container .advisor__smallcase__body
    {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 2px;
        margin-top: 10px;
    }

    .advisor__smallcase__body .advisor__smallcase__strategy__wrapper,
    .advisor__smallcase__body .advisor__smallcase__strategy__details
    {
        width: 100%;
        padding: 10px 0px;
        overflow-y: scroll;
        height: 230px;
        margin-bottom: 10px;
        margin-bottom: 10px;
        /* background-color: #ebebeb; */
    }

    .advisor__smallcase__strategy__wrapper .advisor__smallcase__strategy__card
    {
        width: 100%;
        padding: 10px 10px 0;
        margin-bottom: 10px;
        
    }

    .advisor__smallcase__strategy__card .advisor__strategy__info,
    .advisor__smallcase__strategy__card .advisor__strategy__data,
    .advisor__smallcase__strategy__card .advisor__strategy__options
    {
        margin: 0px 0px 10px;
    }

    .advisor__strategy__icon__box
    {
        width: 30%;
    }

    .advisor__strategy__icon__box img
    {
        width: 12px;
    }

    .advisor__strategy__icon__box span
    {
        font-size: 10px;
    }

    .advisor__strategy__info .advisor__strategy__headings__box
    {
        width: 70%;
        padding-right: 5px;
    }

    .advisor__strategy__headings__box .advisor__strategy__heading
    {
        font-size: 10px;
    }

    .advisor__strategy__headings__box .advisor__strategy__news
    {
        font-size: 10px;
        color: var(--font-primary);
        font-weight: 500;
    }

    .advisor__strategy__data .advisor__strategy__data__table
    {
        width: 70%;
    }

    .advisor__strategy__data__table>div .advisor__title
    {
        font-size: 10px;
        font-weight: 600;
        color: var(--font-primary);
    }

    .advisor__strategy__data__table>div .advisor__value
    {
        font-size: 11px;
        font-weight: 500;
        color: var(--font-primary);
    }

    .advisor__smallcase__strategy__card .advisor__strategy__options
    {
        justify-content: space-between;
        margin-top: 15px;
    }

    .advisor__smallcase__strategy__card .advisor__strategy__options>p
    {
        font-size: 11px;
    }

    .advisor__smallcase__strategy__card .advisor__strategy__options .advisor__strategy__buy__button
    {
        background-color: var(--white);
        padding: 4px 7px 4px 7px;
        font-size: 10px;
    }

    .advisor__smallcase__strategy__details .advisor__strategy__details__box
    {
        width: 100%;
        margin-bottom: 15px;
        padding: 0 10px;
    }

    .advisor__strategy__details__box .advisor__details__header
    {
        display: flex;
        align-items: center;
    }

    .advisor__strategy__details__box .advisor__details__header>img
    {
        width: 16px;
    }

    .advisor__strategy__details__box .advisor__details__header>span
    {
        font-size: 11px;
    }

    .advisor__strategy__details__box .advisor__details__content>p
    {
        font-size: 11px;
        font-weight: 500;
        margin-top: 4px;
        color: var(--font-primary);
    }

    .advisor__strategy__details__box .advisor__details__content.advisor__strategy__name>p
    {
        color: var(--main-blue);
        font-weight: 600;
    }



    .advisor__smallcase__strategy__container
    {
        width: 100%;
        height: 100%;
        padding: 0px 10px 0;
    }

    .advisor__smallcase__strategy__container .advisor__smallcase__strategy__header
    {
        width: 100%;
        padding: 15px 0px;
    }

    .advisor__smallcase__strategy__header>span
    {
        font-size: 11px;
    }

    .advisor__smallcase__strategy__header>div
    {
        padding: 15px 15px 15px 15px;
        font-size: 12px;
        box-shadow: none;
    }

    .advisor__smallcase__strategy__header>div>img
    {
        display: none;
    }


    .advisor__smallcase__strategy__body__wrapper
    {
        width: 100%;
        height: 75%;
        padding: 0 0px;
        margin-top: 0px;
    }

    .advisor__smallcase__strategy__body
    {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 5px;
    }

    .advisor__smallcase__strategy__body .advisor__smallcase__strategy__form
    {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        padding: 10px 0;
    }

    .advisor__smallcase__strategy__body .advisor__smallcase__strategy__image
    {
        display: none;
    }


    .advisor__smallcase__strategy__form .advisor__strategy__select__container
    {
        padding: 0 10px;
    }

    .advisor__strategy__select__container .advisor__strategy__select__header>img
    {
        width: 16px;
    }


    .advisor__strategy__select__container .advisor__strategy__select__header>span
    {
        margin-left: 6px;
        font-size: 11px;
    }

    .advisor__strategy__select__container .advisor__strategy__select__box
    {
        margin-top: 5px;
    }

    .advisor__strategy__select__container .advisor__strategy__select__box .advisor__custom-select .advisor__custom-select-title,
    .advisor__strategy__select__container .advisor__strategy__select__box .advisor__custom-select .advisor__custom-select-option{
        font-size: 11px!important;
    }

    .advisor__strategy__select__container .advisor__strategy__text__box,
    .advisor__strategy__select__container .advisor__strategy__input__box
    {
        margin-top: 5px;
    }

    .advisor__strategy__select__container .advisor__strategy__text__box textarea
    {
        width: 95%;
        height: 100%;
        font-size: 10px;
    }

    .advisor__strategy__select__container .advisor__strategy__input__box input
    {
        width: 100%;
        height: 100%;
        min-height: 40px;
        padding-left: 10px;
        font-size: 10px;
    }

    .advisor__strategy__select__container .advisor__strategy__text__box textarea::-webkit-input-placeholder, .advisor__strategy__select__container .advisor__strategy__input__box input::-webkit-input-placeholder
    {
        color: var(--font-primary);
    }

    .advisor__strategy__select__container .advisor__strategy__text__box textarea:-ms-input-placeholder, .advisor__strategy__select__container .advisor__strategy__input__box input:-ms-input-placeholder
    {
        color: var(--font-primary);
    }

    .advisor__strategy__select__container .advisor__strategy__text__box textarea::placeholder,
    .advisor__strategy__select__container .advisor__strategy__input__box input::placeholder
    {
        color: var(--font-primary);
    }

    .advisor__smallcase__strategy__container .advisor__smallcase__strategy__footer
    {
        margin-top: 20px;
        width: 100%;
        padding: 5px 0px;
    }

    .advisor__smallcase__strategy__footer .advisor__strategy__footer__button
    {
        padding: 7px 20px 7px 20px;
        box-shadow: none;
        font-size: 10px;
    }

    .advisor__smallcase__strategy__footer .advisor__strategy__footer__button>img
    {
        display: none;
    }

    .advisor__strategy__portfolio__table__wrapper
    {
        width: 100%;
        padding: 0px 0;
        margin-top: 20px;
    }

    .advisor__strategy__portfolio__table__wrapper .advisor__strategy__portfolio__table
    {
        width: 100%;
        height: 90%;
    }

    .advisor__strategy__portfolio__table .advisor__strategy__portfolio__table__header,
    .advisor__strategy__portfolio__table .advisor__strategy__portfolio__table__body,
    .advisor__strategy__portfolio__table .advisor__strategy__portfolio__table__footer
    {
        width: 100%;
    }

    .advisor__strategy__portfolio__table .advisor__strategy__portfolio__table__body
    {
        height: 75%;
        overflow-y: scroll;
    }

    .advisor__strategy__portfolio__table__header .advisor__table__row,
    .advisor__strategy__portfolio__table__body .advisor__table__row,
    .advisor__strategy__portfolio__table__footer .advisor__table__row
    {
        width: 100%;
        padding: 4px 2px;
    }

    .advisor__strategy__portfolio__table__header .advisor__edit__table__row,
    .advisor__strategy__portfolio__table__body .advisor__edit__table__row,
    .advisor__strategy__portfolio__table__footer .advisor__edit__table__row
    {
        width: 100%;
        font-size: 10px;
        padding: 4px 2px;
    }

    .advisor__strategy__portfolio__table__footer .advisor__table__row,
    .advisor__strategy__portfolio__table__footer .advisor__edit__table__row
    {
        margin-top: 10px;
        border-top: 1px solid #e8e8e8;
    }

    .advisor__strategy__portfolio__table__header .advisor__table__row>div,
    .advisor__strategy__portfolio__table__footer .advisor__table__row>div,
    .advisor__strategy__portfolio__table__header .advisor__edit__table__row>div,
    .advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div
    {
        font-weight: 700;
    }

    .advisor__strategy__portfolio__table__body .advisor__table__row:hover,
    .advisor__strategy__portfolio__table__body .advisor__edit__table__row:hover
    {
        background-color: #fcfcfc;
        border-radius: 3px;
    }

    .advisor__strategy__portfolio__table__header .advisor__table__row>div:nth-child(1),
    .advisor__strategy__portfolio__table__body .advisor__table__row>div:nth-child(1),
    .advisor__strategy__portfolio__table__footer .advisor__table__row>div:nth-child(1)
    {
        font-size: 11px;
    }

    .advisor__strategy__portfolio__table__header .advisor__edit__table__row>div:nth-child(1),
    .advisor__strategy__portfolio__table__body .advisor__edit__table__row>div:nth-child(1),
    .advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div:nth-child(1)
    {
        flex: 2 1;
        font-size: 10px;
        padding-left: 5px;
    }

    .advisor__strategy__portfolio__table__header .advisor__table__row>div:nth-child(2),
    .advisor__strategy__portfolio__table__body .advisor__table__row>div:nth-child(2),
    .advisor__strategy__portfolio__table__footer .advisor__table__row>div:nth-child(2)
    {
        font-size: 11px;
    }

    .advisor__strategy__portfolio__table__header .advisor__edit__table__row>div:nth-child(2),
    .advisor__strategy__portfolio__table__body .advisor__edit__table__row>div:nth-child(2),
    .advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div:nth-child(2)
    {
        flex: 2 1;
        font-size: 10px;
    }

    .advisor__strategy__portfolio__table__header .advisor__table__row>div:nth-child(3),
    .advisor__strategy__portfolio__table__body .advisor__table__row>div:nth-child(3),
    .advisor__strategy__portfolio__table__footer .advisor__table__row>div:nth-child(3)
    {
        flex: 3 1;
        font-size: 11px;
    }

    .advisor__strategy__portfolio__table__header .advisor__table__row>div:nth-child(4),
    .advisor__strategy__portfolio__table__body .advisor__table__row>div:nth-child(4),
    .advisor__strategy__portfolio__table__footer .advisor__table__row>div:nth-child(4)
    {
        flex: 3 1;
    }

    .advisor__strategy__portfolio__table__header .advisor__edit__table__row>div:nth-child(3),
    .advisor__strategy__portfolio__table__body .advisor__edit__table__row>div:nth-child(3),
    .advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div:nth-child(3)
    {
        flex: 1.advisor__5 1;
        font-size: 10px;
    }

    .advisor__strategy__portfolio__table__header .advisor__edit__table__row>div:nth-child(4),
    .advisor__strategy__portfolio__table__body .advisor__edit__table__row>div:nth-child(4),
    .advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div:nth-child(4)
    {
        padding-right: 5px;
        flex: 1.advisor__5 1;
    }

    .advisor__strategy__portfolio__table__header .advisor__edit__table__row>div:nth-child(5),
    .advisor__strategy__portfolio__table__body .advisor__edit__table__row>div:nth-child(5),
    .advisor__strategy__portfolio__table__footer .advisor__edit__table__row>div:nth-child(5)
    {
        flex: 1 1;
        padding-right: 5px;

    }

    .advisor__strategy__portfolio__table__body .advisor__edit__table__row>div:nth-child(5)>span
    {
        font-size: 9px !important;
        font-weight: 500 !important;
    }

    .advisor__strategy__portfolio__table__body .advisor__table__row>div>input,
    .advisor__strategy__portfolio__table__body .advisor__edit__table__row>div>input
    {
        width: 100%;
        border-width: 1px;
        
    }

    .advisor__strategy__portfolio__table__body .advisor__table__row>div .advisor__stock__remove,
    .advisor__strategy__portfolio__table__body .advisor__edit__table__row>div .advisor__stock__remove
    {
        padding: 5px;
        cursor: pointer;
        transition: 0.advisor__3s;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .advisor__strategy__portfolio__table__body .advisor__table__row>div .advisor__stock__remove:hover,
    .advisor__strategy__portfolio__table__body .advisor__edit__table__row>div .advisor__stock__remove:hover
    {
        background-color: #bce8ff;
    }

    .advisor__strategy__portfolio__table__body .advisor__table__row>div .advisor__stock__remove img
    {
        width: 10px;
        height: 10px;
    }

    .advisor__strategy__portfolio__table__body .advisor__table__row>div>input:focus,
    .advisor__strategy__portfolio__table__body .advisor__edit__table__row>div>input:focus
    {
        border-bottom: 1px solid var(--font-primary);
    }


    .advisor__strategy__portfolio__table .advisor__strategy__table__stock
    {
        display: flex;
        flex-direction: column;
    }

    .advisor__strategy__table__stock .advisor__strategy__table__stock__code
    {
        font-size: 9px;
        font-weight: 700;
    }

    .advisor__strategy__table__stock .advisor__strategy__table__stock__name
    {
        max-width: 50px;
        font-size: 9px;
    }

    .advisor__strategy__portfolio__table__wrapper .advisor__strategy__performance__buttons
    {
        display: flex;
        width: 100%;
    }

    .advisor__strategy__portfolio__table__wrapper .advisor__strategy__performance__buttons>div
    {
        padding: 6px 12px 6px 12px;
        font-size: 11px;
    }

    .advisor__strategy__portfolio__stock__wrapper
    {
        width: 100%;
        /* justify-content: center; */
        padding: 5px 0;
    }

    .advisor__strategy__portfolio__stock__wrapper .advisor__strategy__portfolio__stock__options
    {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* background-color: #19E683; */
    }

    .advisor__strategy__portfolio__stock__options .stock__search
    {
        width: 100%;
        height: 35px;
        border: none!important;
        padding: 0 5px!important;
    }

    .advisor__strategy__portfolio__stock__options .stock__search>input
    {
        font-size: 12px;
    }

    .advisor__strategy__portfolio__stock__options .stock__suggestions
    {
        max-height: 200px!important;
    }

    .advisor__strategy__portfolio__stock__options .stock__suggestions .advisor__search__loader
    {
        height: 200px!important;
    }

    .advisor__strategy__portfolio__stock__options .stock__suggestions>p
    {
        padding: 6px 10px!important;
    }

    .advisor__strategy__portfolio__stock__options .stock__suggestions>p>span:nth-child(1)
    {
        font-size: 10px;
    }

    .advisor__strategy__portfolio__stock__options .stock__suggestions>p>span:nth-child(2)
    {
        font-size: 8px;
    }

    .advisor__strategy__portfolio__stock__options .stock__suggestions>p>span:nth-child(3)
    {
        font-size: 10px;
    }

    .advisor__strategy__portfolio__stock__options .advisor__strategy__stock__buttons
    {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0px;
    }

    .advisor__strategy__portfolio__stock__options .advisor__strategy__stock__buttons>div
    {
        margin: 0px 15px 0px 0px;
        font-size: 11px;
    }

    .advisor__strategy__portfolio__stock__options .advisor__strategy__stock__buttons>div:hover
    {
        background-color: #f8f8f8;
    }

    .advisor__smallcase__success__page
    {
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .advisor__smallcase__success__page .advisor__success__message
    {
        font-size: 12px;
        font-weight: 700;
        margin-top: 10px;
    }

    .advisor__backtest__container .advisor__backtest__header
    {
        width: 100%;
        padding: 5px 10px;
    }

    .advisor__backtest__header>span
    {
        font-size: 11px;
    }

    .advisor__backtest__header>div
    {
        font-size: 11px;
    }

    .advisor__backtest__container .advisor__backtest__wrapper
    {
        margin-top: 10px;
        padding: 0 2%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .advisor__backtest__wrapper .advisor__backtest__chart,
    .advisor__backtest__wrapper .advisor__backtest__data
    {
        width: 100%;
    }

    .advisor__backtest__wrapper .advisor__backtest__chart
    {
        padding: 10px 2px;
        font-size: 8px;
    }


    .advisor__backtest__wrapper .advisor__backtest__chart .advisor__backtest__chart__wrapper>p
    {
        margin-bottom: 20px !important;
        font-size: 11px;
        padding: 0 5px;
    }

    .advisor__backtest__wrapper .advisor__backtest__data
    {
        border-radius: 5px;
        padding: 10px 5px;
    }

    .advisor__backtest__wrapper .advisor__backtest__data .advisor__backtest__data__table
    {
        margin-bottom: 10px;
    }

    .advisor__backtest__data__table .advisor__data__table__title
    {
        font-size: 11px;
        margin-bottom: 10px;
    }

    .advisor__backtest__data__table>div:nth-child(2)
    {
        overflow-x: scroll;
    }

    .advisor__backtest__data__table table
    {
        width: 100%;
    }

    .advisor__backtest__data__table  thead tr td
    {
        font-size: 10px;
    }

    .advisor__backtest__data__table  tbody tr th
    {
        width: 70px;
        padding: 0 60px 0 0;
        font-size: 10px;
    }

    .advisor__backtest__data__table  tbody tr td
    {
        font-weight: 500;
        min-width: 100px;
    }

    .advisor__backtest__container .advisor__backtest__buttons
    {
        margin-top: 10px;
        padding: 0 10px;
        margin-bottom: 20px;
    }

    .advisor__backtest__buttons .advisor__backtest__save
    {
        padding: 6px 10px 6px 10px;
        font-size: 10px;
    }
}
.user-login {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.user-login-popup-wrapper {
  padding: 15px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85%;
  min-height: 600px;
  max-height: 650px;
  width: 400px;
  position: relative;
  background-color: #ffffff;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.user-login-popup-wrapper .popup-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.user-login-popup-wrapper .popup-loader p {
  font-size: 13px;
  font-weight: 600;
}

.user-login-popup-wrapper .login-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.user-login-popup-wrapper .login-header img{
  width: 40px;
}

.user-login-popup-wrapper .login-header h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

.user-login-popup-wrapper .login-header h2 a {
  text-decoration: none;
  letter-spacing: -1px;
  color: var(--font-primary);
}

.user-login-popup-wrapper .login-header h2 a i {
  font-weight: 300;
}

.user-login-popup-wrapper .login-header span {
  font-size: 14px;
  font-weight: 500;
}

.user-login-popup-wrapper .login-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 150px);
}

.user-login-popup-wrapper .login-body {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
  padding: 0 0%;
}

.login-body .resend-otp-button{
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.login-body .resend-message {
  font-size: 12px;
  font-weight: 500;
  margin-top: -5px;
}

.login-body .resend-message.hide {
  opacity: 0;
}

.login-body .login-notes {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
  background-color: #fafafa;
  padding: 10px 10px;
  border-radius: 5px;
}

.login-body .login-notes .login-note-title {
  font-weight: 600;
}

.login-body .login-notes .login-note {
  font-size: 13px;
  font-weight: 500;
}

.login-body .login-notes .login-note a{
  color: var(--font-primary);
  text-decoration: none;
}

.login-content .login-account-selection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
}

.login-account-selection .account-text {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.login-account-selection .login-accounts {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0px;
  row-gap: 0px;
  padding: 10px 10px;
  overflow-y: scroll;
}

.login-accounts .login-account-row {
  width: 100%;
  display: flex;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.login-accounts .login-account-row:hover {
  background-color: #fafafa;
}

.login-account-row .account-icon {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-account-row .account-icon .account-profile {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--font-primary);
}

.login-account-row .account-icon span {
  font-size: 16px;
  font-weight: 600;
}

.login-account-row .account-info {
  width: 90%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 1px;
  row-gap: 1px;
}

.login-account-row .account-info .account-name {
  font-size: 13px;
  font-weight: 600;
}

.login-account-row .account-info .account-role {
  font-size: 11px;
  font-weight: 500;
}

.user-login-popup-wrapper .login-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.login-footer .swift-button-icon p {
  font-weight: 700;
}

.user-login-popup-wrapper .footer-line {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footer-line p {
  font-size: 11px;
  text-align: center;
  font-weight: 500;
}

.footer-line p a{
  color: var(--font-secondary);
  text-decoration: none;
  font-weight: 700;
}

.user-login-popup-wrapper .swift-input-box {
  height: 90px;
}



.user-login-popup-wrapper .swift-input-box > p {
  font-weight: 700;
}

.user-login-popup-wrapper .swift-input-box input {
  width: 100%;
  height: 50px;
}

.user-login-popup-wrapper .input-box-note {
  font-size: 12px;
  font-weight: 500;
}

.user-login-popup-wrapper .login-message{
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
  align-items: center;
}

.user-login-popup-wrapper .login-message>p{
  font-size: 14px;
  font-weight: 600;
}

.user-login-popup-wrapper .login-message>span{
  font-size: 12px;
  font-weight: 500;
  color: var(--font-secondary);
}

.user-login-popup-wrapper #password-note{
  font-size: 12px;
  font-weight: 500;
}

.swift-signup-status-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.swift-signup-status-main a {
  color: var(--font-primary);
  text-decoration: none;
}

.swift-signup-status-main p.brand {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1px;
  color: rgba(1, 22, 39, 0.7);
}

.swift-signup-status-main .swift-signup-status-heading {
  padding-left: 50px;
  padding-top: 30px;
}

.swift-signup-status-main .swift-signup-status-heading p {
  color: var(--text-color);
  font-size: var(--font-heading);
  font-style: normal;
  font-weight: var(--font-weight-heavy);
  line-height: normal;
  letter-spacing: -0.8px;
}

.swift-signup-status-main .swift-signup-status-info-1 {
  width: 100%;
  color: rgba(1, 22, 39, 0.7);
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: var(--font-weight-light);
  line-height: normal;
  letter-spacing: -1px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.swift-signup-status-main .swift-signup-status-button {
  border: 1px solid #404040;
  background-color: transparent;
  border-radius: 3px;
  font-size: 16px;
  color: var(--font-primary);
  padding: 15px 50px;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
}

.swift-signup-status-main .swift-signup-status-info-2 {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 23px;
}

.swift-signup-status-main .swift-signup-status-info {
  width: 50%;
  margin: auto;
}


.logout-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.logout-wrapper>p{
  font-size: 18px;
  font-weight: 700;
}

.logout-wrapper>span{
  font-size: 16px;
  font-weight: 500;
}



@media only screen and (max-width: 768px) {

  .user-login{
    align-items: flex-start;
  }

  .user-login-popup-wrapper{
    border: none;
    height: 100%;
    grid-row-gap: 40px;
    row-gap: 40px;
    padding: 15px 20px 50px;
    max-height: none;
    max-height: initial;
  }

  .user-login-popup-wrapper .popup-loader p{
    font-size: 15px; 
  }

  .user-login-popup-wrapper .login-header img{
    width: 60px;
  }

  .user-login-popup-wrapper .login-header h2 {
    font-size: 24px;
    line-height: 26px;
  }

  .user-login-popup-wrapper .login-header span {
    font-size: 16px;
  }

  .user-login-popup-wrapper .footer-line{
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .footer-line p{
    font-size: 14px;
  }

  .login-body .login-notes .login-note{
    font-size: 14px;
  }

  .login-body .resend-otp-button{
    font-size: 14px;
  }

}
.admin-dashboard-stats-wrapper {
  padding: 5px;
  width: 500px;
}
.admin-dashboard-stats-container {
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}
.admin-dashboard-stats-title {
  font-size: 15px;
  font-weight: 600;
}
.admin-dashboard-stats-data {
  display: flex;
  flex-direction: column;
}
.admin-dashboard-stats-data-row {
  font-size: 13px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.admin-dashboard-stats-data-object {
  display: flex;
  flex-direction: column;
  width: 200px;
  grid-row-gap: 0px;
  row-gap: 0px;
}

.admin-dashboard-stats-data-title {
  font-size: 12px;
  font-weight: 500;
}

.admin-dashboard-stats-data-value {
  font-size: 20px;
  font-weight: 600;
}

.admin-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-popup-wrapper {
  padding: 15px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  height: 85%;
  max-height: 650px;
  min-height: 600px;
  width: 400px;
  position: relative;
  background-color: #ffffff;
}

.login-popup-wrapper .popup-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-popup-wrapper .login-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.login-popup-wrapper .login-header img {
  width: 40px;
}

.login-popup-wrapper .login-header h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

.login-popup-wrapper .login-header a {
  text-decoration: none;
  letter-spacing: -1px;
  color: var(--font-primary);
}

.login-popup-wrapper .login-header h2 a i {
  font-weight: 300;
}

.login-popup-wrapper .login-header span {
  font-size: 14px;
  font-weight: 500;
}

.login-popup-wrapper .login-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 150px);
}

.login-popup-wrapper .login-body {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
}

.login-popup-wrapper .swift-input-box {
  height: 90px;
}

.login-popup-wrapper .swift-input-box > p {
  font-weight: 700;
}

.login-popup-wrapper .swift-input-box input {
  width: 100%;
  height: 50px;
}

.login-body .resend-message {
  font-size: 12px;
  font-weight: 500;
  margin-top: -5px;
}

.login-body .resend-message.hide {
  opacity: 0;
}

.login-popup-wrapper .login-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.login-footer .swift-button-icon p {
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .login-body .resend-message {
    font-size: 14px;
  }
}

.swift-admin-wrapper {
  width: 100vw;
  height: 100%;
  display: flex;
}

.swift-admin-wrapper .swift-menu.active {
  width: 300px;
}

.swift-admin-wrapper .swift-menu .swift-menu-icon .menu-text {
  width: 200px;
}

.swift-admin-body {
  position: absolute;
  top: 0;
  left: 50px;
  width: calc(100% - 50px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px;
}

.swift-admin-body .header-options {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
}

.swift-admin-body-wrapper {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
}


.swift-admin-body-wrapper .admin-body-loader{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.swift-admin-body-wrapper .admin-body-loader>p{
  font-size: 14px;
  font-weight: 600;
}

.swift-admin-body .header-options .title {
  font-size: 16px;
  font-weight: 600;
  color: var(--font-primary);
  letter-spacing: -0.2px;
}

.swift-account-main {
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
}


.swift-account-main .swift-account-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: center;
}

.swift-account-main .swift-account-heading {
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.swift-account-main .swift-account-svg,
.swift-account-main .swift-account-btn,
.swift-modal .swift-account-svg,
.swift-modal .swift-account-btn {
  cursor: pointer;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swift-account-main .swift-account-btn {
  border: none;
  background: none;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 7px 15px;
  border: 1px solid #e8e8e8;
  background-color: #f5f5f5;
  border-radius: 35px;
  color: var(--font-primary);
  cursor: pointer;
}

.swift-account-main .swift-account-btn.disabled{
  cursor: not-allowed;
  color: var(--text-color-blur);
}

.swift-account-main .swift-account-svg.disabled{
  cursor: not-allowed;
}

.swift-account-main .swift-account-btn.text{
  padding: 0;
  background-color: transparent;
  border: none;
  font-weight: 700;
}

.swift-account-main .empty-data-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.swift-account-main .empty-data-wrapper>p{
  font-size: 15px;
  font-weight: 600;
}

/* Style the popup */

.swift-modal .popup-content {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.swift-modal .popup-content img {
  width: 50vw;
  height: 80vh;
  object-fit: contain;
}

.swift-modal .popup-content button {
  border: none;
  padding-top: 10px;
  background: none;
  text-align: center;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: center;
  cursor: pointer;
}

/* Form Style */
.swift-account-main .swift-account-form,
.swift-modal .swift-account-form {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  background: #fff;
  border-radius: 10px;
  padding: 10px 15px 10px;
  width: 500px;
  min-height: 550px;
  max-height: 650px;
}

.swift-account-main .swift-customer-form,
.swift-modal .swift-customer-form {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: auto;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.swift-account-main .swift-account-form-header,
.swift-modal .swift-account-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swift-account-main .swift-account-form-title,
.swift-modal .swift-account-form-title {
  font-size: 14px;
  font-weight: 600;
}

.swift-account-main .swift-account-form-section,
.swift-modal .swift-account-form-section {
  display: flex;
  align-items: baseline;
}

.swift-account-main .swift-account-form-section span,
.swift-modal .swift-account-form-section span {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px;
  /* margin-top: 3%; */
}

.swift-account-main .swift-account-form-section button,
.swift-modal .swift-account-form-section button {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 7px 10px;
  border: none;
  border-radius: 35px;
  background-color: #f0f0f0;
  cursor: pointer;
  margin-left: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 1%;
  color: var(--font-primary);
}

.swift-account-main .broker-tabs,
.swift-modal .broker-tabs {
  display: flex;
  padding: 10px 0;
}

.swift-account-main .broker-tab,
.swift-modal .broker-tab {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* padding: 10px; */
  /* padding-left: 0px; */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
  padding: 7px 10px;

  /* border: 2px solid black; */
}

.swift-account-main table svg {
  height: 25px;
}

.swift-account-main .broker-tab.active,
.swift-modal .broker-tab.active {
  /* background-color: white;
  padding-left: 0px;
  font-weight: 600; */
  line-height: normal;
  /* padding: 10px; */
  border: none;
  border-radius: 35px;
  background-color: #f0f0f0;
  /* height: fit-content; */
  /* border: 2px solid black; */
}

.swift-account-main .broker-tab.delete,
.swift-modal .broker-tab.delete {
  padding: 10px;
  margin: auto;
  line-height: normal;
  border: none;
  border-radius: 35px;
  background-color: #f0f0f0;
  color: var(--font-primary);
}

.swift-account-main .swift-account-form-body,
.swift-modal .swift-account-form-body {
  padding-top: 30px;
  flex: 1 1;
  /* Use flex to take the remaining space */
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.swift-account-main .swift-account-form-body-content,
.swift-modal .swift-account-form-body-content {
  width: 100%;
  flex: 1 1;
  /* overflow-y: auto; */
  /* height: 80vh; */
}

.swift-account-main .swift-account-form-body-content-extra,
.swift-modal .swift-account-form-body-content-extra {
  /* width: 100%; */
  /* flex: 1; */
  overflow-y: auto;
  /* padding: 0 30px; */
  padding-right: 10px;
  /* height: 58vh; */
}

.swift-modal .swift-account-form-body-content-extra {
  height: 450px;
}

.swift-account-main .login-footer,
.swift-modal .login-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 25px 0 15px 0;
}

.swift-account-main .login-footer.extra,
.swift-modal .login-footer.extra {
  justify-content: space-between;
}

.swift-account-main .resend-message,
.swift-modal .resend-message {
  font-size: 12px;
  font-weight: 500;
  /* margin-top: 5px; */
  padding-bottom: 10px;
}

.swift-account-main .resend-message.hide,
.swift-modal .resend-message.hide {
  opacity: 0;
}

.swift-account-main .confirm-popup-btn,
.swift-modal .confirm-popup-btn {
  padding: 10px 40px 0px 40px;
  display: flex;
  justify-content: space-between;
}

.swift-account-main .loader,
.swift-modal .loader {
  margin: auto;
}

.swift-account-main .loader .dot-pulse,
.swift-modal .loader .dot-pulse {
  left: -9855px;
  top: 17px;
}

.swift-account-main .select-search-wrapper,
.swift-modal .select-search-wrapper {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-row-gap: 5px;
  row-gap: 5px;
  align-self: flex-end;
  /* padding-bottom: 20px; */
}

.swift-account-main .select-search-box .filter-icon,
.swift-modal .select-search-box .filter-icon {
  padding-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swift-account-main .error-text,
.swift-modal .error-text {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  line-height: 12px;
}

.swift-account-main .error-text.hide,
.swift-modal .error-text.hide {
  opacity: 0;
}

.swift-account-main .swift-error,
.swift-modal .swift-error {
  padding: 2px 0px 10px 0;
}

.swift-account-main .swift-input-box,
.swift-modal .swift-input-box {
  padding-bottom: 15px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.swift-account-main .swift-account-btn.extra-btn,
.swift-modal .swift-account-btn.extra-btn {
  padding-top: 0px;
}

.swift-account-heading-right {
  display: flex;
  align-items:center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.swift-account-heading-right button {
  font-size: 13px;
  font-weight: 600;
  padding: 10px 35px;
  border-radius: 45px;
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  color: var(--font-primary);
  cursor: pointer;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.swift-account-main .svgicon,
.swift-modal .svgicon {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .swift-account-main .select-search-wrapper {
    width: 90%;
    align-self: flex-start;
    padding: 20px 0px;
  }

  .swift-account-main .swift-account-heading {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 550px) {
  .swift-account-main .swift-account-form {
    width: 450px;
  }
}

@media screen and (max-width: 500px) {
  .swift-account-main .swift-account-form {
    width: 400px;
  }

  .swift-account-main .swift-button-icon {
    width: 150px;
    min-width: 150px;
  }

  .swift-account-main .swift-button-icon p {
    font-size: 12px;
  }
}

@media screen and (max-width: 450px) {
  .swift-account-main .swift-account-form {
    width: 350px;
  }

  .swift-account-main .swift-button-icon {
    width: 130px;
    min-width: 130px;
  }

  .swift-account-main .select-search-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .swift-account-main .swift-account-form {
    width: 300px;
  }

  .swift-account-main .swift-button-icon {
    width: 100px;
    min-width: 100px;
  }
}

@media screen and (max-width: 350px) {
  .swift-account-main .swift-account-form {
    width: 250px;
  }

  .swift-account-main .swift-button-icon {
    width: 90px;
    min-width: 90px;
  }
}



/* ================================================SEARCH BAR=====================================  */

.swift-admin-stock-search-wrapper {
  padding-bottom: 15px;
}

.swift-admin-stock-search-wrapper .stock__search__icons__wrapper {
  /* height: 45px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  box-shadow: var(--light-box-shadow);
  width: 100%;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.swift-admin-stock-search-wrapper .stock__search__icons__wrapper>p {
  font-size: 12px;
  font-weight: 500;
}

.swift-admin-stock-search-wrapper .stock__search {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}

.swift-admin-stock-search-wrapper .stock__search .stock__search__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px 0 15px;
}

.swift-admin-stock-search-wrapper .stock__search .stock__search__icon img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.swift-admin-stock-search-wrapper .stock__search input {
  border: none;
  outline: none;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  color: var(--font-primary);
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase !important;
  background-color: transparent;
}

.swift-admin-stock-search-wrapper .stock__search input::-webkit-input-placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-admin-stock-search-wrapper .stock__search input:-ms-input-placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-admin-stock-search-wrapper .stock__search input::placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: 0%;
  margin-top: 3px;
  z-index: 10000;
  border-radius: 5px;
  max-height: 200px;
  box-shadow: 1px 1px 20px rgb(0 0 0 / 10%);
}

.swift-admin-stock-search-wrapper .stock__suggestions .suggestion__stocks {
  overflow-y: scroll;
  max-height: 200px;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions.active {
  display: block;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions .search__loader {
  width: 100%;
  height: 200px ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions .search__loader p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  padding: 0 !important;
  display: flex;
  color: var(--font-primary);
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p {
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.swift-admin-stock-search-wrapper .suggestion__stocks .suggestion__empty {
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p.active {
  background-color: #dbd9d9;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p span.search__highlight {
  font-weight: 500;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p>span:nth-child(1) {
  flex: 4 1;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p>span:nth-child(2) {
  flex: 10 1;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p>span:nth-child(3) {
  flex: 1 1;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  text-align: right;
}

.swift-admin-stock-search-wrapper .stock__search .stock__suggestions p:hover {
  background-color: #f9f9f9;
}
.swift-account-display-form {
  height: calc(100vh - 40px);
  padding: 5px 0px;
  border-radius: 30px;
}
.main {
  padding: 0px;
}

.swift-account-display-form .swift-account-display-form-row {
  display: flex;
  flex-direction: row;
  grid-column-gap: 5%;
  -webkit-column-gap: 5%;
          column-gap: 5%;
  padding-right: 30px;
}
.swift-account-display-form .custom-select,
.swift-account-display-form .swift-input-box {
  width: 15%;
}
.swift-account-display-form .swift-account-btn.extra-btn {
  color: #011627;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
  border: none;
  border-radius: 35px;
  background-color: #f0f0f0;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 25px;
}
.swift-account-display-form .login-footer.extra {
  justify-content: flex-end;
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  padding-top: 10px;
  padding-right: 30px;
}

.admin-display-form {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 15px;
  row-gap: 15px;
}

.popup-customer_id-form {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.swift-transaction-management .strategy-content {
  position: relative;
}

.trasaction-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.trasaction-header .trasaction-title {
  font-size: 18px;
  color: var(--font-primary);
  font-weight: 700;
}

.admin-wrapper {
  height: 85%;
  padding: 0 0px;
}

.admin-wrapper.full {
  height: 90%;
}

.admin-wrapper .admin-menu {
  display: flex;
  padding: 0 0px;
}

.admin-wrapper .admin-menu .admin-menu-option {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  border: 1px solid var(--white);
  padding: 8px 20px;
  border-radius: 3px;
  transition: 0.3s;
}

.admin-wrapper .admin-history-container {
  border-radius: 15px;
  width: 100%;
  padding: 0px 0px 5px;
  overflow-y: scroll;
  padding: 0 0px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  position: relative;
}

.admin-wrapper .admin-history-container {
  height: 100%;
  overflow-y: scroll;
}

.admin-wrapper .admin-history-container table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.admin-history-container table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.admin-history-container table thead tr td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.admin-history-container table tbody {
  width: 100%;
}

.admin-history-container table tbody tr {
  cursor: auto;
  cursor: initial;
  transition: 0.3s;
  border-bottom: 1px solid #f5f5f5c5;
}

.admin-history-container table tbody tr:hover {
  background-color: #fcfcfc;
}

.admin-history-container table tbody tr td {
  padding: 14px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

@media only screen and (max-width: 768px) {
  .order-container-empty>img {
    width: 80px;
  }

  .order-container-empty>div>p {
    font-size: 16px !important;
  }

  .order-container-empty>div>span {
    font-size: 13px;
  }

  .admin-wrapper {
    height: 100%;
    padding: 0 10px;
  }

  .admin-wrapper .admin-menu .admin-menu-option {
    margin-right: 10px;
    padding: 10px 30px;
  }

  .admin-menu .admin-menu-option svg {
    width: 16px;
    height: 16px;
  }

  .admin-menu .admin-menu-option span {
    font-size: 14px;
    font-weight: 600;
  }

  .admin-wrapper .table-container,
  .admin-wrapper .table-loader {
    background-color: var(--white);
    border-radius: 4px;
    margin-top: 20px;
    width: 100%;
    height: 90%;
    padding: 0px 0px 5px;
    overflow-y: scroll;
  }

  .admin-wrapper .table-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .admin-wrapper .table-loader>p {
    font-size: 14px;
    margin-top: 10px !important;
  }

  .admin-wrapper .order-trades-container {
    box-shadow: 0 0 2px #e8e8e8;
    overflow-x: scroll;
  }

  .admin-wrapper .order-trades-container table {
    min-width: 800px;
    width: 100%;
    border-collapse: separate;
  }

  .order-trades-container table thead tr {
    z-index: 1000;
  }

  .order-trades-container table thead tr th {
    padding: 6px 0 6px 5px;
    min-width: 4.51vw;
    font-size: 12px;
    z-index: 2;
    background-color: var(--white);
  }

  .order-trades-container table thead tr th:nth-child(1) {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 3;
  }

  .order-trades-container table tbody {
    width: 100%;
  }

  .order-trades-container table tbody tr td:nth-child(1) {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: var(--white);
    max-width: 6.45vw;
    z-index: 1;
  }

  .order-trades-container table tbody tr td {
    padding: 12px 0 12px 5px;
    font-size: 10px;
  }

  .order-trades-container table tbody tr td .stock-info .stock-name {
    font-weight: 700;
  }

  .order-trades-container table tbody tr td .stock-info .stock-fullname {
    font-size: 9px;
    max-width: 80px;
  }

  .order-trades-container table tbody tr td:nth-child(9) {
    min-width: 7.74vw;
  }

  .order-trades-container table tbody tr td .modify-order,
  .order-trades-container table tbody tr td .cancel-order {
    width: 24px;
    height: 24px;
  }

  .order-trades-container table tbody tr td .modify-order>img,
  .order-trades-container table tbody tr td .cancel-order img {
    width: 14px;
  }

  .order-info {
    border-radius: 4px;
    margin-top: 20px;
    width: 100%;
    height: 90%;
    padding: 0px 0px 5px;
    background-color: #fdfdfd;
  }
}


.admin-customer-container table tbody tr td,
.admin-broker-container table tbody tr td,
.admin-superuser-container table tbody tr td,
.admin-stock-container table tbody tr td,
.admin-activation-container table tbody tr td,
.admin-orders-mf-container table tbody tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-customer-container table tr td.name,.admin-activation-container table tr td.name{
  width: 220px;
}

.admin-customer-container table tr td.email,.admin-customer-container table tr td.registration-time,.admin-activation-container table tr td.email,.admin-activation-container table tr td.registration-time,.admin-activation-container table tr td.activation-time{
  width: 180px;
}

.admin-customer-container table tr td.pan-image,.admin-customer-container table tr td.status{
  width: 120px;
}

.admin-customer-container table tr td.role{
  width: 140px;
}


.admin-customer-container table tr td.edit,.admin-broker-container table tr td.edit,.admin-superuser-container table tr td.edit,.admin-stock-container table tr td.edit,.admin-dealer-container table tr td.edit{
  width: 60px;
}

.admin-customer-container table tr td.deactivate,.admin-broker-container table tr td.deactivate,.admin-superuser-container table tr td.deactivate,.admin-activation-container table tr td.deactivate{
  width: 120px;
}

.admin-broker-container table tr td.contact{
  width: 240px;
}

.admin-superuser-container table tr td.arrow{
  width: 80px;
}

.admin-stock-container table tr td.company{
  width: 400px;
}

.admin-stock-container table tr td.series{
  width: 100px;
}

.admin-stock-container table tr td.isin{
  width: 180px;
}

.admin-stock-container table tr td.delete{
  width: 100px;
}

.admin-activation-container table tr td.status,.admin-activation-container table tr td.vertical{
  width: 80px;
}

.admin-activation-container table tr td.view{
  width: 80px;
}
.swift-orders-management-mf-heading {
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.admin-orders-mf-container table tbody tr.yes_hover:hover td:not(:last-child) {
  opacity: 0.5;
}

.admin-orders-mf-container table tbody tr.yes_hover:hover .hover-container {
  display: flex;
}

.admin-orders-mf-container .hover-container {
  position: absolute;
  top: 50%;
  right: 20px;
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  display: none;
  height: 100%;
  transform: translateY(-50%);
}

.admin-orders-mf-container .hover-btn {
  z-index: 100000;
  background: #f5f5f5;
  border: 1px solid #e8e8e8;
  border-radius: 35px;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  min-width: 100px;
}

.orders-management-loader .dot-pulse {
  left: -9999px !important;
  top: 0px !important;
}

/* ------------------------------------- SEARCH -----------------------------------*/

.admin-orders-management-mf-search .stock__search__icons__wrapper {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--light-box-shadow);
  width: 100%;
  align-self: flex-end;
}

.admin-orders-management-mf-search .stock__search {
  width: 500px;
  height: 100%;

  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;

  background: rgba(255, 255, 255, 0.933333);
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.admin-orders-management-mf-search .stock__search .stock__search__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px 0 15px;
}

.admin-orders-management-mf-search .stock__search .stock__search__icon img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.admin-orders-management-mf-search .stock__search input {
  border: none;
  outline: none;
  width: 100%;
  height: 90%;
  padding: 13px 0px;
  padding-left: 15px;
  color: var(--font-primary);
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase !important;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.admin-orders-management-mf-search .stock__search input::-webkit-input-placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.admin-orders-management-mf-search .stock__search input:-ms-input-placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.admin-orders-management-mf-search .stock__search input::placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.admin-orders-management-mf-search .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: 0%;
  margin-top: 3px;
  z-index: 10000;
  border-radius: 5px;
  max-height: 425px;
  /* overflow-y : scroll; */
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
  /* display: none; */
}

.admin-orders-management-mf-search .stock__suggestions .suggestion__stocks {
  overflow-y: scroll;
  max-height: 250px;
}

.admin-orders-management-mf-search .stock__search .stock__suggestions.active {
  display: block;
}

.admin-orders-management-mf-search
  .stock__search
  .stock__suggestions
  .search__loader {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-orders-management-mf-search
  .stock__search
  .stock__suggestions
  .search__loader
  p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  padding: 0 !important;
  display: flex;
  color: var(--font-primary);
}

.admin-orders-management-mf-search .stock__search .stock__suggestions p {
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.admin-orders-management-mf-search .suggestion__stocks .suggestion__empty {
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.admin-orders-management-mf-search .stock__search .stock__suggestions p.active {
  background-color: #dbd9d9;
}

.admin-orders-management-mf-search
  .stock__search
  .stock__suggestions
  p
  span.search__highlight {
  font-weight: 500;
}

.admin-orders-management-mf-search .stock__search .stock__suggestions p > span {
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
}

.admin-orders-management-mf-search .stock__search .stock__suggestions p:hover {
  background-color: #f9f9f9;
}

/* ---------------------------------------------------------------------------------- */

.admin-orders-managemen-bonds-offers-btn:hover {
  cursor: pointer;
}

.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
}

.admin-offers-popup-div {
  grid-column-gap: 15px !important;
  -webkit-column-gap: 15px !important;
          column-gap: 15px !important;
}

.admin-offers-popup-div > span {
  padding-left: 10px;
}

.broker-management .header-options {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.orders-management-loader p {
  font-size: 14px;
  font-weight: 600;
}

.admin-orders-mf-container table tbody tr {
  position: relative;
}

.admin-orders-mf-container table tbody tr td {
  padding: 18px 10px !important;
}

.admin-orders-mf-container table tr td.input-time,.admin-orders-bonds-container table tr td.input-time {
  width: 140px;
}

.admin-orders-mf-container table tr td.scheme-name {
  width: 180px;
}

.admin-orders-mf-container table tr td.nav-temp,
.admin-orders-mf-container table tr td.nav {
  width: 80px;
}

.admin-orders-mf-container table tr td.hover-td {
  width: 0;
  padding: 0 !important;
}


.admin-orders-bonds-container table tr td.issue{
  width: 200px;
}

.admin-orders-bonds-container table tr td.price-temp,.admin-orders-bonds-container table tr td.price,.admin-orders-bonds-container table tr td.yield,.admin-orders-bonds-container table tr td.type{
  width: 80px;
}


.swift-mf-popup-btn-switch {
  border-radius: 35px;
  margin-bottom: 20px;
}

.swift-mf-popup-btn-switch .custom-select {
  border-radius: 35px;
  /* padding: 12px 40px; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.05em;
  color: rgba(1, 22, 39, 0.9);
  width: 90px;
}

.swift-mf-popup-btn-switch .custom-select .custom-select-header {
  padding: 5px 20px;
  border-radius: 35px;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  border: none;
}

.swift-mf-popup-btn-switch .custom-select .custom-select-header > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  letter-spacing: -0.05em !important;
  color: rgba(1, 22, 39, 0.9);
}

.swift-mf-popup-btn-switch.positive-btn .custom-select {
  background-color: var(--main-green);
}

.swift-mf-popup-btn-switch.negative-btn .custom-select {
  background-color: var(--main-red);
}

.swift-mf-popup-btn-switch .custom-select .select-title {
  display: none;
}

.admin-orders-management-mf-search > .custom-select .select-title {
  display: none;
}

.admin-orders-management-mf-search > .custom-select .custom-select-header {
  height: 50px;
  width: 100%;
  color: var(--font-primary);
  font-weight: 500;
  font-size: 14px;
  /* text-transform: uppercase !important; */
  background-color: transparent;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.swift-mf-popup-header {
  font-size: 14px;
  font-weight: 600;
}

.swift-mf-admin-popup .swift-mf-popup-body {
  padding-top: 10px;
  flex: auto;
}

.swift-mf-admin-popup {
  min-height: 640px;
}

.broker-management .user-login {
  height: 100vh;
}

.swift-bonds-admin-popup {
  min-height: 705px;
}

.swift-mf-admin-popup
  .custom-select
  .custom-select-header
  .custom-select-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: -webkit-fill-available;
}

.swift-mf-admin-popup
  .custom-select
  .custom-select-container
  .custom-select-option {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.swift-stock-management-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  padding: 0 20px;
}

.swift-stock-management-wrapper .swift-stock-container {
  width: 100%;
  max-width: 1380px;
  position: -webkit-sticky;
  position: sticky;
}

.swift-stock-management-wrapper .swift-stock-management-inner {
  height: 100%;
  width: 100%;
  margin-top: 80px;
}

.swift-stock-management-wrapper a {
  text-decoration: none;
}
.swift-stock-management-wrapper .minus,
.swift-stock-feed-modal .minus {
  color: var(--main-red) !important;
}
.swift-stock-management-wrapper .plus,
.swift-stock-feed-modal .plus {
  color: var(--main-green) !important;
}

.swift-stock-management-wrapper .loader {
  margin: auto !important;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
}
.swift-stock-management-wrapper .dot-pulse {
  align-self: center;
  justify-self: center;
}

.swift-stock-management-wrapper button {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--font-primary);
}
.swift-stock-management-wrapper .swift-stock-management-inner > div {
  margin-top: 40px;
}
.swift-stock-management-wrapper
  .swift-stock-management-inner
  > div:nth-child(1) {
  margin-top: 0px;
}
.swift-stock-management-wrapper
  .swift-stock-management-inner
  > div:nth-child(2) {
  margin-top: 40px;
}
.swift-stock-management-wrapper .swift-stock-management-inner > div:last-child {
  margin-top: 0px;
}

.swift-stock-management-wrapper .swift-stock-header-wrapper{
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  z-index: 100;
  background: white;
}

.swift-stock-management-wrapper .swift-stock-management-header {
  display: flex;
  align-items: flex-start;
  padding: 10px 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  width: 100%;
  max-width: 1380px;
  margin: 0 auto;
}
.swift-stock-management-wrapper .swift-stock-management-title {
  display: flex;
  align-items: center;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}
.swift-stock-management-wrapper .swift-stock-management-menu-icon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}
.swift-stock-management-wrapper .swift-stock-management-menu-icon img {
  width: 45px;
}
.swift-stock-management-wrapper .swift-stock-management-title {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .swift-stock-management-title span {
  font-style: italic;
}
.swift-stock-management-wrapper .swift-stock-management-title p {
  font-weight: 700;
  display: inline;
}
.swift-stock-management-wrapper .swift-stock-management-detail {
  /* padding: 10px 25px; */
  background: #ffffff;
  /* border: 1px solid #e8e8e8; */
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 24px;
  row-gap: 24px;
}

.swift-stock-management-wrapper .swift-stock-management-detail-row-1,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2 {
  display: flex;
  grid-column-gap: 45px;
  -webkit-column-gap: 45px;
          column-gap: 45px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-left {
  /* width: 30%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* row-gap: 6px; */
  padding-bottom: 30px;
}
.swift-stock-management-wrapper .swift-stock-management-deatil-left-1 {
  display: flex;
  flex-direction: column;
}

.swift-stock-management-wrapper .swift-stock-management-detail-symbol {
  font-weight: 700;
  font-size: 16px;
  color: var(--font-primary);
  letter-spacing: -1px;
}

.swift-stock-management-wrapper .swift-stock-management-detail-title {
  font-weight: 500;
  font-size: 13px;
  color: var(--text-color-blur);
}
.swift-stock-management-wrapper .swift-stock-management-detail-value {
  display: flex;
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  font-weight: 700;
  font-size: 15px;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .swift-stock-management-detail-right-row-2 {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  grid-column-gap: 65px;
  -webkit-column-gap: 65px;
          column-gap: 65px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-right-target,
.swift-stock-management-wrapper .swift-stock-management-detail-right-value {
  display: flex;
}

.swift-stock-management-wrapper .swift-stock-management-detail-right {
  padding: 10px 20px 10px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  width: 30%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 8px;
  row-gap: 8px;
}

.swift-stock-management-wrapper .swift-stock-management-detail-right-btn.mid {
  align-self: flex-end;
  justify-self: flex-end;
  padding-top: 20px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-right-btn.right {
  align-self: flex-end;
  justify-self: flex-end;
  padding-top: 10px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-right-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--font-primary);
}
.swift-stock-management-wrapper
  .swift-stock-management-detail-right-target
  span {
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
}

.swift-stock-management-wrapper .swift-stock-management-detail-right-target p {
  font-size: 16px;
  line-height: 24px;
  color: #011627;
}
.swift-stock-management-wrapper .swift-stock-management-detail-right-value {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-right-btn
  button {
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  background: #f1f1f1;
  border: 1px solid #e8e8e8;
  border-radius: 35px;
  padding: 8px 10px 8px 14px;
  width: 100px;
  cursor: pointer;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .swift-stock-management-detail-row-2 {
  display: flex;
  grid-column-gap: 45px;
  -webkit-column-gap: 45px;
          column-gap: 45px;
  /* border: 2px solid black; */
}

.swift-stock-management-wrapper .swift-stock-management-detail-row-2-left,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-mid,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-right {
  width: 30%;
  display: flex;
  flex-direction: column;
  /* border: 2px solid black; */
}
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-left,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-mid {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-left,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-mid,
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-right {
  padding: 25px;
}

.swift-stock-management-wrapper .swift-stock-management-detail-row-2-right {
  /* width: 100%; */
  padding: 10px 20px 10px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-left-detail {
  padding: 12px 0px;
}
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-left-row {
  padding: 7px 0 16px;
  border-bottom: 1px solid rgba(1, 22, 39, 0.1);
  display: flex;
  justify-content: space-between;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-left-row:last-child {
  border-bottom: none; /* Remove border for the last child */
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-left-title {
  font-weight: 700;
  font-size: 16px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-left-row-title {
  font-weight: 500;
  font-size: 13px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-left-row-value {
  font-weight: 500;
  font-size: 13px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-mid-detail {
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
  padding-top: 12px;
  flex: 1 1;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-mid-detail-title {
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-mid-detail-value {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-detail {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-detail-rows {
  padding: 15px 0px 15px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  grid-row-gap: 8px;
  row-gap: 8px;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-detail-rows:first-child {
  padding-top: 0;
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-detail-rows:last-child {
  border-bottom: none; /* Remove border for the last child */
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-news {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: var(--font-primary);
}

.swift-stock-management-wrapper
  .swift-stock-management-detail-row-2-right-date {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: var(--text-color-blur);
}
.swift-stock-management-wrapper .swift-stock-management-detail-menu-btn.active {
  font-weight: 700;
}
.swift-stock-management-wrapper table td {
  min-width: 80px;
}

/* =============================================================CHART=============================== */

.swift-stock-management-wrapper .swift-stock-management-chart {
  padding: 10px 25px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  width: 100%;
  max-width: 1280px;
}

.swift-stock-management-wrapper .swift-stock-management-chart-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 45px;
}

.swift-stock-management-wrapper .swift-stock-management-chart-right {
  display: flex;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  align-items: flex-start;
}
.swift-stock-management-wrapper .swift-stock-management-chart-price-btn,
.swift-stock-management-wrapper .swift-stock-management-chart-pe-btn {
  background: none;
  border: none;
  background: #cccccc;
  border-radius: 35px;
  padding: 10px 35px;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
}

.swift-stock-management-wrapper .swift-stock-management-chart-pe-btn {
  border: 1px solid rgba(1, 22, 39, 0.7);
  border-radius: 35px;
  background: none;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .swift-stock-management-chart-wrapper {
  width: 100%;
  aspect-ratio: 16/7;
  position: relative;
}

.swift-stock-management-wrapper
  .swift-stock-management-chart-wrapper
  .chart-wrapper-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff9d;
  z-index: 100;
}

.swift-stock-management-wrapper .stocks-management-chart-range {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  margin-top: 20px;
}

.swift-stock-management-wrapper
  .stocks-management-chart-range
  .chart-range-value {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.swift-stock-management-wrapper
  .stocks-management-chart-range
  .chart-range-value.active {
  font-size: 13px;
  font-weight: 700;
}
.swift-stock-management-wrapper .swift-stock-management-feed {
  width: 30% !important;
}
/* =============================================================INCOME=============================== */

.swift-stock-management-wrapper .swift-stock-management-income {
  padding: 10px 25px 10px 25px;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
}

.swift-stock-management-wrapper .swift-stock-management-income-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swift-stock-management-wrapper .swift-stock-management-income-title,
.swift-stock-management-wrapper .swift-stock-management-chart-title,
.swift-stock-management-wrapper .swift-stock-management-balance-header,
.swift-stock-management-wrapper .swift-stock-management-case-header,
.swift-stock-management-wrapper .swift-stock-management-technical-header {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.swift-stock-management-wrapper .swift-stock-management-income-header-right {
  display: flex;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.swift-stock-management-wrapper .swift-stock-management-income-btn {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  width: 220px;
  padding: 8px 10px;
}

.swift-stock-management-wrapper .swift-stock-management-income-btn span {
  font-size: 13px;
  font-weight: 700;
  text-align: left;
}
.swift-stock-management-wrapper .swift-stock-management-income-btn p {
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  display: inline;
}

.swift-stock-management-wrapper .swift-stock-management-income-time-btn {
  align-self: flex-end;
  display: flex;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  padding: 0px 10px;
  color: var(--font-primary);
  font-size: 11px;
  text-align: center;
}

.swift-stock-management-wrapper
  .swift-stock-management-income-time-btn
  .selected {
  color: var(--QuoteNav-row-color);
  font-weight: 700;
}

/* ========================================================Table=========================================== */
.swift-stock-management-wrapper .stock-management-table {
  width: 100%;
  height: 97%;
  padding: 0;
  border-radius: 5px;
  overflow-x: auto;
}

.swift-stock-management-wrapper .stock-management-create-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  overflow-x: auto;
}

.swift-stock-management-wrapper .stock-management-create-table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: var(--white);
  background-color: #fcfcfc;
}

.swift-stock-management-wrapper .stock-management-create-table tr {
  height: 35px;
  width: 80px;
}

.swift-stock-management-wrapper .stock-management-create-table td {
  text-align: left;
  border: none;
}

.swift-stock-management-wrapper .stock-management-create-table thead td {
  padding-bottom: 0;
  padding-top: 2px;
  padding-left: 10px;
  border: none;
  padding: 0 10px;
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
  text-align: left;
  background-color: white;
}

.swift-stock-management-wrapper .stock-management-create-table tbody td {
  height: 10px;
  border: none;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 500;
  color: var(--font-primary);
  padding-bottom: 0;
  padding-top: 2px;
  background-color: white;
}

.swift-stock-management-wrapper
  .stock-management-create-table
  tr
  td:first-child {
  padding: 0 10px;
  text-align: left;
  min-width: 120px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  padding-left: 0;
}

.swift-stock-management-wrapper .stock-management-create-table tr {
  border-bottom: 1px solid #f5f5f5;
}

.swift-stock-management-wrapper .stock-management-create-table td.cell__normal {
  font-weight: 500;
}

.swift-stock-management-wrapper .stock-management-create-table td.cell__bold {
  font-weight: 700;
}

/* =============================== BALANCE SHEET =============================== */

.swift-stock-management-wrapper .swift-stock-management-balance,
.swift-stock-management-wrapper .swift-stock-management-case,
.swift-stock-management-wrapper .swift-stock-management-technical {
  padding: 25px 25px;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
}

/* ================================================SEARCH BAR=====================================  */

.swift-stock-management-wrapper .stock__search__icons__wrapper {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--light-box-shadow);
  width: 500px;
}

.swift-stock-management-wrapper .stock__search {
  width: 500px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.swift-stock-management-wrapper .stock__search .stock__search__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px 0 15px;
}

.swift-stock-management-wrapper .stock__search .stock__search__icon img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.swift-stock-management-wrapper .stock__search input {
  border: none;
  outline: none;
  width: 100%;
  height: 90%;
  padding: 13px 0px;
  padding-left: 15px;
  color: var(--font-primary);
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase !important;
  background-color: transparent;
}

.swift-stock-management-wrapper .stock__search input::-webkit-input-placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-stock-management-wrapper .stock__search input:-ms-input-placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-stock-management-wrapper .stock__search input::placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.swift-stock-management-wrapper .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: 0%;
  margin-top: 3px;
  z-index: 10000;
  border-radius: 5px;
  max-height: 425px;
  /* overflow-y : scroll; */
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
  /* display: none; */
}

.swift-stock-management-wrapper .stock__suggestions .suggestion__stocks {
  overflow-y: scroll;
  max-height: 375px;
}

.swift-stock-management-wrapper .stock__search .stock__suggestions.active {
  display: block;
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  .search__loader {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  .search__loader
  p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  padding: 0 !important;
  display: flex;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .stock__search .stock__suggestions p {
  padding: 10px 10px 10px 20px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .suggestion__stocks .suggestion__empty {
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.swift-stock-management-wrapper .stock__search .stock__suggestions p.active {
  background-color: #dbd9d9;
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  p
  span.search__highlight {
  /* color: #c5c5c5; */
  font-weight: 500;
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(1) {
  flex: 4 1;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  /* max-width: 140px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(2) {
  flex: 5 1;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  /* max-width: 200px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
}

.swift-stock-management-wrapper
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(3) {
  flex: 1 1;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  color: var(--font-primary);
}

.swift-stock-management-wrapper .stock__search .stock__suggestions p:hover {
  background-color: #f9f9f9;
}

/* =============================================Technical=============================== */

.swift-stock-management-wrapper .swift-stock-management-technical {
  width: 30%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  row-gap: 15px;
}

.swift-stock-management-technical-technicals {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  grid-row-gap: 40px;
  row-gap: 40px;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view
  .swift-stock-management-technical-slider__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view
  .swift-stock-management-technical-slider__wrapper
  .swift-stock-management-technical-view__title {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view
  .swift-stock-management-technical-slider__wrapper
  .swift-stock-management-technical-slider
  .stock__view__label {
  font-size: 13px;
  font-weight: 600;
  text-align: right;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view
  .swift-stock-management-technical-text {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
}
.swift-stock-management-technical-technicals
  .swift-stock-management-technical-views
  .swift-stock-management-technical-tech__view
  .swift-stock-management-technical-text
  p {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  color: var(--text-color-blur);
  margin-top: -2px;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;
  grid-row-gap: 30px;
  row-gap: 30px;
}

.swift-stock-management-technical-tech__view__final {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 10px 15px;
}

.swift-stock-management-technical-tech__view__final
  .swift-stock-management-technical-view__title {
  font-size: 13px;
  font-weight: 600;
}

.swift-stock-management-technical-tech__view__final
  .swift-stock-management-technical-text {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-color-blur);
}

.swift-stock-management-technical-tech__view__final .stock__view__label {
  margin-top: 6px;
  font-size: 13px;
  font-weight: 700;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price {
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 10px 15px;
  grid-row-gap: 10px;
  row-gap: 10px;
}
.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__genie {
  width: 100%;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__genie
  p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__value {
  width: 100%;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__value
  p {
  font-size: 12px;
  line-height: 16px;
  color: var(--font-primary);
  font-weight: 500;
  display: flex;
}

.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__value
  p
  > span.span__bold {
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 700;
}
.swift-stock-management-technical-technicals
  .swift-stock-management-technical-target
  .swift-stock-management-technical-target__price
  .swift-stock-management-technical-target__value
  p
  .swift-stock-management-technical-target__change.negative {
  color: var(--main-red);
  font-size: 12px;
}

/* ==========================================Feed Modal================================ */
.swift-stock-feed-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 800px;
  height: 100%;
  background-color: var(--white);
  padding: 20px 0px;
  border: 1px solid #e8e8e8;
  z-index: 1000000;
  max-width: 100%;
  box-shadow: -2px 0px 10px #e8e8e8;
}
.swift-stock-feed-modal-header {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
}
.swift-stock-feed-modal-title {
  font-weight: 600;
  font-size: 18px;
}
.swift-stock-feed-modal-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 0 30px;
}
.swift-stock-feed-modal-detail-rows {
  padding: 15px 0px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.swift-stock-feed-modal-detail-rows:last-child() {
  border-bottom: none; /* Remove border for the last child */
}

.swift-stock-feed-modal-news {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--font-primary);
}

.swift-stock-feed-modal-date {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: var(--font-primary);
}

/* =======================================================About Modal =======================================*/

.swift-stock-about-modal {
  width: 900px;
}

.swift-stock-about-modal-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
.swift-stock-about-row-1 {
  display: flex;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  padding-top: 10px;
}
.swift-stock-about-target,
.swift-stock-about-value {
  display: flex;
}
.swift-stock-about-target span {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #011627;
}

.swift-stock-about-target p {
  font-size: 16px;
  line-height: 24px;
  color: #011627;
}
.swift-stock-about-value {
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
}

.swift-stock-about-row-2 {
  padding-top: 36px;
  width: 43%;
}

.swift-stock-about-desc {
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}
.swift-stock-about-regional {
  padding-top: 20px;
  font-weight: 700;
}

.swift-stock-management-wrapper .swift-stock-management-detail-row-2-menu {
  display: flex;
  justify-content: flex-start;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  align-items: center;
  display: none;
}
.swift-stock-management-wrapper .swift-stock-management-detail-row-2-menu div {
  cursor: pointer;
}

.swift-stock-management-wrapper .swift-stock-management-detail-menu-btn {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
  padding-left: 0px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.swift-stock-management-wrapper .swift-menu {
  display: none;
  grid-row-gap: 0px;
  row-gap: 0px;
}
.swift-stock-management-wrapper .swift-account-svg svg {
  width: 16px;
  cursor: pointer;
}
.swift-stock-management-wrapper .stock-management-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: rgba(1, 22, 39, 0.9);
}

@media screen and (max-width: 1000px) {
  .swift-stock-management-wrapper .swift-stock-management-chart-feed-wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: 40px;
    row-gap: 40px;
  }
  .swift-stock-management-wrapper .swift-stock-management-chart {
    width: 100%;
  }
  .swift-stock-management-wrapper .swift-stock-management-feed {
    width: 100% !important;
  }
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-left,
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-mid,
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-right {
    width: 50%;
  }
  .swift-stock-management-wrapper .swift-stock-management-technical {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .swift-stock-management-wrapper {
    padding: 0 15px;
  }

  .swift-stock-management-wrapper .swift-stock-management-inner {
    padding: 0px 0;
  }
  .swift-stock-management-wrapper
    .swift-stock-management-inner
    > div:nth-child(2) {
    margin-top: 10px;
  }
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-left,
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-mid,
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-right {
    width: 100%;
  }
  .swift-stock-management-wrapper .swift-stock-management-technical {
    width: 100%;
  }
  .swift-stock-management-wrapper .swift-stock-management-detail-row-1,
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2 {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
  .swift-stock-management-wrapper .swift-stock-management-detail-row-2-menu {
    display: flex;
  }
  
  .swift-stock-management-wrapper .swift-stock-management-header-row-1 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;
    height: 50px;
  }

  .swift-stock-management-wrapper .swift-menu {
    position: fixed;
    left: 0;
    display: flex;
    box-shadow: none;
    height: 100%;
    width: 50px;
    max-width: 100%;
    background: transparent;
    transition: width 0.1s;
  }
  .swift-stock-management-wrapper .swift-menu.active {
    width: 300px;
    max-width: 100%;
    box-shadow: 2px 0px 10px #e8e8e8;
    background: white;
  }

  .swift-stock-management-wrapper .swift-menu .swift-menu-options {
    display: none;
    flex-direction: column;
    width: 100%;
    grid-row-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .swift-stock-management-wrapper .swift-menu.active .swift-menu-options{
    display: flex;
  }

  .swift-stock-management-wrapper .swift-menu.active .menu-text {
    font-size: 16px;
    width: auto;
  }

  .swift-stock-management-wrapper .swift-stock-management-menu-icon {
    display: flex;
  }
  .swift-stock-management-wrapper .swift-stock-management-income-title,
  .swift-stock-management-wrapper .swift-stock-management-chart-title,
  .swift-stock-management-wrapper .swift-stock-management-balance-header,
  .swift-stock-management-wrapper .swift-stock-management-case-header,
  .swift-stock-management-wrapper .swift-stock-management-technical-header {
    font-size: 13px;
  }
  .swift-stock-about-modal {
    width: 100vw;
  }
  .swift-stock-feed-modal {
    width: 100%;
    height: 100%;
    background-color: var(--white);
  }
  .swift-stock-about-row-2 {
    padding-top: 36px;
    width: 100%;
  }
  .swift-stock-management-wrapper .swift-stock-management-income-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: 15px;
    row-gap: 15px;
  }
  .swift-stock-management-wrapper .swift-stock-management-header {
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
    padding-bottom: 10px;
    align-items: flex-start;
    grid-row-gap: 10px;
    row-gap: 10px;
    padding: 0 10px;
  }

  .swift-stock-management-wrapper .swift-stock-management-menu-icon {
    display: flex;
  }
  .swift-stock-management-wrapper .swift-menu .swift-menu-logo {
    display: flex;
  }

  .swift-stock-management-wrapper .stock__search__icons__wrapper {
    width: 100%;
  }
  .swift-stock-management-wrapper .stock__search {
    width: 100%;
  }
  .swift-stock-management-wrapper .swift-stock-management-income-header-right {
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
  }

  .swift-stock-management-wrapper .swift-stock-management-balance,
  .swift-stock-management-wrapper .swift-stock-management-case,
  .swift-stock-management-wrapper .swift-stock-management-technical {
    padding: 10px 15px;
  }

  .swift-stock-management-wrapper .swift-stock-management-income-time-btn {
    padding: 0;
  }

  .swift-stock-management-wrapper .swift-stock-management-income-btn {
    padding: 0;
    border: none;
    width: 180px;
  }
}

/* broker trades management */

.broker-management {
  width: 100%;
  height: 100%;
}

.swift-broker-trades-management {
  width: 100%;
  height: 95%;
  padding: 30px 60px 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.swift-broker-trades-management.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  padding-top: 200px;
  font-size: 14px;
  font-weight: 600;
}

.swift-broker-trades-management .client-trade-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 10%;
}

.swift-broker-trades-management .client-trade-header .client-header-text {
  display: flex;
  flex-direction: column;
  grid-row-gap: 0px;
  row-gap: 0px;
}

.client-trade-header .client-header-text .client-title {
  font-size: 16px;
  color: var(--font-primary);
  font-weight: 600;
}

.client-trade-header .client-header-text .client-title span {
  font-weight: 500;
}

.client-trade-header .client-header-button button {
  background-color: var(--white);
  padding: 10px 12px;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--font-primary);
  border: 1px solid var(--font-primary);
  transition: 0.3s;
}

.client-trade-header .client-header-button button:hover {
  opacity: 0.95;
}

.swift-broker-trades-management .client-trades-table {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-top: 10px;
}

.client-trades-table .trades-table-menu {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.trades-table-menu .trades-status {
  display: flex;
  flex-direction: row;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.trades-table-menu .trades-status .status-menu {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.trades-table-menu .trades-status .status-menu.active {
  font-weight: 700;
}

.trades-table-menu .trades-search {
  width: 300px;
  height: 40px;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.trades-table-menu .trades-search input {
  background-color: transparent;
  height: 100%;
  width: 80%;
  outline: none;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  padding: 10px 0px;
  border: none;
  outline: none;
}

.trades-table-menu .trades-search .filter-icon {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.client-trades-table .trades-table-wrapper {
  width: 100%;
  height: 90%;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  overflow-y: scroll;
}

.trades-table-wrapper table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.trades-table-wrapper table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.trades-table-wrapper table tbody {
  width: 100%;
}

.trades-table-wrapper tr {
  border-bottom: 1px solid #f1f1f1;
}

.trades-table-wrapper thead tr td {
  text-align: left;
  padding: 10px 10px 10px 10px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trades-table-wrapper tbody tr td {
  padding: 10px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trades-table-wrapper table thead tr td:nth-child(1) {
  max-width: 140px;
}

.trades-table-wrapper table tbody tr td:nth-child(2) {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trades-table-wrapper table tbody tr td .stock-info {
  display: flex;
  flex-direction: column;
}

.trades-table-wrapper table tbody tr td .stock-info .stock-name {
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
}

.trades-table-wrapper table tbody tr td .stock-info .stock-fullname {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.2px;
}

.trades-table-wrapper table tbody tr td.order-item-current-value {
  font-weight: 600;
}

.trades-table-wrapper table tbody tr td.empty-table-cell {
  width: 100%;
  height: 200px;
}

.trades-table-wrapper .empty-table-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.trades-table-wrapper .empty-table-data > p {
  font-size: 16px;
  font-weight: 500;
}

.swift-trades-uploads-wrapper {
  width: 450px;
  height: auto;
  background-color: var(--white);
  border-radius: 5px;
  padding: 10px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  position: relative;
}

.swift-trades-uploads-wrapper .swift-trades-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000000;
  inset: 0;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.8);
}

.swift-trades-uploads-wrapper .swift-trades-upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;
  row-gap: 30px;
}

.swift-trades-upload .swift-upload-header {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swift-trades-upload .swift-upload-header > p {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-trades-upload .swift-upload-header .upload-header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.swift-trades-upload .swift-upload-content {
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 60px;
  row-gap: 60px;
}

.swift-upload-content .browse-file {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.swift-upload-content .browse-file .message {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
}

.swift-upload-content .browse-file .file-browse-btn {
  padding: 8px 36px;
  background-color: #f1f1f1;
  color: var(--font-primary);
  border: 1px solid #e8e8e8;
  outline: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
}

.swift-upload-content .browse-file .uploaded-file {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}

.swift-upload-content .upload-file {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* funds request view css */

.broker-management .funds-request-wrapper {
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.broker-management .funds-request-wrapper .request-box {
  width: auto;
  border: 1px solid #e8e8e8;
  border-radius: 25px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;
  row-gap: 30px;
}

.broker-management .funds-request-wrapper .request-box.loader {
  display: flex;
  justify-content: center;
  min-height: 200px;
  border: none;
}

.funds-request-wrapper .request-box .request-box-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.funds-request-wrapper .request-box .request-box-header img {
  width: 60px;
}

.funds-request-wrapper .request-box .request-box-header h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

.funds-request-wrapper .request-box .request-box-header h2 a {
  text-decoration: none;
  letter-spacing: -1px;
  color: var(--font-primary);
}

.funds-request-wrapper .request-box .request-box-header h2 a i {
  font-weight: 300;
}

.funds-request-wrapper .request-box .request-box-header span {
  font-size: 14px;
  font-weight: 500;
}

.funds-request-wrapper .request-box .request-box-body {
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  padding: 25px 30px;
  background-color: #f1f1f1;
  border-radius: 10px;
  width: 430px;
}

.funds-request-wrapper .request-box .request-box-body > p {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
}

.funds-request-wrapper .request-box .request-box-body > p > span {
  font-weight: 700;
  font-size: 14px;
}

.funds-request-wrapper .request-box .request-box-body > h4 {
  font-weight: 500;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 14px;
}

.funds-request-wrapper .request-box .request-box-body > h4 > span {
  font-weight: 700;
}

.funds-request-wrapper .request-box .request-box-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
}

.broker-management .bonds-content-wrapper {
  height: 100%;
}

.broker-management .broker-bonds-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px 20px 0;
}

.broker-management .broker-bonds-wrapper .bonds-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  height: calc(100% - 40px);
}

.broker-management .bonds-table-wrapper {
  height: 100%;
}

.broker-management .bonds-table-wrapper table tr td.issue {
  width: 240px;
}

.broker-management .bonds-table-wrapper table tr td.type,
.broker-management .bonds-table-wrapper table tr td.yield,
.broker-management .bonds-table-wrapper table tr td.amount,
.broker-management .bonds-table-wrapper table tr td.price,
.broker-management .bonds-table-wrapper table tr td.shares,
.broker-management .bonds-table-wrapper table tr td.quantity,
.broker-management .bonds-table-wrapper table tr td.offer-yield,
.broker-management .bonds-table-wrapper table tr td.offer-price {
  width: 100px;
}

.broker-management .bonds-table-wrapper table tr td.status {
  width: 120px;
}

.broker-management .bonds-table-wrapper table tr td.offer-date,
.broker-management .bonds-table-wrapper table tr td.input-date {
  width: 160px;
}

.broker-management .bonds-table-wrapper .swift-account-btn {
  background: none;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 7px 15px;
  border: 1px solid #e8e8e8;
  background-color: var(--white);
  border-radius: 35px;
  color: var(--font-primary);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .broker-management .funds-request-wrapper .request-box {
    border: none;
  }

  .funds-request-wrapper .request-box .request-box-body {
    width: 100%;
  }
}

.swift-kyc-management {
    width: 100%;
    height: 95%;
    padding: 30px 100px 0;
}

.client-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    padding-top: 30px;
    height: 90%;
}

.client-content .client-content-left {
    width: 40%;
    height: 100%;
}

.client-content-left .kyc-message {
    font-size: 13px;
    font-weight: 500;
    padding-right: 30px;
}

.client-content-left .client-info {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
}

.client-content-left .client-info p {
    font-size: 12px;
    font-weight: 500;
}

.client-content-left .client-info p span {
    font-size: 14px;
    font-weight: 700;
}

.client-content .client-content-right {
    width: 600px;
    height: 100%;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.swift-kyc-management .client-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.swift-kyc-management .client-header .client-title {
    font-size: 18px;
    color: var(--font-primary);
    font-weight: 800;
}

.client-content .first-step-container {
    width: 350px;
}

.first-step-container .step-header {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
}

.first-step-container .step-header h1 {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
}

.first-step-container .step-header p {
    font-size: 22px;
    font-weight: 300;
    line-height: 22px;
}

.first-step-container .client-select {
    width: 260px;
    margin-top: 20px;
}

.first-step-container .step-button {
    margin-top: 20px;
}

.client-content .client-sub-form {
    width: 100%;
    height: 90%;
    padding: 20px 25px;
    overflow-y: scroll;
}

.client-sub-form .sub-form-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.sub-form-header p {
    font-size: 14px;
    font-weight: 800;
}

.client-sub-form .account-holder-select {
    width: 300px;
}

.client-sub-form .sub-primary-form,
.client-sub-form .sub-nomination-form {
    margin-top: 40px;
    width: 100%;
    border-radius: 5px;
}

.sub-primary-form .title,
.sub-nomination-form .title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
}

.sub-primary-form .flex-row,
.sub-nomination-form .flex-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.sub-primary-form .flex-row .flex-item,
.sub-nomination-form .flex-row .flex-item {
    width: 48%;
}

.sub-primary-form .flex-row .flex-item .custom-select,
.sub-nomination-form .flex-row .flex-item .custom-select {
    height: 80px;
}

.sub-primary-form .primary-form-input-select,
.sub-nomination-form .primary-form-input-select {
    width: 300px;
}

.sub-primary-form .primary-form-input-select.select-box,
.sub-nomination-form .primary-form-input-select.select-box {
    height: 80px;
}



.client-sub-form .sub-submit {
    display: flex;
    margin-top: 20px;
}

.sub-nomination-form .sub-nominee-form {
    margin-top: 20px;
}

.sub-nominee-form .nominee-form-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.sub-nomination-form .nominee-number {
    font-size: 13px;
    font-weight: 600;
}

.sub-nomination-form .nominee-add,
.sub-nomination-form .nominee-remove {
    background-color: var(--font-primary);
    padding: 6px 10px;
    border-radius: 3px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    cursor: pointer;
    transition: 0.3s;
}

.sub-nomination-form .nominee-add:hover,
.sub-nomination-form .nominee-remove:hover {
    opacity: 0.95;
}

.sub-nomination-form .nominee-add span,
.sub-nomination-form .nominee-remove span {
    font-size: 12px;
    font-weight: 500;
}

.client-content-right .sub-submit {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 25px;
    border-top: 1px solid #f5f5f5;
}

.client-content-right .sub-submit button {
    padding: 8px 20px;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 13px;
    font-weight: 700;
    background-color: var(--font-primary);
    color: var(--white);
}

.sub-primary-form .pan-upload-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.client-content-right .upload-pan-wrapper {
    width: 70%;
    height: 50px;
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    border: 1px solid #f5f5f5;
    padding: 0 5px;
}

.upload-pan-wrapper .choose-button,
.view-pan-button {
    background-color: var(--font-primary);
    border: none;
    outline: none;
    color: var(--white);
    font-size: 12px;
    font-weight: 600;
    padding: 0px 10px;
    border-radius: 3px;
    height: 30px;
    cursor: pointer;
}

.view-pan-button a {
    color: var(--white);
    text-decoration: none;
}

.upload-pan-wrapper .file-name {
    font-size: 13px;
    font-weight: 500;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload-pan-wrapper .file-name.light {
    color: #ccc;
    font-weight: 500;
}

.pan-upload-btn .upload-button {
    background-color: var(--font-primary);
    border: none;
    outline: none;
    color: var(--white);
    font-size: 12px;
    font-weight: 600;
    padding: 0px 20px;
    border-radius: 3px;
    height: 30px;
    cursor: pointer;
}
.customer-trades{
  width: 100%;
  height: 100%;
  padding: 30px 60px 0;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.customer-trades .customer-trades-header{
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer-trades .customer-trades-header .trades-header-left{
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.customer-trades .customer-trades-header .trades-header-left>div,.customer-trades .customer-trades-header .trades-header-left>div>a{
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-trades .customer-trades-header img{
  width: 30px;
}

.customer-trades .customer-trades-title{
  font-size: 16px;
  font-weight: 700;
}

.customer-trades .customer-trades-table{
  height: calc(100% - 60px);
  width: 100%;
}

.customer-trades .customer-trades-table.loader{
  display: flex;
  justify-content: center;
  align-items: center;
}



@media only screen and (max-width: 768px){
  .customer-trades{
    width: 100%;
    height: 100%;
    padding: 10px 10px 0;
  }

  .customer-trades .customer-trades-header img{
    width: 24px;
  }

  .customer-trades .customer-trades-title{
    font-size: 15px;
  }
}
.backoffice__client__wrapper {
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backoffice__client {
    width: 90%;
    height: 90%;
    min-width: 1000px;
    min-height: 700px;
    border-radius: 3px;
}

/* backoffice client details */
.bo__client__details {
    width: 100%;
    height: 10%;
    /* border: 1px solid #e8e8e8; */
    margin-bottom: 10px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bo__client__details>p {
    font-size: 20px;
    color: var(--font-primary);
    /* font-weight: 700; */
    text-transform: uppercase;
}

/* backoffice client data */
.bo__data {
    width: 100%;
    height: 90%;
    display: flex;
    overflow: hidden;
    box-shadow: 1px 3px 5px #e8e8e8, 0px -1px 2px #e8e8e8;
}

.bo__data .bo__menu {
    width: 15%;
    min-width: 150px;
    height: 100%;
    box-shadow: 1px 3px 5px #e8e8e8;
    margin-right: 2px;
}

.bo__menu .bo__menu__item {
    width: 100%;
    padding: 12px 10px;
    cursor: pointer;
    font-size: 13px;
    color: var(--font-primary);
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;
    transition: 0.3s;
}

.bo__menu .bo__menu__item.active {
    background-color: var(--main-blue);
    color: var(--white);
}

.bo__menu .bo__menu__item:hover {
    background-color: #f4f4f4;
}

.bo__menu .bo__menu__item.active:hover {
    background-color: var(--main-blue);
}

.bo__menu .bo__menu__item.selected {
    background-color: var(--main-blue);
    color: white;
}

.bo__data .bo__table {
    width: 85%;
    height: 100%;
    position: relative;
    overflow-y: scroll;
}

.bo__table .bo__table__empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bo__table .bo__table__empty .bo__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bo__table__empty .bo__empty img {
    width: 140px;
}

.bo__table__empty .bo__empty p {
    font-size: 14px;
    color: var(--font-primary);
}

.bo__table table {
    width: 100%;
}

.bo__table table thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #f6f6f6;
}

.bo__table table thead tr {
    width: 100%;
}

.bo__table table thead tr th {
    padding: 10px 0;
    font-size: 11px;
    color: var(--font-primary);
    font-weight: 700;
    min-width: 100px;
}

.bo__table table tbody tr {
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
    /* border-bottom: 1px solid #e8e8e8; */
    box-shadow: 0px 1px 1px #e8e8e8;
}



.bo__table table tbody tr:hover {
    background-color: #fcfcfc;
}

.bo__table table tbody tr td {
    padding: 12px 0;
    font-size: 12px;
    color: var(--font-primary);
    font-weight: 500;
    text-align: center;
    box-shadow: 1px 0px 1px #e8e8e8;
}


.bo__table table tbody tr td img {
    width: 20px;
}
.order-status
{
    width: 100%;
    height: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.order-status .order-status-header
{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.order-status .order-status-header>img
{
    width: 30px;
    cursor: pointer;
}

.order-status .order-status-body
{
    width: 100%;
    height: 90%;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.order-status-body .order-status-title
{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    color: var(--font-primary);
}

.order-status-body .order-status-description
{
    font-size: 13px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--font-primary);
    text-align: center;
}

.order-status-body .order-status-exchange {
    font-size: 12px;
    color: var(--main-red);
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
}
.order-status-body .order-status-buttons
{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.order-status-body .order-status-buttons>div
{
    width: 200px;
    height: 40px;
    border: 1px solid var(--font-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    color: var(--font-primary);
    font-size: 13px;
    font-weight: 600;
    margin: 5px 0;
    cursor: pointer;
}

.checkmark__icon
{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    margin-bottom: 2%;
    box-shadow: inset 0px 0px 0px var(--main-green);
    animation: fillcirclesuccess .6s ease-in-out .9s forwards, scalecircle .6s ease-in-out 1s both;
}

.order-icon.success .checkmark__icon
{
    animation: fillcirclesuccess .6s ease-in-out .9s forwards, scalecircle .6s ease-in-out 1s both;
}

.order-icon.failure .checkmark__icon
{
    animation: fillcirclefailure .6s ease-in-out .9s forwards, scalecircle .6s ease-in-out 1s both;
}


.checkmark__circle__inner
{
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 8;
    stroke-miterlimit: 10;
    fill: none !important;
    animation: strokecircle 0.9s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    stroke: var(--main-green);
}

.order-icon.success .checkmark__circle__inner
{
    stroke: var(--main-green);
}


.order-icon.failure .checkmark__circle__inner
{
    stroke: var(--main-red);
}

.checkmark__check__inner
{
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: strokecircle 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}


@keyframes strokecircle {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scalecircle {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fillcirclesuccess {
    0%,100% {
        box-shadow: inset 0px 0px 0px 30px var(--main-green);
    }
}

@keyframes fillcirclefailure {
    0%,100% {
        box-shadow: inset 0px 0px 0px 30px var(--main-red);
    }
}


@media only screen and (max-width:768px)
{
    
}
.modify-popup-overlay {
  position: fixed;
  inset: 0;
  background: #87888850;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
}

.modify-popup {
  z-index: 1000000;
  width: 40%;
  height: 60%;
  padding: 0 20px;
  border-radius: 5px;
  transform: translateY(8px);
  box-shadow: 0 0 14px 0 rgb(15 17 17 / 10%);
  /* box-shadow: 1px 3px 5px #e8e8e8; */
  background-color: var(--white);
  animation: getup 0.1s linear forwards;
}

@keyframes getup {
  0% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.modify-popup.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modify-header {
  width: 100%;
  height: 15%;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modify-title {
  width: 100%;
  display: flex;
  align-items: center;
}

.modify-title .title-name {
  display: flex;
  align-items: center;
}

.modify-title .title-price {
  margin-left: 20px;
  display: flex;
}

.title-price .title-price-value {
  font-size: 12px;
  font-weight: 600;
}

.title-price .title-price-change {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 600;
}

.modify-close img {
  width: 30px;
  cursor: pointer;
}

.modify-body {
  width: 100%;
  height: 60%;
}

.modify-body .modify-body-row {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.modify-body-row.modify-box {
  flex-wrap: wrap;
}

.modify-body-row .modify-type-box {
  width: 80px;
  height: 35px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--font-primary);
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
  cursor: pointer;
  transition: 0.2s;
  letter-spacing: 0.1px;
}

.modify-body-row.buy .modify-type-box:hover {
  background-color: #fdfdfd;
  color: var(--main-green);
  border-color: var(--main-green);
}

.modify-body-row.buy .modify-type-box.active {
  background-color: var(--main-green);
  border: none;
  color: var(--white);
}

.modify-body-row.sell .modify-type-box:hover {
  background-color: #fdfdfd;
  color: var(--main-red);
  border-color: var(--main-red);
}

.modify-body-row.sell .modify-type-box.active {
  background-color: var(--main-red);
  border: none;
  color: var(--white);
}

.modify-body-row .modify-error {
  display: none;
}

.modify-body-row .modify-error p {
  font-size: 12px;
  font-weight: 700;
  color: var(--main-red) !important;
}

.modify-footer {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: bisque; */
}

.modify-footer .modify-error p {
  font-size: 12px;
  font-weight: 700;
  color: var(--main-red) !important;
}

.modify-footer .modify-buttons {
  display: flex;
}

.modify-footer .modify-buttons .modify-button {
  width: 90px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
}

.modify-footer .modify-buttons .modify-button:hover {
  opacity: 0.9;
}

.modify-button.buy.disabled,
.modify-button.sell.disabled {
  opacity: 0.7;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: not-allowed;
}

.modify-button.buy.disabled:hover,
.modify-button.sell.disabled:hover {
  opacity: 0.7;
}

.modify-button.buy {
  background-color: var(--main-green);
  color: var(--white);
}

.modify-button.sell {
  background-color: var(--main-red);
  color: var(--white);
}

.modify-button.close {
  background-color: var(--white);
  color: var(--font-primary);
  border: 1px solid var(--font-primary);
}

@media only screen and (max-width: 768px) {
  .modify-popup {
    /* inset: 0; */
    /* transform: translate(0%,0%); */
    z-index: 1000000;
    width: 100%;
    height: 100%;
  }

  .modify-header {
    padding: 0 0px;
    height: 10%;
  }

  .title-name .title-action {
    width: 70px;
    height: 30px;
    border-radius: 25px;
    font-size: 13px;
    margin-right: 10px;
  }

  .title-name .title-symbol {
    font-size: 16px;
    font-weight: 600;
  }

  .modify-body {
    width: 100%;
    height: 70%;
    padding: 0 0px;
  }

  .modify-body .modify-body-row {
    margin: 10px 0 20px;
  }

  .modify-body-row .modify-type-box {
    width: 70px;
    height: 30px;
    margin-right: 10px;
    font-size: 11px;
  }

  .modify-body-row.modify-box {
    justify-content: space-between;
  }

  .modify-body-row .modify-error {
    display: block;
  }

  .modify-footer {
    padding: 0 10px;
    justify-content: flex-end;
  }

  .modify-footer .modify-error {
    display: none;
  }

  .modify-footer .modify-buttons .modify-button {
    width: 80px;
    height: 30px;
    font-size: 12px;
  }
}

.backoffice-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backoffice__login__check {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.windowtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 15px;
  color: #404040;
}

.windowtitle > div {
  display: flex;
  align-items: center;
}

.windowtitle .trade-options {
  display: flex;
  margin-left: 20px;
}

.windowtitle .trade-options > div {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  min-width: 120px;
  height: 30px;
  padding: 4px 10px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 500;
}

.windowtitle .trade-options > div.active {
  background-color: #606060;
  color: var(--white);
}

.windowtitle > div > p {
  font-size: 18px;
  font-weight: 700;
}

.windowtitle .logout-btn {
  /* width: 100px; */
  /* height: 35px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  transition: 0.3s;
  cursor: pointer;
  padding: 8px 14px;
}

.windowtitle > div > span {
  letter-spacing: 0.6px;
  font-weight: 500;
  transition: 0.3s;
}

.windowtitle > div > img {
  width: 20px;
  transform: rotateY(180deg);
  margin-left: 10px;
}

.windowtitle > div:hover {
  border-color: #888888;
  background-color: #ffffff;
}

.windowtitle > div:hover > span {
  font-weight: 700;
}

.getOrder__container {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 12px;
  border-radius: 3px;
  width: 90%;
  height: 100%;
  padding-top: 20px;
  max-width: 1800px;
}

.container__empty {
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container__empty > img {
  width: 240px;
  transform: rotateY(180deg);
}

.container__empty > p {
  margin-top: 30px !important;
  font-size: 16px;
  font-weight: bold;
}

.getOrder__selection__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.getOrder__selection__wrapper > div {
  display: flex;
}

.getOrder__header {
  width: 100%;
}

.getOrder__header__wrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.getOrder__header__wrapper.no-space {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.getOrder__header__wrapper.no-space .getOrder__details {
  margin-right: 50px;
}

.getOrder__details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
  width: 22%;
  border-radius: 3px;
  height: 60px;
  box-shadow: 1px 3px 5px #e8e8e8;
}

.getOrder__details > img {
  width: 30px;
  height: 30px;
}

.getOrder__details > div {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: #404040;
}

.getOrder__details > div .getOrder__details__heading {
  font-size: 12px;
}

.getOrder__details > div .getOrder__details__val {
  font-size: 16px;
  font-weight: 700;
}

.getOrder__header__comp {
  display: flex;
  margin: 0 20px 0 0;
}

.getOrder__header__comp .custom-select {
  box-shadow: 0 0 2px #c1c1c1;
  color: #404040 !important;
  /* border: 1px solid #404040; */
}

.header__comp__input {
  height: 40px;
  width: 240px;
  box-shadow: 0 0 2px #c1c1c1;
  border-radius: 3px;
}

.header__comp__input input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
  font-weight: bold;
  color: #404040;
}

.header__comp__input input::-webkit-input-placeholder {
  color: #404040;
  /* font-weight: 500; */
}

.header__comp__input input:-ms-input-placeholder {
  color: #404040;
  /* font-weight: 500; */
}

.header__comp__input input::placeholder {
  color: #404040;
  /* font-weight: 500; */
}

.getOrder__header__heading {
  font-weight: normal;
  /* width: 100px; */
  align-self: center;
}

.getOrder__header__comp .custom-select .custom-select-option {
  display: block;
}

.getOrder__header__comp .custom-select .custom-select-container span {
  padding: 6px 12px !important;
}
.getOrder__header__comp
  .custom-select
  .custom-select-container
  span
  .custom-select-additional-option {
  padding: 0px !important;
  font-size: 10px !important;
  /* color: grey!important; */
  text-align: left !important;
}

.getOrder__header__val {
  /* font-size: 12px; */
  font-weight: 600;
}
.getOrder__add__import {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px 0;
}

.order__browse {
  display: flex;
  align-items: center;
  margin-left: 15px;
  /* width: 40%; */
}

.order__search {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.getOrder__import {
  display: flex;
  align-items: center;
  position: relative;
}
.getOrder__import input[type="file"] {
  left: 0;
  top: 0;
  opacity: 0;
  position: absolute;
  /* font-size: 13px */
}
.getOrder__import .btn-upload {
  background-color: #470985;
  color: white;
  padding: 5px 20px;
  border-radius: 3px;
  /* font-size: 13px; */
  font-weight: bold;
}
.getOrder__add__import label {
  /* font-size: 14px; */
  font-weight: 600;
  color: #470985;
  /* width: 100px; */
}

.getOrder__add {
  width: 40%;
}

.getOrder__add__import .getOrder__add {
  margin-left: 10px;
  height: 35px;
  width: 400px;
  box-shadow: var(--shadow-code-secondary);
}

.getOrder__add__import .getOrder__add .stock__search {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  width: 100%;
  border: none !important;
  box-shadow: 0 0 2px #e8e8e8;
  position: relative;
  border-radius: 3px;
}

.getOrder__add__import .getOrder__add .stock__search input {
  border: none;
  outline: none;
  width: 80%;
  /* font-size: 14px; */
  font-weight: 700;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--font-primary);
}
.getOrder__add__import .getOrder__add .stock__search .stock__search__icon {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.getOrder__add__import .getOrder__add .stock__search .stock__search__icon img {
  width: 16px;
}

.getOrder__add__import .getOrder__add .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: 0%;
  margin-top: 3px;
  z-index: 1000;
  border-radius: 3px;
  max-height: 250px;
  overflow-y: scroll;
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
  /* display: none; */
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  .search__loader {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  .search__loader
  p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  display: flex;
  color: var(--font-primary);
}

.getOrder__add__import .getOrder__add .stock__search .stock__suggestions p {
  padding: 8px 20px 8px 20px;
  cursor: pointer;
  /* font-size: 14px; */
  font-weight: 600;
  display: flex;
  align-items: center;
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p.active {
  background-color: #dbd9d9;
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p
  span.search__highlight {
  background-color: #e2c9fc;
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(1) {
  flex: 3 1;
  /* font-size: 12px; */
  font-weight: 700;
  color: var(--font-primary);
}
.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(2) {
  flex: 7 1;
  font-size: 9px;
  font-weight: 600;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
}
.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(3) {
  flex: 1 1;
  /* font-size: 12px; */
  font-weight: 700;
  text-align: right;
  color: var(--font-primary);
}

.getOrder__add__import
  .getOrder__add
  .stock__search
  .stock__suggestions
  p:hover {
  background-color: #f7f7f7;
}

.getOrder__download__format {
  background-color: var(--main-brand);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: bold;
  display: flex-end;
  cursor: pointer;
  margin-left: 20px;
}
.getOrder__download__format a {
  color: white;
  font-weight: bold;
}

.getOrder__container .getOrder__sections {
  display: flex;
  align-items: center;
}

.getOrder__container .getOrder__sections .order__section {
  margin: 0 20px 0 0;
  width: 150px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: var(--font-primary);
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.getOrder__container .getOrder__sections .order__section.active {
  background-color: #606060;
  color: var(--white);
}

.getOrder__sections .order__section a {
  color: var(--font-primary);
  text-decoration: none;
}
.getOrder__container .getOrder__table {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 350px;
  padding: 0px 5px 3px;
  overflow-y: scroll;
  box-shadow: 0 0 2px #f1f1f1;
  border-radius: 3px;
}

.getOrder__container .getOrder__table__empty {
  width: 100%;
  height: 400px;
  box-shadow: 0px 0px 2px #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.getOrder__container .getOrder__table__empty > img {
  width: 100px;
  margin-bottom: 30px;
}

.getOrder__container .getOrder__table__empty > p {
  font-size: 14px;
  font-weight: 500;
  color: #a0a0a0;
}

.getOrder__container .getOrder__table__empty > p > span {
  color: #470985;
  font-weight: 700;
  cursor: pointer;
}

.getOrder__container .getOrder__table thead {
  background-color: #fefefe;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

.getOrder__container .getOrder__table thead tr th:nth-child(5),
.getOrder__container .getOrder__table thead tr th:nth-child(6),
.getOrder__container .getOrder__table thead tr th:nth-child(7),
.getOrder__container .getOrder__table thead tr th:nth-child(10),
.getOrder__container .getOrder__table thead tr th:nth-child(11) {
  min-width: 80px;
}

.getOrder__container .getOrder__table table thead tr th {
  width: 1px;
  white-space: nowrap;
  text-align: left;
  padding: 10px 5px;
  /* border-bottom: solid 1px grey; */
  font-size: 11px;
}
/* .getOrder__container .getOrder__table table thead tr th:nth-child(9){
  width: 100px;
} */

.getOrder__container .getOrder__table table tbody tr td {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  width: 1px;
  white-space: nowrap;
  padding: 10px 5px;
  color: var(--font-primary);
}

.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td:nth-child(9)
  .custom-select
  .custom-select-container {
  /* height: 100px; */
  overflow-y: scroll;
}
.getOrder__table table {
  width: 100%;
}

.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td
  .custom-select
  .custom-select-header {
  padding: 0 3px;
}

.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td
  .custom-select
  .custom-select-header
  .custom-select-title {
  font-size: 10px !important;
}
.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td
  .custom-select
  .custom-select-header
  span {
  font-size: 10px !important;
}
.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td
  .custom-select
  .custom-select-header
  span
  img {
  height: 10px;
  width: 10px;
}

.getOrder__container
  .getOrder__table
  table
  tbody
  tr
  td
  .custom-select
  .custom-select-container
  span {
  font-size: 10px !important;
  padding: 2px 5px;
}
.getOrder__container .getOrder__table table tbody tr td input {
  width: 60px;
  padding: 3px 5px;
  border-radius: 2px;
  border: none;
  outline: none;
  border: 1px solid #e8e8e8;
  background-color: transparent;
  color: var(--font-primary);
}

.getOrder__container .getOrder__table table tbody tr td input:focus {
  border: 1px solid #e8e8e8;
}

.getOrder__container .getOrder__footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.getOrder__container .getOrder__footer > div {
  width: 100%;
}

.getOrder__container .getOrder__footer > div.getOrder__message {
  display: flex;
  flex-direction: column;
}

.getOrder__container .getOrder__footer > div.getOrder__message > p#error {
  color: var(--main-red);
  font-weight: 700;
}

.getOrder__table .custom-select {
  box-shadow: none;
  border: 1px solid #e8e8e8;
}

.order__button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.order__button button {
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #e8e8e8;
  color: #404040;
  transition: 0.3s;
  background-color: transparent;
}

.order__button button:hover {
  opacity: 0.9;
}

#success {
  color: #2fd6aa;
  font-weight: 700;
  font-size: 14px;
}

/* orderbook styling */
.orderbook__container {
  /* border: 1px solid #e8e8e8; */
  border-radius: 3px;
  padding: 0 5px;
  height: 300px;
  box-shadow: 0px 0px 2px #f1f1f1;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: scroll;
}
.orderbook__container.empty,
.orderbook__container.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.orderbook__container.empty {
  flex-direction: column;
}
.orderbook__container.empty > img {
  width: 100px;
}
.orderbook__container.empty > p {
  font-size: 14px;
  font-weight: 600;
}
.orderbook__container table {
  width: 100%;
}
.orderbook__container table thead {
  width: 100%;
  color: var(--font-primary);
  background-color: var(--white);
  border-bottom: 1px solid #e8e8e8;
}

.orderbook__container table thead tr th {
  padding: 10px 0 10px 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 11px;
  color: var(--font-primary);
  text-align: left;
  background-color: var(--white);
}

/* .orderbook__container table thead tr th:nth-child(8)
{
  text-align: center;
} */

.orderbook__container table tbody {
  width: 100%;
}

.orderbook__container table tbody tr {
  cursor: pointer;
  transition: 0.3s;
}

.orderbook__container table tbody tr:hover {
  background-color: #fcfcfc;
}

.orderbook__container table tbody tr td {
  padding: 12px 0 12px 0px;
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 500;
}

.orderbook__container table tbody tr td:nth-child(1) {
  width: 150px;
}

.orderbook__container table tbody tr td .stock-info {
  display: flex;
  flex-direction: column;
}

.orderbook__container table tbody tr td .stock-info .stock-name {
  font-weight: 700;
}

.orderbook__container table tbody tr td .stock-info .stock-fullname {
  font-size: 10px;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.orderbook__container table tbody tr td:nth-child(10),
.orderbook__container table tbody tr td:nth-child(11) {
  text-align: center;
}

.orderbook__container table tbody tr td .modify-order,
.orderbook__container table tbody tr td .cancel-order {
  color: var(--white);
  border-radius: 3px;
  transition: 0.3s;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
}

.orderbook__container table tbody tr td .modify-order > img,
.orderbook__container table tbody tr td .cancel-order img {
  width: 18px;
}

.orderbook__container table tbody tr td .modify-order:hover,
.orderbook__container table tbody tr td .cancel-order:hover {
  border: 2px solid #404040;
}

.orderbook__container table tbody tr td .buy {
  color: var(--main-green);
  font-weight: 700;
}

.orderbook__container table tbody tr td .sell {
  color: var(--main-red);
  font-weight: 700;
}

.orderbook__container table tbody tr td .complete,
.orderbook__container table tbody tr td .cancelled,
.orderbook__container table tbody tr td .pending,
.orderbook__container table tbody tr td .rejected {
  border-radius: 2px;
  padding: 4px 0;
  font-weight: 700;
  display: flex;
  /* justify-content: center; */
}

.orderbook__container table tbody tr td .complete {
  color: var(--main-green);
}

.orderbook__container table tbody tr td .cancelled {
  color: var(--main-red);
}

.orderbook__container table tbody tr td .pending {
  color: var(--main-blue);
}

.orderbook__container table tbody tr td .rejected {
  color: #e67e22;
}

.view__split {
  color: var(--white);
  cursor: pointer;
  background-color: #606060;
  padding: 3px 5px;
  border-radius: 2px;
}

/* share split popup style */
.share__split__overlay {
  position: fixed;
  inset: 0;
  background: #87888850;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
}

.share__split__popup {
  z-index: 1000000;
  width: 30%;
  height: 60%;
  border-radius: 5px;
  padding: 0 20px;
  transform: translateY(8px);
  box-shadow: 0 0 14px 0 rgb(15 17 17 / 10%);
  /* box-shadow: 1px 3px 5px #e8e8e8; */
  background-color: var(--white);
  animation: getup 0.1s linear forwards;
}

@keyframes getup {
  0% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.share__split__popup .share__popup__header {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #eeeeee;
  position: relative;
}

.share__split__popup .share__popup__header > p {
  font-size: 13px;
  font-weight: 700;
  color: var(--font-primary);
}

.share__split__popup .share__popup__header > div > img {
  width: 30px;
  cursor: pointer;
}

.share__split__popup .share__popup__body {
  width: 100%;
  height: 90%;
}

.share__popup__body .share__lable {
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
  margin: 10px 0 !important;
}

.share__popup__body .share__lable > span {
  font-weight: 700;
}

.share__popup__body .share__table {
  width: 100%;
  border: 1px solid #e8e8e8;
  max-height: 70%;
  border-radius: 3px;
  margin-top: 20px;
  overflow: scroll;
}

.share__popup__body .share__table table {
  width: 100%;
  table-layout: fixed;
}

.share__table table thead tr {
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  position: -webkit-sticky;
  position: sticky;
}

.share__table table thead tr th {
  padding: 6px 0;
  text-align: center;
  color: var(--font-primary);
}

.share__table table tbody tr td {
  padding: 8px 0;
  text-align: center;
  color: var(--font-primary);
}

/* dealer console style */

.backoffice-wrapper .dealer-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px 50px;
}

.dealer-wrapper .dealer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.dealer-wrapper .dealer-header .dealer-header-brand {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.dealer-header .dealer-header-brand img {
  width: 36px;
}

.dealer-header .dealer-header-brand p {
  font-size: 16px;
  font-weight: 600;
}

.dealer-wrapper .dealer-content {
  width: 100%;
  height: calc(100% - 60px);
  padding: 0 0 0 0px;
}

.dealer-content .dealer-menu {
  width: 100%;
  height: 20px;
}

.dealer-content .dealer-content-wrapper {
  width: 100%;
  height: calc(100% - 30px);
}

.dealer-content-wrapper .dealer-orders {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.dealer-content-wrapper .dealer-orders.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
  padding-top: 100px;
}

.dealer-content-wrapper .dealer-orders.loading > p {
  font-size: 14px;
  font-weight: 600;
}

.dealer-content-wrapper .dealer-orders .dealer-orders-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 60px;
}

.dealer-orders .dealer-orders-header .header-customers {
  width: 350px;
}

.dealer-orders .dealer-orders-header .header-config {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.dealer-orders-header .header-config .portfolio-config {
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.header-config .portfolio-config .portfolio-config-box {
  display: flex;
  flex-direction: column;
}

.header-config .portfolio-config .portfolio-config-box > span {
  font-size: 12px;
  font-weight: 500;
}

.header-config .portfolio-config .portfolio-config-box > p {
  font-size: 15px;
  font-weight: 700;
}

.dealer-content-wrapper .dealer-orders .dealer-orders-table-wrapper {
  width: 100%;
  height: calc(100% - 80px);
}

.dealer-content-wrapper .dealer-orders .dealer-orders-table-wrapper.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dealer-orders .dealer-orders-table-wrapper .dealer-orders-table {
  width: 100%;
  height: calc(100% - 70px);
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  overflow-y: scroll;
}

.dealer-orders-table-wrapper .dealer-orders-table .orders-table-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.dealer-orders-table-wrapper .dealer-orders-table .orders-table-empty > p {
  font-size: 15px;
  font-weight: 500;
}

.dealer-orders-table table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.dealer-orders-table table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.dealer-orders-table thead tr td {
  text-align: left;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.dealer-orders-table table tbody tr {
  cursor: default;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid #f5f5f5;
}

.dealer-orders-table table thead tr td.name {
  width: 180px;
}

.dealer-orders-table table thead tr td.price,
.dealer-orders-table table thead tr td.bid,
.dealer-orders-table table thead tr td.ask,
.dealer-orders-table table thead tr td.ltp,
.dealer-orders-table table thead tr td.holdings,
.dealer-orders-table table thead tr td.trade {
  width: 120px;
}

.dealer-orders-table table thead tr td.remove {
  width: 40px;
}

.dealer-orders-table table tbody tr td {
  vertical-align: center;
  padding: 15px 10px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.dealer-orders-table table tbody tr td .quantity-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 2px;
  grid-column-gap: 5px;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.dealer-orders-table table tbody tr td .quantity-box span {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
  background-color: #f5f5f5;
  font-size: 10px;
}

.dealer-orders-table table tbody tr td input {
  width: 90%;
  background-color: transparent;
  color: var(--font-primary);
  border: none;
  outline: none;
  border-radius: 3px;
  padding: 0px 0px 0px;
  font-size: 13px;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
}

.dealer-orders-table table tbody tr td .ordertype-box {
  display: flex;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
  align-items: center;
  cursor: pointer;
}

.dealer-orders-table table tbody tr td .remove-symbol {
  cursor: pointer;
}

.dealer-orders-table-wrapper .dealer-orders-footer {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dealer-orders-footer .trade-value {
  display: flex;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.dealer-orders-footer .trade-value > p {
  font-size: 12px;
  font-weight: 500;
}

.dealer-orders-footer .trade-value > p > span {
  font-weight: 600;
}

.dealer-content-wrapper .orders-confirmation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.orders-confirmation .orders-confirmation-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
}

.orders-confirmation .orders-confirmation-header .status-menu {
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.orders-confirmation .orders-confirmation-header .status-menu p {
  font-size: 12px;
  cursor: pointer;
}

.orders-confirmation .orders-confirmation-header .status-menu p.active {
  font-weight: 700;
}

.orders-confirmation.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.orders-confirmation.loader > p {
  font-size: 14px;
  font-weight: 600;
}

.orders-confirmation .orders-confirmation-table {
  width: 100%;
  height: calc(100% - 50px);
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  overflow-y: scroll;
}

.orders-confirmation .orders-confirmation-table .empty-table-data{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.orders-confirmation .orders-confirmation-table table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.orders-confirmation .orders-confirmation-table table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.orders-confirmation .orders-confirmation-table tr {
  border-bottom: 1px solid #f8f8f8;
}

.orders-confirmation .orders-confirmation-table thead tr td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orders-confirmation .orders-confirmation-table table thead tr td:nth-child(1) {
  max-width: 140px;
}

.orders-confirmation .orders-confirmation-table table tbody {
  width: 100%;
}

.orders-confirmation .orders-confirmation-table tbody tr td {
  padding: 8px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orders-confirmation .orders-confirmation-table table tbody tr td .stock-info {
  display: flex;
  flex-direction: column;
}

.orders-confirmation .orders-confirmation-table table tbody tr td .stock-info .stock-name {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
}

.orders-confirmation .orders-confirmation-table table tbody tr td .stock-info .stock-fullname {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.2px;
}
