.smallcase__strategy__details {
  position: relative;
}

.smallcase__container {
  width: 100%;
  height: 100%;
}

.smallcase__container.loader{
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallcase__container .smallcase__header {
  width: 100%;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
}

.smallcase__header .smallcase__title > img {
  width: 20px;
}

.smallcase__header .smallcase__title > span {
  margin-left: 0px;
  font-size: 16px;
  color: var(--font-primary);
  font-weight: 700;
}

.smallcase__header .smallcase__subtext {
  font-size: 20px;
  font-weight: 300;
}

.smallcase__header .smallcase__menu {
  display: flex;
}

.smallcase__header .smallcase__menu .smallcase__stratergy__button {
  background-color: var(--white);
  padding: 7px 15px 7px 15px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #e8e8e8;
  color: var(--font-primary);
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
  margin-left: 10px;
}

.smallcase__header .smallcase__menu .smallcase__stratergy__button:hover {
  opacity: 0.9;
}

.smallcase__content__wrapper {
  display: flex;
  column-gap: 20px;
  height: 100%;
}

.smallcase__left {
  width: 340px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.smallcase__strategies__content {
  width: 100%;
  height: 100%;
  padding: 10px 0px;
}

.smallcase__personal__strategies {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.smallcase__public__strategies {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.personal__strategies__header,
.smallcase__public__strategies .public__strategies__header {
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 20px;
}

.personal__strategies__header {
  margin-top: 0px;
  justify-content: space-between;
  padding: 20px 15px 15px;
}

.personal__strategies__header>p{
  font-size: 14px;
  font-weight: 600;
}

.personal__strategies__header > div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.personal__strategies__header .strategy__menu {
  display: flex;
  column-gap: 10px;
}

.personal__strategies__header .strategy__menu > p,
.public__strategies__header .strategy__menu > p {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.personal__strategies__header .strategy__menu > p.active,
.public__strategies__header .strategy__menu > p.active {
  font-weight: 700;
}

.smallcase__personal__strategies .personal__strategies__content,
.smallcase__public__strategies .public__strategies__content {
  width: 100%;
  /* height: calc(100% - 25px); */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.smallcase__personal__strategies .personal__strategies__content {
  height: 100%;
}

.smallcase__left .smallcase__strategy__wrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-radius: 5px;
  padding: 0px 10px 15px 10px;
  display: flex;
  flex-direction: column;
}

.smallcase__left .smallcase__public__strategies .smallcase__strategy__wrapper {
  height: 85%;
}

.smallcase__search__wrapper {
  padding: 0px 10px;
  width: 100%;
}

.smallcase__search__wrapper .smallcase__search__box {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 3px;
  border: 1px solid #f5f5f5;
}

.smallcase__search__wrapper .smallcase__search__box > img {
  width: 16px;
}

.smallcase__search__wrapper .smallcase__search__box > input {
  width: 85%;
  height: 100%;
  border: none;
  outline: none;
  margin-left: 0px;
  font-size: 12px;
  font-weight: 600;
  padding-left: 0px;
  background-color: transparent;
  color: var(--font-primary);
}

.smallcase__search__wrapper .smallcase__search__box > input::placeholder {
  color: #808080;
  font-weight: 500;
}

.smallcase__right {
  width: 960px;
  padding: 0px 0px;
  height: 100%;
  overflow-y: scroll;
}

.smallcase__right .smallcase__strategy__wrapper,
.smallcase__right .smallcase__strategy__details {
  border-radius: 5px;
  overflow-y: scroll;
  height: 100%;
}

.smallcase__right .smallcase__strategy__wrapper {
  width: 30%;
}

.smallcase__right .smallcase__strategy__wrapper::-webkit-scrollbar {
  display: none;
}

.smallcase__right .smallcase__strategy__details {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 15px 15px;
}

.smallcase__strategy__details .strategy__section {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.smallcase__right .smallcase__strategy__details.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  row-gap: 5px;
  padding-top: 100px;
}

.smallcase__right .smallcase__strategy__details.loader > p {
  font-size: 14px;
  font-weight: 600;
}

.smallcase__right .smallcase__strategy__details.loader > h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--font-primary);
}

.smallcase__right .smallcase__strategy__details.loader > span {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.smallcase__right .smallcase__strategy__details.loader .create__smallcase {
  padding: 8px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  color: var(--font-primary);
  margin-top: 15px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.smallcase__strategy__wrapper .smallcase__strategy__card {
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
}

.smallcase__strategy__card .strategy__info,
.smallcase__strategy__card .strategy__data,
.smallcase__strategy__card .strategy__options {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px;
}

.smallcase__strategy__card .strategy__data {
  flex-direction: column;
  align-items: flex-start;
}

.strategy__icon__box {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.strategy__icon__box img {
  width: 20px;
}

.strategy__icon__box span {
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
}

.strategy__info .strategy__headings__box {
  width: 100%;
  padding: 10px 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.strategy__info .strategy__headings__box:hover {
  background-color: #fafafa;
}

.strategy__headings__box .strategy__heading {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
  cursor: pointer;
}

.strategy__headings__box .strategy__news {
  font-size: 11px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.strategy__data .strategy__data__table {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: 0 0 2px #e8e8e8; */
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 5px 10px;
}

.strategy__data__table > div .title {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
}

.strategy__data__table > div .value {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin-top: 5px !important;
  color: var(--font-primary);
}

.smallcase__strategy__card .strategy__options {
  justify-content: space-between;
  margin-top: 20px;
}

.smallcase__strategy__card .strategy__options > p {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.smallcase__strategy__card .strategy__options > p:hover {
  color: var(--font-primary);
}

.smallcase__strategy__card .strategy__options .strategy__buy__button {
  background-color: var(--white);
  padding: 6px 18px 6px 18px;
  font-size: 12px;
  font-weight: 500;
  color: var(--font-primary);
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  transition: 0.3s;
  cursor: pointer;
}

.smallcase__strategy__card .strategy__options .strategy__buy__button:hover {
  background-color: #f8f8f8;
}

.smallcase__strategy__details .startegy__details__close {
  display: none;
}

.smallcase__strategy__details .strategy__details__box {
  width: 100%;
}

.smallcase__strategy__details .strategy__name__wrapper {
  display: flex;
  justify-content: space-between;
}

.smallcase__strategy__details .strategy__configure {
  /* border: solid 1px #e8e8e8; */
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  color: var(--font-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.strategy__details__box .details__header {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.strategy__details__box .details__header > img {
  width: 20px;
}

.strategy__details__box .details__header > span {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.strategy__details__box .details__header .full__portfolio {
  font-size: 14px;
  font-style: italic;
  cursor: pointer;
}

.strategy__details__box .details__content > p {
  font-size: 14px;
  font-weight: 500;
  color: var(--font-primary);
}

.strategy__details__box .details__content.strategy__name > p {
  color: var(--font-primary);
}

.strategy__details__box .details__content.details__chart {
  margin-top: 10px;
}

.strategy__details__box .strategy__stocks {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.strategy__details__box .stocks__table {
  width: 100%;
  border-radius: 15px;
  margin-top: 10px;
  border: 1px solid #f5f5f5;
}

.strategy__details__box .stocks__table table {
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  border-collapse: collapse;
}

.strategy__details__box .stocks__table table tr td {
  font-size: 11px;
  font-weight: 500;
  padding: 8px 12px;
}

.strategy__details__box .stocks__table table thead tr td {
  font-weight: 700;
}

.strategy__details__box .stocks__table table thead tr {
  border-bottom: 1px solid #f5f5f5;
}

.strategy__details__box .stocks__table table tr td:nth-child(2) {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.strategy__details__box .stocks__table table tr td:nth-child(5) {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.strategy__details__box .stocks__table table tbody tr td:nth-child(5) {
  text-transform: uppercase;
}

.strategy__details__box .strategy__stocks .stock__logo__name {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: 10px 0px 0px 0px;
}

.strategy__stocks .stock__logo__name .logo__image {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f5f5f5;
  border-radius: 50%;
  overflow: hidden;
}

.strategy__stocks .stock__logo__name .logo__image img {
  width: 100%;
  object-fit: contain;
}

.strategy__stocks .stock__logo__name .logo__image span {
  font-size: 16px;
  font-weight: 700;
  font-family: "Satisfy", cursive;
}

.strategy__stocks .stock__logo__name span {
  font-size: 11px;
  color: var(--font-primary);
  border-radius: 3px;
  font-weight: 500;
}

.smallcase__strategy__details .strategy__charts {
  width: 100%;
  display: flex;
  column-gap: 40px;
}

.strategy__charts .strategy__details__box {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 0 20px;
  border-radius: 15px;
  width: 580px;
}

.strategy__charts .strategy__details__box .details__header {
  text-align: center;
}

.strategy__details__box .strategy__sector__chart {
  width: 100%;
  height: 300px;
  border-radius: 5px;
}

.details__content.details__chart .strategy__data__table {
  display: flex;
  justify-content: space-around;
  box-shadow: 0px 1px 3px #e8e8e8, 0px -1px 1px #f7f7f7;
  border-radius: 3px;
  padding: 5px 0px;
}

.strategy__chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #f5f5f5;
  padding: 10px 15px;
  margin-top: 20px;
  border-radius: 5px;
  height: 350px;
}

.smallcase__strategy__container {
  width: 100%;
  height: 100%;
  font-size: 12px;
}

.smallcase__strategy__header {
  width: 100%;
  padding-bottom: 0px;
}

.smallcase__strategy__header > span {
  font-size: 13px;
  font-weight: 800;
}

.smallcase__strategy__header .strategy__name__icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  column-gap: 20px;
}

.smallcase__strategy__header .strategy__options {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 25px;
}

.smallcase__strategy__header .strategy__options > span {
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s;
  color: var(--font-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.smallcase__strategy__header .strategy__options > span:hover {
  color: var(--font-primary);
}

.smallcase__strategy__header .strategy__name {
  font-size: 14px;
  font-weight: 700;
}

.smallcase__strategy__header .strategy__info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.strategy__info .strategy__text {
  width: 95%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.strategy__info .strategy__text p {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.strategy__info .strategy__text p > span {
  font-size: 13px;
  font-weight: 600;
}

.strategy__info .strategy__numbers {
  width: 260px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.strategy__info .strategy__numbers > div {
  display: flex;
  flex-direction: row;
}

.strategy__info .strategy__numbers > div .numbers__box:nth-child(1) {
  flex: 4;
}

.strategy__info .strategy__numbers > div .numbers__box:nth-child(2) {
  flex: 2;
}

.strategy__numbers > div .numbers__box span {
  font-size: 12px;
  font-weight: 500;
}

.strategy__numbers > div .numbers__box p {
  font-size: 16px;
  font-weight: 800;
  margin-top: -3px;
}

.smallcase__strategy__body__wrapper {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.smallcase__strategy__body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  margin-top: 5px;
  column-gap: 10px;
}

.smallcase__strategy__body.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.smallcase__strategy__body.loader .loading__text {
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary) !important;
}

.smallcase__strategy__body .strategy__title {
  font-size: 12px;
  font-weight: 700;
}

.smallcase__strategy__body .smallcase__strategy__form {
  width: 30%;
  height: 90%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 15px;
}

.smallcase__strategy__body .smallcase__strategy__right {
  width: 70%;
  height: 100%;
}

.smallcase__strategy__body .smallcase__strategy__table {
  width: 100%;
  height: 90%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

.smallcase__strategy__body .smallcase__strategy__form .form__row {
  width: 100%;
  display: flex;
  column-gap: 15px;
}

.smallcase__strategy__form .strategy__select__container {
  padding: 0 0px;
}

.strategy__select__container .strategy__select__header {
  display: flex;
  align-items: center;
}

.strategy__select__container .strategy__select__header > img {
  width: 20px;
}

.strategy__select__container .strategy__select__header > span {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 500;
  text-transform: capitalize;
}

.strategy__select__container .strategy__select__box {
  margin-top: 5px;
}

.strategy__select__container .strategy__text__box,
.strategy__select__container .strategy__input__box {
  width: 450px;
  margin-top: 5px;
  border-radius: 3px;
  border: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.strategy__select__container .strategy__text__box textarea {
  width: 100%;
  border: none;
  outline: none;
  min-height: 100px;
  resize: none;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  padding-top: 5px;
  background-color: transparent;
  padding-left: 10px;
}

.strategy__select__container .strategy__input__box input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  min-height: 40px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
  background-color: transparent;
}

.strategy__select__container .strategy__text__box textarea::placeholder,
.strategy__select__container .strategy__input__box input::placeholder {
  font-weight: 500;
  color: #909090;
}

.strategy__select__container .errSpan {
  color: var(--font-primary);
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  display: block;
}

.strategy__select__container .errSpan.hide {
  opacity: 0;
}

.smallcase__strategy__container .smallcase__strategy__footer {
  margin-top: 5px;
  padding: 5px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
  width: 100%;
}

.smallcase__strategy__footer .strategy__footer__button {
  background-color: var(--white);
  border: 1px solid #f5f5f5;
  padding: 5px 25px 5px 25px;
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
}

.smallcase__strategy__footer .strategy__footer__button:hover {
  opacity: 0.9;
}

.strategy__select__box .custom-select {
  width: 300px !important;
  box-shadow: none;
  border-radius: 3px;
}

.strategy__select__box .custom-select .custom-select-container {
  min-width: 100% !important;
}

.strategy__portfolio__table__wrapper {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.strategy__portfolio__table__wrapper .strategy__portfolio__table {
  width: 100%;
  height: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  overflow-y: scroll;
}

.strategy__portfolio__table table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.strategy__portfolio__table table thead {
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.strategy__portfolio__table table thead td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
}

.strategy__portfolio__table table tbody td {
  text-align: left;
  padding: 8px 10px 8px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.strategy__portfolio__table table thead td.symbol {
  width: 160px;
}

/* .strategy__portfolio__table table thead td.exchange{
    width: 140px;
} */

.strategy__portfolio__table table thead td.remove {
  width: 80px;
  text-align: right;
}

.strategy__portfolio__table table tbody td .input-box {
  width: 140px;
  height: 35px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0 5px;
}

.strategy__portfolio__table table tbody td .input-box input {
  width: 100px;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 6px 0 6px 4px;
  cursor: text;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s;
  color: var(--font-primary);
  border: 1px solid #f5f5f5;
  text-align: center;
  border-radius: 5px;
}

/* .strategy__portfolio__table table tbody td .input-box .input-arrows{
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0px;
} */

.strategy__portfolio__table table tbody td .input-box span {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f5f5f5;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
}

.strategy__portfolio__table table tbody td input:focus {
  border: 1px solid #d8d8d8;
}

.strategy__portfolio__table table td:first-child {
  font-weight: 600;
}

.strategy__portfolio__table table td:last-child {
  text-align: center;
}

.strategy__portfolio__table table td span.remove {
  cursor: pointer;
}

.strategy__portfolio__table table td span.remove img {
  width: 12px;
}

.strategy__portfolio__table .strategy__portfolio__table__header,
.strategy__portfolio__table .strategy__portfolio__table__body,
.strategy__portfolio__table .strategy__portfolio__table__footer {
  width: 100%;
}

.strategy__portfolio__table .strategy__portfolio__table__header {
  height: 10%;
}

.strategy__portfolio__table .strategy__portfolio__table__footer {
  height: 10%;
}

.strategy__portfolio__table .strategy__portfolio__table__body {
  height: 90%;
  overflow-y: scroll;
}

.strategy__portfolio__table__header .table__row,
.strategy__portfolio__table__body .table__row,
.strategy__portfolio__table__footer .table__row {
  width: 100%;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}

.strategy__portfolio__table__header .edit__table__row,
.strategy__portfolio__table__body .edit__table__row,
.strategy__portfolio__table__footer .edit__table__row {
  width: 100%;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.strategy__portfolio__table__header .table__row,
.strategy__portfolio__table__header .edit__table__row {
  border-bottom: 1px solid #f5f5f5;
  font-size: 12px;
}

.strategy__portfolio__table__footer .table__row,
.strategy__portfolio__table__footer .edit__table__row {
  font-size: 12px;
  border-top: 1px solid #f5f5f5;
}

.strategy__portfolio__table__header .table__row > div,
.strategy__portfolio__table__footer .table__row > div,
.strategy__portfolio__table__header .edit__table__row > div,
.strategy__portfolio__table__footer .edit__table__row > div {
  font-weight: 500;
}

.strategy__portfolio__table__body .table__row:hover,
.strategy__portfolio__table__body .edit__table__row:hover {
  background-color: #fcfcfc;
  border-radius: 3px;
}

.strategy__portfolio__table__header .table__row > div:nth-child(1),
.strategy__portfolio__table__body .table__row > div:nth-child(1),
.strategy__portfolio__table__footer .table__row > div:nth-child(1) {
  flex: 4;
  font-size: 11px;
  font-weight: 700;
}

.strategy__portfolio__table__header .edit__table__row > div:nth-child(1),
.strategy__portfolio__table__body .edit__table__row > div:nth-child(1),
.strategy__portfolio__table__footer .edit__table__row > div:nth-child(1) {
  flex: 4;
  padding: 5px 0;
  color: var(--font-primary);
  font-size: 12px;
  font-weight: 500;
}

.strategy__portfolio__table__header .table__row > div:nth-child(2),
.strategy__portfolio__table__body .table__row > div:nth-child(2),
.strategy__portfolio__table__footer .table__row > div:nth-child(2) {
  flex: 4;
  font-size: 11px;
  font-weight: 700;
}

.strategy__portfolio__table__header .edit__table__row > div:nth-child(2),
.strategy__portfolio__table__body .edit__table__row > div:nth-child(2),
.strategy__portfolio__table__footer .edit__table__row > div:nth-child(2) {
  flex: 4;
  /* background-color: #d4e300; */
}

.strategy__portfolio__table__header .table__row > div:nth-child(3),
.strategy__portfolio__table__body .table__row > div:nth-child(3),
.strategy__portfolio__table__footer .table__row > div:nth-child(3) {
  flex: 5;
  font-size: 11px;
  font-weight: 700;
  /* display: flex; */
  /* justify-content: center; */
}

.strategy__portfolio__table__header .edit__table__row > div:nth-child(3),
.strategy__portfolio__table__body .edit__table__row > div:nth-child(3),
.strategy__portfolio__table__footer .edit__table__row > div:nth-child(3) {
  flex: 2;
}

.strategy__portfolio__table__header .table__row > div:nth-child(4),
.strategy__portfolio__table__body .table__row > div:nth-child(4),
.strategy__portfolio__table__footer .table__row > div:nth-child(4) {
  flex: 1;
  display: flex;
  justify-content: center;
}

.strategy__portfolio__table__header .edit__table__row > div:nth-child(4),
.strategy__portfolio__table__body .edit__table__row > div:nth-child(4),
.strategy__portfolio__table__footer .edit__table__row > div:nth-child(4) {
  flex: 4;
  /* display: flex; */
  /* justify-content: center; */
}

.strategy__portfolio__table__header .edit__table__row > div:nth-child(5),
.strategy__portfolio__table__body .edit__table__row > div:nth-child(5),
.strategy__portfolio__table__footer .edit__table__row > div:nth-child(5) {
  flex: 1;
  display: flex;
  justify-content: center;
}

.strategy__portfolio__table__body .table__row > div > input,
.strategy__portfolio__table__body .edit__table__row > div > input {
  width: 50%;
  height: 100%;
  border: none;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  outline: none;
  background-color: transparent;
  padding: 6px 0 6px 10px;
  cursor: text;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s;
  color: var(--font-primary);
}

.strategy__portfolio__table__body .table__row > div .stock__remove,
.strategy__portfolio__table__body .edit__table__row > div .stock__remove {
  padding: 5px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.strategy__portfolio__table__body .table__row > div .stock__remove:hover,
.strategy__portfolio__table__body .edit__table__row > div .stock__remove:hover {
  background-color: #bce8ff;
}

.strategy__portfolio__table__body .table__row > div .stock__remove img {
  width: 10px;
  height: 10px;
}

.strategy__portfolio__table__body .table__row > div > input:focus,
.strategy__portfolio__table__body .edit__table__row > div > input:focus {
  border: 1px solid #d8d8d8;
}

.strategy__portfolio__table .strategy__table__stock {
  display: flex;
  flex-direction: column;
}

.strategy__table__stock .strategy__table__stock__code {
  font-size: 11px;
  color: var(--font-primary);
  font-weight: 600;
}

.strategy__table__stock .strategy__table__stock__name {
  max-width: 200px;
  color: var(--font-primary);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.strategy__portfolio__table__wrapper .strategy__performance__buttons {
  display: flex;
  width: 100%;
}

.strategy__portfolio__table__wrapper .strategy__performance__buttons > div {
  padding: 7px 15px 7px 15px;
  font-size: 12px;
  margin: 0 10px 0 0;
  font-weight: 700;
  color: var(--font-primary);
  background-color: var(--white);
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
}

.strategy__portfolio__table__wrapper
  .strategy__performance__buttons
  > div:hover {
  opacity: 0.9;
}

.strategy__portfolio__table__wrapper
  .strategy__performance__buttons
  > div
  > img {
  width: 10px;
  margin-left: 4px;
}

.strategy__portfolio__stock__wrapper {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.strategy__portfolio__stock__wrapper .strategy__portfolio__stock__options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.strategy__portfolio__stock__wrapper .stock__search__icon img {
  width: 14px;
}

.strategy__portfolio__stock__wrapper > img {
  width: 60%;
}

.strategy__portfolio__stock__wrapper .stock__search {
  width: 500px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  position: relative;
}

.strategy__portfolio__stock__wrapper .stock__search .stock__search__icon {
  width: 20px;
  margin-right: 10px;
}

.strategy__portfolio__stock__wrapper .stock__search input {
  width: 90%;
  height: 100%;
  font-size: 12px;
  text-transform: uppercase;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--font-primary);
  font-weight: 600;
}

.strategy__portfolio__stock__wrapper .stock__search input::placeholder {
  text-transform: capitalize;
}

.strategy__portfolio__stock__wrapper .stock__suggestions {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  background-color: white;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 5px 0px;
  z-index: 1000;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0px;
  row-gap: 0px;
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
}

.strategy__portfolio__stock__wrapper .stock__suggestions .search__loader {
  height: 200px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.strategy__portfolio__stock__wrapper .stock__suggestions .search__loader > p {
  margin-top: 10px !important;
  font-size: 14px;
  font-weight: 500;
}

.strategy__portfolio__stock__wrapper .stock__suggestions > p {
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.strategy__portfolio__stock__wrapper .stock__suggestions > p:hover {
  background-color: #f5f5f5;
}

.strategy__portfolio__stock__wrapper
  .stock__suggestions
  > p
  > span:nth-child(1) {
  flex: 4;
  font-size: 12px;
  font-weight: 500;
}

.strategy__portfolio__stock__wrapper
  .stock__suggestions
  > p
  > span:nth-child(2) {
  flex: 9;
  font-size: 12px;
}

.strategy__portfolio__stock__wrapper
  .stock__suggestions
  > p
  > span:nth-child(3) {
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
}

.strategy__portfolio__stock__wrapper
  .stock__suggestions
  > p
  > span
  .search__highlight {
  font-weight: 500;
}

.strategy__portfolio__stock__options {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.strategy__portfolio__stock__options > span {
  background-color: var(--white);
  color: var(--font-secondary);
  padding: 7px 5px 7px 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
}

.strategy__portfolio__stock__options > span > a {
  color: var(--font-secondary);
  text-decoration: none;
}

.strategy__portfolio__stock__options > span:hover,
.strategy__portfolio__stock__options > span a:hover {
  color: var(--font-primary);
}

.smallcase__success__page {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.smallcase__success__page .success__message {
  font-size: 14px;
  color: var(--font-primary);
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 25px;
}

.smallcase__container__empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  padding-top: 100px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
}

.smallcase__container__empty .create__smallcase__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.smallcase__container__empty .create__smallcase__content > p {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.smallcase__container__empty > div.create__smallcase {
  margin-top: 50px;
}

.smallcase__container__empty > div.create__smallcase button {
  padding: 10px 18px;
  border-radius: 3px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: var(--main-blue);
  color: var(--white);
  font-weight: 500;
  transition: 0.3s;
}

.smallcase__container__empty > div.create__smallcase button:hover {
  opacity: 0.9;
}

.backtest__container {
  height: 90%;
}

.backtest__container .backtest__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backtest__header > span {
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 800;
}

.backtest__header > div {
  background-color: var(--white);
  font-size: 14px;
  font-weight: 500;
  color: var(--font-primary);
  border-radius: 20px;
  /* box-shadow: 0 2px 5px rgb(0 0 0 / 05%); */
  transition: 0.3s;
  cursor: pointer;
}

.backtest__header > div:hover {
  opacity: 0.9;
}

.backtest__header > div > span {
  margin-left: 5px;
}

.backtest__container .backtest__wrapper {
  margin-top: 10px;
  width: 800px;
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border: 1px solid #f5f5f5;
  padding: 10px 15px;
}

.backtest__wrapper .backtest__stocks__wrapper {
  margin-bottom: 20px;
}

.backtest__wrapper .backtest__stocks__wrapper > p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.backtest__wrapper .backtest__stocks {
  width: 100%;
  display: flex;
  column-gap: 12px;
  flex-wrap: wrap;
}

.backtest__wrapper .backtest__stocks .stock__info {
  padding: 6px 12px;
  background-color: #f0f0f0;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  min-width: 60px;
  text-align: center;
}

.backtest__stocks .stock__logo__name {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.backtest__stocks .stock__logo__name .logo__image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backtest__stocks .stock__logo__name .logo__image img {
  width: 100%;
  object-fit: contain;
}

.backtest__stocks .stock__logo__name span {
  font-size: 12px;
  font-weight: 600;
}

.backtest__wrapper .backtest__extra__chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.backtest__extra__chart .strategy__details__box {
  width: 100%;
  background-color: #fafafa;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.backtest__wrapper .backtest__chart,
.backtest__wrapper .backtest__data {
  width: 100%;
}

.backtest__wrapper .backtest__chart {
  border-radius: 5px;
}

.backtest__wrapper .backtest__chart .backtest__chart__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.backtest__wrapper .backtest__chart > p {
  margin-bottom: 10px !important;
  align-self: flex-start;
  font-size: 14px;
  font-weight: 600;
}

.backtest__wrapper .backtest__chart .backtest__chart__wrapper {
  width: 100%;
  height: 400px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 5px 10px;
}

.backtest__wrapper .backtest__data {
  border-radius: 5px;
  margin-top: 20px;
}

.backtest__wrapper .backtest__data .backtest__data__table {
  margin-bottom: 20px;
}

.backtest__data__table .table__wrapper {
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  padding: 5px 5px;
}

.backtest__data__table .data__table__title {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.backtest__data__table table {
  width: 100%;
}

.backtest__data__table tbody tr th {
  font-weight: 500;
  font-size: 12px;
  width: 100px;
  text-align: left;
  color: var(--font-primary);
}

.backtest__data__table thead tr td {
  font-size: 11px;
  padding-bottom: 5px;
  font-weight: 600;
  color: var(--font-primary);
}

.backtest__data__table table thead tr {
  border-bottom: 1px solid #e8e8e8;
}

.backtest__data__table tbody tr td {
  font-weight: 500;
  font-size: 11px;
  padding: 5px 0;
  color: var(--font-primary);
}

.backtest__container .backtest__buttons {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0px;
}

.backtest__buttons .backtest__save {
  background-color: var(--white);
  padding: 7px 15px 7px 15px;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #f5f5f5;
  color: var(--font-primary);
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
  margin: 0 20px 0 0;
}

.backtest__buttons .backtest__create {
  background-color: var(--white);
  padding: 7px 15px 7px 15px;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #f5f5f5;
  color: var(--font-primary);
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
  margin: 0 20px 0 0;
}

.backtest__buttons .backtest__save:hover {
  opacity: 0.9;
}

@media only screen and (max-width: 768px) {
  .smallcase__container {
    width: 100%;
    height: 100%;
  }

  .smallcase__container .smallcase__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .smallcase__header .smallcase__title > img {
    width: 14px;
  }

  .smallcase__header .smallcase__title > span {
    letter-spacing: -0.2px;
  }

  .smallcase__header .smallcase__menu {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
  }

  .smallcase__header .smallcase__menu .smallcase__stratergy__button {
    padding: 0;
    font-size: 12px;
    margin-left: 0;
    margin-right: 10px;
    margin-top: 10px;
    border: none;
  }

  .smallcase__search__wrapper {
    padding: 0 0px;
    width: 100%;
    /* margin-top: 15px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .smallcase__search__wrapper .smallcase__search__box {
    width: 100%;
    height: 45px;
  }

  .smallcase__search__wrapper .smallcase__search__box > img {
    width: 14px;
  }

  .smallcase__search__wrapper .smallcase__search__box > input {
    font-size: 12px;
  }

  .smallcase__container .smallcase__right {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 2px;
    margin-top: 10px;
  }

  .smallcase__right .smallcase__strategy__wrapper,
  .smallcase__right .smallcase__strategy__details {
    width: 100%;
    padding: 10px 10px;
    overflow-y: scroll;
    min-height: 250px;
    margin-bottom: 10px;
    margin-bottom: 10px;
    /* background-color: #ebebeb; */
  }

  .smallcase__right .smallcase__strategy__wrapper {
    height: 100%;
    padding: 0;
    margin-top: 10px;
  }

  .smallcase__strategy__details .strategy__name__wrapper {
    margin-top: 20px;
  }

  .smallcase__right .smallcase__strategy__details {
    /* display: none; */
    position: absolute;
    inset: 0;
    background-color: var(--white);
    border: none;
  }

  .smallcase__right .smallcase__strategy__details.loader {
    display: none;
  }

  .smallcase__strategy__wrapper .smallcase__strategy__card {
    width: 100%;
    padding: 10px 10px 0;
    margin-bottom: 10px;
  }

  .smallcase__strategy__card .strategy__info,
  .smallcase__strategy__card .strategy__data,
  .smallcase__strategy__card .strategy__options {
    margin: 0px 0px 10px;
  }

  .strategy__icon__box {
    width: 30%;
  }

  .strategy__icon__box img {
    width: 12px;
  }

  .strategy__icon__box span {
    font-size: 10px;
  }

  .strategy__info .strategy__headings__box {
    width: 70%;
    padding-right: 5px;
  }

  .strategy__headings__box .strategy__heading {
    font-size: 12px;
  }

  .strategy__headings__box .strategy__news {
    font-size: 11px;
    color: var(--font-primary);
    font-weight: 500;
  }

  .strategy__data .strategy__data__table {
    width: 70%;
  }

  .strategy__data__table > div .title {
    font-size: 12px;
    color: var(--font-primary);
  }

  .strategy__data__table > div .value {
    font-size: 11px;
    font-weight: 500;
    color: var(--font-primary);
  }

  .smallcase__strategy__card .strategy__options {
    justify-content: space-between;
    margin-top: 15px;
  }

  .smallcase__strategy__card .strategy__options > p {
    font-size: 12px;
  }

  .smallcase__strategy__card .strategy__options .strategy__buy__button {
    background-color: var(--white);
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    border: none;
  }

  .smallcase__strategy__details .startegy__details__close {
    display: flex;
    justify-content: flex-end;
  }

  .smallcase__strategy__details .startegy__details__close > img {
    width: 30px;
  }

  .smallcase__strategy__details .strategy__details__box {
    width: 100%;
    margin-bottom: 15px;
    padding: 0 10px;
  }

  .strategy__details__box .details__header {
    display: flex;
    align-items: center;
  }

  .strategy__details__box .details__header > img {
    width: 16px;
  }

  .strategy__details__box .details__header > span {
    font-size: 12px;
  }

  .strategy__details__box .details__content > p {
    font-size: 12px;
    font-weight: 500;
    margin-top: 4px;
    color: var(--font-primary);
  }

  .strategy__chart {
    height: 250px;
  }

  .smallcase__strategy__container {
    width: 100%;
    height: 100%;
    padding: 0px 10px 0;
  }

  .smallcase__strategy__container .smallcase__strategy__header {
    width: 100%;
    padding: 0px 0px;
  }

  .smallcase__strategy__header > span {
    font-size: 13px;
  }

  .smallcase__strategy__header > div {
    padding: 15px 0px 15px 15px;
    font-size: 13px;
    box-shadow: none;
  }

  .smallcase__strategy__header > div > img {
    display: none;
  }

  .smallcase__strategy__body__wrapper {
    width: 100%;
    /* height: 75%; */
    min-height: 450px;
    padding: 0 0px;
    margin-top: 0px;
    transition: 0.3s;
  }

  .smallcase__strategy__body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5px;
  }

  .smallcase__strategy__body .smallcase__strategy__form {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 10px 0;
  }

  .smallcase__strategy__body .smallcase__strategy__image {
    display: none;
  }

  .smallcase__strategy__form .strategy__select__container {
    padding: 0 10px;
  }

  .strategy__select__container .strategy__select__header > img {
    width: 16px;
  }

  .strategy__select__container .strategy__select__header > span {
    margin-left: 6px;
    font-size: 12px;
  }

  .strategy__select__container .strategy__select__box {
    margin-top: 5px;
  }

  .strategy__select__container
    .strategy__select__box
    .custom-select
    .custom-select-title,
  .strategy__select__container
    .strategy__select__box
    .custom-select
    .custom-select-option {
    font-size: 11px !important;
  }

  .strategy__select__container .strategy__text__box,
  .strategy__select__container .strategy__input__box {
    margin-top: 5px;
  }

  .strategy__select__container .strategy__text__box textarea {
    width: 95%;
    height: 100%;
    font-size: 10px;
  }

  .strategy__select__container .strategy__input__box input {
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding-left: 10px;
    font-size: 10px;
  }

  .strategy__select__container .strategy__text__box textarea::placeholder,
  .strategy__select__container .strategy__input__box input::placeholder {
    color: var(--font-primary);
  }

  .smallcase__strategy__container .smallcase__strategy__footer {
    margin-top: 20px;
    width: 100%;
    padding: 5px 0px;
  }

  .smallcase__strategy__footer .strategy__footer__button {
    padding: 10px 40px 10px 40px;
    box-shadow: none;
    font-size: 12px;
  }

  .smallcase__strategy__footer .strategy__footer__button > img {
    display: none;
  }

  .strategy__portfolio__table__wrapper {
    width: 100%;
    padding: 0px 0;
    margin-top: 20px;
  }

  .strategy__portfolio__table__wrapper .strategy__portfolio__table {
    width: 100%;
    height: 90%;
  }

  .strategy__portfolio__table .strategy__portfolio__table__header,
  .strategy__portfolio__table .strategy__portfolio__table__body,
  .strategy__portfolio__table .strategy__portfolio__table__footer {
    width: 100%;
  }

  .strategy__portfolio__table .strategy__portfolio__table__body {
    height: 75%;
    overflow-y: scroll;
  }

  .strategy__portfolio__table__header .table__row,
  .strategy__portfolio__table__body .table__row,
  .strategy__portfolio__table__footer .table__row {
    width: 100%;
    padding: 4px 2px;
  }

  .strategy__portfolio__table__header .edit__table__row,
  .strategy__portfolio__table__body .edit__table__row,
  .strategy__portfolio__table__footer .edit__table__row {
    width: 100%;
    font-size: 10px;
    padding: 4px 2px;
  }

  .strategy__portfolio__table__footer .table__row,
  .strategy__portfolio__table__footer .edit__table__row {
    margin-top: 10px;
    border-top: 1px solid #e8e8e8;
  }

  .strategy__portfolio__table__header .table__row > div,
  .strategy__portfolio__table__footer .table__row > div,
  .strategy__portfolio__table__header .edit__table__row > div,
  .strategy__portfolio__table__footer .edit__table__row > div {
    font-weight: 700;
  }

  .strategy__portfolio__table__body .table__row:hover,
  .strategy__portfolio__table__body .edit__table__row:hover {
    background-color: #fcfcfc;
    border-radius: 3px;
  }

  .strategy__portfolio__table__header .table__row > div:nth-child(1),
  .strategy__portfolio__table__body .table__row > div:nth-child(1),
  .strategy__portfolio__table__footer .table__row > div:nth-child(1) {
    font-size: 11px;
  }

  .strategy__portfolio__table__header .edit__table__row > div:nth-child(1),
  .strategy__portfolio__table__body .edit__table__row > div:nth-child(1),
  .strategy__portfolio__table__footer .edit__table__row > div:nth-child(1) {
    flex: 2;
    font-size: 10px;
    padding-left: 5px;
  }

  .strategy__portfolio__table__header .table__row > div:nth-child(2),
  .strategy__portfolio__table__body .table__row > div:nth-child(2),
  .strategy__portfolio__table__footer .table__row > div:nth-child(2) {
    font-size: 11px;
  }

  .strategy__portfolio__table__header .edit__table__row > div:nth-child(2),
  .strategy__portfolio__table__body .edit__table__row > div:nth-child(2),
  .strategy__portfolio__table__footer .edit__table__row > div:nth-child(2) {
    flex: 2;
    font-size: 10px;
  }

  .strategy__portfolio__table__header .table__row > div:nth-child(3),
  .strategy__portfolio__table__body .table__row > div:nth-child(3),
  .strategy__portfolio__table__footer .table__row > div:nth-child(3) {
    flex: 3;
    font-size: 11px;
  }

  .strategy__portfolio__table__header .table__row > div:nth-child(4),
  .strategy__portfolio__table__body .table__row > div:nth-child(4),
  .strategy__portfolio__table__footer .table__row > div:nth-child(4) {
    flex: 3;
  }

  .strategy__portfolio__table__header .edit__table__row > div:nth-child(3),
  .strategy__portfolio__table__body .edit__table__row > div:nth-child(3),
  .strategy__portfolio__table__footer .edit__table__row > div:nth-child(3) {
    flex: 1.5;
    font-size: 10px;
  }

  .strategy__portfolio__table__header .edit__table__row > div:nth-child(4),
  .strategy__portfolio__table__body .edit__table__row > div:nth-child(4),
  .strategy__portfolio__table__footer .edit__table__row > div:nth-child(4) {
    padding-right: 5px;
    flex: 1.5;
  }

  .strategy__portfolio__table__header .edit__table__row > div:nth-child(5),
  .strategy__portfolio__table__body .edit__table__row > div:nth-child(5),
  .strategy__portfolio__table__footer .edit__table__row > div:nth-child(5) {
    flex: 1;
    padding-right: 5px;
  }

  .strategy__portfolio__table__body
    .edit__table__row
    > div:nth-child(5)
    > span {
    font-size: 9px !important;
    font-weight: 500 !important;
  }

  .strategy__portfolio__table__body .table__row > div > input,
  .strategy__portfolio__table__body .edit__table__row > div > input {
    width: 100%;
    border-width: 1px;
  }

  .strategy__portfolio__table__body .table__row > div .stock__remove,
  .strategy__portfolio__table__body .edit__table__row > div .stock__remove {
    padding: 5px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .strategy__portfolio__table__body .table__row > div .stock__remove:hover,
  .strategy__portfolio__table__body
    .edit__table__row
    > div
    .stock__remove:hover {
    background-color: #bce8ff;
  }

  .strategy__portfolio__table__body .table__row > div .stock__remove img {
    width: 10px;
    height: 10px;
  }

  .strategy__portfolio__table__body .table__row > div > input:focus,
  .strategy__portfolio__table__body .edit__table__row > div > input:focus {
    border-bottom: 1px solid var(--font-primary);
  }

  .strategy__portfolio__table .strategy__table__stock {
    display: flex;
    flex-direction: column;
  }

  .strategy__table__stock .strategy__table__stock__code {
    font-size: 9px;
    font-weight: 700;
  }

  .strategy__table__stock .strategy__table__stock__name {
    max-width: 50px;
    font-size: 9px;
  }

  .strategy__portfolio__table__wrapper .strategy__performance__buttons {
    display: flex;
    width: 100%;
  }

  .strategy__portfolio__table__wrapper .strategy__performance__buttons > div {
    padding: 6px 12px 6px 12px;
    font-size: 11px;
  }

  .strategy__portfolio__stock__wrapper {
    width: 100%;
    /* justify-content: center; */
    padding: 5px 0;
  }

  .strategy__portfolio__stock__wrapper .strategy__portfolio__stock__options {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .strategy__portfolio__stock__options .stock__search {
    width: 100%;
    height: 35px;
    padding: 0 5px !important;
    border-radius: 3px;
    border: 1px solid #e8e8e8;
  }

  .strategy__portfolio__stock__options .stock__search > input {
    font-size: 12px;
  }

  .strategy__portfolio__stock__options .stock__suggestions {
    max-height: 200px !important;
  }

  .strategy__portfolio__stock__options .stock__suggestions .search__loader {
    height: 200px !important;
  }

  .strategy__portfolio__stock__options .stock__suggestions > p {
    padding: 6px 10px !important;
  }

  .strategy__portfolio__stock__options
    .stock__suggestions
    > p
    > span:nth-child(1) {
    font-size: 10px;
  }

  .strategy__portfolio__stock__options
    .stock__suggestions
    > p
    > span:nth-child(2) {
    font-size: 8px;
  }

  .strategy__portfolio__stock__options
    .stock__suggestions
    > p
    > span:nth-child(3) {
    font-size: 10px;
  }

  .strategy__portfolio__stock__options .strategy__stock__buttons {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
  }

  .strategy__portfolio__stock__options .strategy__stock__buttons > div {
    margin: 0px 15px 0px 0px;
    font-size: 11px;
  }

  .strategy__portfolio__stock__options .strategy__stock__buttons > div:hover {
    background-color: #f8f8f8;
  }

  .smallcase__success__page {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .smallcase__success__page .success__message {
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
  }

  .backtest__container .backtest__header {
    width: 100%;
    padding: 5px 10px;
  }

  .backtest__header > span {
    font-size: 11px;
  }

  .backtest__header > div {
    font-size: 11px;
  }

  .backtest__container .backtest__wrapper {
    margin-top: 10px;
    padding: 0 2%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .backtest__wrapper .backtest__chart,
  .backtest__wrapper .backtest__data {
    width: 100%;
  }

  .backtest__wrapper .backtest__chart {
    padding: 10px 2px;
    font-size: 8px;
  }

  .backtest__wrapper .backtest__chart .backtest__chart__wrapper > p {
    margin-bottom: 20px !important;
    font-size: 11px;
    padding: 0 5px;
  }

  .backtest__wrapper .backtest__data {
    border-radius: 5px;
    padding: 10px 5px;
  }

  .backtest__wrapper .backtest__data .backtest__data__table {
    margin-bottom: 10px;
  }

  .backtest__data__table .data__table__title {
    font-size: 11px;
    margin-bottom: 10px;
  }

  .backtest__data__table > div:nth-child(2) {
    overflow-x: scroll;
  }

  .backtest__data__table table {
    width: 100%;
  }

  .backtest__data__table thead tr td {
    font-size: 10px;
  }

  .backtest__data__table tbody tr th {
    width: 70px;
    padding: 0 60px 0 0;
    font-size: 10px;
  }

  .backtest__data__table tbody tr td {
    font-weight: 500;
    min-width: 100px;
  }

  .backtest__container .backtest__buttons {
    margin-top: 10px;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .backtest__buttons .backtest__save {
    padding: 6px 10px 6px 10px;
    font-size: 10px;
  }
}
