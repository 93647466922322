.strategy-content {
  height: 97%;
}

/*app body*/
.app__body {
  overflow: hidden;
  padding: 0px 0px 0px;
  height: 100%;
}

.app__body.hide {
  display: none;
}

.app__body__top__zoom {
  height: 0;
  display: none;
}

.app__body__bottom {
  width: 100%;
  height: 100%;
  display: flex;
  /* align-items: center; */
  /* position: relative; */
  justify-content: space-between;
}

.app__body__bottom__zoom {
  height: 100%;
  padding: 5px 10px;
}

.app__body.app__body__zoom {
  height: 100%;
  padding: 0px;
}

.app__body__bottom .app__body__footer {
  position: absolute;
  z-index: 10000;
  bottom: 0.5%;
  left: -7%;
}

.app__body__bottom .app__body__logout {
  position: absolute;
  left: 101%;
  bottom: 1%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.app__body__bottom .app__body__logout > div {
  display: flex;
  align-items: center;
}

.app__body__bottom .app__body__logout img {
  width: 20px;
  transform: rotate(180deg);
  margin-right: 5px;
}

.app__body__bottom .app__body__logout span {
  font-size: 12px !important;
  font-weight: bold !important;
  letter-spacing: -0.5px;
  color: var(--font-primary);
}

.app__body .app__body__left {
  width: 74%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 10px;
}

.app__body .app__body__left.app__body__left__zoom {
  width: 75%;
  height: 100%;
}

.app__body .app__body__left .Indicator__popup,
.app__body .app__body__left .Interactive__popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 1px 1px 5px 1px #cccccc;
  z-index: 1000;
  width: 40%;
  height: 70%;
  border-radius: 5px;
  overflow-x: hidden;
  display: none;
  z-index: 100000;
}

.app__body .app__body__left .Indicator__popup .Indicator__title__name,
.Stock__watch__popup .Stock__watch__title__name,
.app__body .app__body__left .Interactive__popup .Interactive__title__name {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  font-size: 18px;
  letter-spacing: 0px;
  font-weight: bold;
  font-size: 14px;
  border-bottom: 0.5px solid #eeeeee;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__title__name
  #Indicator__close,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__title__name
  #Interactive__close,
.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__title__name
  #Stock__watch__close {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__title__name
  #Indicator__close:hover,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__title__name
  #Interactive__close:hover,
.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__title__name
  #Stock__watch__close:hover {
  background-color: #f5f5f5;
}

.app__body .app__body__left .Indicator__popup .Indicator__options,
.app__body .app__body__left .Interactive__popup .Interactive__options {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  position: relative;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options::-webkit-scrollbar,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options::-webkit-scrollbar,
.app__body
  .business__news__section
  .business__news__wrapper
  .business__news__box::-webkit-scrollbar,
.credit__ratings::-webkit-scrollbar,
.business__chart::-webkit-scrollbar {
  width: 5px;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options::-webkit-scrollbar-track,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options::-webkit-scrollbar-track,
.app__body
  .business__news__section
  .business__news__wrapper
  .business__news__box::-webkit-scrollbar-track,
.credit__ratings::-webkit-scrollbar-track,
.business__chart::-webkit-scrollbar-track {
  display: none;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options::-webkit-scrollbar-thumb,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options::-webkit-scrollbar-thumb,
.app__body
  .business__news__section
  .business__news__wrapper
  .business__news__box::-webkit-scrollbar-thumb,
.credit__ratings::-webkit-scrollbar-track,
.business__chart::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 10px;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options::-webkit-scrollbar-thumb:hover,
.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options::-webkit-scrollbar-thumb:hover,
.app__body
  .business__news__section
  .business__news__wrapper
  .business__news__box::-webkit-scrollbar-thumb:hover,
.credit__ratings::-webkit-scrollbar-track,
.business__chart::-webkit-scrollbar-track {
  cursor: pointer;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block {
  position: relative;
  width: 100%;
  border-bottom: 0.5px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block:hover {
  background-color: #f5f5f5;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block
  .indicator__option__title {
  width: 100%;
  height: 100%;
  padding: 10px 30px 10px 30px;
  /* background-color: #00A0E3; */
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block
  p {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block
  > div {
  display: flex;
  align-items: center;
}

/* 
.app__body .app__body__left .Indicator__popup .Indicator__options .Indicator__option__block>div button 
{
  border: none;
  outline: none;
  background-color: #eeeeee;
  box-shadow: 0 2px 3px rgba(0,0,0,.25);
  margin-right: 20px;
  border-radius: 2px;
  width: 130px;
  height: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
} */

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block
  > div
  span {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__options
  .Indicator__option__block
  > div
  span:hover {
  font-weight: bold;
  background-color: #470985;
  color: #ffffff;
}

.app__body .app__body__left .Indicator__popup .Indicator__info {
  position: absolute;
  /* background-color: #470985;
  */
  background-color: #fbfbfb;
  box-shadow: -2px 0 4px #cccccc;
  top: 0%;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  transform: translateX(100%);
  transition: 0.3s;
  z-index: 1999;
}

.app__body .app__body__left .Indicator__popup .Indicator__info.active {
  transform: translateX(0%);
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__name {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 0.5px solid #eeeeee;
  position: relative;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__name
  span#Indicator__Info__close {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffff5;
  cursor: pointer;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__data {
  padding: 10px 30px;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__data
  > div {
  margin-bottom: 20px;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__data
  > div
  .Indicator__info__data__title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.app__body
  .app__body__left
  .Indicator__popup
  .Indicator__info
  .Indicator__info__data
  > div
  .Indicator__info__data__value {
  font-size: 14px;
  font-weight: 500;
}

.app__body .app__body__left .Stock__watch__popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 1px 1px 5px 1px #cccccc;
  z-index: 100000;
  width: 40%;
  height: 80%;
  border-radius: 5px;
  overflow: hidden;
  display: none;
}

.app__body .app__body__left .Stock__watch__popup .Stock__watch__container {
  width: 100%;
  height: 85%;
  overflow-y: scroll;
  position: relative;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__suggessted {
  width: 100%;
  height: 100%;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__suggessted__loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__section {
  background-color: #f1f2f6;
  padding: 5px 30px;
  font-size: 12px;
  font-weight: 900;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item {
  width: 100%;
  padding: 8px 30px 8px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 0.5px solid #eeeeee;
  cursor: pointer;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item.loader {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item:hover {
  background-color: #f5f5f5;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item:hover
  .Stock__watch__exchange__section
  > div {
  background-color: #ffffff;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__name {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__fullname {
  font-size: 10px;
  font-weight: 600;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__exchange__section {
  display: flex;
  align-items: center;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__exchange {
  font-weight: bold;
  font-size: 12px;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__exchange__section
  > div {
  background-color: #f1f2f6;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 3px;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container
  .Stock__watch__item
  .Stock__watch__exchange__section
  .Stock__watch__change__per {
  margin-right: 15px;
  font-size: 14px;
  font-weight: bold;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container::-webkit-scrollbar {
  width: 2px;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container::-webkit-scrollbar-track {
  /* display: none; */
  background: #f4f7f9;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 3px;
}

.app__body
  .app__body__left
  .Stock__watch__popup
  .Stock__watch__container::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block {
  width: 100%;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  > p {
  padding: 5px 30px;
  background-color: #f8f8f8;
  font-size: 12px;
  font-weight: bold;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  .Interactive__tool {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 10px 30px;
  cursor: pointer;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  .Interactive__tool:hover {
  background-color: #eeeeee;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  > div
  img {
  width: 20px;
  margin-right: 10px;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  > div
  p {
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}

.app__body
  .app__body__left
  .Interactive__popup
  .Interactive__options
  .Interactive__option__block
  > div
  > p:hover {
  background-color: #eeeeee;
}

.app__body .app__body__left .Indicator__popup.active,
.app__body .app__body__left .Interactive__popup.active,
.app__body .app__body__left .Stock__watch__popup.active {
  display: block;
}

.app__body .app__body__left .chart__container {
  width: 100%;
  height: 90%;
  border-radius: 15px;
  position: relative;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 5px;
  border: 1px solid #f5f5f5;
  padding: 0px 0px 10px 0px;
}

.app__body .app__body__left .chart__container.chart__container__zoom {
  height: 84%;
  border-radius: 0px;
  border-top: none;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options {
  flex-direction: column;
  width: 100%;
  height: 10%;
  display: none;
  flex: 1;
}

.app__body
  .app__body__left
  .chart__container.chart__container__zoom
  .chart__container__stock__options {
  height: 7%;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 2%), -3px -3px 10px rgb(0 0 0 / 2%);
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options.active {
  display: flex;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  /* background-color: #19E683; */
  /* border: 1px solid #eeeeee; */
  z-index: 100;
  display: flex;
  align-items: center;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  height: 100%;
  padding: 0px 15px;
  /* border: 1px solid #eeeeee; */
  position: relative;
  cursor: pointer;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block:hover {
  background-color: #f7f7f7;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  img {
  width: 28px;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  img#stock-watch-arrow {
  width: 15px;
  height: 15px;
  transform: rotate(0deg);
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  img#stock-watch-arrow.active {
  transform: rotate(180deg);
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  span#chart__type__icon {
  width: 18px;
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__stock__name {
  padding: 0 30px;
  font-size: 16px;
  cursor: pointer;
}

.app__body .chart__options .chart__option__block .stock__chart__types {
  background-color: #ffffff;
  width: 200px;
  position: absolute;
  top: 100%;
  left: 0%;
  margin-top: 4px;
  z-index: 100;
  border-radius: 2px;
  padding: 5px 5px 0 5px;
  box-shadow: 1px 3px 5px #e8e8e8;
  transition: 0.2s;
  display: none;
}

.app__body .chart__options .chart__option__block .stock__chart__types.active {
  display: block;
  border-radius: 3px;
  transition: 0.3s;
}

.app__body .chart__options .chart__option__block .stock__chart__types > div {
  display: flex;
  align-items: center;
  padding: 8px 0 8px 10px;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #f7f7f7;
}

.app__body
  .chart__options
  .chart__option__block
  .stock__chart__types
  > div.active {
  background-color: #f7f7f7;
}

.app__body
  .chart__options
  .chart__option__block
  .stock__chart__types
  > div:hover {
  background-color: #f7f7f7;
}

.app__body
  .chart__options
  .chart__option__block
  .stock__chart__types
  > div
  span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .chart__container
  .chart__container__stock__options
  .chart__options
  .chart__option__block
  .stock__chart__types
  > div
  img {
  width: 16px;
}

.app__body .chart__options .chart__option__block #chart__type__icon {
  width: 20px;
  height: 20px;
}

.app__body .app__body__left .chart__container .stock__info__chart {
  position: relative;
  height: calc(100% - 30px);
}

.app__body
  .app__body__left
  .chart__container.chart__container__zoom
  .stock__info__chart {
  height: 87%;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 2%), -3px -3px 10px rgb(0 0 0 / 2%);
  margin-top: 5px;
  border-radius: 5px;
}

.app__body .app__body__left .chart__container .chart__container__stock__info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 10px 80px 0 20px;
}

.app__body .app__body__left .chart__container .stock__info {
  z-index: 10;
  padding-left: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 20px;
  display: flex;
  background-color: white;
}

.app__body .app__body__left .chart__container .stock__info .stock__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  column-gap: 5px;
}

.app__body .app__body__left .chart__container .stock__info .stock__details p {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.app__body
  .app__body__left
  .chart__container
  .stock__info
  .stock__details
  .stock__type {
  width: auto;
  height: auto;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.app__body
  .app__body__left
  .chart__container
  .stock__info
  .stock__details
  .stock__type
  img {
  width: 14px;
  margin-right: 4px;
}

.app__body
  .app__body__left
  .chart__container
  .stock__info
  .stock__details
  .stock__type
  p {
  font-size: 11px;
  font-weight: 500;
  color: var(--font-primary);
}

.app__body .app__body__left .chart__container .stock__info #stock__full__name {
  font-size: 10px;
  color: var(--font-primary);
  font-weight: 500;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__purchase {
  display: flex;
  flex-direction: row;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  align-items: flex-start;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details {
  display: flex;
  align-items: center;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details p {
  font-size: 16px;
  font-weight: bold;
  margin-right: 2px;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details .price__decimals {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 20px;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details .price__fraction {
  color: var(--font-primary);
  font-size: 14px;
  font-weight: bold;
  align-self: flex-start;
  margin-left: 0px;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details span.stock__performance {
  font-size: 12px;
  color: #470985;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details p span.clock {
  margin: 0 3px;
  font-size: 12px;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__details {
  font-size: 12px;
  font-weight: bold;
}

.app__body .app__body__left .chart__container .stock__purchase {
  z-index: 10;
  display: flex;
  align-items: center;
  column-gap: 60px;
}

.app__body .app__body__left .chart__container .stock__purchase.zoom {
  right: 6%;
}

.app__body .app__body__left .chart__container .stock__purchase .buy__sell__box {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  align-items: center;
  background-color: var(--white);
}

.app__body
  .app__body__left
  .chart__container
  .stock__purchase
  .buy__sell__box
  > div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body .app__body__left .chart__container .stock__purchase .currency {
  font-size: 12px;
  font-weight: 500;
}

.squareoff__stock {
  margin-left: 5px;
  border: 1px solid var(--main-blue);
  padding: 3px 10px;
  color: var(--main-blue);
  border-radius: 3px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body .app__body__left .chart__container .stock__purchase > div:hover img {
  transform: scale(1.2);
}

.app__body .app__body__left .chart__container .stock__purchase > div img {
  width: 12px;
  height: 12px;
  transition: 0.3s;
}

.app__body .app__body__left .chart__container .stock__info .stock__price__change {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--font-primary);
}

.app__body .app__body__left .chart__container .stock__info .stock__price__change div {
  margin-left: 0px;
}

.app__body .app__body__left .chart__container .stock__chart {
  width: 100%;
  height: 100%;
  position: relative;
}

.app__body .app__body__left .chart__container .stock__chart.stock__chart__blur .react-stockchart {
  opacity: 0.7;
}

.app__body .app__body__left .chart__container .stock__chart.stock__chart__blur .stock__chart__pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.app__body .app__body__left .chart__container .stock__chart.stock__chart__load {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app__body .app__body__left .chart__container .stock__chart.stock__chart__load p {
  margin-top: 10px !important;
  font-weight: 500;
  font-size: 14px;
}

.app__body .app__body__left .chart__container .chart__range {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 10px;
}

.app__body .app__body__left .chart__container .chart__range .chart__range__left {
  display: flex;
}

.app__body .app__body__left .chart__container.chart__container__zoom .chart__range {
  height: 6%;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 2%), -3px -3px 10px rgb(0 0 0 / 2%);
  margin-top: 5px;
}

.app__body .app__body__left .chart__container .chart__range .chart__range__value {
  font-size: 12px;
  margin: 0 5px 0 0;
  color: var(--font-primary);
  font-weight: 500;
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 3px;
  transition: 0.3s;
}

.app__body .app__body__left .chart__container .chart__range .active__range {
  color: var(--font-primary);
  background-color: none;
  font-weight: 800;
  font-size: 12px;
}

.app__body .app__body__left .chart__container .chart__range .chart__range__right{
  display: flex;
  align-items: flex-end;
}

.app__body .app__body__left .chart__container .chart__range .chart__range__right span {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  padding: 4px 20px;
  border-radius: 20px;
  border: 1px solid #e8e8e8;
}

.app__body .app__body__left .chart__container .chart__range .chart__range__right span a {
  color: var(--font-primary);
  text-decoration: none;
}

.app__body .app__body__left .chart__container .chart__zoom {
  position: absolute;
  right: 0%;
  bottom: 0%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.app__body .app__body__left .chart__container .chart__zoom img {
  width: 30px;
}

.app__body .app__body__left .key__statistics {
  width: 100%;
  height: 12%;
  min-height: 60px;
  border: 1px solid #f5f5f5;
  background-color: var(--white);
  border-radius: 15px;
  padding: 5px 15px 5px 15px;
  position: relative;
}

.app__body .app__body__left .key__statistics.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__body .app__body__left .key__statistics.key__statistics__zoom {
  height: 15%;
  border-radius: 0px;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 2%), -3px -3px 10px rgb(0 0 0 / 2%);
}

.app__body .app__body__left__zoom .key__statistics {
  height: 15%;
  border-radius: 0px;
}

.app__body .app__body__left .key__statistics .ks__title {
  color: var(--font-primary);
  font-size: 14px !important;
  letter-spacing: -0.5px;
  font-weight: 500;
}

.app__body .app__body__left .key__statistics .ks__title > p {
  letter-spacing: -0.5px;
}

.app__body .app__body__left .key__statistics .ks__container {
  width: 100%;
  height: 100%;
}

.app__body .app__body__left .key__statistics .ks__container .ks__container__half,
.app__body .app__body__left .key__statistics .ks__container .ks__container__full {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app__body .app__body__left .key__statistics .ks__container .ks__container__mobile {
  display: none;
}

.app__body .app__body__left .key__statistics .ks__container .ks__slot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 25%;
}

.app__body .app__body__left .key__statistics .ks__container .statistics .ks__name {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: bold;
}

.app__body .app__body__left .key__statistics .ks__container .statistics .ks__value {
  font-size: 14px;
  color: var(--font-primary);
  font-weight: 600;
  display: flex;
  align-items: center;
}

.app__body .app__body__left .key__statistics .ks__container .ks__slot .statistics .ks__value .range__line {
  position: relative;
  width: 30px;
  height: 2px;
  background-color: var(--main-blue);
  margin: 0 8px;
}

.ks__value .range__line::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  width: 8px;
  height: 8px;
  transform: translate(-50%, -50%);
  background-color: var(--main-blue);
  border-radius: 50%;
}

.ks__value .range__line::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0%;
  width: 8px;
  height: 8px;
  transform: translate(50%, -50%);
  background-color: var(--main-blue);
  border-radius: 50%;
}

.app__body .app__body__left .key__statistics .ks__container .ks__container__full {
  padding: 0 20px 0 0;
}

.app__body .app__body__left .key__statistics .ks__container .statistics .ks__name {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 500;
}

.app__body .app__body__left .key__statistics .ks__container .ks__container__full .ks__slot .statistics {
  margin: 3px 0;
}

.app__body .app__body__right {
  width: 25%;
  height: 100%;
}

.app__body .app__body__right.app__body__right__zoom {
  width: 25%;
  margin-left: 5px;
}

/* responsive ui */

@media only screen and (max-width: 768px) {

  .strategy-content {
    height: calc(100% - 50px);
    padding: 0 10px;
  }
  
  .app__body .app__body__left {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    transition: 0.4s;
    background-color: white;
    z-index: 10000;
    padding: 0 10px;
  }

  .app__body .app__body__left.active{
    top: 0px;
    justify-content: flex-start;
    padding: 0px 0px;
  }

  .app__body .app__body__left .chart__mobile__header{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 10px 10px 0;
  }

  .chart__mobile__header .chart__close{
    cursor: pointer;
  }

  .app__body .app__body__left .chart__container .chart__container__stock__info{
    padding: 0px 0px;
    align-items: flex-start;
    position: initial;
    padding: 0 10px;
    flex-direction: column;
  }

  .app__body .app__body__left .chart__container .stock__info{
    width: 100%;
    justify-content: space-between;
  }

  .app__body .app__body__left .chart__container .stock__info .stock__details p{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .app__body .app__body__left .chart__container .stock__info .stock__price__purchase{
    flex-direction: column;
    align-items: flex-end;
  }

  .app__body .app__body__left .chart__container .stock__info .stock__price__details .price__decimals{
    font-size: 20px;
    line-height: 24px;
  }

  .app__body .app__body__left .chart__container .stock__info .stock__price__change{
    column-gap: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .app__body .app__body__left .chart__container .stock__info #stock__full__name{
    font-size: 13px;
    line-height: 20px;
  }

  .app__body .app__body__left .chart__container{
    height: 450px;
    border: none;
    justify-content: flex-end;
  }

  .app__body .app__body__left .chart__container .stock__info__chart{
    height: 400px;
  }

  .app__body .app__body__left .chart__container .chart__range .chart__range__right span{
    border: none;
    padding: 0 5px;
  }

  .app__body .app__body__left .chart__container .chart__range{
    padding: 0 10px 0 4px;
  }

  .app__body .app__body__left .chart__container .chart__range .chart__range__value{
    font-size: 14px;
    line-height: 16px;
  }

  .app__body .app__body__left .key__statistics{
    height: auto;
    border: none;
    border-top: 1px solid #e8e8e8;
    border-radius: 0;
    padding: 10px 10px;
  }

  .app__body .app__body__left .key__statistics .ks__container .ks__container__half{
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 20px;
  }

  .app__body .app__body__left .key__statistics .ks__container .statistics .ks__name{
    font-size: 13px;
  }

  .app__body .app__body__left .key__statistics .ks__container .statistics .ks__value{
    font-size: 15px;
  }

  .app__body .app__body__right{
    width: 100%;
  }

}
