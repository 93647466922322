
/* hover tooltip */
.react-stockcharts-tooltip-content
{
  filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.1));
}

.react-stockcharts-indicator-options
{
  pointer-events: all;
  cursor: pointer;
}

.react-stockchart-indicator-box
{
  fill: #f0f0f0;
  /* -webkit-filter: drop-shadow( 0px 0px 4px rgba(0, 0, 0, .2)); */
  /* filter: drop-shadow( 0px 0px 4px rgba(0, 0, 0, .2)); */
  cursor: pointer;

}

.react-stockcharts-tooltip,.react-stockcharts-indicator-tooltip
{
  pointer-events: all;
  cursor: pointer;
}

.react-stockcharts-x-axis{
  opacity: 1;
  fill: white;
}

/* .app__body__left .react-stockcharts-x-axis
{
  display: none;
} */

.app__body__left__zoom .react-stockcharts-x-axis
{
  display: block;
}


/* recharts components */
.recharts-legend-item 
{ 
  font-size: 10px; 
}

.custom-tooltip
{
  background-color: #fffffff0;
    padding: 5px 20px;
    box-shadow: 0px 0px 8px #e8e8e8;
  border-radius: 3px;
}

.custom-tooltip .custom-label
{
  font-size: 12px !important;
  margin-bottom: 5px!important;
  font-weight: 500;
}

/*interactive setting popup */
.react-stockcharts-indicator-setting
{
  filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.1));
  pointer-events: all;
  cursor: pointer;
}



/*rsuite components*/
/* .confidence__slider .rs-slider-mark
{
  top: -13px!important;
}

.confidence__slider .rs-slider-last-mark
{
  top: auto!important;
  bottom: -8px!important;
}

*/ .confidence__slider {
  display: flex;
  column-gap: 20px;
  height: 300px;
  align-items: center;
}

.confidence__slider .slider__wrapper {
  width: 2px;
  height: 100%;
  background-color: #e8e8e8;
  border-radius: 5px;
  position: relative;
}

.slider__wrapper .slider__point {
  width: 10px;
  height: 10px;
  background-color: #e8e8e8;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider__wrapper .slider__point.active {
  background-color: var(--font-primary);
}

.confidence__slider .slider__marks {
  height: 105%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.confidence__slider .slider__marks .mark__text {
  font-weight: 300;
  font-size: 12px;
  color: var(--font-primary);
}

.confidence__slider .slider__marks .mark__text.active {
  font-weight: 500;
}

/* react-confirm-alert*/
.react-confirm-alert-overlay
{
  z-index: 1000!important;
}

.react-confirm-alert-body
{
  font-family: 'Open Sans', sans-serif;
}

.react-confirm-alert-body h1
{
  font-size: 26px!important;
  color: var(--font-primary) !important;
}

.react-confirm-alert-button-group > button
{
  background-color: #404040!important;
  border-radius: 3px!important;
}

/*checkmark */

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--main-green);
  fill: none;
  animation: stroke 0.9s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  margin-bottom: 2%;
  box-shadow: inset 0px 0px 0px var(--main-green);
  animation: fill .6s ease-in-out .9s forwards, scale .6s ease-in-out 1s both
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--main-green);
  }
}