.swift-orders-management-mf-heading {
  column-gap: 20px;
}

.admin-orders-mf-container table tbody tr.yes_hover:hover td:not(:last-child) {
  opacity: 0.5;
}

.admin-orders-mf-container table tbody tr.yes_hover:hover .hover-container {
  display: flex;
}

.admin-orders-mf-container .hover-container {
  position: absolute;
  top: 50%;
  right: 20px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  display: none;
  height: 100%;
  transform: translateY(-50%);
}

.admin-orders-mf-container .hover-btn {
  z-index: 100000;
  background: #f5f5f5;
  border: 1px solid #e8e8e8;
  border-radius: 35px;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  min-width: 100px;
}

.orders-management-loader .dot-pulse {
  left: -9999px !important;
  top: 0px !important;
}

/* ------------------------------------- SEARCH -----------------------------------*/

.admin-orders-management-mf-search .stock__search__icons__wrapper {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--light-box-shadow);
  width: 100%;
  align-self: flex-end;
}

.admin-orders-management-mf-search .stock__search {
  width: 500px;
  height: 100%;

  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;

  background: rgba(255, 255, 255, 0.933333);
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.admin-orders-management-mf-search .stock__search .stock__search__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px 0 15px;
}

.admin-orders-management-mf-search .stock__search .stock__search__icon img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.admin-orders-management-mf-search .stock__search input {
  border: none;
  outline: none;
  width: 100%;
  height: 90%;
  padding: 13px 0px;
  padding-left: 15px;
  color: var(--font-primary);
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase !important;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.admin-orders-management-mf-search .stock__search input::placeholder {
  color: #888888;
  text-transform: capitalize !important;
}

.admin-orders-management-mf-search .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: 0%;
  margin-top: 3px;
  z-index: 10000;
  border-radius: 5px;
  max-height: 425px;
  /* overflow-y : scroll; */
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
  /* display: none; */
}

.admin-orders-management-mf-search .stock__suggestions .suggestion__stocks {
  overflow-y: scroll;
  max-height: 250px;
}

.admin-orders-management-mf-search .stock__search .stock__suggestions.active {
  display: block;
}

.admin-orders-management-mf-search
  .stock__search
  .stock__suggestions
  .search__loader {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-orders-management-mf-search
  .stock__search
  .stock__suggestions
  .search__loader
  p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  padding: 0 !important;
  display: flex;
  color: var(--font-primary);
}

.admin-orders-management-mf-search .stock__search .stock__suggestions p {
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.admin-orders-management-mf-search .suggestion__stocks .suggestion__empty {
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.admin-orders-management-mf-search .stock__search .stock__suggestions p.active {
  background-color: #dbd9d9;
}

.admin-orders-management-mf-search
  .stock__search
  .stock__suggestions
  p
  span.search__highlight {
  font-weight: 500;
}

.admin-orders-management-mf-search .stock__search .stock__suggestions p > span {
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
}

.admin-orders-management-mf-search .stock__search .stock__suggestions p:hover {
  background-color: #f9f9f9;
}

/* ---------------------------------------------------------------------------------- */

.admin-orders-managemen-bonds-offers-btn:hover {
  cursor: pointer;
}

.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
}

.admin-offers-popup-div {
  column-gap: 15px !important;
}

.admin-offers-popup-div > span {
  padding-left: 10px;
}

.broker-management .header-options {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.orders-management-loader p {
  font-size: 14px;
  font-weight: 600;
}

.admin-orders-mf-container table tbody tr {
  position: relative;
}

.admin-orders-mf-container table tbody tr td {
  padding: 18px 10px !important;
}

.admin-orders-mf-container table tr td.input-time,.admin-orders-bonds-container table tr td.input-time {
  width: 140px;
}

.admin-orders-mf-container table tr td.scheme-name {
  width: 180px;
}

.admin-orders-mf-container table tr td.nav-temp,
.admin-orders-mf-container table tr td.nav {
  width: 80px;
}

.admin-orders-mf-container table tr td.hover-td {
  width: 0;
  padding: 0 !important;
}


.admin-orders-bonds-container table tr td.issue{
  width: 200px;
}

.admin-orders-bonds-container table tr td.price-temp,.admin-orders-bonds-container table tr td.price,.admin-orders-bonds-container table tr td.yield,.admin-orders-bonds-container table tr td.type{
  width: 80px;
}


.swift-mf-popup-btn-switch {
  border-radius: 35px;
  margin-bottom: 20px;
}

.swift-mf-popup-btn-switch .custom-select {
  border-radius: 35px;
  /* padding: 12px 40px; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.05em;
  color: rgba(1, 22, 39, 0.9);
  width: 90px;
}

.swift-mf-popup-btn-switch .custom-select .custom-select-header {
  padding: 5px 20px;
  border-radius: 35px;
  column-gap: 5px;
  border: none;
}

.swift-mf-popup-btn-switch .custom-select .custom-select-header > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  letter-spacing: -0.05em !important;
  color: rgba(1, 22, 39, 0.9);
}

.swift-mf-popup-btn-switch.positive-btn .custom-select {
  background-color: var(--main-green);
}

.swift-mf-popup-btn-switch.negative-btn .custom-select {
  background-color: var(--main-red);
}

.swift-mf-popup-btn-switch .custom-select .select-title {
  display: none;
}

.admin-orders-management-mf-search > .custom-select .select-title {
  display: none;
}

.admin-orders-management-mf-search > .custom-select .custom-select-header {
  height: 50px;
  width: 100%;
  color: var(--font-primary);
  font-weight: 500;
  font-size: 14px;
  /* text-transform: uppercase !important; */
  background-color: transparent;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.swift-mf-popup-header {
  font-size: 14px;
  font-weight: 600;
}

.swift-mf-admin-popup .swift-mf-popup-body {
  padding-top: 10px;
  flex: auto;
}

.swift-mf-admin-popup {
  min-height: 640px;
}

.broker-management .user-login {
  height: 100vh;
}

.swift-bonds-admin-popup {
  min-height: 705px;
}

.swift-mf-admin-popup
  .custom-select
  .custom-select-header
  .custom-select-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: -webkit-fill-available;
}

.swift-mf-admin-popup
  .custom-select
  .custom-select-container
  .custom-select-option {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
