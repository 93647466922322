.reports-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.reports-header .reports-title {
  font-size: 18px;
  /* color: var(--font-primary); */
  color: rgba(1, 22, 39, 0.9) 98;
  font-weight: 700;
}

.reports-header .reports-subtext {
  font-size: 20px;
  font-weight: 300;
}

.report-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: 20px;
  position: relative;
}

.report-dashboard .report-dashboard-left {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.report-dashboard-left .report-generate {
  height: 100%;
  padding: 5px 10px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.report-generate .report-generate-header {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.report-generate-header .report-generate-title {
  font-weight: 600;
  font-size: 14px;
}

.report-generate .report-generate-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  padding-top: 10px;
  height: calc(100% - 50px);
  position: relative;
}

.report-generate-content .report-generate-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  position: absolute;
  padding-top: 50px;
  background-color: hsla(0, 0%, 100%, 0.85);
  z-index: 1000;
}

.report-generate-content .report-generate-loading p {
  font-size: 13px;
  font-weight: 600;
}

.report-generate .report-section-customer {
  width: 100%;
}

.report-generate .report-generate-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.report-generate-options .report-section-type {
  width: 100%;
}

.report-generate-options .report-generate-year {
  width: 100%;
}

.report-dashboard .report-section-button {
  margin-top: 10px;
  align-self: flex-end;
}

.report-dashboard-right .report-history {
  height: 100%;
  width: 370px;
  padding: 5px 0px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.report-history .report-history-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  row-gap: 10px;
}

.report-history .report-history-loading > p {
  font-size: 13px;
  font-weight: 600;
}

.report-history-content .report-history-empty {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.report-history-content .report-history-empty > p {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

.report-history .report-history-header {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 10px;
}

.report-history .report-history-title {
  font-weight: 600;
  font-size: 14px;
}

.report-history .report-history-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  overflow-y: scroll;
  padding: 10px 10px 0;
  height: calc(100% - 20px);
}

.report-history .report-history-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.report-history-row .history-left {
  display: flex;
  flex-direction: column;
}

.report-history-row .history-left .report-customer {
  font-size: 13px;
  font-weight: 500;
}

.report-history-row .history-left .report-date {
  font-size: 10px;
  font-weight: 500;
  color: #b8b8b8;
}

.report-history-row .history-right {
  display: flex;
  column-gap: 10px;
}

.report-history-row .history-right .report-type {
  font-size: 12px;
  font-weight: 600;
}

.report-history-row .history-right .report-download {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.report-dashboard .report-dashboard-right {
  height: 100%;
  width: 75%;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.report-dashboard-right .report-scheduler-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: hsla(0, 0%, 100%, 0.85);
  z-index: 10000;
  display: flex;
  justify-content: center;
}

.report-scheduler {
  width: 440px;
  padding: 15px 10px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 20px;
  height: 500px;
  background-color: var(--white);
}

.report-scheduler .report-scheduler-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-scheduler .report-scheduler-title {
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  height: 20px;
}

.report-scheduler-header .report-scheduler-close {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-scheduler .report-scheduler-content {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 30px);
}

.report-scheduler .report-scheduler-inputs {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.report-scheduler .report-section-button {
  align-self: flex-end;
}

.report-dashboard-right .report-schedule {
  width: 600px;
  height: 100%;
  padding: 5px 10px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  border-radius: 15px;
}

.report-schedule .report-schedule-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  row-gap: 10px;
}

.report-schedule .report-schedule-loading > p {
  font-size: 13px;
  font-weight: 600;
}

.report-schedule .report-schedule-header {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.report-schedule-header .report-schedule-title {
  font-weight: 600;
  font-size: 14px;
}

.report-schedule-header .report-schedule-add {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.report-schedule .report-schedule-content {
  width: 100%;
  padding-top: 10px;
  height: calc(100% - 50px);
}

.report-schedule-content .report-schedule-empty {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.report-schedule-content .report-schedule-empty > p {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

.report-schedule-content .report-schedule-customer {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f5f5f5;
  width: 280px;
  padding: 0 10px;
  border-radius: 5px;
}

.report-schedule-customer input {
  background-color: transparent;
  height: 100%;
  width: 80%;
  outline: none;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  padding: 10px 0px;
  border: none;
  outline: none;
}

.report-schedule-customer .filter-icon {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.report-schedule-content .report-schedule-table {
  width: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  margin-top: 20px;
  max-height: 90%;
  overflow-y: scroll;
}

.report-schedule-table table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.report-schedule-table thead {
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.report-schedule-table table thead tr td {
  font-size: 11px;
  padding: 6px 10px;
  font-weight: 700;
}

.report-schedule-table table thead tr td.name {
  width: 160px;
}

.report-schedule-table table thead tr td:last-child {
  width: 40px;
}

.report-schedule-table table tbody tr {
  border-bottom: 1px solid #f5f5f5c5;
}

.report-schedule-table table tbody tr td {
  font-size: 13px;
  padding: 10px 10px;
  font-weight: 500;
}

.report-schedule-table table tbody tr td:nth-child(1) {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.report-schedule-table table tbody tr td:nth-child(3) {
  font-weight: 500;
}

.report-schedule-table table tbody tr td:last-child {
  width: 40px;
  padding: 6px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* responsive ui */

@media only screen and (max-width: 768px) {
  .report-dashboard {
    flex-direction: column;
    row-gap: 20px;
  }

  .report-dashboard .report-dashboard-left{
    width: 100%;
  }
  
  .report-dashboard-left .report-generate{
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
    padding-bottom: 10px;
  }

  .report-generate-header .report-generate-title,.report-schedule-header .report-schedule-title,.report-history .report-history-title{
    font-size: 15px;
    font-weight: 600;
  }


  .report-dashboard .report-dashboard-right{
    width: 100%;
    flex-direction: column;
    row-gap: 20px;
  }

  .report-dashboard-right .report-schedule{
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .report-scheduler{
    padding: 15px 15px;
  }

  .report-generate .report-generate-content{
    padding-top: 20px;
  }

  .report-schedule .report-schedule-content{
    padding: 0;
    height: auto;
    max-height: 300px;
  }

  .report-schedule-content .report-schedule-customer{
    display: none;
  }

  .report-schedule-content .report-schedule-empty{
    padding-top: 40px;
  }

  .report-schedule-content .report-schedule-empty > p,.report-history-content .report-history-empty > p {
    font-size: 15px;
  }

  .report-schedule-content .report-schedule-table{
    margin-top: 10px;
  }

  .report-dashboard-right .report-history{
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .report-history .report-history-header{
    padding: 0;
  }

  .report-history .report-history-content{
    padding: 0 0px 0 0;
    margin-top: 10px;
    height: auto;
    height: 100%;
  }

  .report-history .report-history-content::-webkit-scrollbar{
    display: none;
  }

  .report-scheduler{
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: 1000;
    background-color: white;
  }

  .report-scheduler .report-scheduler-content{
    justify-content: flex-start;
    row-gap: 40px;
  }

  .report-schedule-content .report-schedule-table{
    border: none;
  }

  .report-schedule-table .report-schedule-row{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;
  }

  .report-schedule-table .report-schedule-row>div{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .report-schedule-table .report-schedule-row>div:nth-child(1){
    font-weight: 700;
  }

  .report-schedule-table .report-schedule-row>div:nth-child(2){
    font-weight: 500;
    font-size: 13px;
  }

  .report-schedule-table .report-schedule-row>div:nth-child(3){
    justify-content: flex-end;
    font-weight: 600;
    font-size: 13px;
  }


  .report-history-row .history-left .report-customer{
    font-size: 14px;
    font-weight: 600;
  }

  .report-history-row .history-left .report-date{
    font-size: 12px;
    color: var(--font-primary);
  }

  .report-history-row .history-right .report-type{
    display: none;
  }

}
