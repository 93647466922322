@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: 500;

  color-scheme: light dark;
  color: #192b40;
  background-color: #FFFFFF;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #e8e8e8;
}

::-webkit-scrollbar-thumb:hover {
  background: #e8e8e8;
}
