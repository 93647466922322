.swift-kyc-management {
    width: 100%;
    height: 95%;
    padding: 30px 100px 0;
}

.client-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    padding-top: 30px;
    height: 90%;
}

.client-content .client-content-left {
    width: 40%;
    height: 100%;
}

.client-content-left .kyc-message {
    font-size: 13px;
    font-weight: 500;
    padding-right: 30px;
}

.client-content-left .client-info {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.client-content-left .client-info p {
    font-size: 12px;
    font-weight: 500;
}

.client-content-left .client-info p span {
    font-size: 14px;
    font-weight: 700;
}

.client-content .client-content-right {
    width: 600px;
    height: 100%;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.swift-kyc-management .client-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.swift-kyc-management .client-header .client-title {
    font-size: 18px;
    color: var(--font-primary);
    font-weight: 800;
}

.client-content .first-step-container {
    width: 350px;
}

.first-step-container .step-header {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.first-step-container .step-header h1 {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
}

.first-step-container .step-header p {
    font-size: 22px;
    font-weight: 300;
    line-height: 22px;
}

.first-step-container .client-select {
    width: 260px;
    margin-top: 20px;
}

.first-step-container .step-button {
    margin-top: 20px;
}

.client-content .client-sub-form {
    width: 100%;
    height: 90%;
    padding: 20px 25px;
    overflow-y: scroll;
}

.client-sub-form .sub-form-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.sub-form-header p {
    font-size: 14px;
    font-weight: 800;
}

.client-sub-form .account-holder-select {
    width: 300px;
}

.client-sub-form .sub-primary-form,
.client-sub-form .sub-nomination-form {
    margin-top: 40px;
    width: 100%;
    border-radius: 5px;
}

.sub-primary-form .title,
.sub-nomination-form .title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
}

.sub-primary-form .flex-row,
.sub-nomination-form .flex-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.sub-primary-form .flex-row .flex-item,
.sub-nomination-form .flex-row .flex-item {
    width: 48%;
}

.sub-primary-form .flex-row .flex-item .custom-select,
.sub-nomination-form .flex-row .flex-item .custom-select {
    height: 80px;
}

.sub-primary-form .primary-form-input-select,
.sub-nomination-form .primary-form-input-select {
    width: 300px;
}

.sub-primary-form .primary-form-input-select.select-box,
.sub-nomination-form .primary-form-input-select.select-box {
    height: 80px;
}



.client-sub-form .sub-submit {
    display: flex;
    margin-top: 20px;
}

.sub-nomination-form .sub-nominee-form {
    margin-top: 20px;
}

.sub-nominee-form .nominee-form-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.sub-nomination-form .nominee-number {
    font-size: 13px;
    font-weight: 600;
}

.sub-nomination-form .nominee-add,
.sub-nomination-form .nominee-remove {
    background-color: var(--font-primary);
    padding: 6px 10px;
    border-radius: 3px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    transition: 0.3s;
}

.sub-nomination-form .nominee-add:hover,
.sub-nomination-form .nominee-remove:hover {
    opacity: 0.95;
}

.sub-nomination-form .nominee-add span,
.sub-nomination-form .nominee-remove span {
    font-size: 12px;
    font-weight: 500;
}

.client-content-right .sub-submit {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 25px;
    border-top: 1px solid #f5f5f5;
}

.client-content-right .sub-submit button {
    padding: 8px 20px;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 13px;
    font-weight: 700;
    background-color: var(--font-primary);
    color: var(--white);
}

.sub-primary-form .pan-upload-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.client-content-right .upload-pan-wrapper {
    width: 70%;
    height: 50px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    border: 1px solid #f5f5f5;
    padding: 0 5px;
}

.upload-pan-wrapper .choose-button,
.view-pan-button {
    background-color: var(--font-primary);
    border: none;
    outline: none;
    color: var(--white);
    font-size: 12px;
    font-weight: 600;
    padding: 0px 10px;
    border-radius: 3px;
    height: 30px;
    cursor: pointer;
}

.view-pan-button a {
    color: var(--white);
    text-decoration: none;
}

.upload-pan-wrapper .file-name {
    font-size: 13px;
    font-weight: 500;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload-pan-wrapper .file-name.light {
    color: #ccc;
    font-weight: 500;
}

.pan-upload-btn .upload-button {
    background-color: var(--font-primary);
    border: none;
    outline: none;
    color: var(--white);
    font-size: 12px;
    font-weight: 600;
    padding: 0px 20px;
    border-radius: 3px;
    height: 30px;
    cursor: pointer;
}