.swift-account-display-form {
  height: calc(100vh - 40px);
  padding: 5px 0px;
  border-radius: 30px;
}
.main {
  padding: 0px;
}

.swift-account-display-form .swift-account-display-form-row {
  display: flex;
  flex-direction: row;
  column-gap: 5%;
  padding-right: 30px;
}
.swift-account-display-form .custom-select,
.swift-account-display-form .swift-input-box {
  width: 15%;
}
.swift-account-display-form .swift-account-btn.extra-btn {
  color: #011627;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
  border: none;
  border-radius: 35px;
  background-color: #f0f0f0;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 25px;
}
.swift-account-display-form .login-footer.extra {
  justify-content: flex-end;
  column-gap: 50px;
  padding-top: 10px;
  padding-right: 30px;
}

.admin-display-form {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
}

.popup-customer_id-form {
  height: fit-content !important;
}
