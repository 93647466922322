.portfolio__container__loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
}

.portfolio__container__loader > p {
  font-size: 14px;
  font-weight: 500;
}

.portfolio-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.portfolio-header .portfolio-title {
  font-size: 18px;
  color: rgba(1, 22, 39, 0.9) 98;
  /* color: var(--font-primary); */
  font-weight: 700;
}

.portfolio-header .portfolio-subtext {
  font-size: 20px;
  color: var(--font-primary);
  font-weight: 300;
  margin-top: -2px;
}

.portfolio__blur {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.85);
}

.portfolio__container {
  width: 100%;
  height: 90%;
  padding: 0 0px;
}

.strategy-content .portfolio__container {
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
}

.portfolio__container.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.portfolio__container.hide {
  display: none;
}

.portfolio__container.loader p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  color: var(--font-primary);
}

.portfolio__container.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  row-gap: 0px;
}

.portfolio__container.empty p {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-top: 8px !important;
  color: var(--font-primary);
  font-weight: 500 !important;
}

.strategy-content .portfolio__options {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0px 0 10px;
}

.strategy-content .portfolio__options.strategy .portfolio__search__bar {
  width: 500px;
}

.strategy-content .portfolio__options.full {
  height: 70px;
}

.portfolio__options .portfolio__selection {
  display: flex;
  column-gap: 10px;
}

.portfolio__selection .portfolio__customer {
  width: 300px;
}

.portfolio__selection .portfolio__select {
  width: 200px;
}

.portfolio__container .portfolio__options .custom-select {
  box-shadow: none;
}

.portfolio__container .portfolio__options .custom-select .custom-select-title {
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px !important;
}

.portfolio__container .portfolio__options .custom-select .custom-select-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px !important;
}

.portfolio__container .portfolio__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.portfolio__container .portfolio__menu .portfolio__update {
  height: 40px;
  width: 550px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
}

.portfolio__search__bar {
  display: flex;
  align-items: flex-end;
  width: 340px;
}

.portfolio__search__bar .stock__search {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  width: 100%;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  position: relative;
  border-radius: 5px;
  margin-left: 10px;
  padding: 0 10px;
}

.portfolio__search__bar .stock__search__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio__search__bar .stock__search img {
  width: 16px;
}

.portfolio__search__bar .stock__search input {
  border: none;
  outline: none;
  width: 100%;
  height: 90%;
  padding: 12px 10px;
  color: var(--font-primary);
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase !important;
  background: transparent;
}

.portfolio__search__bar .stock__search input::placeholder {
  color: #808080;
  text-transform: capitalize;
}

.portfolio__search__bar .stock__search .stock__suggestions {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 100%;
  left: -0.5%;
  margin-top: 3px;
  z-index: 1000;
  border-radius: 5px;
  max-height: 250px;
  overflow-y: scroll;
  box-shadow: 1px 1px 20px rgb(0 0 0 / 5%);
  /* display: none; */
}

.portfolio__search__bar .stock__suggestions .search__loader {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio__search__bar .stock__search .stock__suggestions .search__loader p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  display: flex;
  color: var(--font-primary);
}

.portfolio__search__bar .stock__search .stock__suggestions p {
  padding: 8px 20px 8px 20px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  color: var(--font-primary);
}

.portfolio__search__bar .stock__search .stock__suggestions p.active {
  background-color: #dbd9d9;
}

.portfolio__search__bar
  .stock__search
  .stock__suggestions
  p
  span.search__highlight {
  font-weight: 500;
}

.portfolio__search__bar
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(1) {
  flex: 3;
  font-size: 12px;
  font-weight: 600;
  color: var(--font-primary);
}

.portfolio__search__bar
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(2) {
  flex: 7;
  font-size: 11px;
  font-weight: 500;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--font-primary);
  letter-spacing: -5px;
}

.portfolio__search__bar
  .stock__search
  .stock__suggestions
  p
  > span:nth-child(3) {
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  color: var(--font-primary);
}

.portfolio__search__bar .stock__search .stock__suggestions p:hover {
  background-color: #f7f7f7;
}

.strategy-content .portfolio__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  column-gap: 20px;
  padding: 0px 0px;
}

.portfolio__header .portfolio__info__box {
  background-color: transparent;
  padding: 2px 0px;
  border-radius: 4px;
  width: auto;
  align-items: center;
  justify-content: space-between;
  display: flex;
  transition: 0.3s;
  /* width: 110px; */
}

.portfolio__info__box .portfolio__info__title {
  width: 30%;
  display: flex;
  justify-content: center;
}

.portfolio__info__box .portfolio__info__title img {
  width: 24px;
}

.portfolio__info__box .portfolio__info__title span {
  font-size: 12px;
  color: var(--font-primary);
  display: inline-block;
  margin-left: 5px;
  align-self: flex-end;
}

.portfolio__header .portfolio__info__box .portfolio__info__value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4px;
}

.portfolio__header .portfolio__info__box .portfolio__info__value > div {
  display: flex;
}

.portfolio__header .portfolio__info__box .portfolio__info__value > div > span {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.portfolio__info__head {
  font-size: 11px;
  font-weight: 500;
}

.portfolio__header
  .portfolio__info__box
  .portfolio__info__value
  .portfolio__info__name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
}

.portfolio__header
  .portfolio__info__box
  .portfolio__info__value
  .portfolio__trade__value {
  font-size: 12px;
  display: inline-block;
  margin-left: 5px;
}

.portfolio__info__box .portfolio__info__value .portfolio__info__value__change {
  display: flex;
}

.portfolio__info__box .portfolio__info__value .portfolio__change__value span {
  font-size: 14px;
}

.portfolio__info__box .portfolio__info__value .portfolio__change__per {
  margin-left: 5px;
}

.portfolio__info__box .portfolio__info__value .portfolio__change__per span {
  font-size: 14px !important;
}

.portfolio__container .portfolio__table__container {
  height: 100%;
  padding: 0 0px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.portfolio__container .portfolio__table__container.hide {
  display: none;
}

.portfolio__container .portfolio__table {
  height: calc(100% - 80px);
}

.portfolio__container .portfolio__table.full {
  height: 100%;
}

.portfolio__table.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.portfolio__table.loader p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  color: var(--font-primary);
}

.portfolio__container .portfolio__table .empty__table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
}

.portfolio__container .portfolio__table .empty__table > p {
  font-size: 13px;
  font-weight: 600;
}

.portfolio__container .portfolio__table .table__content {
  width: 100%;
  max-height: 100%;
  border-radius: 15px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  overflow-y: scroll;
}

.portfolio__container .portfolio__table table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.portfolio__container .portfolio__table table thead {
  /* background-color: #fcfcfc; */
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.portfolio__container .portfolio__table table thead tr td {
  text-align: left;
  padding: 8px 10px 8px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
}

.portfolio__container .portfolio__table table thead tr td.ordertype {
  width: 150px;
}

.portfolio__container .portfolio__table table thead tr td.name {
  width: 180px;
}

.portfolio__container .portfolio__table table thead tr td.return.shrink,
.portfolio__container .portfolio__table table thead tr td.today.shrink,
.portfolio__container .portfolio__table table thead tr td.order.shrink {
  width: 70px;
}

.portfolio__container .portfolio__table table thead tr td.portweight.shrink,
.portfolio__container .portfolio__table table thead tr td.quantity.shrink,
.portfolio__container .portfolio__table table thead tr td.tradevol.shrink {
  width: 120px;
}

.portfolio__container .portfolio__table table thead tr td.dynamicfield {
  display: none;
}

.portfolio__container .portfolio__table table thead tr td.addorder,
.portfolio__container .portfolio__table table thead tr td.removeorder,
.portfolio__container .portfolio__table table thead tr td.deleteorder {
  width: 40px;
}

.portfolio__container .portfolio__table table thead tr td.currprice,
.portfolio__container .portfolio__table table thead tr td.quantity,
.portfolio__container .portfolio__table table thead tr td.order {
  font-weight: 700;
}

.portfolio__container .portfolio__table table thead tr td.quantity {
  width: 70px;
}

.portfolio__container .portfolio__table table thead tr td.buyprice,
.portfolio__container .portfolio__table table thead tr td.currprice,
.portfolio__container .portfolio__table table thead tr td.cost,
.portfolio__container .portfolio__table table thead tr td.currvalue {
  width: 120px;
}

.portfolio__container .portfolio__table table thead tr td.order {
  width: 150px;
}

.portfolio__container .portfolio__table table thead tr td.tradevol {
  width: 100px;
}

.portfolio__container .portfolio__table table tbody tr {
  cursor: default;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid #f5f5f5;
}

.portfolio__container .portfolio__table table tbody tr td {
  /* vertical-align: top; */
  padding: 15px 0;
}

.portfolio__container .portfolio__table table tbody tr td > div {
  padding: 0px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.portfolio__container .portfolio__table table tbody tr td > div.ordertype-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 6px;
  cursor: pointer;
}

.portfolio__container
  .portfolio__table
  table
  tbody
  tr
  td
  > div.ordertype-box
  p {
  color: var(--text-color-blur);
  font-size: 12px;
}

.portfolio__container .portfolio__table table tbody tr td.portweight > div {
  display: flex;
  align-items: center;
}

.portfolio__container .portfolio__table table tbody tr td.portweight span {
  font-size: 10px;
}

.portfolio__container .portfolio__table table tbody tr td.currprice,
.portfolio__container .portfolio__table table tbody tr td.quantity,
.portfolio__container .portfolio__table table tbody tr td.order {
  font-weight: 700;
}

.portfolio__container .portfolio__table table tbody tr td.order .input-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 2px;
  padding: 0 10px;
  column-gap: 5px;
}

.portfolio__container .portfolio__table table tbody tr td.order-item-broker .value-select-content{
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
}

.portfolio__container
  .portfolio__table
  table
  tbody
  tr
  td.order
  .input-box
  input {
  width: 90%;
  background-color: transparent;
  color: var(--font-primary);
  border: none;
  outline: none;
  border-radius: 3px;
  padding: 0px 0px 0px;
  font-size: 13px;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
}

/* .portfolio__container .portfolio__table table tbody tr td.order .input-box .input-arrows{
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 0px;
    row-gap: 0px;
} */

.portfolio__container
  .portfolio__table
  table
  tbody
  tr
  td.order
  .input-box
  span {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
  background-color: #f5f5f5;
  font-size: 10px;
}

.portfolio__container
  .portfolio__table
  table
  tbody
  tr
  td.order
  .input-box
  span:first-child {
  margin-right: 5px;
  font-weight: 600;
}

.portfolio__container .portfolio__table table tfoot {
  box-shadow: 0px -1px 1px #f5f5f5, 0px 5px 5px #f5f5f5;
  position: sticky;
  left: 0;
  bottom: 0;
  background-color: var(--white);
}

.portfolio__container .portfolio__table table tfoot tr td {
  font-size: 14px;
  padding: 12px 10px 12px;
  color: var(--font-primary);
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 20px;
}

.portfolio__container .portfolio__table table tbody .portfolio__stock__code {
  display: flex;
  align-items: center;
}

.portfolio__container
  .portfolio__table
  table
  tbody
  .portfolio__stock__code
  .stock__status {
  width: 5px;
  height: 5px;
  background-color: var(--main-blue);
  border-radius: 100%;
}

.portfolio__container .portfolio__table table tbody .portfolio__stock__code .stock__code {
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 600;
  /* margin-left: 3px; */
}

.portfolio__container .portfolio__table table tbody .portfolio__stock__name {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color-blur);
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.portfolio__container .portfolio__table .increase-circle {
  color: var(--font-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 3px;
  width: 100%;
}

/* 
.portfolio__container .portfolio__table .increase-circle.positive
{
    background-color: var(--main-blue);
}

.portfolio__container .portfolio__table .increase-circle.negative
{
    background-color: var(--main-red);
} */

.portfolio__container .portfolio__table .increase-circle > img {
  width: 100%;
}

.portfolio__container .trade__value__section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.portfolio__container .trade__value {
  display: flex;
  grid-column-gap: 30px;
  column-gap: 30px;
}

.portfolio__container .trade__value p {
  border-radius: 5px;
  padding: 0px 0px;
  font-size: 12px;
  font-weight: 500;
}

.portfolio__container .total__trade__value {
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  color: var(--font-primary);
  font-weight: 700;
  margin: 5px 10px 0 0;
}

.portfolio__container .total__trade__value > span {
  margin-left: 4px;
}

.portfolio__buttons {
  /* position: absolute; */
  width: 100%;
  height: 60px;
  /* top: calc(100% - 60px); */
  /* bottom: 3%;
    left: 0%; */
  padding: 0 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
}

.portfolio__buttons button {
  /* margin: 0 10px 0 10px; */
  /* border : 1px solid #e8e8e8; */
  background-color: var(--white);
  /* padding: 8px 15px 8px 15px; */
  font-size: 12px;
  color: var(--font-primary);
  font-weight: 500;
  border-radius: 3px;
  transition: 0.3s;
  border: none;
  outline: none;
}

.portfolio__buttons button:hover {
  font-weight: 500;
}

.portfolio__analysis__wrapper {
  width: 100%;
  height: calc(100% - 0px);
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  border: 1px solid #f5f5f5;
  border-radius: 15px;
  padding: 0px 15px;
}

.portfolio__analysis__wrapper > p {
  font-size: 13px;
  font-weight: 600;
}

.portfolio__analysis__wrapper.hide {
  display: none;
}

.portfolio__analysis__wrapper__blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.85);
}

.portfolio__analysis__wrapper__blur.hide {
  display: none;
}

.portfolio__analysis__wrapper.loader {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0px;
}

.portfolio__analysis__wrapper.hide {
  display: none;
}

.portfolio__analysis__wrapper.loader > p {
  font-size: 14px;
  color: var(--font-primary);
  margin-top: 10px !important;
  font-weight: 500;
}

.portfolio__analysis__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1%;
}

.portfolio__analysis__header > span {
  color: var(--font-primary);
  font-size: 14px;
  font-weight: 500;
}

.portfolio__analysis__header > div {
  font-size: 12px;
  cursor: pointer;
  color: var(--font-primary);
}

.portfolio__analysis__options {
  padding: 0 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.portfolio__download__button {
  background-color: var(--white);
  color: var(--font-primary);
  border: 1px solid #f5f5f5;
  font-size: 12px;
  font-weight: 700;
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 35px;
  padding: 0 10px;
  border-radius: 5px;
}

.portfolio__download__button .button__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio__analysis__container {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  padding: 0 0px;
  margin-top: 0px;
}

.portfolio__analysis__container .portfolio__charts {
  width: 100%;
}

.portfolio__analysis__container .portfolio__data {
  width: 45%;
  height: 100%;
  overflow-y: scroll;
  padding: 0 10px;
}

.portfolio__analysis__options .portfolio__info {
  display: flex;
  align-items: center;
  margin-top: 10px;
  column-gap: 20px;
}

.portfolio__analysis__options .portfolio__info .portfolio__detail__box {
  display: flex;
  flex-direction: column;
}

.portfolio__info .portfolio__detail__box > span:first-child {
  font-size: 12px;
  font-weight: 500;
}

.portfolio__info .portfolio__detail__box > span:last-child {
  font-size: 12px;
  font-weight: 600;
}

.portfolio__charts .portfolio__charts__nav {
  width: 100%;
  margin-top: 0px;
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;
  justify-content: space-between;
}

.portfolio__charts .portfolio__charts__nav > span {
  font-size: 13px;
  color: var(--font-primary);
  font-weight: 600;
}

.portfolio__charts .portfolio__charts__nav .benchmark__select {
  width: 260px;
}

/* .portfolio__charts .portfolio__charts__nav .custom-select {
    width: 160px !important;
    height: 35px !important;
    box-shadow: none;
}

.portfolio__charts .portfolio__charts__nav .custom-select .custom-select-header .custom-select-title {
    font-size: 11px !important;
}

.custom-select .custom-select-container .custom-select-option {
    font-size: 11px !important;
}

*/
.portfolio__charts .portfolio__charts__baseline,
.portfolio__charts .portfolio__charts__pie {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.portfolio__charts .portfolio__charts__pie .strategy__sector__chart {
  width: 100%;
  height: 100%;
}

.portfolio__charts .portfolio__charts__wrapper {
  width: 90%;
  overflow-y: scroll;
  display: flex;
  column-gap: 40px;
}

.portfolio__charts .portfolio__charts__wrapper::-webkit-scrollbar {
  display: none;
}

.portfolio__charts .portfolio__charts__wrapper .charts {
  width: 620px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 10px 0px;
}

.portfolio__charts .portfolio__charts__wrapper .charts > p {
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px !important;
  color: var(--font-primary);
  font-weight: 600;
}

.portfolio__analysis__container .portfolio__data > div {
  margin-bottom: 20px;
}

.portfolio__analysis__container .portfolio__data > div .table__heading {
  font-size: 13px;
  color: var(--font-primary);
  margin-bottom: 10px;
  font-weight: 600;
}

.portfolio__data .table__wrapper {
  border-radius: 3px;
  border: 1px solid #f5f5f5;
  padding: 5px 5px;
}

.portfolio__data table thead tr {
  border-bottom: 1px solid #e8e8e8;
}

.portfolio__data table thead tr td {
  font-size: 12px;
  padding: 0px 12px 5px;
  color: var(--font-primary);
  font-weight: 600;
}

.portfolio__data table tbody tr th {
  font-weight: 500;
  font-size: 12px;
  width: 100px;
  text-align: left;
  padding: 5px 5px;
  color: var(--font-primary);
}

.portfolio__data table tbody tr td {
  font-weight: 500;
  font-size: 12px;
  padding: 5px 12px;
  color: var(--font-primary);
}

@media only screen and (max-width: 768px) {
  .portfolio__container {
    width: 100%;
    height: 100%;
    padding: 10px 0px;
  }

  .portfolio__container__loader > p{
    font-size: 16px;
    font-weight: 600;
  }

  .portfolio__container.loader {
    min-height: 300px;
  }

  .portfolio__container.loader p {
    font-size: 12px !important;
  }

  .portfolio__container .portfolio__options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .portfolio__select {
    width: 100%;
  }

  .portfolio__container .portfolio__options .custom-select {
    width: 100% !important;
    height: 45px !important;
  }

  .portfolio__container
    .portfolio__options
    .custom-select
    .custom-select-title {
    width: 200px;
    font-size: 11px !important;
  }

  .portfolio__container
    .portfolio__options
    .custom-select
    .custom-select-option {
    font-size: 11px !important;
    width: 100% !important;
  }

  .portfolio__container .portfolio__menu {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
    row-gap: 10px;
  }

  .portfolio__container .portfolio__menu .portfolio__update {
    margin-top: 10px;
    margin-left: 0px;
    height: 45px;
    width: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
  }

  .portfolio__container .portfolio__menu .portfolio__update .stock__search {
    padding: 0 5px;
  }

  .portfolio__container .portfolio__menu .portfolio__update .stock__search img {
    width: 14px;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    input {
    font-size: 10px;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions {
    border-radius: 5px;
    max-height: 250px;
    /* display: none; */
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    .search__loader {
    width: 100%;
    height: 250px;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    .search__loader
    p {
    font-size: 11px !important;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    p {
    padding: 4px 20px 4px 20px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    p
    > span:nth-child(1) {
    flex: 3;
    font-size: 10px;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    p
    > span:nth-child(2) {
    flex: 7;
    font-size: 8px;
    width: 160px;
  }

  .portfolio__container
    .portfolio__menu
    .portfolio__update
    .stock__search
    .stock__suggestions
    p
    > span:nth-child(3) {
    flex: 1;
    font-size: 10px;
  }

  
  .strategy-content .portfolio__options{
    height: auto;
    display: initial;
  }

  .strategy-content .portfolio__header {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 10px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
  }

  .portfolio__header .portfolio__info__box {
    display: flex;
    padding: 0px 0px;
    border-radius: 3px;
    margin: 0px 0;
    box-shadow: none;
    border: none;
  }

  .portfolio__info__box .portfolio__info__title img {
    width: 20px;
  }

  .portfolio__info__box .portfolio__info__title span {
    font-size: 12px;
    align-self: center;
  }

  .portfolio__info__head {
    font-size: 14px;
    font-weight: 500;
    color: var(--font-secondary);
  }

  .portfolio__info__box .portfolio__info__title {
    width: 5%;
    display: flex;
    justify-content: center;
    display: none;
  }

  .portfolio__header .portfolio__info__box .portfolio__info__value {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .portfolio__header .portfolio__info__box .portfolio__info__value > div > span {
    font-size: 16px;
    font-weight: 700;
  }

  .portfolio__header
    .portfolio__info__box
    .portfolio__info__value
    .portfolio__trade__value {
    font-size: 12px;
  }

  .portfolio__info__box
    .portfolio__info__value
    .portfolio__info__value__change {
    display: flex;
  }

  .portfolio__info__box .portfolio__info__value .portfolio__change__value span {
    font-size: 12px;
    margin-right: 2px;
    letter-spacing: -2px;
  }

  .portfolio__info__box .portfolio__info__value .portfolio__change__per span {
    font-size: 12px !important;
  }

  .strategy-content .portfolio__options.strategy .portfolio__search__bar{
    display: none;
  }

  .portfolio__container .trade__value__section{
    display: none;
  }

  .portfolio__analysis__wrapper {
    width: 100%;
    height: auto;
    padding: 0 10px 20px;
  }

  .portfolio__container .title{
    font-size: 12px;
    font-weight: 600;
    color: var(--font-secondary);
  }

  .portfolio__container .portfolio__table {
    margin: 0px auto 0;
    width: 100%;
    max-height: auto;
    padding: 0 0px;
  }

  .portfolio__container .portfolio__table table thead {
    top: 0%;
    z-index: 1;
    height: 30px;
    width: 100%;
  }

  .portfolio__container .portfolio__table table thead tr th {
    text-align: left;
    font-weight: 700;
    font-size: 10px;
  }

  .portfolio__container .portfolio__table table tbody {
    height: 90%;
    /* background-color: #666666; */
  }

  .portfolio__container .portfolio__table table tbody tr {
    cursor: default;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select {
    box-shadow: none;
    width: 80px !important;
    height: 25px !important;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select-header {
    padding: 0 3px !important;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select-header img {
    width: 14px;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select-title,
  .portfolio__container .portfolio__table table thead tr th .custom-select-option {
    font-size: 9px !important;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select-option {
    padding: 6px 8px !important;
  }

  .portfolio__container .portfolio__table table thead tr th .custom-select-container {
    min-width: 110% !important;
    height: 100px;
    overflow-y: scroll;
  }

  .portfolio__container .portfolio__table table thead tr th,
  .portfolio__container .portfolio__table table tbody tr td {
    display: none;
    font-size: 10px;
  }

  .portfolio__container .portfolio__table table thead tr th.active,
  .portfolio__container .portfolio__table table tbody tr td.active {
    display: table-cell;
    padding: 10px 2px;
    max-width: 80px;
    font-size: 11px;
  }

  .portfolio__container .portfolio__table table tbody tr:last-child {
    background-color: #ffffff;
    position: sticky;
    bottom: 0%;
    left: 0%;
    height: 30px;
    font-size: 9px;
  }

  .portfolio__container .portfolio__table table tbody tr:last-child td {
    font-size: 11px;
    font-weight: 700;
  }

  .portfolio__container .portfolio__table table tbody .portfolio__stock__code {
    display: flex;
    align-items: center;
  }

  .portfolio__container .portfolio__table table tbody .portfolio__stock__code .stock__status {
    width: 5px;
    height: 5px;
    background-color: var(--main-blue);
    border-radius: 100%;
  }

  .portfolio__container .portfolio__table table tbody .portfolio__stock__code .stock__code {
    font-size: 11px;
  }

  .portfolio__container .portfolio__table table tbody .portfolio__stock__name {
    width: 50px;
    font-size: 9px;
  }

  .portfolio__container .portfolio__table .increase-circle {
    font-size: 18px;
    width: 70%;
  }

  .portfolio__container .portfolio__table .portfolio-table-row{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0;
  }

  .portfolio__container .portfolio__table .portfolio-table-row .table-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .portfolio__container .portfolio__table .portfolio-table-row .table-row:nth-child(2),
  .portfolio__container .portfolio__table .portfolio-table-row .table-row:nth-child(3){
    font-size: 13px;
    color: var(--font-secondary);
    font-weight: 500;
  }

  .portfolio__container .portfolio__table .portfolio-table-row .table-row>div{
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  .portfolio__container .portfolio__table .portfolio-table-row .table-row:nth-child(1) span{
    font-weight: 700;
    font-size: 15px;
  }

  .portfolio__container .total__trade__value {
    display: flex;
    justify-content: flex-end;
    font-size: 9px;
    font-weight: 700;
    margin: 3px 10px 0 0;
  }

  .portfolio__buttons {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .portfolio__buttons > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .portfolio__buttons > div:first-child button {
    margin: 4px 0;
    font-weight: 700;
    padding: 0;
    width: 180px;
    height: 40px;
  }

  .portfolio__buttons > div:last-child {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .portfolio__buttons button {
    margin: 0px 0;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
  }

  .portfolio__analysis__header {
    margin-top: 20px;
  }

  .portfolio__analysis__header > div {
    font-size: 12px;
    /* padding: 4px 20px; */
    border-radius: 3px;
  }

  .portfolio__analysis__options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .portfolio__analysis__options .portfolio__download__button {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
  }

  .portfolio__analysis__options .portfolio__info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    column-gap: 10px;
  }

  .portfolio__analysis__options
    .portfolio__info
    .portfolio__info__left
    > div
    > img {
    width: 16px;
  }

  .portfolio__analysis__options
    .portfolio__info
    .portfolio__info__left
    > div
    > span {
    font-size: 11px;
    margin-left: 5px;
  }

  .portfolio__analysis__options .portfolio__info .portfolio__info__left > span {
    font-size: 11px;
  }

  .portfolio__analysis__options .portfolio__info .portfolio__info__right {
    margin-left: 0px;
  }

  .portfolio__analysis__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0%;
  }

  .portfolio__analysis__container .portfolio__charts {
    width: 100%;
    overflow-y: scroll;
  }

  .portfolio__analysis__container .portfolio__data {
    width: 100%;
    height: auto;
  }

  .portfolio__charts .portfolio__charts__nav > span {
    font-size: 14px;
  }

  .portfolio__charts .portfolio__charts__wrapper {
    height: 100%;
    overflow-y: scroll;
  }

  .portfolio__charts .portfolio__charts__wrapper .charts {
    padding: 5px 5px;
  }

  .portfolio__charts .portfolio__charts__wrapper .charts > p {
    width: 100%;
    font-size: 14px !important;
  }

  .portfolio__analysis__container .portfolio__data {
    padding: 0px 5px;
  }

  .portfolio__analysis__container .portfolio__data > div {
    margin-bottom: 10px;
  }

  .portfolio__analysis__container .portfolio__data > div .table__heading {
    font-size: 14px;
  }

  .portfolio__data .table__wrapper {
    padding: 5px 5px;
    overflow-x: scroll;
  }

  .portfolio__data table thead tr td {
    font-size: 12px;
  }

  .portfolio__data table tbody tr th {
    font-size: 12px;
    min-width: 100px;
  }

  .portfolio__data table tbody tr td {
    font-size: 12px;
    min-width: 100px;
  }

  .portfolio__container .portfolio__table .table__content{
    border: none;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }

  .portfolio__container .portfolio__table .table__content .content__row{
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0 10px;
  }

  .portfolio__container .portfolio__table .table__content .content__row .cont__row{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .portfolio__container .portfolio__table .table__content .content__row .cont__row>div{
    display: flex;
    column-gap: 6px;
  }

  .portfolio__container .portfolio__table .table__content .content__row .cont__row:nth-child(1){
    font-size: 14px;
    font-weight: 700;
  }
}
