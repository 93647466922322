.custom-select {
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  width: 100%;
}

.custom-select .select-title {
  font-size: 12px;
  font-weight: 500;
}

.custom-select .custom-select-header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  height: 40px;
  position: relative;
  cursor: pointer;
}

.custom-select .custom-select-header .custom-select-title {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.custom-select .custom-select-header .custom-width-select-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.custom-select .custom-select-header .custom-width-select-title > p {
  width: 60%;
  background-color: #404040;
  border-radius: 10px;
}

.custom-select .custom-select-container {
  /* padding: 0px 3px; */
  width: 100%;
  position: absolute;
  top: 105%;
  left: 0%;
  border-radius: 3px;
  z-index: 1000;
  border: 1px solid #f5f5f5;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 8px 0px;
  background-color: var(--white);
  padding: 10px 4px 10px;
}
.custom-select .overflow {
  max-height: 175px;
  overflow-y: auto;
}

.custom-select .custom-select-container .custom-select-option {
  display: block;
  font-size: 12px;
  font-weight: 600;
  padding: 12px 12px 12px 16px;
  border-radius: 3px;
  cursor: pointer;
  border-bottom: 1px solid #f5f5f5;
}

.custom-select .custom-select-container .custom-width-select-option {
  font-size: 13px;
  font-weight: 600;
  padding: 5px 10px;
  margin: 1px 0;
  border-radius: 3px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-select .custom-select-container .custom-width-select-option > p {
  border-radius: 10px;
  transition: 0.3s;
}

.custom-select .custom-select-container .custom-select-option.option-selected,
.custom-select
  .custom-select-container
  .custom-width-select-option.option-selected {
  color: var(--font-primary);
}

.custom-select
  .custom-select-container
  .custom-width-select-option.option-selected
  > p {
  background-color: #ffffff;
}

.custom-select
  .custom-select-container
  .custom-select-option.option-selected:hover,
.custom-select
  .custom-select-container
  .custom-width-select-option.option-selected:hover {
  background-color: #f9f9f9;
}

.custom-select
  .custom-select-container
  .custom-width-select-option.option-selected:hover
  > p {
  background-color: #ffffff;
}

.custom-select .custom-select-container .custom-select-option:hover {
  background-color: #f9f9f9;
  color: var(--font-primary);
}

.custom-select .custom-select-container .custom-width-select-option:hover {
  background-color: #f9f9f9;
  color: var(--font-primary);
}



@media only screen and (max-width: 768px){

  .custom-select{
    row-gap: 8px;
  }

  .custom-select .select-title{
    font-size: 14px;
    font-weight: 500;
  }

  .custom-select .custom-select-header .custom-select-title{
    font-size: 14px !important;
  }

  .custom-select .custom-select-container .custom-select-option {
    font-size: 14px;
  }

}