.modify-popup-overlay {
  position: fixed;
  inset: 0;
  background: #87888850;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
}

.modify-popup {
  z-index: 1000000;
  width: 40%;
  height: 60%;
  padding: 0 20px;
  border-radius: 5px;
  transform: translateY(8px);
  box-shadow: 0 0 14px 0 rgb(15 17 17 / 10%);
  /* box-shadow: 1px 3px 5px #e8e8e8; */
  background-color: var(--white);
  animation: getup 0.1s linear forwards;
}

@keyframes getup {
  0% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.modify-popup.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modify-header {
  width: 100%;
  height: 15%;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modify-title {
  width: 100%;
  display: flex;
  align-items: center;
}

.modify-title .title-name {
  display: flex;
  align-items: center;
}

.modify-title .title-price {
  margin-left: 20px;
  display: flex;
}

.title-price .title-price-value {
  font-size: 12px;
  font-weight: 600;
}

.title-price .title-price-change {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 600;
}

.modify-close img {
  width: 30px;
  cursor: pointer;
}

.modify-body {
  width: 100%;
  height: 60%;
}

.modify-body .modify-body-row {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.modify-body-row.modify-box {
  flex-wrap: wrap;
}

.modify-body-row .modify-type-box {
  width: 80px;
  height: 35px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--font-primary);
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
  color: var(--font-primary);
  cursor: pointer;
  transition: 0.2s;
  letter-spacing: 0.1px;
}

.modify-body-row.buy .modify-type-box:hover {
  background-color: #fdfdfd;
  color: var(--main-green);
  border-color: var(--main-green);
}

.modify-body-row.buy .modify-type-box.active {
  background-color: var(--main-green);
  border: none;
  color: var(--white);
}

.modify-body-row.sell .modify-type-box:hover {
  background-color: #fdfdfd;
  color: var(--main-red);
  border-color: var(--main-red);
}

.modify-body-row.sell .modify-type-box.active {
  background-color: var(--main-red);
  border: none;
  color: var(--white);
}

.modify-body-row .modify-error {
  display: none;
}

.modify-body-row .modify-error p {
  font-size: 12px;
  font-weight: 700;
  color: var(--main-red) !important;
}

.modify-footer {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: bisque; */
}

.modify-footer .modify-error p {
  font-size: 12px;
  font-weight: 700;
  color: var(--main-red) !important;
}

.modify-footer .modify-buttons {
  display: flex;
}

.modify-footer .modify-buttons .modify-button {
  width: 90px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
}

.modify-footer .modify-buttons .modify-button:hover {
  opacity: 0.9;
}

.modify-button.buy.disabled,
.modify-button.sell.disabled {
  opacity: 0.7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: not-allowed;
}

.modify-button.buy.disabled:hover,
.modify-button.sell.disabled:hover {
  opacity: 0.7;
}

.modify-button.buy {
  background-color: var(--main-green);
  color: var(--white);
}

.modify-button.sell {
  background-color: var(--main-red);
  color: var(--white);
}

.modify-button.close {
  background-color: var(--white);
  color: var(--font-primary);
  border: 1px solid var(--font-primary);
}

@media only screen and (max-width: 768px) {
  .modify-popup {
    /* inset: 0; */
    /* transform: translate(0%,0%); */
    z-index: 1000000;
    width: 100%;
    height: 100%;
  }

  .modify-header {
    padding: 0 0px;
    height: 10%;
  }

  .title-name .title-action {
    width: 70px;
    height: 30px;
    border-radius: 25px;
    font-size: 13px;
    margin-right: 10px;
  }

  .title-name .title-symbol {
    font-size: 16px;
    font-weight: 600;
  }

  .modify-body {
    width: 100%;
    height: 70%;
    padding: 0 0px;
  }

  .modify-body .modify-body-row {
    margin: 10px 0 20px;
  }

  .modify-body-row .modify-type-box {
    width: 70px;
    height: 30px;
    margin-right: 10px;
    font-size: 11px;
  }

  .modify-body-row.modify-box {
    justify-content: space-between;
  }

  .modify-body-row .modify-error {
    display: block;
  }

  .modify-footer {
    padding: 0 10px;
    justify-content: flex-end;
  }

  .modify-footer .modify-error {
    display: none;
  }

  .modify-footer .modify-buttons .modify-button {
    width: 80px;
    height: 30px;
    font-size: 12px;
  }
}
