/* input box */

.swift-input-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  height: 80px;
}

.swift-input-box.hide {
  opacity: 0;
}

.swift-input-box p {
  font-size: 12px;
  font-weight: 500;
}

.swift-input-box.disabled p {
  color: #ccc;
}

.swift-input-box .input-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.swift-input-box .input-header .primary-text {
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.swift-input-box .input-header .secondary-text {
  font-size: 12px;
  font-weight: 500;
}

.swift-input-box .input-header .primary-text:hover {
  color: rgb(120, 120, 120);
}

.swift-input-box input {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
  margin-top: 5px;
}

.swift-textarea-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  height: 140px;
}

.swift-textarea-box textarea {
  width: 100%;
  background-color: transparent;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
  margin-top: 5px;
  padding-top: 5px;
  height: 100px;
  resize: none;
}

.swift-textarea-box p {
  font-size: 12px;
  font-weight: 500;
}

.swift-input-box input::placeholder,
.swift-textarea-box textarea::placeholder {
  font-weight: 500;
}

.swift-input-box.disabled input,
.swift-textarea-box.disabled textarea {
  border: 1px solid #ccc;
  color: #ccc;
}

.swift-input-box input:focus,
.swift-textarea-box textarea:focus {
  border-color: #d8d8d8;
}

.swift-input-box .error-text {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  line-height: 12px;
}

.swift-input-box .error-text.hide {
  opacity: 0;
}

/* input box */

/* select box */
.swift-select-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  height: 80px;
}

.swift-select-box.hide {
  opacity: 0;
}

.swift-select-box p {
  font-size: 12px;
  font-weight: 500;
}

.swift-select-box select {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-select-box input::placeholder {
  font-weight: 500;
}

.swift-select-box .error-text {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  line-height: 12px;
}

.swift-select-box .error-text.hide {
  opacity: 0;
}

/* select box */

/* button */

.swift-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  min-width: 150px;
  padding: 10px 10px;
  width: fit-content;
  transition: 0.3s;
  color: var(--font-primary);
  cursor: pointer;
  background-color: #ffffff;
}

.swift-button.disabled {
  border-color: #ccc;
  color: #ccc;
  cursor: not-allowed;
}

.swift-button.hide {
  opacity: 0;
}

.swift-button:hover {
  background-color: #f5f5f5;
}

.swift-button p {
  font-size: 13px;
  font-weight: 500;
}

/* button */

/* icon button */

.swift-button-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  min-width: 150px;
  min-height: 40px;
  padding: 10px 10px;
  width: fit-content;
  transition: 0.3s;
  color: var(--font-primary);
  cursor: pointer;
  background-color: #ffffff;
  column-gap: 10px;
}

.swift-button-icon:hover {
  background-color: #f5f5f5;
}

.swift-button-icon.loading,
.swift-button-icon.loading:hover {
  cursor: not-allowed;
  background-color: #ffffff;
}

.swift-button-icon.success,
.swift-button-icon.success:hover {
  cursor: not-allowed;
  background-color: #ffffff;
}

.swift-button-icon.failure,
.swift-button-icon.failure:hover {
  cursor: not-allowed;
  background-color: #ffffff;
}

.swift-button-icon.disabled {
  border-color: #e8e8e8;
  color: #ccc;
  cursor: not-allowed;
}

.swift-button-icon.hide {
  opacity: 0;
}


.swift-button-icon .button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swift-button-icon p {
  font-size: 13px;
  font-weight: 500;
}

/* icon button */

/* custom date */

.swift-date-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  height: 80px;
}

.swift-date-wrapper input {
  width: 100%;
  height: 40px;
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: var(--font-primary);
}

.swift-date-title {
  font-size: 12px;
  font-weight: 500;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  margin: 0px;
  margin-right: 10px !important;
  cursor: pointer;
}

/* For CustomActionIconButton.jsx */

.swift-button-icon .circle {
  stroke-dasharray: 55;
  stroke-dashoffset: -55;
  animation: circle 0.4s linear;
  animation-fill-mode: forwards;
}

.swift-button-icon .tick {
  stroke-dasharray: 35;
  stroke-dashoffset: 35;
  animation: tick 1s ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}

@keyframes tick {
  from {
    stroke-dashoffset: 35;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes circle {
  0% {
    stroke-dashoffset: -55;
    fill: none;
  }
  99%{
    fill: none;
  }
  100% {
    stroke-dashoffset: 0;
  }
}


.swift-button-icon .cross {
  stroke-dasharray: 45;
  stroke-dashoffset: 45;
  animation: drawCross 1s ease-out forwards;
  animation-delay: 0.4s;
}

@keyframes drawCross {
  from {
    stroke-dashoffset: 45;
  }
  to {
    stroke-dashoffset: 0;
  }
}


/* swift info message */

.swift-info-message{
  position: relative;
  height: 100%;
}

.swift-info-message svg{
  cursor: pointer;
}

.swift-info-message .swift-info-message-box{
  position: fixed;
  z-index: 1000000;
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 5px;
  min-width: 100px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color-blur);
}


@media only screen and (max-width: 768px){
  .swift-input-box p {
    font-size: 14px;
    font-weight: 600;
  }

  .swift-input-box input{
    font-size: 15px;
  }

  .swift-button-icon p{
    font-size: 14px;
  }

  .swift-button p{
    font-size: 14px;
    font-weight: 600;
  }
}
